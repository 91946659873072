/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** number or string or date or boolean */
  AdvancedFilterValue: { input: string | number | boolean; output: string | number | boolean };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. BigInt can represent values between -(2^63) + 1 and 2^63 - 1. */
  BigInt: { input: number; output: number };
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  /** Vectice id scalar type */
  VecticeId: { input: string | number; output: string | number };
};

export enum AccessibilityLevel {
  Editor = 'Editor',
  Manager = 'Manager',
  ReadOnly = 'ReadOnly',
}

export type ActivityCount = {
  __typename?: 'ActivityCount';
  count: Scalars['Float']['output'];
  identifier: Scalars['String']['output'];
};

export type ActivityCountType = {
  identifier: Scalars['String']['input'];
  targetType: ActivityTargetType;
  type: ActivityType;
};

export type ActivityInsightsOutput = {
  __typename?: 'ActivityInsightsOutput';
  approvedPhasesCount: Scalars['Float']['output'];
  completedPhasesCount: Scalars['Float']['output'];
  completedProjectsCount: Scalars['Float']['output'];
  datasetVersionsCount: Scalars['Float']['output'];
  modelVersionsCount: Scalars['Float']['output'];
};

export type ActivityTargetFilter = {
  targetIds: Array<Scalars['VecticeId']['input']>;
  targetType: ActivityTargetType;
};

export enum ActivityTargetType {
  CdtReport = 'CDTReport',
  Code = 'Code',
  CodeVersion = 'CodeVersion',
  /** @deprecated Deprecated */
  DataResource = 'DataResource',
  DataSet = 'DataSet',
  DataSetVersion = 'DataSetVersion',
  Finding = 'Finding',
  Invite = 'Invite',
  Iteration = 'Iteration',
  IterationStep = 'IterationStep',
  Lineage = 'Lineage',
  Model = 'Model',
  ModelVersion = 'ModelVersion',
  Phase = 'Phase',
  Project = 'Project',
  Reply = 'Reply',
  Review = 'Review',
  ReviewComment = 'ReviewComment',
  StepDefinition = 'StepDefinition',
  Thread = 'Thread',
  Workspace = 'Workspace',
  WorkspaceUser = 'WorkspaceUser',
}

export enum ActivityType {
  AttachmentFile = 'AttachmentFile',
  Created = 'Created',
  Deleted = 'Deleted',
  Duplicated = 'Duplicated',
  Metrics = 'Metrics',
  Moved = 'Moved',
  Properties = 'Properties',
  Restored = 'Restored',
  Updated = 'Updated',
  UpdatedProp = 'UpdatedProp',
}

export enum ActivityUpdateType {
  Add = 'Add',
  AlgorithmName = 'AlgorithmName',
  Approval = 'Approval',
  Delete = 'Delete',
  Description = 'Description',
  Documentation = 'Documentation',
  DueDate = 'DueDate',
  Image = 'Image',
  InventoryReference = 'InventoryReference',
  LineageAdded = 'LineageAdded',
  LineageCodeUpdated = 'LineageCodeUpdated',
  LineageRemoved = 'LineageRemoved',
  ModelVersion = 'ModelVersion',
  MultipleAdd = 'MultipleAdd',
  MultipleDelete = 'MultipleDelete',
  MultipleEdit = 'MultipleEdit',
  Name = 'Name',
  Owner = 'Owner',
  Privacy = 'Privacy',
  Report = 'Report',
  Repository = 'Repository',
  Reviewer = 'Reviewer',
  Risk = 'Risk',
  Role = 'Role',
  Severity = 'Severity',
  Star = 'Star',
  Status = 'Status',
  Type = 'Type',
  Unknown = 'Unknown',
}

/** AdvancedFilter allows you to filter on multiple field / value. Works also with nested entities */
export type AdvancedFilter = {
  /** field you want to filter on. If field is a subEntity, you must precise nestedKey */
  field: Scalars['String']['input'];
  /** match is the filter itself. Mandatory if valuesWithMatch is undefined or empty. EXISTS and NOT_EXISTS don't work the same way if you're on a nested entity or in a field itself. On a field it applies an IS NULL or IS NOT NULL for a field. */
  match?: InputMaybe<MatchEnum>;
  /** nestedKey is mandatory when working on sub field. It represents the key you want to filter on and the value it must satisfy */
  nestedKey?: InputMaybe<KeyFieldValue>;
  /** nestedValue is mandatory when working on a sub field if match is different than EXISTS and NOT_EXISTS. It represents the column value you want to filter on and the value that must satify the match */
  nestedValue?: InputMaybe<ValueFieldValue>;
  /** values represents a list of values. Can be applied when wanting to apply the same match on multiple values at the same time */
  values?: InputMaybe<Array<Scalars['AdvancedFilterValue']['input']>>;
  /** valuesWithMatch is a list of values with specific match. Useful to filter on the same key multiple times */
  valuesWithMatch?: InputMaybe<Array<ValueMatchMap>>;
};

export type AlgorithmNameFiltersInput = {
  search: Scalars['String']['input'];
};

export type AlgorithmNameOrderInput = {
  direction: OrderDirection;
  field: Scalars['String']['input'];
};

export type AlgorithmNameOutput = {
  __typename?: 'AlgorithmNameOutput';
  algorithmName: Scalars['String']['output'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
  status: ApiKeyStatus;
  updatedDate: Scalars['DateTime']['output'];
  userId: Scalars['Float']['output'];
  version: Scalars['Float']['output'];
};

export type ApiKeyCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ApiKeyCreateOutput = {
  __typename?: 'ApiKeyCreateOutput';
  apiKey: ApiKey;
  key: Scalars['String']['output'];
};

export enum ApiKeyStatus {
  Corrupted = 'CORRUPTED',
  Deployed = 'DEPLOYED',
  Generated = 'GENERATED',
  Revoked = 'REVOKED',
}

export type ApiKeyUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ApiKeyStatus>;
};

export type Archive = {
  __typename?: 'Archive';
  itemCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export enum ArtifactType {
  Code = 'CODE',
  Dataset = 'DATASET',
  Model = 'MODEL',
}

export enum ArtifactVersion {
  Backend = 'BACKEND',
  Global = 'GLOBAL',
}

export enum ArtifactsStepStrategy {
  Excludes = 'EXCLUDES',
  Includes = 'INCLUDES',
}

export type AskAiAssetListFiltersInput = {
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type AskAiAssetResponse = {
  __typename?: 'AskAIAssetResponse';
  isStarred: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: AskAiAssetType;
  vecticeId: Scalars['String']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum AskAiAssetType {
  Dataset = 'DATASET',
  Model = 'MODEL',
  Phase = 'PHASE',
}

export enum AskAiSearchAssetType {
  All = 'ALL',
  DatasetVersion = 'DATASET_VERSION',
  ModelVersion = 'MODEL_VERSION',
  Phase = 'PHASE',
}

export type AssetListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  algorithmNames?: InputMaybe<Array<Scalars['String']['input']>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  dsTypes?: InputMaybe<Array<DatasetType>>;
  includeDisabledUsers?: InputMaybe<Scalars['Boolean']['input']>;
  inventoryReferences?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersionStatus?: InputMaybe<Array<ModelVersionStatus>>;
  repositorySearch?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  types?: InputMaybe<Array<ModelType>>;
};

export type AssetOriginOutput = {
  __typename?: 'AssetOriginOutput';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  vecticeId?: Maybe<Scalars['ID']['output']>;
};

export type AssetOriginsOutput = {
  __typename?: 'AssetOriginsOutput';
  iteration?: Maybe<AssetOriginOutput>;
  phase?: Maybe<AssetOriginOutput>;
};

export type AssetSearchResponse = {
  __typename?: 'AssetSearchResponse';
  dataset: PaginatedDataSetResponse;
  model: PaginatedModelResponse;
  project: PaginatedProjectResponse;
  user: PaginatedUserResponse;
};

export enum AssetType {
  Dataset = 'DATASET',
  Model = 'MODEL',
  Project = 'PROJECT',
  User = 'USER',
}

export type AssetsAskAiSearchResponse = {
  __typename?: 'AssetsAskAISearchResponse';
  all: PaginatedAskAiAssetResponse;
  datasetVersion: PaginatedDataSetVersionResponse;
  modelVersion: PaginatedModelVersionResponse;
  phase: PaginatedPhaseResponseOutput;
};

export type AttachmentOutput = {
  __typename?: 'AttachmentOutput';
  attachmentType: AttachmentTypeEnum;
  content?: Maybe<Scalars['JSONObject']['output']>;
  entityId: Scalars['Float']['output'];
  hasPreview?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Float']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: EntityMetadataTypeEnum;
};

export enum AttachmentTypeEnum {
  EntityFile = 'ENTITY_FILE',
  EntityMetadata = 'ENTITY_METADATA',
}

export enum AuthMethod {
  SdkAuth = 'SDK_AUTH',
  UserAuth = 'USER_AUTH',
}

export type BaseDocumentationDuplicateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BaseDocumentationListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  parentId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export type BaseIterationArtifactsFiltersInput = {
  withAssetDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BaseStepCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
};

export type BaseStepUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CdtGenerateMarkdownInput = {
  assets: Array<CdtReportAssetInput>;
  globalId?: InputMaybe<Scalars['String']['input']>;
  macroId?: InputMaybe<Scalars['Float']['input']>;
};

export type CdtReport = {
  __typename?: 'CDTReport';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  file: DocumentationFile;
  fileId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  modelVersion?: Maybe<ModelVersion>;
  modelVersionId?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['Float']['output'];
  template?: Maybe<CdtTemplate>;
  templateId?: Maybe<Scalars['Float']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type CdtReportAssetInput = {
  id: Scalars['VecticeId']['input'];
  name: Scalars['String']['input'];
  type: CdtTemplateAssetType;
};

export type CdtReportCreateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  modelVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  templateGlobalId?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['Float']['input']>;
};

export type CdtReportFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  modelVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
  projectId?: InputMaybe<Scalars['VecticeId']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export enum CdtReportQueryType {
  ModelVersion = 'MODEL_VERSION',
  Project = 'PROJECT',
}

export type CdtReportUpdateInput = {
  modelVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CdtTemplate = {
  __typename?: 'CDTTemplate';
  assets: Array<CdtTemplateAsset>;
  content: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  footerContent: Scalars['String']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  headerContent: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  isMacro: Scalars['Boolean']['output'];
  isRichText: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type CdtTemplateAsset = {
  __typename?: 'CDTTemplateAsset';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  templateId: Scalars['Float']['output'];
  type: CdtTemplateAssetType;
};

export type CdtTemplateAssetInput = {
  name: Scalars['String']['input'];
  type: CdtTemplateAssetType;
};

export enum CdtTemplateAssetType {
  DatasetVersion = 'DATASET_VERSION',
  Iteration = 'ITERATION',
  ModelVersion = 'MODEL_VERSION',
  Phase = 'PHASE',
  Project = 'PROJECT',
}

export type CdtTemplateCreateInput = {
  assets: Array<CdtTemplateAssetInput>;
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  footerContent?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  headerContent?: InputMaybe<Scalars['String']['input']>;
  isMacro?: InputMaybe<Scalars['Boolean']['input']>;
  isRichText?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type CdtTemplateFilterInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRichText?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export type CdtTemplateUpdateInput = {
  assets?: InputMaybe<Array<CdtTemplateAssetInput>>;
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  footerContent?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  headerContent?: InputMaybe<Scalars['String']['input']>;
  isMacro?: InputMaybe<Scalars['Boolean']['input']>;
  isRichText?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum CategoryType {
  Boolean = 'BOOLEAN',
  Categorical = 'CATEGORICAL',
  Date = 'DATE',
  Numerical = 'NUMERICAL',
  Other = 'OTHER',
  Text = 'TEXT',
}

export enum CloseReviewStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type Code = {
  __typename?: 'Code';
  author: User;
  authorId: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  uri?: Maybe<Scalars['String']['output']>;
  version: Scalars['Float']['output'];
};

export type CodeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type CodeFileVersion = {
  __typename?: 'CodeFileVersion';
  author: User;
  authorId: Scalars['Float']['output'];
  codeVersionId: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  mimeType: Scalars['String']['output'];
  path: DataResourcePath;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type CodeLineageUpdateInput = {
  firstAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  secondAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  thirdAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  type: CodeVersionType;
  url: Scalars['String']['input'];
};

export type CodeListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export type CodeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type CodeVersion = {
  __typename?: 'CodeVersion';
  author: User;
  authorId: Scalars['Float']['output'];
  code?: Maybe<Code>;
  codeFiles: Array<CodeFileVersion>;
  codeId?: Maybe<Scalars['Float']['output']>;
  createdDate: Scalars['DateTime']['output'];
  databricksVersion?: Maybe<DatabricksVersion>;
  databricksVersionId?: Maybe<Scalars['Float']['output']>;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gitVersion?: Maybe<GitVersion>;
  gitVersionId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  isStarred: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otherCodeVersion?: Maybe<OtherCodeVersion>;
  otherCodeVersionId?: Maybe<Scalars['Float']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  uri?: Maybe<Scalars['String']['output']>;
  version: Scalars['Float']['output'];
  versionNumber: Scalars['Float']['output'];
};

export enum CodeVersionAction {
  CreateGitVersion = 'CREATE_GIT_VERSION',
  CreateUserDeclaredVersion = 'CREATE_USER_DECLARED_VERSION',
}

export type CodeVersionCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type CodeVersionCreationBody = {
  action: CodeVersionAction;
  gitVersion?: InputMaybe<GitVersionCreateInput>;
  userDeclaredVersion: CodeVersionCreateInput;
};

export type CodeVersionRegisterInput = {
  databricksVersion?: InputMaybe<DatabricksVersionCreateInput>;
  gitVersion?: InputMaybe<GitVersionCreateInput>;
};

export enum CodeVersionType {
  Databricks = 'DATABRICKS',
  Git = 'GIT',
  Other = 'OTHER',
}

export type ColumnDescriptionInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ColumnOutput = {
  __typename?: 'ColumnOutput';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export enum ColumnSourceType {
  DbColumnSource = 'DBColumnSource',
  FileSourceColumn = 'FileSourceColumn',
}

export type ColumnTypeCount = {
  __typename?: 'ColumnTypeCount';
  categoryType: CategoryType;
  count: Scalars['Float']['output'];
};

export type ColumnTypeCountInput = {
  categoryType: CategoryType;
  count: Scalars['Float']['input'];
};

export type CommentSubscriptionOutput = {
  __typename?: 'CommentSubscriptionOutput';
  content: Scalars['String']['output'];
  fileId: Scalars['Float']['output'];
  isDeleted: Scalars['Boolean']['output'];
  reply?: Maybe<ThreadReply>;
  replyId?: Maybe<Scalars['Float']['output']>;
  status: ThreadStatus;
  thread?: Maybe<Thread>;
  threadId: Scalars['Float']['output'];
};

export type CommentUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export enum ConfigDeployment {
  Private = 'Private',
  Public = 'Public',
}

export type ContributorListFiltersInput = {
  activityTargetTypes?: InputMaybe<Array<ActivityTargetType>>;
  activityTypes?: InputMaybe<Array<ActivityType>>;
  includeWorkspaceMembers?: InputMaybe<Scalars['Boolean']['input']>;
  projectIds?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  searchFilter?: InputMaybe<SearchFilter>;
  targetFilter?: InputMaybe<ActivityTargetFilter>;
};

export type CreateEntityMetricInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['DateTime']['input'];
  value: Scalars['Float']['input'];
};

export type CreateEntityPropertyInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CreateSuperAdminUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type DbColumnSource = {
  __typename?: 'DBColumnSource';
  categoryType: CategoryType;
  createdDate: Scalars['DateTime']['output'];
  dataType: Scalars['String']['output'];
  dbTableSource: DbTableSource;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  isFK?: Maybe<Scalars['Boolean']['output']>;
  isPK?: Maybe<Scalars['Boolean']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  statistics?: Maybe<DataframeStatistics>;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type DbTableSource = {
  __typename?: 'DBTableSource';
  columnList: PaginatedDbSourceColumnsList;
  columnsNumber?: Maybe<Scalars['Float']['output']>;
  columnsSummary?: Maybe<Array<ColumnTypeCount>>;
  columnsTypesCounts: Array<ColumnTypeCount>;
  createdDate: Scalars['DateTime']['output'];
  datasetSource: DatasetSource;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  extraMetadata?: Maybe<Array<ExtraMetadata>>;
  fingerprint?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  /** relative path of the table from root of ds version */
  name: Scalars['String']['output'];
  rowsNumber?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
  skippedStatistics?: Maybe<Scalars['Boolean']['output']>;
  tableCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  tableUpdatedDate?: Maybe<Scalars['DateTime']['output']>;
  /** name of the table without any other path */
  tablename: Scalars['String']['output'];
  type: DbTableType;
  updatedDate: Scalars['DateTime']['output'];
  /** full path of the source */
  uri?: Maybe<Scalars['String']['output']>;
  version: Scalars['Float']['output'];
};

export type DbTableSourceColumnListArgs = {
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export type DbTableSourceInput = {
  columns?: InputMaybe<Array<DbColumnSourceInput>>;
  columnsNumber?: InputMaybe<Scalars['Float']['input']>;
  columnsSummary?: InputMaybe<Array<ColumnTypeCountInput>>;
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  extraMetadata?: InputMaybe<Array<ExtraMetadataInput>>;
  fingerprint?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rowsNumber?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['BigInt']['input']>;
  skippedStatistics?: InputMaybe<Scalars['Boolean']['input']>;
  tablename?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DbTableType>;
  updatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export enum DbTableType {
  Pandas = 'PANDAS',
  Spark = 'SPARK',
  Unknown = 'UNKNOWN',
}

export type DataResourcePath = {
  __typename?: 'DataResourcePath';
  id: Scalars['String']['output'];
  isFolder?: Maybe<Scalars['Boolean']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  type?: Maybe<TreeItemType>;
  uniqueId?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type DataSet = {
  __typename?: 'DataSet';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isPatternBase: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  lastVersion?: Maybe<DataSetVersion>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  pattern: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['Float']['output'];
  sourceOrigin: Scalars['String']['output'];
  type: DatasetType;
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
  versionCount: Scalars['Float']['output'];
};

export type DataSetListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  dsTypes?: InputMaybe<Array<DatasetType>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  versionId?: InputMaybe<Scalars['VecticeId']['input']>;
};

export type DataSetVersion = {
  __typename?: 'DataSetVersion';
  attachments: PaginatedEntityFileList;
  children: LineageChildrenOutput;
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  dataSet: DataSet;
  datasetSources: Array<DatasetSource>;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasColumnWithoutDescription: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  inputs: Array<DataSetVersion>;
  isStarred: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['Float']['output'];
  origin?: Maybe<Lineage>;
  origins: AssetOriginsOutput;
  properties: Array<EntityProperty>;
  sourceOrigin: Scalars['String']['output'];
  targetColumn?: Maybe<Scalars['String']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
  versionNumber: Scalars['Float']['output'];
};

export type DataSetVersionAttachmentsArgs = {
  filters?: InputMaybe<TextSearchListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export type DataSetVersionFilterInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  parentName?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  showStarred?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DataSourceListFiltersInput = {
  datasetSourceId?: InputMaybe<Scalars['Float']['input']>;
  datasetVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export type DatabricksVersion = {
  __typename?: 'DatabricksVersion';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  relativePath?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  urlNotebook: Scalars['String']['output'];
  version: Scalars['Float']['output'];
};

export type DatabricksVersionCreateInput = {
  relativePath: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
  urlNotebook: Scalars['String']['input'];
};

export type DataframeBooleanStatistics = {
  __typename?: 'DataframeBooleanStatistics';
  false: Scalars['Float']['output'];
  missing: Scalars['Float']['output'];
  true: Scalars['Float']['output'];
};

export type DataframeBooleanStatisticsInput = {
  false: Scalars['Float']['input'];
  missing: Scalars['Float']['input'];
  true: Scalars['Float']['input'];
};

export type DataframeDateStatistics = {
  __typename?: 'DataframeDateStatistics';
  maximum: Scalars['DateTime']['output'];
  mean: Scalars['DateTime']['output'];
  median: Scalars['DateTime']['output'];
  minimum: Scalars['DateTime']['output'];
  missing: Scalars['Float']['output'];
};

export type DataframeDateStatisticsInput = {
  maximum: Scalars['DateTime']['input'];
  mean: Scalars['DateTime']['input'];
  median: Scalars['DateTime']['input'];
  minimum: Scalars['DateTime']['input'];
  missing: Scalars['Float']['input'];
};

export type DataframeNumericalStatistics = {
  __typename?: 'DataframeNumericalStatistics';
  mean: Scalars['Float']['output'];
  missing: Scalars['Float']['output'];
  quantiles: DataframeNumericalStatisticsQuantiles;
  stdDeviation: Scalars['Float']['output'];
};

export type DataframeNumericalStatisticsInput = {
  mean: Scalars['Float']['input'];
  missing: Scalars['Float']['input'];
  quantiles: DataframeNumericalStatisticsQuantilesInput;
  stdDeviation: Scalars['Float']['input'];
};

export type DataframeNumericalStatisticsQuantiles = {
  __typename?: 'DataframeNumericalStatisticsQuantiles';
  q25: Scalars['Float']['output'];
  q50: Scalars['Float']['output'];
  q75: Scalars['Float']['output'];
  qMax: Scalars['Float']['output'];
  qMin: Scalars['Float']['output'];
};

export type DataframeNumericalStatisticsQuantilesInput = {
  q25: Scalars['Float']['input'];
  q50: Scalars['Float']['input'];
  q75: Scalars['Float']['input'];
  qMax: Scalars['Float']['input'];
  qMin: Scalars['Float']['input'];
};

export type DataframeStatistics = {
  __typename?: 'DataframeStatistics';
  boolean?: Maybe<DataframeBooleanStatistics>;
  date?: Maybe<DataframeDateStatistics>;
  numerical?: Maybe<DataframeNumericalStatistics>;
  text?: Maybe<DataframeTextStatistics>;
};

export type DataframeStatisticsInput = {
  boolean?: InputMaybe<DataframeBooleanStatisticsInput>;
  date?: InputMaybe<DataframeDateStatisticsInput>;
  numerical?: InputMaybe<DataframeNumericalStatisticsInput>;
  text?: InputMaybe<DataframeTextStatisticsInput>;
};

export type DataframeTextStatistics = {
  __typename?: 'DataframeTextStatistics';
  missing: Scalars['Float']['output'];
  mostCommons: Array<DataframeTextStatisticsMostCommonValue>;
  unique: Scalars['Float']['output'];
};

export type DataframeTextStatisticsInput = {
  missing: Scalars['Float']['input'];
  mostCommons: Array<DataframeTextStatisticsMostCommonValueInput>;
  unique: Scalars['Float']['input'];
};

export type DataframeTextStatisticsMostCommonValue = {
  __typename?: 'DataframeTextStatisticsMostCommonValue';
  frequency: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

export type DataframeTextStatisticsMostCommonValueInput = {
  frequency: Scalars['Float']['input'];
  value: Scalars['String']['input'];
};

export type DatasetRegisterInput = {
  codeVersion?: InputMaybe<CodeVersionRegisterInput>;
  codeVersionId?: InputMaybe<Scalars['Float']['input']>;
  datasetSources?: InputMaybe<Array<DatasetSourceInput>>;
  inputs?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  name: Scalars['String']['input'];
  properties?: InputMaybe<Array<EntityPropertyInput>>;
  type: DatasetType;
};

export type DatasetRegisterResultOutput = {
  __typename?: 'DatasetRegisterResultOutput';
  datasetVersion: DataSetVersion;
  useExistingDataset: Scalars['Boolean']['output'];
  useExistingVersion: Scalars['Boolean']['output'];
};

export type DatasetSource = {
  __typename?: 'DatasetSource';
  columnsCount: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  files: PaginatedDatasetFilesSourceResponse;
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  itemsCount: Scalars['Float']['output'];
  origin: Scalars['String']['output'];
  size?: Maybe<Scalars['BigInt']['output']>;
  tables: PaginatedDbSourceResponse;
  type: DatasetSourceType;
  updatedDate: Scalars['DateTime']['output'];
  usage?: Maybe<DatasetSourceUsage>;
  version: Scalars['Float']['output'];
};

export type DatasetSourceInput = {
  dbs?: InputMaybe<Array<DbTableSourceInput>>;
  files?: InputMaybe<Array<FileSourceInput>>;
  itemsCount?: InputMaybe<Scalars['Float']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['BigInt']['input']>;
  type: DatasetSourceType;
  usage?: InputMaybe<DatasetSourceUsage>;
};

export enum DatasetSourceOrigin {
  Bigquery = 'BIGQUERY',
  DatabricksTable = 'DATABRICKS_TABLE',
  Dataframe = 'DATAFRAME',
  Gcs = 'GCS',
  Local = 'LOCAL',
  Other = 'OTHER',
  Redshift = 'REDSHIFT',
  S3 = 'S3',
  Snowflake = 'SNOWFLAKE',
  SparkTable = 'SPARK_TABLE',
}

export enum DatasetSourceType {
  Db = 'DB',
  Files = 'FILES',
}

export enum DatasetSourceUsage {
  Testing = 'TESTING',
  Training = 'TRAINING',
  Validation = 'VALIDATION',
}

export enum DatasetType {
  Clean = 'CLEAN',
  Modeling = 'MODELING',
  Origin = 'ORIGIN',
  Testing = 'TESTING',
  Training = 'TRAINING',
  Unknown = 'UNKNOWN',
  Validation = 'VALIDATION',
}

export type DatasetUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DatasetType>;
};

export type DatasetVersionUpdateInput = {
  columnsDescription?: InputMaybe<Array<ColumnDescriptionInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  targetColumn?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type DateFilter = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  field?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DbColumnSourceInput = {
  categoryType?: InputMaybe<CategoryType>;
  dataType: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isFK?: InputMaybe<Scalars['Boolean']['input']>;
  isPK?: InputMaybe<Scalars['Boolean']['input']>;
  isUnique?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  nullable?: InputMaybe<Scalars['Boolean']['input']>;
  statistics?: InputMaybe<DataframeStatisticsInput>;
};

export type DocumentationCounts = {
  __typename?: 'DocumentationCounts';
  done: Scalars['Float']['output'];
  ongoing: Scalars['Float']['output'];
  toDo: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type DocumentationFile = {
  __typename?: 'DocumentationFile';
  content: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  fingerprint?: Maybe<Scalars['String']['output']>;
  footerContent: Scalars['String']['output'];
  headerContent: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  isFileLocked: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  lastUpdatedSource?: Maybe<Scalars['String']['output']>;
  lockedUser?: Maybe<User>;
  lockedUserId?: Maybe<Scalars['Float']['output']>;
  lockoutDuration?: Maybe<Scalars['Float']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export enum DocumentationPageStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  InReview = 'InReview',
  NotStarted = 'NotStarted',
}

export type EmailDomain = {
  __typename?: 'EmailDomain';
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  domain: Scalars['String']['output'];
  emailsEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** To use only on automated test org */
  isTestDomain: Scalars['Boolean']['output'];
  /** Used inside a trial org, will not be used to match toward specific org login page */
  isTrial: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
};

export enum EmailVerificationStatus {
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
  Validated = 'VALIDATED',
}

export type EntityFile = {
  __typename?: 'EntityFile';
  contentType: TreeItemType;
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  entityId: Scalars['Float']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  hasPreview: Scalars['Boolean']['output'];
  hasStaticView: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  modelFramework?: Maybe<Scalars['String']['output']>;
  size: Scalars['BigInt']['output'];
  type: EntityFilesTypeEnum;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
  workspace: Workspace;
  workspaceId: Scalars['Float']['output'];
};

export enum EntityFilesTypeEnum {
  CdtReport = 'CDT_REPORT',
  CodeVersion = 'CODE_VERSION',
  Dataset = 'DATASET',
  DatasetVersion = 'DATASET_VERSION',
  Finding = 'FINDING',
  Iteration = 'ITERATION',
  Model = 'MODEL',
  ModelVersion = 'MODEL_VERSION',
  Phase = 'PHASE',
  Project = 'PROJECT',
  Run = 'RUN',
}

export type EntityMetadata = {
  __typename?: 'EntityMetadata';
  content: Scalars['JSONObject']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  entityId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  type: EntityMetadataTypeEnum;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type EntityMetadataCreateInput = {
  content: Scalars['JSONObject']['input'];
  name: Scalars['String']['input'];
};

export enum EntityMetadataTypeEnum {
  DatasetVersion = 'DATASET_VERSION',
  Iteration = 'ITERATION',
  ModelVersion = 'MODEL_VERSION',
}

export type EntityMetric = {
  __typename?: 'EntityMetric';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  key: Scalars['String']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  modelVersionId?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  updatedDate: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
  version: Scalars['Float']['output'];
};

export type EntityMetricInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['DateTime']['input'];
  value: Scalars['Float']['input'];
};

export enum EntityMetricType {
  ModelVersion = 'modelVersion',
}

export type EntityProperty = {
  __typename?: 'EntityProperty';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  dataSetVersionId?: Maybe<Scalars['Float']['output']>;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  key: Scalars['String']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  modelVersionId?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  updatedDate: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
  version: Scalars['Float']['output'];
};

export type EntityPropertyInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['String']['input'];
};

export enum EntityPropertyType {
  DataSetVersion = 'dataSetVersion',
  ModelVersion = 'modelVersion',
}

export enum EntityType {
  Dataset = 'DATASET',
  Model = 'MODEL',
}

export type EpicFeatureFlag = {
  __typename?: 'EpicFeatureFlag';
  code: Scalars['String']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  maturity: FeatureFlagMaturity;
  overrides?: Maybe<Scalars['Float']['output']>;
  stories?: Maybe<Array<StoryFeatureFlag>>;
  ticket?: Maybe<Scalars['String']['output']>;
};

export type ExtraMetadata = {
  __typename?: 'ExtraMetadata';
  displayName?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ExtraMetadataInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractFileResult = {
  __typename?: 'ExtractFileResult';
  images: Scalars['Float']['output'];
  tables: Scalars['Float']['output'];
};

export enum FakeOrgDataSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Xsmall = 'XSMALL',
}

export enum FeatureFlagMaturity {
  Alpha = 'ALPHA',
  Beta = 'BETA',
  Other = 'OTHER',
  Production = 'PRODUCTION',
}

export type FeatureFlagValue = {
  __typename?: 'FeatureFlagValue';
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type FileContentUpdateInput = {
  content: Scalars['String']['input'];
  fingerprint?: InputMaybe<Scalars['String']['input']>;
  lastUpdatedSource: Scalars['String']['input'];
};

export type FileHeaderFooterUpdateInput = {
  footerContent: Scalars['String']['input'];
  headerContent: Scalars['String']['input'];
};

export type FileHistory = {
  __typename?: 'FileHistory';
  content: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  fingerprint?: Maybe<Scalars['String']['output']>;
  footerContent: Scalars['String']['output'];
  headerContent: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isLastInSession: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  parentFile: DocumentationFile;
  sessionUuid: Scalars['String']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type FileHistoryFilter = {
  sessionUuid?: InputMaybe<Scalars['String']['input']>;
  skipLastVersion?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileHistoryWithSessionOutput = {
  __typename?: 'FileHistoryWithSessionOutput';
  createdDate: Scalars['DateTime']['output'];
  fileHistoryId: Scalars['String']['output'];
  numberOfHistoriesInSession: Scalars['Float']['output'];
  sessionUuid: Scalars['String']['output'];
  user: User;
};

export type FileSource = {
  __typename?: 'FileSource';
  columnList: PaginatedFileSourceColumnsList;
  columnsNumber?: Maybe<Scalars['Float']['output']>;
  columnsSummary?: Maybe<Array<ColumnTypeCount>>;
  columnsTypesCounts: Array<ColumnTypeCount>;
  contentType: TreeItemType;
  createdDate: Scalars['DateTime']['output'];
  datasetSource: DatasetSource;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  extraMetadata?: Maybe<Array<ExtraMetadata>>;
  fileCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  fileUpdatedDate?: Maybe<Scalars['DateTime']['output']>;
  /** filename of the file without any other path */
  filename: Scalars['String']['output'];
  fingerprint?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  mimeType: Scalars['String']['output'];
  /** relative path of the file from root of ds version */
  name: Scalars['String']['output'];
  rowsNumber?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
  skippedStatistics?: Maybe<Scalars['Boolean']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  /** full path of the source */
  uri?: Maybe<Scalars['String']['output']>;
  version: Scalars['Float']['output'];
};

export type FileSourceColumnListArgs = {
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export type FileSourceColumn = {
  __typename?: 'FileSourceColumn';
  categoryType: CategoryType;
  createdDate: Scalars['DateTime']['output'];
  dataType: Scalars['String']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileSource: FileSource;
  hash: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  statistics?: Maybe<DataframeStatistics>;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type FileSourceColumnInput = {
  categoryType?: InputMaybe<CategoryType>;
  dataType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  statistics?: InputMaybe<DataframeStatisticsInput>;
};

export type FileSourceInput = {
  columns?: InputMaybe<Array<FileSourceColumnInput>>;
  columnsNumber?: InputMaybe<Scalars['Float']['input']>;
  columnsSummary?: InputMaybe<Array<ColumnTypeCountInput>>;
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  extraMetadata?: InputMaybe<Array<ExtraMetadataInput>>;
  filename?: InputMaybe<Scalars['String']['input']>;
  fingerprint?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rowsNumber?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['BigInt']['input']>;
  skippedStatistics?: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type FileSubscriptionOutput = {
  __typename?: 'FileSubscriptionOutput';
  eventType: NotificationType;
  file?: Maybe<DocumentationFile>;
  id?: Maybe<Scalars['Float']['output']>;
  ids?: Maybe<Array<Scalars['Float']['output']>>;
};

export enum FilterConditionEnum {
  And = 'AND',
  Or = 'OR',
}

export type Finding = {
  __typename?: 'Finding';
  closedDate?: Maybe<Scalars['DateTime']['output']>;
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  file: DocumentationFile;
  fileId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  modelVersion?: Maybe<ModelVersion>;
  modelVersionId?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['Float']['output'];
  project: Project;
  projectId: Scalars['Float']['output'];
  report?: Maybe<CdtReport>;
  reportId?: Maybe<Scalars['Float']['output']>;
  reviewer?: Maybe<User>;
  reviewerId?: Maybe<Scalars['Float']['output']>;
  severity: FindingSeverity;
  status: FindingStatus;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type FindingCreateInput = {
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  initialText?: InputMaybe<Scalars['String']['input']>;
  modelVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
  name: Scalars['String']['input'];
  ownerId: Scalars['Float']['input'];
  reportId?: InputMaybe<Scalars['Float']['input']>;
  reviewerId?: InputMaybe<Scalars['Float']['input']>;
  severity?: InputMaybe<FindingSeverity>;
  status?: InputMaybe<FindingStatus>;
};

export type FindingFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  dueDate?: InputMaybe<DateFilter>;
  modelVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
  ownerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId?: InputMaybe<Scalars['VecticeId']['input']>;
  reportId?: InputMaybe<Scalars['Float']['input']>;
  reviewerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  severity?: InputMaybe<Array<FindingSeverity>>;
  status?: InputMaybe<Array<FindingStatus>>;
};

export enum FindingQueryType {
  ModelVersion = 'MODEL_VERSION',
  Project = 'PROJECT',
  Report = 'REPORT',
}

export enum FindingSeverity {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Undefined = 'Undefined',
}

export enum FindingStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  RemediationPending = 'RemediationPending',
}

export type FindingUpdateInput = {
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  modelVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Float']['input']>;
  reportId?: InputMaybe<Scalars['Float']['input']>;
  reviewerId?: InputMaybe<Scalars['Float']['input']>;
  severity?: InputMaybe<FindingSeverity>;
  status?: InputMaybe<FindingStatus>;
};

export type GenerateDocumentationInput = {
  customPrompt: Scalars['String']['input'];
  injected?: InputMaybe<Scalars['Boolean']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateLlmDocumentationInput = {
  customPrompt: Scalars['String']['input'];
  id: Scalars['VecticeId']['input'];
  type: LlmPromptTypeEnum;
};

export type GenerateMultipleOutputs = {
  __typename?: 'GenerateMultipleOutputs';
  input: Scalars['String']['output'];
  outputs: Array<Scalars['String']['output']>;
};

export type GitVersion = {
  __typename?: 'GitVersion';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  author: User;
  authorId: Scalars['Float']['output'];
  branchName?: Maybe<Scalars['String']['output']>;
  commitAuthorEmail?: Maybe<Scalars['String']['output']>;
  commitAuthorName?: Maybe<Scalars['String']['output']>;
  commitComment?: Maybe<Scalars['String']['output']>;
  commitHash?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  entrypoint?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isDirty: Scalars['Boolean']['output'];
  repositoryName: Scalars['String']['output'];
  updatedDate: Scalars['DateTime']['output'];
  uri?: Maybe<Scalars['String']['output']>;
  version: Scalars['Float']['output'];
  workspace: Workspace;
  workspaceId: Scalars['Float']['output'];
};

export type GitVersionCreateInput = {
  branchName?: InputMaybe<Scalars['String']['input']>;
  commitAuthorEmail?: InputMaybe<Scalars['String']['input']>;
  commitAuthorName?: InputMaybe<Scalars['String']['input']>;
  commitComment?: InputMaybe<Scalars['String']['input']>;
  commitHash: Scalars['String']['input'];
  entrypoint?: InputMaybe<Scalars['String']['input']>;
  isDirty: Scalars['Boolean']['input'];
  repositoryName: Scalars['String']['input'];
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type GlobalTemplate = {
  __typename?: 'GlobalTemplate';
  content: Scalars['String']['output'];
  footerContent?: Maybe<Scalars['String']['output']>;
  headerContent?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GroupFilter = {
  condition?: FilterConditionEnum;
  filters?: InputMaybe<Array<AdvancedFilter>>;
  groups?: InputMaybe<Array<GroupFilter>>;
};

export type InventoryReferenceOrderInput = {
  direction: OrderDirection;
  field: Scalars['String']['input'];
};

export type InventoryReferenceOutput = {
  __typename?: 'InventoryReferenceOutput';
  inventoryReference: Scalars['String']['output'];
};

export type Iteration = {
  __typename?: 'Iteration';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  index: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  owner: User;
  ownerId: Scalars['Float']['output'];
  paginatedArtifacts: PaginatedIterationStepArtifactResponse;
  phase: Phase;
  phaseId: Scalars['Float']['output'];
  sections: PaginatedIterationStepResponse;
  starred: Scalars['Boolean']['output'];
  status: IterationStatus;
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
};

export type IterationPaginatedArtifactsArgs = {
  filters?: InputMaybe<IterationArtifactsFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type IterationSectionsArgs = {
  filters?: InputMaybe<IterationStepFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type IterationArtifactsFiltersInput = {
  stepStrategy?: InputMaybe<ArtifactsStepStrategy>;
  withAssetDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IterationContextInput = {
  iterationId: Scalars['VecticeId']['input'];
  stepName?: InputMaybe<Scalars['String']['input']>;
};

export type IterationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IterationFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  onlyMine?: InputMaybe<Scalars['Boolean']['input']>;
  phaseId?: InputMaybe<Scalars['VecticeId']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<IterationStatus>>;
};

export type IterationRemoveAssetsInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  iterationId: Scalars['VecticeId']['input'];
  stepName?: InputMaybe<Scalars['String']['input']>;
};

export enum IterationStatus {
  Abandoned = 'Abandoned',
  Completed = 'Completed',
  InProgress = 'InProgress',
  InReview = 'InReview',
  NotStarted = 'NotStarted',
}

export type IterationStep = {
  __typename?: 'IterationStep';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  index: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  paginatedArtifacts: PaginatedIterationStepArtifactResponse;
  parent: Iteration;
  parentId: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  /** @deprecated Useless now, Legacy to support 23.4 and older */
  stepType: IterationStepType;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type IterationStepPaginatedArtifactsArgs = {
  filters?: InputMaybe<BaseIterationArtifactsFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type IterationStepArtifact = {
  __typename?: 'IterationStepArtifact';
  createdDate: Scalars['DateTime']['output'];
  datasetVersion?: Maybe<DataSetVersion>;
  datasetVersionId?: Maybe<Scalars['Float']['output']>;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  entityFile?: Maybe<EntityFile>;
  entityFileId?: Maybe<Scalars['Float']['output']>;
  entityMetadata?: Maybe<EntityMetadata>;
  entityMetadataId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  index: Scalars['Float']['output'];
  iteration: Iteration;
  iterationId: Scalars['Float']['output'];
  modelVersion?: Maybe<ModelVersion>;
  modelVersionId?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<IterationStep>;
  stepId?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type: IterationStepArtifactType;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type IterationStepArtifactInput = {
  entityMetadata?: InputMaybe<EntityMetadataCreateInput>;
  id?: InputMaybe<Scalars['VecticeId']['input']>;
  sectionName?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type: IterationStepArtifactType;
};

export enum IterationStepArtifactType {
  Comment = 'Comment',
  DataSetVersion = 'DataSetVersion',
  EntityFile = 'EntityFile',
  EntityMetadata = 'EntityMetadata',
  ModelVersion = 'ModelVersion',
}

export type IterationStepFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export enum IterationStepType {
  Dataset = 'DATASET',
  Image = 'IMAGE',
  Model = 'MODEL',
  Number = 'NUMBER',
  Plot = 'PLOT',
  String = 'STRING',
  Unknown = 'UNKNOWN',
}

export type IterationStepUpdateInput = {
  artifacts?: InputMaybe<Array<IterationStepArtifactInput>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type IterationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<IterationStatus>;
};

export type IterationsCounts = {
  __typename?: 'IterationsCounts';
  abandoned: Scalars['Float']['output'];
  completed: Scalars['Float']['output'];
  inProgress: Scalars['Float']['output'];
  inReview: Scalars['Float']['output'];
  notStarted: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type KeyFieldValue = {
  field: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type KeycloakIdentityProvider = {
  __typename?: 'KeycloakIdentityProvider';
  alias: Scalars['String']['output'];
  config?: Maybe<KeycloakIdentityProviderConfig>;
  displayName?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  providerId: Provider;
};

export type KeycloakIdentityProviderConfig = KeycloakOpenIdConfig | KeycloakSamlConfig | KeycloakSocialConfig;

export type KeycloakIdentityProviderCreate = {
  alias?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  providerId: Provider;
};

export type KeycloakIdentityProviderUpdate = {
  alias: Scalars['String']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  providerId: Provider;
};

export type KeycloakOpenIdConfig = {
  __typename?: 'KeycloakOpenIDConfig';
  authorizationUrl: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  defaultScope?: Maybe<Scalars['String']['output']>;
  logoutUrl: Scalars['String']['output'];
  tokenUrl: Scalars['String']['output'];
};

export type KeycloakOpenIdConfigCreate = {
  authorizationUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  defaultScope?: InputMaybe<Scalars['String']['input']>;
  logoutUrl: Scalars['String']['input'];
  tokenUrl: Scalars['String']['input'];
};

export type KeycloakOpenIdConfigUpdate = {
  authorizationUrl?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  defaultScope?: InputMaybe<Scalars['String']['input']>;
  logoutUrl?: InputMaybe<Scalars['String']['input']>;
  tokenUrl?: InputMaybe<Scalars['String']['input']>;
};

export type KeycloakSamlConfig = {
  __typename?: 'KeycloakSAMLConfig';
  entityId: Scalars['String']['output'];
  forceAuthn?: Maybe<Scalars['Boolean']['output']>;
  singleLogoutServiceUrl?: Maybe<Scalars['String']['output']>;
  singleSignOnServiceUrl?: Maybe<Scalars['String']['output']>;
};

export type KeycloakSamlConfigCreate = {
  forceAuthn?: InputMaybe<Scalars['Boolean']['input']>;
  singleLogoutServiceUrl?: InputMaybe<Scalars['String']['input']>;
  singleSignOnServiceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type KeycloakSamlConfigUpdate = {
  forceAuthn?: InputMaybe<Scalars['Boolean']['input']>;
  singleLogoutServiceUrl?: InputMaybe<Scalars['String']['input']>;
  singleSignOnServiceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type KeycloakSocialConfig = {
  __typename?: 'KeycloakSocialConfig';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  defaultScope?: Maybe<Scalars['String']['output']>;
};

export type KeycloakSocialConfigCreate = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  defaultScope?: InputMaybe<Scalars['String']['input']>;
};

export type KeycloakSocialConfigUpdate = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  defaultScope?: InputMaybe<Scalars['String']['input']>;
};

export type LlmPrompt = {
  __typename?: 'LLMPrompt';
  content: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  type: LlmPromptTypeEnum;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type LlmPromptCreateInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: LlmPromptTypeEnum;
};

export type LlmPromptFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  type: LlmPromptTypeEnum;
};

export enum LlmPromptTypeEnum {
  DatasetVersion = 'DATASET_VERSION',
  Iteration = 'ITERATION',
  ModelVersion = 'MODEL_VERSION',
  Phase = 'PHASE',
}

export type LlmPromptUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Lineage = {
  __typename?: 'Lineage';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  datasetInputs: Array<Scalars['String']['output']>;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  iteration?: Maybe<Iteration>;
  iterationId?: Maybe<Scalars['Float']['output']>;
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  lineageArtifacts: Array<LineageArtifact>;
  phase?: Maybe<Phase>;
  phaseId?: Maybe<Scalars['Float']['output']>;
  project: Project;
  projectId: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type LineageArtifact = {
  __typename?: 'LineageArtifact';
  artifactType: ArtifactType;
  codeVersion?: Maybe<CodeVersion>;
  codeVersionId?: Maybe<Scalars['Float']['output']>;
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  dataSetVersion?: Maybe<DataSetVersion>;
  dataSetVersionId?: Maybe<Scalars['Float']['output']>;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  lineageArtifactType: LineageArtifactType;
  lineageId: Scalars['Float']['output'];
  modelVersion?: Maybe<ModelVersion>;
  modelVersionId?: Maybe<Scalars['Float']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export enum LineageArtifactType {
  Input = 'INPUT',
  Output = 'OUTPUT',
}

export type LineageChildrenOutput = {
  __typename?: 'LineageChildrenOutput';
  datasetVersions: Array<DataSetVersion>;
  modelVersions: Array<ModelVersion>;
};

export enum LineageType {
  DatasetVersion = 'DATASET_VERSION',
  ModelVersion = 'MODEL_VERSION',
}

export type LineageUpdateInput = {
  codeLineageInput?: InputMaybe<CodeLineageUpdateInput>;
  datasetVersionIds?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type ListOrderInput = {
  direction: OrderDirection;
  field: Scalars['String']['input'];
  nestedKey?: InputMaybe<KeyFieldValue>;
  secondaryOrder?: InputMaybe<ListOrderInput>;
};

export type ListOrderUserAndInvitesInput = {
  direction: OrderDirection;
  field: Scalars['String']['input'];
  /** Only works up to level 2 for userAndInvites endpoint */
  secondaryOrder?: InputMaybe<ListOrderUserAndInvitesInput>;
};

export enum MatchEnum {
  Contains = 'CONTAINS',
  Different = 'DIFFERENT',
  EndsWith = 'ENDS_WITH',
  Equal = 'EQUAL',
  Exists = 'EXISTS',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  Lower = 'LOWER',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  NotContains = 'NOT_CONTAINS',
  NotExists = 'NOT_EXISTS',
  StartsWith = 'STARTS_WITH',
}

export type MetadataAuthOuput = {
  __typename?: 'MetadataAuthOuput';
  expirationDate: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export type Model = {
  __typename?: 'Model';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  lastVersion?: Maybe<ModelVersion>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  project: Project;
  projectId: Scalars['Float']['output'];
  repository?: Maybe<Scalars['String']['output']>;
  type: ModelType;
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
  versionCount: Scalars['Float']['output'];
  versionStats?: Maybe<ModelVersionStatistics>;
};

export type ModelCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  repository?: InputMaybe<Scalars['String']['input']>;
  type: ModelType;
};

export type ModelListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  algorithmNames?: InputMaybe<Array<Scalars['String']['input']>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  modelVersionStatus?: InputMaybe<Array<ModelVersionStatus>>;
  repositorySearch?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  types?: InputMaybe<Array<ModelType>>;
  versionId?: InputMaybe<Scalars['VecticeId']['input']>;
};

export type ModelRegisterInput = {
  algorithmName?: InputMaybe<Scalars['String']['input']>;
  codeVersion?: InputMaybe<CodeVersionRegisterInput>;
  codeVersionId?: InputMaybe<Scalars['Float']['input']>;
  context?: InputMaybe<ModelVersionContextInput>;
  framework?: InputMaybe<Scalars['String']['input']>;
  inputs?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  metrics?: InputMaybe<Array<EntityMetricInput>>;
  modelType: ModelType;
  name: Scalars['String']['input'];
  properties?: InputMaybe<Array<EntityPropertyInput>>;
  status: ModelVersionStatus;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ModelRegisterResultOutput = {
  __typename?: 'ModelRegisterResultOutput';
  modelVersion: ModelVersion;
  useExistingModel: Scalars['Boolean']['output'];
};

export enum ModelType {
  AnomalyDetection = 'ANOMALY_DETECTION',
  Classification = 'CLASSIFICATION',
  Clustering = 'CLUSTERING',
  Other = 'OTHER',
  RecommendationModels = 'RECOMMENDATION_MODELS',
  Regression = 'REGRESSION',
  TimeSeries = 'TIME_SERIES',
}

export type ModelUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  repository?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ModelType>;
};

export type ModelVersion = {
  __typename?: 'ModelVersion';
  algorithmName?: Maybe<Scalars['String']['output']>;
  approval: ModelVersionApproval;
  attachments: PaginatedEntityFileList;
  context?: Maybe<ModelVersionContext>;
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  framework?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  inputs: Array<DataSetVersion>;
  inventoryReference?: Maybe<Scalars['String']['output']>;
  isStarred: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  metrics: Array<EntityMetric>;
  model: Model;
  modelId: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['Float']['output'];
  origin?: Maybe<Lineage>;
  origins: AssetOriginsOutput;
  properties: Array<EntityProperty>;
  risk: ModelVersionRisk;
  status: ModelVersionStatus;
  type?: Maybe<Scalars['String']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
  versionNumber: Scalars['Float']['output'];
};

export type ModelVersionAttachmentsArgs = {
  filters?: InputMaybe<TextSearchListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export enum ModelVersionApproval {
  Approved = 'Approved',
  InValidation = 'InValidation',
  Rejected = 'Rejected',
  ToValidate = 'ToValidate',
  Validated = 'Validated',
}

export type ModelVersionContext = {
  __typename?: 'ModelVersionContext';
  extraInfo?: Maybe<Array<ExtraMetadata>>;
  library?: Maybe<ModelVersionContextLibrary>;
  run?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ModelVersionContextInput = {
  extraInfo?: InputMaybe<Array<ExtraMetadataInput>>;
  library?: InputMaybe<ModelVersionContextLibrary>;
  run?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum ModelVersionContextLibrary {
  Mlflow = 'MLFLOW',
  Other = 'OTHER',
}

export type ModelVersionCreateInput = {
  algorithmName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status: ModelVersionStatus;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type ModelVersionListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  algorithmNames?: InputMaybe<Array<Scalars['String']['input']>>;
  approvals?: InputMaybe<Array<ModelVersionApproval>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  haveinventoryReference?: InputMaybe<Scalars['Boolean']['input']>;
  modelVersionStatus?: InputMaybe<Array<ModelVersionStatus>>;
  parentName?: InputMaybe<Scalars['String']['input']>;
  risks?: InputMaybe<Array<ModelVersionRisk>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  showStarred?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ModelVersionRisk {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Undefined = 'UNDEFINED',
}

export type ModelVersionStatistics = {
  __typename?: 'ModelVersionStatistics';
  discardedCount: Scalars['Float']['output'];
  experimentationCount: Scalars['Float']['output'];
  productionCount: Scalars['Float']['output'];
  retiredCount: Scalars['Float']['output'];
  stagingCount: Scalars['Float']['output'];
};

export enum ModelVersionStatus {
  Discarded = 'DISCARDED',
  Experimentation = 'EXPERIMENTATION',
  Production = 'PRODUCTION',
  Retired = 'RETIRED',
  Staging = 'STAGING',
}

export type ModelVersionUpdateInput = {
  algorithmName?: InputMaybe<Scalars['String']['input']>;
  approval?: InputMaybe<ModelVersionApproval>;
  description?: InputMaybe<Scalars['String']['input']>;
  inventoryReference?: InputMaybe<Scalars['String']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  risk?: InputMaybe<ModelVersionRisk>;
  status?: InputMaybe<ModelVersionStatus>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeUserNotification: UserNotification;
  addEmailDomain: Scalars['Boolean']['output'];
  addEmailDomainToOrganization: Organization;
  addIterationContent: Array<IterationStepArtifact>;
  /** @deprecated Match older step Flow, use updateIterationStepContent instead */
  addIterationStepArtifact: IterationStep;
  addModelVersion: ModelVersion;
  addMyselfToWorkspace: WorkspaceUser;
  addToStarredDatasetVersions: DataSetVersion;
  addToStarredModelVersions: ModelVersion;
  addUsersToWorkspace: Array<WorkspaceUser>;
  askUserTypeUpgrade: Scalars['Boolean']['output'];
  attachFileToLineage: Scalars['Boolean']['output'];
  cancelReview: Review;
  cancelUserInvite: Scalars['Boolean']['output'];
  changeOrgDisplayName: Scalars['Boolean']['output'];
  changeUserPassword: Scalars['Boolean']['output'];
  checkEmailVerificationToken: EmailVerificationStatus;
  cloneWorkspace: Workspace;
  closeReview: Review;
  createCDTReport: CdtReport;
  createCDTTemplate: CdtTemplate;
  createCode: Code;
  createCodeVersion: CodeVersion;
  createEntityMetric: EntityMetric;
  createEntityMetrics: Array<EntityMetric>;
  createEntityProperties: Array<EntityProperty>;
  createEntityProperty: EntityProperty;
  createFakeOrganization: Scalars['Boolean']['output'];
  createFinding: Finding;
  createIdentityProvider: KeycloakIdentityProvider;
  createIteration: Iteration;
  /** @deprecated Use createSection instead. */
  createIterationStep: IterationStep;
  createIterationStepArtifact: IterationStepArtifact;
  createLLMPrompt: LlmPrompt;
  createModel: Model;
  createOrganization: Organization;
  createOrganizationUserInvite: Array<UserInviteResponse>;
  createPasswordRecoveryRequest: Scalars['Boolean']['output'];
  createPhase: Phase;
  createProject: Project;
  createRequirement: StepDefinition;
  createReviewComment: ReviewComment;
  createSection: IterationStep;
  /** @deprecated Use createRequirement instead. */
  createStepDefinition: StepDefinition;
  createSuperAdminUser: User;
  createThread: Thread;
  createThreadReply: ThreadReply;
  createUserFromInvite: User;
  createUserInvite: Array<UserInviteResponse>;
  createUserMentionNotification: UserNotification;
  createUserViewActivity: UserViewActivity;
  createWorkspace: Workspace;
  deleteApiKey: Scalars['Boolean']['output'];
  deleteCode: Scalars['Boolean']['output'];
  deleteDataset: Scalars['ID']['output'];
  deleteDatasetVersion: Scalars['ID']['output'];
  deleteEntityFile: Scalars['Float']['output'];
  deleteEntityMetadata: Scalars['Float']['output'];
  deleteEntityMetric: Scalars['Float']['output'];
  deleteEntityMetrics: Array<Scalars['Float']['output']>;
  deleteEntityProperties: Array<Scalars['Float']['output']>;
  deleteEntityProperty: Scalars['Float']['output'];
  deleteIdentityProvider: Scalars['String']['output'];
  deleteModel: Scalars['ID']['output'];
  deleteModelVersion: Scalars['ID']['output'];
  deleteOrganization: Scalars['ID']['output'];
  deleteProject: Scalars['ID']['output'];
  deleteThread: Scalars['ID']['output'];
  deleteThreadReply: Scalars['ID']['output'];
  deleteWorkspace: Scalars['ID']['output'];
  disableUser: User;
  duplicateCDTReport: CdtReport;
  duplicatePhase: Phase;
  /** @deprecated Deprecated, use createProject with duplicateProjectId instead */
  duplicateProject: Project;
  enableUser: User;
  extractEntityFile: ExtractFileResult;
  generateApiKey: ApiKeyCreateOutput;
  generateAssetDocumentation: GenerateMultipleOutputs;
  generateCDTMarkdown: Scalars['String']['output'];
  generateDocumentation: Scalars['String']['output'];
  importWorkspaces: Array<Workspace>;
  inviteTrialUser: TrialUserInviteStatus;
  leaveWorkspace: Scalars['Boolean']['output'];
  markAllUserNotificationsAsSeen: Scalars['Boolean']['output'];
  moveArtifacts: Array<IterationStepArtifact>;
  moveProject: Project;
  previewMacro: Scalars['String']['output'];
  recoverPasswordAfterRequest: User;
  registerDataset: DatasetRegisterResultOutput;
  registerModel: ModelRegisterResultOutput;
  removeAssetsFromIteration: Scalars['Float']['output'];
  removeCDTReport: Scalars['Float']['output'];
  removeCDTTemplate: Scalars['Float']['output'];
  removeEmailDomainFromOrganization: Organization;
  removeFinding: Scalars['Float']['output'];
  removeIteration: Scalars['ID']['output'];
  removeIterationAssets: Scalars['Float']['output'];
  /** @deprecated Use removeSection instead. */
  removeIterationStep: Scalars['ID']['output'];
  removeLLMPrompt: Scalars['ID']['output'];
  removePhase: Scalars['ID']['output'];
  removeRequirement: Scalars['ID']['output'];
  removeSection: Scalars['ID']['output'];
  /** @deprecated Use removeRequirement instead. */
  removeStepDefinition: Scalars['ID']['output'];
  removeUserFromWorkspace: Scalars['ID']['output'];
  renameEntityFile: EntityFile;
  renameEntityMetadata: EntityMetadata;
  requestReview: Review;
  requestWorkspaceAccess: WorkspaceAccessRequest;
  resendUserInvite: Scalars['Boolean']['output'];
  resetOrganizationFeatureFlag: OrgFeatureFlagValue;
  resolveWorkspaceAccessRequest: WorkspaceAccessRequest;
  restoreHistory: Scalars['Boolean']['output'];
  restorePhase: Scalars['ID']['output'];
  sendEmailVerification: EmailVerificationStatus;
  setLicenseOptions: OrganizationLicenseOptions;
  setOrganizationSettings: OrganizationConfigOutput;
  setUserRole: User;
  setUserWorkspaceAccessibility: WorkspaceUser;
  unlockFile: Scalars['Boolean']['output'];
  updateApiKey: ApiKey;
  updateBigQueryUserViewActivity: Scalars['Boolean']['output'];
  updateCDTReport: CdtReport;
  updateCDTTemplate: CdtTemplate;
  updateCode: Code;
  updateColumnSourceDescription: Scalars['ID']['output'];
  updateDataset: DataSet;
  updateDatasetVersion: DataSetVersion;
  /** @deprecated use updateEmailAvailabilityForOrganization */
  updateEmailAvailabilityForEmailDomain: Scalars['Boolean']['output'];
  updateEmailAvailabilityForOrganization: Scalars['Boolean']['output'];
  updateEntityMetric: EntityMetric;
  updateEntityProperties: Array<EntityProperty>;
  updateEntityProperty: EntityProperty;
  updateFeatureFlagAvailability: FeatureFlagValue;
  updateFileContent: DocumentationFile;
  updateFileHeaderFooter: DocumentationFile;
  updateFinding: Finding;
  updateIdentityProvider: KeycloakIdentityProvider;
  updateIteration: Iteration;
  /** @deprecated Use updateSection instead. */
  updateIterationStep: IterationStep;
  updateIterationStepArtifact: IterationStepArtifact;
  /** @deprecated Use updateSectionContent instead. */
  updateIterationStepContent: IterationStep;
  updateLLMPrompt: LlmPrompt;
  updateLineage: Scalars['Boolean']['output'];
  updateModel: Model;
  updateModelVersion: ModelVersion;
  updateOrganization: Organization;
  updateOrganizationFeatureFlagAvailability: OrgFeatureFlagValue;
  updatePhase: Phase;
  updateProject: Project;
  updateRequirement: StepDefinition;
  updateSection: IterationStep;
  updateSectionContent: IterationStep;
  /** @deprecated Use updateRequirement instead. */
  updateStepDefinition: StepDefinition;
  updateThread: Thread;
  updateThreadReply: ThreadReply;
  updateUser: User;
  updateWorkspace: Workspace;
  upsertEntityMetadata: Array<EntityMetadata>;
  upsertEntityMetrics: PaginatedEntityMetricResponse;
  upsertEntityProperties: PaginatedEntityPropertyResponse;
};

export type MutationAcknowledgeUserNotificationArgs = {
  id: Scalars['Float']['input'];
};

export type MutationAddEmailDomainArgs = {
  domain: Scalars['String']['input'];
  emailsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['Float']['input'];
};

export type MutationAddEmailDomainToOrganizationArgs = {
  emailDomain: Scalars['String']['input'];
  organizationId: Scalars['Float']['input'];
};

export type MutationAddIterationContentArgs = {
  artifacts: Array<IterationStepArtifactInput>;
  iterationContext: IterationContextInput;
};

export type MutationAddIterationStepArtifactArgs = {
  data: IterationStepArtifactInput;
  id: Scalars['Float']['input'];
};

export type MutationAddModelVersionArgs = {
  data: ModelVersionCreateInput;
  modelId: Scalars['VecticeId']['input'];
};

export type MutationAddMyselfToWorkspaceArgs = {
  workspaceId: Scalars['VecticeId']['input'];
};

export type MutationAddToStarredDatasetVersionsArgs = {
  resource: StarredResourceSettings;
};

export type MutationAddToStarredModelVersionsArgs = {
  resource: StarredResourceSettings;
};

export type MutationAddUsersToWorkspaceArgs = {
  data: WorkspaceUserInput;
};

export type MutationAskUserTypeUpgradeArgs = {
  askedUserType?: UserRole;
};

export type MutationAttachFileToLineageArgs = {
  entityFileId: Scalars['Float']['input'];
  lineageId: Scalars['Float']['input'];
};

export type MutationCancelReviewArgs = {
  id: Scalars['VecticeId']['input'];
};

export type MutationCancelUserInviteArgs = {
  id: Scalars['Float']['input'];
};

export type MutationChangeOrgDisplayNameArgs = {
  displayName: Scalars['String']['input'];
};

export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationCheckEmailVerificationTokenArgs = {
  emailVerificationToken: Scalars['String']['input'];
};

export type MutationCloneWorkspaceArgs = {
  newWorkspaceName: Scalars['String']['input'];
  organizationId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
  workspaceId: Scalars['Float']['input'];
};

export type MutationCloseReviewArgs = {
  id: Scalars['VecticeId']['input'];
  review: ReviewUpdateInput;
};

export type MutationCreateCdtReportArgs = {
  data: CdtReportCreateInput;
  projectId: Scalars['VecticeId']['input'];
};

export type MutationCreateCdtTemplateArgs = {
  data: CdtTemplateCreateInput;
};

export type MutationCreateCodeArgs = {
  code: CodeCreateInput;
  projectId: Scalars['VecticeId']['input'];
};

export type MutationCreateCodeVersionArgs = {
  codeId: Scalars['Float']['input'];
  codeVersion: CodeVersionCreationBody;
};

export type MutationCreateEntityMetricArgs = {
  createMetric: CreateEntityMetricInput;
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityMetricType;
};

export type MutationCreateEntityMetricsArgs = {
  createMetricList: Array<CreateEntityMetricInput>;
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityMetricType;
};

export type MutationCreateEntityPropertiesArgs = {
  createPropertyList: Array<CreateEntityPropertyInput>;
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityPropertyType;
};

export type MutationCreateEntityPropertyArgs = {
  createProperty: CreateEntityPropertyInput;
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityPropertyType;
};

export type MutationCreateFakeOrganizationArgs = {
  size?: FakeOrgDataSize;
};

export type MutationCreateFindingArgs = {
  data: FindingCreateInput;
  projectId: Scalars['VecticeId']['input'];
};

export type MutationCreateIdentityProviderArgs = {
  identityProvider: KeycloakIdentityProviderCreate;
  openIDConfig?: InputMaybe<KeycloakOpenIdConfigCreate>;
  samlConfig?: InputMaybe<KeycloakSamlConfigCreate>;
  socialConfig?: InputMaybe<KeycloakSocialConfigCreate>;
};

export type MutationCreateIterationArgs = {
  data?: InputMaybe<IterationCreateInput>;
  phaseId: Scalars['VecticeId']['input'];
};

export type MutationCreateIterationStepArgs = {
  createModel: BaseStepCreateInput;
  parentId: Scalars['VecticeId']['input'];
};

export type MutationCreateIterationStepArtifactArgs = {
  data: IterationStepArtifactInput;
  id: Scalars['VecticeId']['input'];
  sectionId?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationCreateLlmPromptArgs = {
  data: LlmPromptCreateInput;
};

export type MutationCreateModelArgs = {
  model: ModelCreateInput;
  projectId: Scalars['VecticeId']['input'];
};

export type MutationCreateOrganizationArgs = {
  data: OrganizationCreateInput;
  mustChangePassword?: Scalars['Boolean']['input'];
};

export type MutationCreateOrganizationUserInviteArgs = {
  data: UserInviteInput;
  organizationId: Scalars['Float']['input'];
};

export type MutationCreatePasswordRecoveryRequestArgs = {
  email: Scalars['String']['input'];
};

export type MutationCreatePhaseArgs = {
  createModel: PhaseCreateInput;
  parentId: Scalars['VecticeId']['input'];
};

export type MutationCreateProjectArgs = {
  data: ProjectCreateInput;
  workspaceId: Scalars['VecticeId']['input'];
};

export type MutationCreateRequirementArgs = {
  createModel: BaseStepCreateInput;
  parentId: Scalars['VecticeId']['input'];
};

export type MutationCreateReviewCommentArgs = {
  reviewComment: ReviewCommentCreateInput;
};

export type MutationCreateSectionArgs = {
  createModel: BaseStepCreateInput;
  parentId: Scalars['VecticeId']['input'];
};

export type MutationCreateStepDefinitionArgs = {
  createModel: BaseStepCreateInput;
  parentId: Scalars['VecticeId']['input'];
};

export type MutationCreateSuperAdminUserArgs = {
  data: CreateSuperAdminUserInput;
};

export type MutationCreateThreadArgs = {
  data: ThreadCreateInput;
};

export type MutationCreateThreadReplyArgs = {
  data: ThreadReplyCreateInput;
};

export type MutationCreateUserFromInviteArgs = {
  data: UserCreateInput;
};

export type MutationCreateUserInviteArgs = {
  data: UserInviteInput;
};

export type MutationCreateUserMentionNotificationArgs = {
  content: UserMentionNotificationContentInput;
  userId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
};

export type MutationCreateUserViewActivityArgs = {
  userViewActivity: UserViewActivityCreateInput;
};

export type MutationCreateWorkspaceArgs = {
  data: WorkspaceCreateInput;
};

export type MutationDeleteApiKeyArgs = {
  apiKeyId: Scalars['Float']['input'];
};

export type MutationDeleteCodeArgs = {
  codeId: Scalars['Float']['input'];
};

export type MutationDeleteDatasetArgs = {
  datasetId: Scalars['VecticeId']['input'];
};

export type MutationDeleteDatasetVersionArgs = {
  datasetVersionId: Scalars['VecticeId']['input'];
};

export type MutationDeleteEntityFileArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteEntityMetadataArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteEntityMetricArgs = {
  entityType: EntityMetricType;
  metricId: Scalars['Float']['input'];
};

export type MutationDeleteEntityMetricsArgs = {
  entityType: EntityMetricType;
  metricIds: Array<Scalars['Float']['input']>;
};

export type MutationDeleteEntityPropertiesArgs = {
  entityType: EntityPropertyType;
  propertyIds: Array<Scalars['Float']['input']>;
};

export type MutationDeleteEntityPropertyArgs = {
  entityType: EntityPropertyType;
  propertyId: Scalars['Float']['input'];
};

export type MutationDeleteIdentityProviderArgs = {
  alias: Scalars['String']['input'];
};

export type MutationDeleteModelArgs = {
  modelId: Scalars['VecticeId']['input'];
};

export type MutationDeleteModelVersionArgs = {
  modelVersionId: Scalars['VecticeId']['input'];
};

export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['Float']['input'];
};

export type MutationDeleteProjectArgs = {
  projectId: Scalars['VecticeId']['input'];
};

export type MutationDeleteThreadArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteThreadReplyArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteWorkspaceArgs = {
  id: Scalars['VecticeId']['input'];
};

export type MutationDisableUserArgs = {
  userId: Scalars['Float']['input'];
};

export type MutationDuplicateCdtReportArgs = {
  duplicateInput: BaseDocumentationDuplicateInput;
  id: Scalars['Float']['input'];
};

export type MutationDuplicatePhaseArgs = {
  duplicateInput: PhaseDuplicateInput;
  id: Scalars['VecticeId']['input'];
};

export type MutationDuplicateProjectArgs = {
  projectDuplicateInput: ProjectDuplicateInput;
};

export type MutationEnableUserArgs = {
  userId: Scalars['Float']['input'];
};

export type MutationExtractEntityFileArgs = {
  id: Scalars['Float']['input'];
};

export type MutationGenerateApiKeyArgs = {
  apiKey: ApiKeyCreateInput;
};

export type MutationGenerateAssetDocumentationArgs = {
  data: GenerateLlmDocumentationInput;
};

export type MutationGenerateCdtMarkdownArgs = {
  data: CdtGenerateMarkdownInput;
};

export type MutationGenerateDocumentationArgs = {
  data: GenerateDocumentationInput;
};

export type MutationImportWorkspacesArgs = {
  organizationId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
  workspaces: Scalars['String']['input'];
};

export type MutationInviteTrialUserArgs = {
  email: Scalars['String']['input'];
};

export type MutationLeaveWorkspaceArgs = {
  id: Scalars['VecticeId']['input'];
};

export type MutationMoveArtifactsArgs = {
  artifactsIdList: Array<Scalars['Float']['input']>;
  iterationId: Scalars['VecticeId']['input'];
  targetStepId?: InputMaybe<Scalars['Float']['input']>;
  targetStepIndex?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMoveProjectArgs = {
  projectMoveInput: ProjectMoveInput;
};

export type MutationPreviewMacroArgs = {
  assets: Array<CdtReportAssetInput>;
  content: Scalars['String']['input'];
};

export type MutationRecoverPasswordAfterRequestArgs = {
  newPassword: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type MutationRegisterDatasetArgs = {
  data: DatasetRegisterInput;
  iterationContext?: InputMaybe<IterationContextInput>;
  iterationId?: InputMaybe<Scalars['VecticeId']['input']>;
  phaseId?: InputMaybe<Scalars['VecticeId']['input']>;
  projectId?: InputMaybe<Scalars['VecticeId']['input']>;
};

export type MutationRegisterModelArgs = {
  data: ModelRegisterInput;
  iterationContext?: InputMaybe<IterationContextInput>;
  iterationId?: InputMaybe<Scalars['VecticeId']['input']>;
  phaseId?: InputMaybe<Scalars['VecticeId']['input']>;
  projectId?: InputMaybe<Scalars['VecticeId']['input']>;
};

export type MutationRemoveAssetsFromIterationArgs = {
  artifactsIdList: Array<Scalars['Float']['input']>;
  iterationId: Scalars['VecticeId']['input'];
};

export type MutationRemoveCdtReportArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveCdtTemplateArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveEmailDomainFromOrganizationArgs = {
  emailDomain: Scalars['String']['input'];
  organizationId: Scalars['Float']['input'];
};

export type MutationRemoveFindingArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveIterationArgs = {
  id: Scalars['VecticeId']['input'];
};

export type MutationRemoveIterationAssetsArgs = {
  data: IterationRemoveAssetsInput;
};

export type MutationRemoveIterationStepArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveLlmPromptArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemovePhaseArgs = {
  id: Scalars['VecticeId']['input'];
};

export type MutationRemoveRequirementArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveSectionArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveStepDefinitionArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveUserFromWorkspaceArgs = {
  userId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
};

export type MutationRenameEntityFileArgs = {
  id: Scalars['Float']['input'];
  newName: Scalars['String']['input'];
};

export type MutationRenameEntityMetadataArgs = {
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type MutationRequestReviewArgs = {
  review: ReviewCreateInput;
};

export type MutationRequestWorkspaceAccessArgs = {
  workspaceId: Scalars['VecticeId']['input'];
};

export type MutationResendUserInviteArgs = {
  id: Scalars['Float']['input'];
};

export type MutationResetOrganizationFeatureFlagArgs = {
  code: Scalars['String']['input'];
  organizationId: Scalars['Float']['input'];
};

export type MutationResolveWorkspaceAccessRequestArgs = {
  approve: Scalars['Boolean']['input'];
  requestId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
};

export type MutationRestoreHistoryArgs = {
  fileHistoryId: Scalars['String']['input'];
};

export type MutationRestorePhaseArgs = {
  id: Scalars['VecticeId']['input'];
};

export type MutationSetLicenseOptionsArgs = {
  licenseOptions: OrganizationLicenseOptionsInput;
  organizationId: Scalars['Float']['input'];
};

export type MutationSetOrganizationSettingsArgs = {
  configuration: OrganizationConfigInput;
};

export type MutationSetUserRoleArgs = {
  role: UserRole;
  userId: Scalars['Float']['input'];
};

export type MutationSetUserWorkspaceAccessibilityArgs = {
  accessibility: AccessibilityLevel;
  userId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
};

export type MutationUnlockFileArgs = {
  fileId: Scalars['Float']['input'];
};

export type MutationUpdateApiKeyArgs = {
  apiKey: ApiKeyUpdateInput;
  apiKeyId: Scalars['Float']['input'];
};

export type MutationUpdateCdtReportArgs = {
  data: CdtReportUpdateInput;
  id: Scalars['Float']['input'];
};

export type MutationUpdateCdtTemplateArgs = {
  data: CdtTemplateUpdateInput;
  id: Scalars['Float']['input'];
};

export type MutationUpdateCodeArgs = {
  code: CodeUpdateInput;
  codeId: Scalars['Float']['input'];
};

export type MutationUpdateColumnSourceDescriptionArgs = {
  columnSourceType: ColumnSourceType;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
};

export type MutationUpdateDatasetArgs = {
  dataset: DatasetUpdateInput;
  datasetId: Scalars['VecticeId']['input'];
};

export type MutationUpdateDatasetVersionArgs = {
  data: DatasetVersionUpdateInput;
  datasetVersionId: Scalars['VecticeId']['input'];
};

export type MutationUpdateEmailAvailabilityForEmailDomainArgs = {
  domain: Scalars['String']['input'];
  emailsEnabled: Scalars['Boolean']['input'];
};

export type MutationUpdateEmailAvailabilityForOrganizationArgs = {
  emailsEnabled: Scalars['Boolean']['input'];
  organizationId: Scalars['Float']['input'];
};

export type MutationUpdateEntityMetricArgs = {
  entityType: EntityMetricType;
  updateMetric: UpdateEntityMetricInput;
};

export type MutationUpdateEntityPropertiesArgs = {
  entityType: EntityPropertyType;
  updatePropertyList: Array<UpdateEntityPropertyInput>;
};

export type MutationUpdateEntityPropertyArgs = {
  entityType: EntityPropertyType;
  updateProperty: UpdateEntityPropertyInput;
};

export type MutationUpdateFeatureFlagAvailabilityArgs = {
  code: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type MutationUpdateFileContentArgs = {
  data: FileContentUpdateInput;
  fileId: Scalars['Float']['input'];
};

export type MutationUpdateFileHeaderFooterArgs = {
  data: FileHeaderFooterUpdateInput;
  fileId: Scalars['Float']['input'];
};

export type MutationUpdateFindingArgs = {
  data: FindingUpdateInput;
  id: Scalars['Float']['input'];
};

export type MutationUpdateIdentityProviderArgs = {
  identityProvider: KeycloakIdentityProviderUpdate;
  openIDConfig?: InputMaybe<KeycloakOpenIdConfigUpdate>;
  samlConfig?: InputMaybe<KeycloakSamlConfigUpdate>;
  socialConfig?: InputMaybe<KeycloakSocialConfigUpdate>;
};

export type MutationUpdateIterationArgs = {
  data: IterationUpdateInput;
  id: Scalars['VecticeId']['input'];
};

export type MutationUpdateIterationStepArgs = {
  id: Scalars['Float']['input'];
  updateModel: BaseStepUpdateInput;
};

export type MutationUpdateIterationStepArtifactArgs = {
  data: UpdateIterationStepArtifactInput;
  id: Scalars['Float']['input'];
};

export type MutationUpdateIterationStepContentArgs = {
  data?: InputMaybe<IterationStepUpdateInput>;
  id: Scalars['Float']['input'];
};

export type MutationUpdateLlmPromptArgs = {
  data: LlmPromptUpdateInput;
  id: Scalars['Float']['input'];
};

export type MutationUpdateLineageArgs = {
  data: LineageUpdateInput;
  id: Scalars['VecticeId']['input'];
  type: LineageType;
};

export type MutationUpdateModelArgs = {
  model: ModelUpdateInput;
  modelId: Scalars['VecticeId']['input'];
};

export type MutationUpdateModelVersionArgs = {
  data: ModelVersionUpdateInput;
  modelVersionId: Scalars['VecticeId']['input'];
};

export type MutationUpdateOrganizationArgs = {
  data: OrganizationUpdateInput;
  organizationId: Scalars['ID']['input'];
};

export type MutationUpdateOrganizationFeatureFlagAvailabilityArgs = {
  code: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  organizationId: Scalars['Float']['input'];
};

export type MutationUpdatePhaseArgs = {
  id: Scalars['VecticeId']['input'];
  updateModel: PhaseUpdateInput;
};

export type MutationUpdateProjectArgs = {
  data: ProjectUpdateInput;
  projectId: Scalars['VecticeId']['input'];
};

export type MutationUpdateRequirementArgs = {
  id: Scalars['Float']['input'];
  updateModel: BaseStepUpdateInput;
};

export type MutationUpdateSectionArgs = {
  id: Scalars['Float']['input'];
  updateModel: BaseStepUpdateInput;
};

export type MutationUpdateSectionContentArgs = {
  data?: InputMaybe<IterationStepUpdateInput>;
  id: Scalars['Float']['input'];
};

export type MutationUpdateStepDefinitionArgs = {
  id: Scalars['Float']['input'];
  updateModel: BaseStepUpdateInput;
};

export type MutationUpdateThreadArgs = {
  data: ThreadUpdateInput;
};

export type MutationUpdateThreadReplyArgs = {
  data: CommentUpdateInput;
};

export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
};

export type MutationUpdateWorkspaceArgs = {
  data: WorkspaceUpdateInput;
  id: Scalars['VecticeId']['input'];
};

export type MutationUpsertEntityMetadataArgs = {
  data: Array<EntityMetadataCreateInput>;
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityMetadataTypeEnum;
};

export type MutationUpsertEntityMetricsArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityMetricType;
  upsertMetricList: Array<UpsertEntityMetricInput>;
};

export type MutationUpsertEntityPropertiesArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityPropertyType;
  upsertPropertyList: Array<UpsertEntityPropertyInput>;
};

export enum NotificationType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED',
}

export enum OnboardingStatus {
  Skipped = 'Skipped',
  Step1 = 'Step1',
  Step2 = 'Step2',
  Step3 = 'Step3',
  Step4 = 'Step4',
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrgConfigOutput = {
  __typename?: 'OrgConfigOutput';
  deployment: ConfigDeployment;
  featureFlags: Array<FeatureFlagValue>;
  isTrialEnabled: Scalars['Boolean']['output'];
  organization: Organization;
  sendEmail: Scalars['Boolean']['output'];
  versions: Array<VersionOutput>;
};

export type OrgFeatureFlagValue = {
  __typename?: 'OrgFeatureFlagValue';
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  organizationId: Scalars['Float']['output'];
  overridden: Scalars['Boolean']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  configuration: OrganizationConfigOutput;
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  emailDomains: Array<EmailDomain>;
  emailsWhitelisted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: OrganizationLevel;
  licenseOptions: OrganizationLicenseOptions;
  name: Scalars['String']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type OrganizationConfigInput = {
  autoProvisioning?: InputMaybe<Scalars['Boolean']['input']>;
  dfStatisticsMaxColumns?: InputMaybe<Scalars['Float']['input']>;
  dfStatisticsRowThreshold?: InputMaybe<Scalars['Float']['input']>;
  dfStatisticsSampleRows?: InputMaybe<Scalars['Float']['input']>;
  emailNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  everyOneCanCreateWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  everyOneCanInviteUser?: InputMaybe<Scalars['Boolean']['input']>;
  passwordAuthenticationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationConfigOutput = {
  __typename?: 'OrganizationConfigOutput';
  autoProvisioning: Scalars['Boolean']['output'];
  dfStatisticsMaxColumns: Scalars['Float']['output'];
  dfStatisticsRowThreshold: Scalars['Float']['output'];
  dfStatisticsSampleRows: Scalars['Float']['output'];
  emailNotificationEnabled: Scalars['Boolean']['output'];
  everyOneCanCreateWorkspace: Scalars['Boolean']['output'];
  everyOneCanInviteUser: Scalars['Boolean']['output'];
  passwordAuthenticationEnabled: Scalars['Boolean']['output'];
};

export type OrganizationCreateInput = {
  adminUserEmail: Scalars['String']['input'];
  adminUserPassword: Scalars['String']['input'];
  allowSendEmails?: InputMaybe<Scalars['Boolean']['input']>;
  copyFromOrgId?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  everyOneCanCreateWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  everyOneCanInviteUser?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationFeatureFlag = {
  __typename?: 'OrganizationFeatureFlag';
  code: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  organization: Organization;
  organizationId: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type OrganizationFiltersInput = {
  levels?: InputMaybe<Array<OrganizationLevel>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export enum OrganizationLevel {
  Internal = 'INTERNAL',
  Other = 'OTHER',
  Production = 'PRODUCTION',
  Sandbox = 'SANDBOX',
  Trial = 'TRIAL',
}

export type OrganizationLicenseOptions = {
  __typename?: 'OrganizationLicenseOptions';
  isAskAIEnabled: Scalars['Boolean']['output'];
  isSingleSignOnEnabled: Scalars['Boolean']['output'];
};

export type OrganizationLicenseOptionsInput = {
  isAskAIEnabled?: Scalars['Boolean']['input'];
  isSingleSignOnEnabled: Scalars['Boolean']['input'];
};

export type OrganizationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<OrganizationLevel>;
};

export type OtherCodeVersion = {
  __typename?: 'OtherCodeVersion';
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  firstAdditionalInformation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  secondAdditionalInformation?: Maybe<Scalars['String']['output']>;
  thirdAdditionalInformation?: Maybe<Scalars['String']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  version: Scalars['Float']['output'];
};

export type PageIndexInput = {
  index: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type PageInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  beforeCursor?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  size: Scalars['Int']['input'];
};

export type PageOutput = {
  __typename?: 'PageOutput';
  afterCursor?: Maybe<Scalars['String']['output']>;
  beforeCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  index?: Maybe<Scalars['Float']['output']>;
  size: Scalars['Int']['output'];
};

export type PaginatedApiKeyOutput = {
  __typename?: 'PaginatedApiKeyOutput';
  items: Array<ApiKey>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedAskAiAssetResponse = {
  __typename?: 'PaginatedAskAIAssetResponse';
  items: Array<AskAiAssetResponse>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedCdtReportResponse = {
  __typename?: 'PaginatedCDTReportResponse';
  items: Array<CdtReport>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedCdtTemplateResponse = {
  __typename?: 'PaginatedCDTTemplateResponse';
  items: Array<CdtTemplate>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedCodeResponse = {
  __typename?: 'PaginatedCodeResponse';
  items: Array<Code>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedColumn = {
  __typename?: 'PaginatedColumn';
  items: Array<ColumnOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedDbSourceResponse = {
  __typename?: 'PaginatedDBSourceResponse';
  items: Array<DbTableSource>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedDataSetResponse = {
  __typename?: 'PaginatedDataSetResponse';
  items: Array<DataSet>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedDataSetVersionResponse = {
  __typename?: 'PaginatedDataSetVersionResponse';
  items: Array<DataSetVersion>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedDatasetFilesSourceResponse = {
  __typename?: 'PaginatedDatasetFilesSourceResponse';
  items: Array<FileSource>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedDbSourceColumnsList = {
  __typename?: 'PaginatedDbSourceColumnsList';
  items: Array<DbColumnSource>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedEntityFileList = {
  __typename?: 'PaginatedEntityFileList';
  items: Array<EntityFile>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedEntityMetricResponse = {
  __typename?: 'PaginatedEntityMetricResponse';
  items: Array<EntityMetric>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedEntityPropertyResponse = {
  __typename?: 'PaginatedEntityPropertyResponse';
  items: Array<EntityProperty>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedFileHistoryResponse = {
  __typename?: 'PaginatedFileHistoryResponse';
  items: Array<FileHistoryWithSessionOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedFileSourceColumnsList = {
  __typename?: 'PaginatedFileSourceColumnsList';
  items: Array<FileSourceColumn>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedFindingResponse = {
  __typename?: 'PaginatedFindingResponse';
  items: Array<Finding>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedIterationResponse = {
  __typename?: 'PaginatedIterationResponse';
  items: Array<Iteration>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedIterationStepArtifactResponse = {
  __typename?: 'PaginatedIterationStepArtifactResponse';
  items: Array<IterationStepArtifact>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedIterationStepResponse = {
  __typename?: 'PaginatedIterationStepResponse';
  items: Array<IterationStep>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedIterationStepSubResponse = {
  __typename?: 'PaginatedIterationStepSubResponse';
  items: Array<IterationStep>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedIterationSubResponse = {
  __typename?: 'PaginatedIterationSubResponse';
  items: Array<Iteration>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedLlmPromptResponseOutput = {
  __typename?: 'PaginatedLLMPromptResponseOutput';
  items: Array<LlmPrompt>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedLineageArtifactResponse = {
  __typename?: 'PaginatedLineageArtifactResponse';
  items: Array<LineageArtifact>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedModelResponse = {
  __typename?: 'PaginatedModelResponse';
  items: Array<Model>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedModelVersionResponse = {
  __typename?: 'PaginatedModelVersionResponse';
  items: Array<ModelVersion>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedOrganizationList = {
  __typename?: 'PaginatedOrganizationList';
  items: Array<Organization>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedPhaseCompletedWithCompletionDate = {
  __typename?: 'PaginatedPhaseCompletedWithCompletionDate';
  items: Array<PhaseCompletedWithCompletionDate>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedPhaseResponseOutput = {
  __typename?: 'PaginatedPhaseResponseOutput';
  items: Array<Phase>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedPhaseStatusCountWithTotalPerUserOutput = {
  __typename?: 'PaginatedPhaseStatusCountWithTotalPerUserOutput';
  items: Array<PhaseStatusCountWithTotalPerUserOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedPhaseSubResponse = {
  __typename?: 'PaginatedPhaseSubResponse';
  items: Array<Phase>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedProjectCompletedWithCompletionDate = {
  __typename?: 'PaginatedProjectCompletedWithCompletionDate';
  items: Array<ProjectCompletedWithCompletionDate>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedProjectResponse = {
  __typename?: 'PaginatedProjectResponse';
  items: Array<Project>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedReviewCommentResponse = {
  __typename?: 'PaginatedReviewCommentResponse';
  items: Array<ReviewComment>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedReviewCommentSubResponse = {
  __typename?: 'PaginatedReviewCommentSubResponse';
  items: Array<ReviewComment>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedReviewResponse = {
  __typename?: 'PaginatedReviewResponse';
  items: Array<Review>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedReviewStatusCountWithTotalPerUserOutput = {
  __typename?: 'PaginatedReviewStatusCountWithTotalPerUserOutput';
  items: Array<ReviewStatusCountWithTotalPerUserOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedStepDefinitionResponseOutput = {
  __typename?: 'PaginatedStepDefinitionResponseOutput';
  items: Array<StepDefinition>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedThreadReplyResponse = {
  __typename?: 'PaginatedThreadReplyResponse';
  items: Array<ThreadReply>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedThreadResponse = {
  __typename?: 'PaginatedThreadResponse';
  items: Array<Thread>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedTopAssetCreator = {
  __typename?: 'PaginatedTopAssetCreator';
  items: Array<WorkspaceNumberAssetsByCreator>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUniqueAlgorithmNamesOutput = {
  __typename?: 'PaginatedUniqueAlgorithmNamesOutput';
  items: Array<AlgorithmNameOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUniqueInventoryReferencesOutput = {
  __typename?: 'PaginatedUniqueInventoryReferencesOutput';
  items: Array<InventoryReferenceOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUserActivityResponse = {
  __typename?: 'PaginatedUserActivityResponse';
  items: Array<UserActivity>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUserAndInvitesResponse = {
  __typename?: 'PaginatedUserAndInvitesResponse';
  items: Array<UserAndInvites>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUserInviteResponse = {
  __typename?: 'PaginatedUserInviteResponse';
  items: Array<UserInvite>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUserList = {
  __typename?: 'PaginatedUserList';
  items: Array<User>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUserListWithActivityCounts = {
  __typename?: 'PaginatedUserListWithActivityCounts';
  items: Array<UserWithActivityCounts>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUserNotificationResponse = {
  __typename?: 'PaginatedUserNotificationResponse';
  items: Array<UserNotification>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedUserResponse = {
  __typename?: 'PaginatedUserResponse';
  items: Array<User>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedWorkspaceAccessRequestResponse = {
  __typename?: 'PaginatedWorkspaceAccessRequestResponse';
  items: Array<WorkspaceAccessRequest>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedWorkspaceCountPerUserOutput = {
  __typename?: 'PaginatedWorkspaceCountPerUserOutput';
  items: Array<WorkspaceCountPerUser>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedWorkspaceOngoingPhasesOutput = {
  __typename?: 'PaginatedWorkspaceOngoingPhasesOutput';
  items: Array<WorkspaceOngoingPhasesOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedWorkspaceOngoingProjectsOutput = {
  __typename?: 'PaginatedWorkspaceOngoingProjectsOutput';
  items: Array<WorkspaceOngoingProjectsOutput>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedWorkspaceResponseOutput = {
  __typename?: 'PaginatedWorkspaceResponseOutput';
  items: Array<Workspace>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export type PaginatedWorkspaceUserList = {
  __typename?: 'PaginatedWorkspaceUserList';
  items: Array<WorkspaceUser>;
  page: PageOutput;
  total: Scalars['Int']['output'];
};

export enum PasswordRecoveryRequestStatus {
  AlreadySent = 'ALREADY_SENT',
  Expired = 'EXPIRED',
  NotFound = 'NOT_FOUND',
  PasswordAuthenticationDisabled = 'PASSWORD_AUTHENTICATION_DISABLED',
  Resent = 'RESENT',
  ResentFailed = 'RESENT_FAILED',
  Valid = 'VALID',
}

export type Phase = {
  __typename?: 'Phase';
  activeReview?: Maybe<Review>;
  activeReviewId?: Maybe<Scalars['Float']['output']>;
  /** @deprecated For retrocompatibility reason only */
  alias?: Maybe<Scalars['String']['output']>;
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enforceRequirementsAsSections: Scalars['Boolean']['output'];
  file: DocumentationFile;
  fileId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  index: Scalars['Float']['output'];
  iterationsCount: IterationsCounts;
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['Float']['output']>;
  parent: Project;
  parentId: Scalars['Float']['output'];
  pristineFile: Scalars['Boolean']['output'];
  reviewsCount: ReviewsCounts;
  status: DocumentationPageStatus;
  stepsCount: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
};

export type PhaseCompletedWithCompletionDate = {
  __typename?: 'PhaseCompletedWithCompletionDate';
  completionDate: Scalars['DateTime']['output'];
  entity: Phase;
};

export type PhaseCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enforceRequirementsAsSections?: InputMaybe<Scalars['Boolean']['input']>;
  index?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  ownerId?: InputMaybe<Scalars['Float']['input']>;
  status?: DocumentationPageStatus;
};

export type PhaseDuplicateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PhaseFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  onlyMine?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['VecticeId']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  status?: InputMaybe<Array<DocumentationPageStatus>>;
  withIterations?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PhaseStatusCountWithTotalOutput = {
  __typename?: 'PhaseStatusCountWithTotalOutput';
  items: Array<PhaseStatusWithCountOutput>;
  total: Scalars['Float']['output'];
};

export type PhaseStatusCountWithTotalPerUserOutput = {
  __typename?: 'PhaseStatusCountWithTotalPerUserOutput';
  items: Array<PhaseStatusWithCountOutput>;
  total: Scalars['Float']['output'];
  user: User;
};

export type PhaseStatusWithCountOutput = {
  __typename?: 'PhaseStatusWithCountOutput';
  count: Scalars['Float']['output'];
  status: DocumentationPageStatus;
};

export type PhaseUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enforceRequirementsAsSections?: InputMaybe<Scalars['Boolean']['input']>;
  index?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<DocumentationPageStatus>;
};

export type Project = {
  __typename?: 'Project';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  owner: User;
  ownerId: Scalars['Float']['output'];
  /** @deprecated Use getPhaseList instead */
  phases: Array<Phase>;
  phasesCounts: DocumentationCounts;
  stats: ProjectStats;
  status: ProjectStatus;
  templateUsed?: Maybe<Scalars['String']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
  workspace?: Maybe<Workspace>;
  workspaceId: Scalars['Float']['output'];
};

export type ProjectStatsArgs = {
  contributorsLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type ProjectCompletedWithCompletionDate = {
  __typename?: 'ProjectCompletedWithCompletionDate';
  completionDate: Scalars['DateTime']['output'];
  entity: Project;
};

export type ProjectCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duplicateProjectId?: InputMaybe<Scalars['VecticeId']['input']>;
  name: Scalars['String']['input'];
  templateName?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectDuplicateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['VecticeId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  targetWorkspaceId: Scalars['VecticeId']['input'];
};

export type ProjectListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  myRecent?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export type ProjectMoveInput = {
  id: Scalars['VecticeId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  targetWorkspaceId: Scalars['VecticeId']['input'];
};

export type ProjectStats = {
  __typename?: 'ProjectStats';
  contributors: Array<User>;
  contributorsCount: Scalars['Float']['output'];
  currentPhaseName?: Maybe<Scalars['String']['output']>;
  datasetVersionsPeriodCount?: Maybe<Scalars['Float']['output']>;
  datasetVersionsTotalCount: Scalars['Float']['output'];
  lastActivityDate?: Maybe<Scalars['DateTime']['output']>;
  lastPhaseStatusChange?: Maybe<Scalars['DateTime']['output']>;
  modelVersionsPeriodCount?: Maybe<Scalars['Float']['output']>;
  modelVersionsTotalCount: Scalars['Float']['output'];
};

export enum ProjectStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export type ProjectStatusCountWithTotalOutput = {
  __typename?: 'ProjectStatusCountWithTotalOutput';
  items: Array<ProjectStatusWithCountOutput>;
  total: Scalars['Float']['output'];
};

export type ProjectStatusWithCountOutput = {
  __typename?: 'ProjectStatusWithCountOutput';
  count: Scalars['Float']['output'];
  status: ProjectStatus;
};

export type ProjectUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Float']['input']>;
};

export enum Provider {
  Google = 'GOOGLE',
  Oidc = 'OIDC',
  Saml = 'SAML',
}

export type PublicConfigOutput = {
  __typename?: 'PublicConfigOutput';
  deployment: ConfigDeployment;
  featureFlags: Array<FeatureFlagValue>;
  isTrialEnabled: Scalars['Boolean']['output'];
  sendEmail: Scalars['Boolean']['output'];
  versions: Array<VersionOutput>;
};

export type Query = {
  __typename?: 'Query';
  CDTReport: CdtReport;
  CDTReports: PaginatedCdtReportResponse;
  CDTTemplate: CdtTemplate;
  CDTTemplates: PaginatedCdtTemplateResponse;
  Finding: Finding;
  Findings: PaginatedFindingResponse;
  GlobalTemplate: GlobalTemplate;
  attachments: Array<AttachmentOutput>;
  authenticateWithApiKey: MetadataAuthOuput;
  authenticateWithPassword: RealmInformation;
  authenticateWithUniqueCode: RealmInformation;
  checkCDTReportNameAvailability: Scalars['Boolean']['output'];
  checkCDTTemplateNameAvailability: Scalars['Boolean']['output'];
  checkDatasetNameAvailability: Scalars['Boolean']['output'];
  checkIfApiKeyNameExists: Scalars['Boolean']['output'];
  checkIterationNameAvailability: Scalars['Boolean']['output'];
  /** @deprecated Use checkSectionNameAvailability instead. */
  checkIterationStepNameAvailability: Scalars['Boolean']['output'];
  checkLLMPromptNameAvailability: Scalars['Boolean']['output'];
  checkModelNameAvailability: Scalars['Boolean']['output'];
  checkOrganizationEmailDomainAvailability: Scalars['Boolean']['output'];
  checkPhaseNameAvailability: Scalars['Boolean']['output'];
  checkProjectNameAvailability: Scalars['Boolean']['output'];
  checkRecoverPasswordRequest: PasswordRecoveryRequestStatus;
  checkRequirementNameAvailability: Scalars['Boolean']['output'];
  checkSectionNameAvailability: Scalars['Boolean']['output'];
  /** @deprecated Use checkRequirementNameAvailability instead. */
  checkStepDefinitionNameAvailability: Scalars['Boolean']['output'];
  checkWorkspaceNameAvailability: Scalars['Boolean']['output'];
  countUnacknowledgedUserNotifications: Scalars['Float']['output'];
  countUnseenUserNotifications: Scalars['Float']['output'];
  dbSources: PaginatedDbSourceResponse;
  entityMetadata: EntityMetadata;
  entityMetadataContent: Scalars['JSONObject']['output'];
  exportMacros: Scalars['Boolean']['output'];
  exportProject: Scalars['Boolean']['output'];
  exportWorkspace: Scalars['Boolean']['output'];
  fileSources: PaginatedDatasetFilesSourceResponse;
  getAccessibilityLevels: Array<AccessibilityLevel>;
  getActiveIterationOrCreateOne: Iteration;
  getActivityInsights: ActivityInsightsOutput;
  getAllOrganizations: PaginatedOrganizationList;
  getApiKeyList: PaginatedApiKeyOutput;
  getCode: Code;
  getCodeByName: Code;
  getCodeList: PaginatedCodeResponse;
  getCodeVersion: CodeVersion;
  getCodeVersionByName: CodeVersion;
  getCompletedReviewsCountPerUser: PaginatedReviewStatusCountWithTotalPerUserOutput;
  getContributorList: PaginatedUserResponse;
  getDBSource: DbTableSource;
  getDBSourceColumnsList: PaginatedDbSourceColumnsList;
  /** @deprecated use dbSources instead */
  getDBSourceList: PaginatedDbSourceResponse;
  /** @deprecated use getDatasetList instead */
  getDataSetList: PaginatedDataSetResponse;
  getDataset: DataSet;
  getDatasetByName: DataSet;
  getDatasetFilesSource: FileSource;
  /** @deprecated use fileSources instead */
  getDatasetFilesSourceList: PaginatedDatasetFilesSourceResponse;
  getDatasetList: PaginatedDataSetResponse;
  getDatasetSourceOriginOfficialValues: Array<DatasetSourceOrigin>;
  getDatasetVersion: DataSetVersion;
  getDatasetVersionByName: DataSetVersion;
  getDatasetVersionsList: PaginatedDataSetVersionResponse;
  getEntityFileById: EntityFile;
  getEntityFileList: PaginatedEntityFileList;
  getEntityMetric: EntityMetric;
  getEntityMetricsByParentId: PaginatedEntityMetricResponse;
  getEntityPropertiesByParentId: PaginatedEntityPropertyResponse;
  getEntityProperty: EntityProperty;
  getEpicFeatureFlags: Array<EpicFeatureFlag>;
  getExportedArchivesList: Array<Archive>;
  getFeatureFlagOrgOverrides: Array<OrganizationFeatureFlag>;
  getFileById: DocumentationFile;
  getFileHistory: FileHistory;
  getFileHistoryList: PaginatedFileHistoryResponse;
  getFileSourceColumnsList: PaginatedFileSourceColumnsList;
  getIdentityProvider: KeycloakIdentityProvider;
  getIdentityProviderList: Array<KeycloakIdentityProvider>;
  getIterationById: Iteration;
  getIterationByIndex: Iteration;
  getIterationList: PaginatedIterationResponse;
  /** @deprecated Use sections instead. */
  getIterationStepList: PaginatedIterationStepResponse;
  getJobArtifactByJobRunId: PaginatedLineageArtifactResponse;
  getJobRunById: Lineage;
  getLastAssets: PaginatedUserActivityResponse;
  /** @deprecated Use retrieveIteration instead */
  getLastUpdatedIterationOrCreateOne: Iteration;
  /** @deprecated Not used anymore */
  getListWorkspaceAccessRequests: PaginatedWorkspaceAccessRequestResponse;
  getMetricKeysOfEntity: Array<Scalars['String']['output']>;
  getModelById: Model;
  getModelByName: Model;
  getModelList: PaginatedModelResponse;
  getModelVersion: ModelVersion;
  getModelVersionByName: ModelVersion;
  getModelVersionsList: PaginatedModelVersionResponse;
  getMostRecentlyCompletedPhases: PaginatedPhaseCompletedWithCompletionDate;
  getMostRecentlyCompletedProjects: PaginatedProjectCompletedWithCompletionDate;
  /** get the config for authenticated org */
  getOrgConfig: OrgConfigOutput;
  getOrganization: Organization;
  getOrganizationFeatureFlags: Array<OrgFeatureFlagValue>;
  getOrganizationUser: User;
  /** @deprecated Use users instead */
  getOrganizationUsersForOrgAdmin: PaginatedUserList;
  getOrganizationUsersForSuperAdmin: PaginatedUserList;
  /** @deprecated Non paginated, use users instead */
  getOrganizationUsersForUser: Array<User>;
  getOrganizationWorkspaceList: PaginatedWorkspaceResponseOutput;
  getPendingReviewsCountPerUser: PaginatedWorkspaceCountPerUserOutput;
  getPhaseById: Phase;
  getPhaseByName: Phase;
  getPhaseList: PaginatedPhaseResponseOutput;
  getPhaseStatusCounts: PhaseStatusCountWithTotalOutput;
  getPhaseStatusCountsPerOwner: PaginatedPhaseStatusCountWithTotalPerUserOutput;
  getProjectById: Project;
  getProjectByName: Project;
  getProjectList: PaginatedProjectResponse;
  getProjectStatusCounts: ProjectStatusCountWithTotalOutput;
  getPropertyKeysOfEntity: Array<Scalars['String']['output']>;
  /** get the config without any authentication */
  getPublicConfig: PublicConfigOutput;
  getRealmInformationFromEmail: RealmInformation;
  getReviewById: Review;
  getReviewCommentList: PaginatedReviewCommentResponse;
  getReviewList: PaginatedReviewResponse;
  getStepById: IterationStep;
  getStepByName: IterationStep;
  getStepDefinitionById: StepDefinition;
  getStepDefinitionByName: StepDefinition;
  /** @deprecated Use requirements instead. */
  getStepDefinitionList: PaginatedStepDefinitionResponseOutput;
  getTopAssetsCreators: PaginatedTopAssetCreator;
  getUniqueAlgorithmNames: PaginatedUniqueAlgorithmNamesOutput;
  getUniqueInventoryReferences: PaginatedUniqueInventoryReferencesOutput;
  getUserActivityList: PaginatedUserActivityResponse;
  getUserAndInvitesList: PaginatedUserAndInvitesResponse;
  getUserInviteWithAuthInfo: UserInviteWithAuthInfoOutput;
  getUserInvitesList: PaginatedUserInviteResponse;
  getUserNotificationById: UserNotification;
  getUserNotificationList: PaginatedUserNotificationResponse;
  getUserWorkspaceAccessibility: AccessibilityLevel;
  getUserWorkspaceList: PaginatedWorkspaceResponseOutput;
  /** @deprecated It's used for frontend as it's not ready for paginated workspaces but you should consider using getUserWorkspaceList instead */
  getUserWorkspaceListWithoutPagination: Array<Workspace>;
  getUsingAssets: PaginatedLineageArtifactResponse;
  getWorkspaceAccessRequest: WorkspaceAccessRequest;
  getWorkspaceActivityTrend: Array<WorkspaceActivityTrendOutput>;
  getWorkspaceById: Workspace;
  getWorkspaceByName: Workspace;
  getWorkspaceCompletedIterationsCountPerUser: PaginatedWorkspaceCountPerUserOutput;
  getWorkspaceCompletedPhasesCountPerUser: PaginatedWorkspaceCountPerUserOutput;
  getWorkspaceMemberList: PaginatedUserList;
  getWorkspaceMostActiveUsers: PaginatedUserListWithActivityCounts;
  getWorkspaceOngoingPhases: PaginatedWorkspaceOngoingPhasesOutput;
  getWorkspaceOngoingProjects: PaginatedWorkspaceOngoingProjectsOutput;
  getWorkspaceStats: WorkspaceStatsResult;
  getWorkspaceUserList: PaginatedWorkspaceUserList;
  /** @deprecated Replaced by getWorkspaceUserList */
  getWorkspaceUsers: Array<User>;
  hasColumn: Scalars['Boolean']['output'];
  isFeatureFlagEnabled: Scalars['Boolean']['output'];
  listSuperAdminUsers: Array<User>;
  llmPrompts: PaginatedLlmPromptResponseOutput;
  logout: Scalars['Boolean']['output'];
  requirements: PaginatedStepDefinitionResponseOutput;
  retrieveIteration: RetrieveIterationOutput;
  searchAllAskAIAssets: AssetsAskAiSearchResponse;
  searchAssets: AssetSearchResponse;
  sections: PaginatedIterationStepResponse;
  sourceColumn: ColumnOutput;
  sourceColumns: PaginatedColumn;
  threadReplies: PaginatedThreadReplyResponse;
  threads: PaginatedThreadResponse;
  users: PaginatedUserList;
  whoAmI: UserAndDefaultWorkspaceOutput;
};

export type QueryCdtReportArgs = {
  id: Scalars['Float']['input'];
};

export type QueryCdtReportsArgs = {
  filters: CdtReportFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  type?: InputMaybe<CdtReportQueryType>;
};

export type QueryCdtTemplateArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCdtTemplatesArgs = {
  filters?: InputMaybe<CdtTemplateFilterInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryFindingArgs = {
  id: Scalars['Float']['input'];
};

export type QueryFindingsArgs = {
  filters: FindingFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  type?: InputMaybe<FindingQueryType>;
};

export type QueryGlobalTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryAttachmentsArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityMetadataTypeEnum;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAuthenticateWithApiKeyArgs = {
  apiKey: Scalars['String']['input'];
  clientIp?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAuthenticateWithPasswordArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type QueryAuthenticateWithUniqueCodeArgs = {
  clientId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  realm: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCheckCdtReportNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryCheckCdtTemplateNameAvailabilityArgs = {
  isRichText?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryCheckDatasetNameAvailabilityArgs = {
  datasetId?: InputMaybe<Scalars['VecticeId']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['VecticeId']['input'];
};

export type QueryCheckIfApiKeyNameExistsArgs = {
  apiKeyName: Scalars['String']['input'];
};

export type QueryCheckIterationNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['VecticeId']['input']>;
};

export type QueryCheckIterationStepNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryCheckLlmPromptNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
  type: LlmPromptTypeEnum;
};

export type QueryCheckModelNameAvailabilityArgs = {
  modelId?: InputMaybe<Scalars['VecticeId']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['VecticeId']['input'];
};

export type QueryCheckOrganizationEmailDomainAvailabilityArgs = {
  userEmail: Scalars['String']['input'];
};

export type QueryCheckPhaseNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['VecticeId']['input']>;
};

export type QueryCheckProjectNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['VecticeId']['input']>;
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryCheckRecoverPasswordRequestArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryCheckRequirementNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryCheckSectionNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryCheckStepDefinitionNameAvailabilityArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryCheckWorkspaceNameAvailabilityArgs = {
  exceptId?: InputMaybe<Scalars['VecticeId']['input']>;
  name: Scalars['String']['input'];
};

export type QueryDbSourcesArgs = {
  filters: DataSourceListFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryEntityMetadataArgs = {
  id: Scalars['Float']['input'];
};

export type QueryEntityMetadataContentArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityMetadataTypeEnum;
  table: Scalars['String']['input'];
};

export type QueryExportMacrosArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryExportProjectArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['VecticeId']['input'];
  organizationId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryExportWorkspaceArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['VecticeId']['input'];
  organizationId?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryFileSourcesArgs = {
  filters: DataSourceListFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetActiveIterationOrCreateOneArgs = {
  data?: InputMaybe<IterationCreateInput>;
  phaseId: Scalars['VecticeId']['input'];
};

export type QueryGetActivityInsightsArgs = {
  numberOfDays?: InputMaybe<Scalars['Float']['input']>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetAllOrganizationsArgs = {
  filters?: InputMaybe<OrganizationFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetApiKeyListArgs = {
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  userId: Scalars['Float']['input'];
};

export type QueryGetCodeArgs = {
  codeId: Scalars['Float']['input'];
};

export type QueryGetCodeByNameArgs = {
  name: Scalars['String']['input'];
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetCodeListArgs = {
  filters?: InputMaybe<CodeListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetCodeVersionArgs = {
  codeVersionId: Scalars['Float']['input'];
};

export type QueryGetCodeVersionByNameArgs = {
  codeId: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type QueryGetCompletedReviewsCountPerUserArgs = {
  numberOfDays?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetContributorListArgs = {
  filters?: InputMaybe<ContributorListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetDbSourceArgs = {
  dbSourceId: Scalars['Float']['input'];
};

export type QueryGetDbSourceColumnsListArgs = {
  dbSourceId: Scalars['Float']['input'];
  filters?: InputMaybe<TextSearchListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetDbSourceListArgs = {
  datasetSourceId: Scalars['Float']['input'];
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetDataSetListArgs = {
  filters?: InputMaybe<DataSetListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetDatasetArgs = {
  datasetId: Scalars['VecticeId']['input'];
};

export type QueryGetDatasetByNameArgs = {
  name: Scalars['String']['input'];
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetDatasetFilesSourceArgs = {
  fileSourceId: Scalars['Float']['input'];
};

export type QueryGetDatasetFilesSourceListArgs = {
  datasetSourceId: Scalars['Float']['input'];
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetDatasetListArgs = {
  filters?: InputMaybe<DataSetListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetDatasetVersionArgs = {
  datasetVersionId: Scalars['VecticeId']['input'];
};

export type QueryGetDatasetVersionByNameArgs = {
  datasetId: Scalars['VecticeId']['input'];
  name: Scalars['String']['input'];
};

export type QueryGetDatasetVersionsListArgs = {
  datasetId: Scalars['VecticeId']['input'];
  filters?: InputMaybe<DataSetVersionFilterInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetEntityFileByIdArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetEntityFileListArgs = {
  entityId: Scalars['VecticeId']['input'];
  filters?: InputMaybe<TextSearchListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  type: EntityFilesTypeEnum;
};

export type QueryGetEntityMetricArgs = {
  entityType: EntityMetricType;
  metricId: Scalars['Float']['input'];
};

export type QueryGetEntityMetricsByParentIdArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityMetricType;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetEntityPropertiesByParentIdArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityPropertyType;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetEntityPropertyArgs = {
  entityType: EntityPropertyType;
  propertyId: Scalars['Float']['input'];
};

export type QueryGetFeatureFlagOrgOverridesArgs = {
  code: Scalars['String']['input'];
};

export type QueryGetFileByIdArgs = {
  fileId: Scalars['Float']['input'];
};

export type QueryGetFileHistoryArgs = {
  fileHistoryId: Scalars['String']['input'];
};

export type QueryGetFileHistoryListArgs = {
  fileId: Scalars['Float']['input'];
  filters?: InputMaybe<FileHistoryFilter>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetFileSourceColumnsListArgs = {
  fileSourceId: Scalars['Float']['input'];
  filters?: InputMaybe<TextSearchListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetIdentityProviderArgs = {
  alias: Scalars['String']['input'];
};

export type QueryGetIterationByIdArgs = {
  id: Scalars['VecticeId']['input'];
};

export type QueryGetIterationByIndexArgs = {
  index: Scalars['Float']['input'];
  phaseId: Scalars['VecticeId']['input'];
};

export type QueryGetIterationListArgs = {
  filters: IterationFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetIterationStepListArgs = {
  filters?: InputMaybe<IterationStepFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  parentId: Scalars['VecticeId']['input'];
};

export type QueryGetJobArtifactByJobRunIdArgs = {
  lineageId: Scalars['Float']['input'];
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetJobRunByIdArgs = {
  lineageId: Scalars['Float']['input'];
};

export type QueryGetLastAssetsArgs = {
  page?: InputMaybe<PageIndexInput>;
  targetTypes?: InputMaybe<Array<ActivityTargetType>>;
};

export type QueryGetLastUpdatedIterationOrCreateOneArgs = {
  phaseId: Scalars['VecticeId']['input'];
};

export type QueryGetListWorkspaceAccessRequestsArgs = {
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetMetricKeysOfEntityArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityType;
};

export type QueryGetModelByIdArgs = {
  modelId: Scalars['VecticeId']['input'];
};

export type QueryGetModelByNameArgs = {
  name: Scalars['String']['input'];
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetModelListArgs = {
  filters?: InputMaybe<ModelListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetModelVersionArgs = {
  modelVersionId: Scalars['VecticeId']['input'];
};

export type QueryGetModelVersionByNameArgs = {
  modelId: Scalars['VecticeId']['input'];
  name: Scalars['String']['input'];
};

export type QueryGetModelVersionsListArgs = {
  filters?: InputMaybe<ModelVersionListFiltersInput>;
  modelId: Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetMostRecentlyCompletedPhasesArgs = {
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetMostRecentlyCompletedProjectsArgs = {
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetOrganizationArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetOrganizationFeatureFlagsArgs = {
  organizationId: Scalars['Float']['input'];
};

export type QueryGetOrganizationUserArgs = {
  userId: Scalars['Float']['input'];
};

export type QueryGetOrganizationUsersForOrgAdminArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetOrganizationUsersForSuperAdminArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  organizationId?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetOrganizationUsersForUserArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
};

export type QueryGetOrganizationWorkspaceListArgs = {
  filters?: InputMaybe<WorkspaceListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetPendingReviewsCountPerUserArgs = {
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetPhaseByIdArgs = {
  id: Scalars['VecticeId']['input'];
};

export type QueryGetPhaseByNameArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
};

export type QueryGetPhaseListArgs = {
  filters: PhaseFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetPhaseStatusCountsArgs = {
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetPhaseStatusCountsPerOwnerArgs = {
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetProjectByIdArgs = {
  projectId: Scalars['VecticeId']['input'];
};

export type QueryGetProjectByNameArgs = {
  name: Scalars['String']['input'];
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetProjectListArgs = {
  filters?: InputMaybe<ProjectListFiltersInput>;
  numberOfDays?: InputMaybe<Scalars['Float']['input']>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
  workspaceId?: InputMaybe<Scalars['VecticeId']['input']>;
};

export type QueryGetProjectStatusCountsArgs = {
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetPropertyKeysOfEntityArgs = {
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityType;
};

export type QueryGetRealmInformationFromEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryGetReviewByIdArgs = {
  id: Scalars['VecticeId']['input'];
};

export type QueryGetReviewCommentListArgs = {
  filters: ReviewCommentFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetReviewListArgs = {
  filters: ReviewFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetStepByIdArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetStepByNameArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
};

export type QueryGetStepDefinitionByIdArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetStepDefinitionByNameArgs = {
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
};

export type QueryGetStepDefinitionListArgs = {
  filters: BaseDocumentationListFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetTopAssetsCreatorsArgs = {
  numberOfDays?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetUniqueAlgorithmNamesArgs = {
  filters?: InputMaybe<AlgorithmNameFiltersInput>;
  modelIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  order?: InputMaybe<AlgorithmNameOrderInput>;
  page?: InputMaybe<PageInput>;
  projectIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type QueryGetUniqueInventoryReferencesArgs = {
  filters?: InputMaybe<AlgorithmNameFiltersInput>;
  modelIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  order?: InputMaybe<InventoryReferenceOrderInput>;
  page?: InputMaybe<PageInput>;
  projectIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type QueryGetUserActivityListArgs = {
  filters?: InputMaybe<UserActivityListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type QueryGetUserAndInvitesListArgs = {
  filters?: InputMaybe<UserAndInvitesFiltersInput>;
  order?: InputMaybe<ListOrderUserAndInvitesInput>;
  organizationId?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageIndexInput>;
};

export type QueryGetUserInviteWithAuthInfoArgs = {
  inviteToken: Scalars['String']['input'];
};

export type QueryGetUserInvitesListArgs = {
  filters?: InputMaybe<UserInviteListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  organizationId?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetUserNotificationByIdArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetUserNotificationListArgs = {
  filters?: InputMaybe<UserNotificationListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetUserWorkspaceAccessibilityArgs = {
  userId?: InputMaybe<Scalars['Float']['input']>;
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetUserWorkspaceListArgs = {
  filters?: InputMaybe<WorkspaceListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetUserWorkspaceListWithoutPaginationArgs = {
  filters?: InputMaybe<WorkspaceListFiltersInput>;
};

export type QueryGetUsingAssetsArgs = {
  datasetVersionId: Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetWorkspaceAccessRequestArgs = {
  requestId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetWorkspaceActivityTrendArgs = {
  numberOfDays?: Scalars['Float']['input'];
  userTimezone: Scalars['String']['input'];
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetWorkspaceByIdArgs = {
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetWorkspaceByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryGetWorkspaceCompletedIterationsCountPerUserArgs = {
  numberOfDays?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetWorkspaceCompletedPhasesCountPerUserArgs = {
  numberOfDays?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetWorkspaceMemberListArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetWorkspaceMostActiveUsersArgs = {
  activityCountTypes: Array<ActivityCountType>;
  numberOfDays?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageIndexInput>;
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetWorkspaceOngoingPhasesArgs = {
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetWorkspaceOngoingProjectsArgs = {
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList: Array<Scalars['VecticeId']['input']>;
};

export type QueryGetWorkspaceStatsArgs = {
  days: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetWorkspaceUserListArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryGetWorkspaceUsersArgs = {
  workspaceId: Scalars['VecticeId']['input'];
};

export type QueryHasColumnArgs = {
  column: Scalars['String']['input'];
  datasetVersionId: Scalars['VecticeId']['input'];
};

export type QueryIsFeatureFlagEnabledArgs = {
  code: Scalars['String']['input'];
};

export type QueryLlmPromptsArgs = {
  filters: LlmPromptFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export type QueryRequirementsArgs = {
  filters: BaseDocumentationListFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryRetrieveIterationArgs = {
  data?: InputMaybe<IterationCreateInput>;
  phaseId: Scalars['VecticeId']['input'];
};

export type QuerySearchAllAskAiAssetsArgs = {
  assetType: AskAiSearchAssetType;
  filters: AskAiAssetListFiltersInput;
  page?: InputMaybe<PageIndexInput>;
};

export type QuerySearchAssetsArgs = {
  assetType: AssetType;
  filters?: InputMaybe<AssetListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type QuerySectionsArgs = {
  filters?: InputMaybe<IterationStepFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  parentId: Scalars['VecticeId']['input'];
};

export type QuerySourceColumnArgs = {
  columnSourceType: ColumnSourceType;
  id: Scalars['Float']['input'];
};

export type QuerySourceColumnsArgs = {
  columnSourceType: ColumnSourceType;
  filters?: InputMaybe<TextSearchListFiltersInput>;
  id: Scalars['Float']['input'];
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export type QueryThreadRepliesArgs = {
  filters: ThreadReplyFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryThreadsArgs = {
  filters: ThreadFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryUsersArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type RealmInformation = {
  __typename?: 'RealmInformation';
  clientId: Scalars['String']['output'];
  realm: Scalars['String']['output'];
};

export type RetrieveIterationOutput = {
  __typename?: 'RetrieveIterationOutput';
  iteration: Iteration;
  useExistingIteration: Scalars['Boolean']['output'];
};

export type Review = {
  __typename?: 'Review';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['Float']['output'];
  phase: Phase;
  phaseId: Scalars['Float']['output'];
  reviewer: User;
  reviewerId: Scalars['Float']['output'];
  status: ReviewStatus;
  updatedDate: Scalars['DateTime']['output'];
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
};

export type ReviewComment = {
  __typename?: 'ReviewComment';
  content: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  review: Review;
  reviewId: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type ReviewCommentCreateInput = {
  content: Scalars['String']['input'];
  reviewId: Scalars['VecticeId']['input'];
};

export type ReviewCommentFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  reviewId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export type ReviewCreateInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  phaseId: Scalars['VecticeId']['input'];
  reviewerId: Scalars['Float']['input'];
};

export type ReviewFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  affectedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  phaseId?: InputMaybe<Scalars['VecticeId']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
  status?: InputMaybe<Array<ReviewStatus>>;
};

export enum ReviewStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export type ReviewStatusCountWithTotalPerUserOutput = {
  __typename?: 'ReviewStatusCountWithTotalPerUserOutput';
  items: Array<ReviewStatusWithCountOutput>;
  total: Scalars['Float']['output'];
  user: User;
};

export type ReviewStatusWithCountOutput = {
  __typename?: 'ReviewStatusWithCountOutput';
  count: Scalars['Float']['output'];
  status: ReviewStatus;
};

export type ReviewUpdateInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  status: CloseReviewStatus;
};

export type ReviewsCounts = {
  __typename?: 'ReviewsCounts';
  cancelledReviews: Scalars['Float']['output'];
  completedReviews: Scalars['Float']['output'];
  pendingReviews: Scalars['Float']['output'];
  rejectedReviews: Scalars['Float']['output'];
  totalReviews: Scalars['Float']['output'];
};

export type SearchFilter = {
  caseSensitive?: Scalars['Boolean']['input'];
  condition?: FilterConditionEnum;
  fields: Array<Scalars['String']['input']>;
  search: Scalars['String']['input'];
  searchMatch?: SearchMatchEnum;
};

export type SearchFilterWithAccents = {
  /** Known limitation - Only for users right now */
  accentUnsensitive?: Scalars['Boolean']['input'];
  caseSensitive?: Scalars['Boolean']['input'];
  condition?: FilterConditionEnum;
  fields: Array<Scalars['String']['input']>;
  search: Scalars['String']['input'];
  searchMatch?: SearchMatchEnum;
};

export enum SearchMatchEnum {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  FullMatch = 'FULL_MATCH',
  StartsWith = 'STARTS_WITH',
}

export type StarredResourceSettings = {
  isStarred: Scalars['Boolean']['input'];
  resourceId: Scalars['VecticeId']['input'];
};

export type StepDefinition = {
  __typename?: 'StepDefinition';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  index: Scalars['Float']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  parent: Phase;
  parentId: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type StoryFeatureFlag = {
  __typename?: 'StoryFeatureFlag';
  code: Scalars['String']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  overrides?: Maybe<Scalars['Float']['output']>;
  status: StoryFlagStatus;
  ticket?: Maybe<Scalars['String']['output']>;
};

export enum StoryFlagStatus {
  Completed = 'COMPLETED',
  Inprogress = 'INPROGRESS',
  Todo = 'TODO',
}

export type Subscription = {
  __typename?: 'Subscription';
  fileUpdated: FileSubscriptionOutput;
  subscribeToFileComments: CommentSubscriptionOutput;
  subscribeToIterationList: PaginatedIterationSubResponse;
  subscribeToIterationStepList: PaginatedIterationStepSubResponse;
  subscribeToPhaseList: PaginatedPhaseSubResponse;
  subscribeToReview: Review;
  subscribeToReviewCommentList: PaginatedReviewCommentSubResponse;
  userNotificationChanged: UserNotificationOutput;
  userNotificationCreatedUnseen: UserNotificationOutput;
  workspaceCreated: WorkspaceNotificationOutput;
  workspaceDeleted: WorkspaceNotificationOutput;
  workspaceUpdated: WorkspaceNotificationOutput;
};

export type SubscriptionFileUpdatedArgs = {
  fileId: Scalars['Float']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionSubscribeToFileCommentsArgs = {
  fileId: Scalars['Float']['input'];
};

export type SubscriptionSubscribeToIterationListArgs = {
  filters: IterationFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type SubscriptionSubscribeToIterationStepListArgs = {
  filters: BaseDocumentationListFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type SubscriptionSubscribeToPhaseListArgs = {
  filters: PhaseFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type SubscriptionSubscribeToReviewArgs = {
  id: Scalars['VecticeId']['input'];
};

export type SubscriptionSubscribeToReviewCommentListArgs = {
  filters: ReviewCommentFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
};

export type TextSearchListFiltersInput = {
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilter>;
};

export type Thread = {
  __typename?: 'Thread';
  content: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  editorId: Scalars['String']['output'];
  fileId: Scalars['Float']['output'];
  highlightedText: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  isEditedContent: Scalars['Boolean']['output'];
  latestReply?: Maybe<ThreadReply>;
  repliesInformation: ThreadRepliesInformation;
  status: ThreadStatus;
  updatedDate: Scalars['DateTime']['output'];
  updatedDateContent?: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['Float']['output'];
};

export type ThreadCreateInput = {
  content: Scalars['String']['input'];
  editorId: Scalars['String']['input'];
  fileId: Scalars['Float']['input'];
  highlightedText: Scalars['String']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type ThreadFiltersInput = {
  editorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fileId: Scalars['Float']['input'];
  status?: InputMaybe<ThreadStatus>;
};

export type ThreadRepliesInformation = {
  __typename?: 'ThreadRepliesInformation';
  lastReplyDate?: Maybe<Scalars['DateTime']['output']>;
  repliesCount: Scalars['Float']['output'];
  users: Array<User>;
};

export type ThreadReply = {
  __typename?: 'ThreadReply';
  content: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  isEditedContent: Scalars['Boolean']['output'];
  threadId: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  updatedDateContent?: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['Float']['output'];
};

export type ThreadReplyCreateInput = {
  content: Scalars['String']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  threadId: Scalars['Float']['input'];
};

export type ThreadReplyFiltersInput = {
  threadId: Scalars['Float']['input'];
};

export enum ThreadStatus {
  Resolved = 'RESOLVED',
  Unresolved = 'UNRESOLVED',
}

export type ThreadUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  status?: InputMaybe<ThreadStatus>;
};

export enum TreeItemType {
  CsvFile = 'CsvFile',
  DataSet = 'DataSet',
  DataTable = 'DataTable',
  ExcelFile = 'ExcelFile',
  File = 'File',
  Folder = 'Folder',
  ImageFile = 'ImageFile',
  MdFile = 'MdFile',
  Notebook = 'Notebook',
  TextFile = 'TextFile',
}

export enum TrialUserInviteStatus {
  CannotSendEmail = 'CannotSendEmail',
  InvalidEmail = 'InvalidEmail',
  InviteExists = 'InviteExists',
  Success = 'Success',
  UserExists = 'UserExists',
}

export type UpdateEntityMetricInput = {
  id: Scalars['Float']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateEntityPropertyInput = {
  id: Scalars['VecticeId']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIterationStepArtifactInput = {
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertEntityMetricInput = {
  key: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type UpsertEntityPropertyInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  about?: Maybe<Scalars['String']['output']>;
  avatarId?: Maybe<Scalars['Float']['output']>;
  color: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  disabled: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  emailVerificationStatus: EmailVerificationStatus;
  id: Scalars['ID']['output'];
  mustChangePassword: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  preferences: UserPreferencesOutput;
  role: UserRole;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type UserActivity = {
  __typename?: 'UserActivity';
  actor?: Maybe<User>;
  authOrigin?: Maybe<AuthMethod>;
  content?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  fromUser?: Maybe<User>;
  fromUserId?: Maybe<Scalars['Float']['output']>;
  fromValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  importance: Scalars['Float']['output'];
  isAutomated: Scalars['Boolean']['output'];
  organizationId: Scalars['Float']['output'];
  parentId?: Maybe<Scalars['Float']['output']>;
  parentName?: Maybe<Scalars['String']['output']>;
  parentVecticeId?: Maybe<Scalars['ID']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Float']['output']>;
  sessionUuid: Scalars['String']['output'];
  sourceId?: Maybe<Scalars['Float']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<ActivityTargetType>;
  sourceVecticeId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated use vecticeId counterpart */
  sourceWorkspaceId?: Maybe<Scalars['Float']['output']>;
  sourceWorkspaceVecticeId?: Maybe<Scalars['ID']['output']>;
  targetId: Scalars['Float']['output'];
  targetName?: Maybe<Scalars['String']['output']>;
  targetType: ActivityTargetType;
  targetVecticeId?: Maybe<Scalars['ID']['output']>;
  toUser?: Maybe<User>;
  toUserId?: Maybe<Scalars['Float']['output']>;
  toValue?: Maybe<Scalars['String']['output']>;
  type: ActivityType;
  updateType: ActivityUpdateType;
  userId?: Maybe<Scalars['Float']['output']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Float']['output']>;
};

export type UserActivityFilter = {
  activityTargetType: ActivityTargetType;
  activityTypes: Array<ActivityType>;
  activityUpdateTypes?: InputMaybe<Array<ActivityUpdateType>>;
};

export type UserActivityListFiltersInput = {
  activitiesFilters?: InputMaybe<Array<UserActivityFilter>>;
  activityTargetTypes?: InputMaybe<Array<ActivityTargetType>>;
  activityTypes?: InputMaybe<Array<ActivityType>>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']>>;
  date?: InputMaybe<DateFilter>;
  onlyMine?: InputMaybe<Scalars['Boolean']['input']>;
  projectIds?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
  targetFilter?: InputMaybe<ActivityTargetFilter>;
};

export type UserAndDefaultWorkspaceOutput = {
  __typename?: 'UserAndDefaultWorkspaceOutput';
  defaultWorkspace?: Maybe<Workspace>;
  user: User;
};

export type UserAndInvites = {
  __typename?: 'UserAndInvites';
  status: UserAndInvitesStatus;
  type: UserAndInvitesType;
  user?: Maybe<Array<User>>;
  userInvite?: Maybe<Array<UserInvite>>;
};

export type UserAndInvitesFiltersInput = {
  role?: InputMaybe<Array<UserRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilterWithAccents>;
  status?: InputMaybe<Array<UserAndInvitesStatus>>;
};

export enum UserAndInvitesStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
  Pending = 'pending',
}

export enum UserAndInvitesType {
  User = 'User',
  UserInvite = 'UserInvite',
}

export type UserCreateInput = {
  inviteToken: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  accessibility: AccessibilityLevel;
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  expiringAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inviteLinkToken: Scalars['String']['output'];
  invitedUser?: Maybe<User>;
  invitingUser?: Maybe<User>;
  isTrial: Scalars['Boolean']['output'];
  role: UserRole;
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
  workspaces: Array<Workspace>;
};

export type UserInviteInput = {
  accessibility?: InputMaybe<AccessibilityLevel>;
  role?: InputMaybe<UserRole>;
  userEmails: Array<Scalars['String']['input']>;
  workspaceIds?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type UserInviteListFiltersInput = {
  expiredInvites?: InputMaybe<Scalars['Boolean']['input']>;
  pendingInvites?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserInviteResponse = {
  __typename?: 'UserInviteResponse';
  email: Scalars['String']['output'];
  invite?: Maybe<UserInvite>;
  status: UserInviteStatus;
};

export enum UserInviteStatus {
  CreatedEmailDomainBlocked = 'CreatedEmailDomainBlocked',
  FailedInvalidDomain = 'FailedInvalidDomain',
  FailedUserDisabled = 'FailedUserDisabled',
  FailedUsersLimitReached = 'FailedUsersLimitReached',
  InviteExists = 'InviteExists',
  Success = 'Success',
  SuccessUserExists = 'SuccessUserExists',
}

export type UserInviteWithAuthInfoOutput = {
  __typename?: 'UserInviteWithAuthInfoOutput';
  passwordAuthenticationEnabled: Scalars['Boolean']['output'];
  userInvite: UserInvite;
};

export type UserListFiltersInput = {
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  includeDisabledUsers?: InputMaybe<Scalars['Boolean']['input']>;
  includeReadOnlyUsers?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<AccessibilityLevel>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<SearchFilterWithAccents>;
  userIds?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type UserMentionNotificationContentInput = {
  pageId: Scalars['String']['input'];
  pageType: Scalars['String']['input'];
};

export type UserNotification = {
  __typename?: 'UserNotification';
  content?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['DateTime']['output'];
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['String']['output']>;
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  status: UserNotificationStatus;
  type: UserNotificationType;
  updatedDate: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
  version: Scalars['Float']['output'];
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Float']['output']>;
};

export type UserNotificationListFiltersInput = {
  creatorIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  date?: InputMaybe<DateFilter>;
  statuses?: InputMaybe<Array<UserNotificationStatus>>;
  types?: InputMaybe<Array<UserNotificationType>>;
  workspaceIds?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type UserNotificationOutput = {
  __typename?: 'UserNotificationOutput';
  /** @deprecated Not useful */
  changeType?: Maybe<UserNotificationStatus>;
  eventType: NotificationType;
  id?: Maybe<Scalars['Float']['output']>;
  ids?: Maybe<Array<Scalars['Float']['output']>>;
  /** @deprecated Nothing is returned here, to delete */
  payload?: Maybe<Array<UserNotification>>;
  unacknowledgedCount: Scalars['Float']['output'];
  unseenCount: Scalars['Float']['output'];
  userId: Scalars['Float']['output'];
};

export enum UserNotificationStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Created = 'CREATED',
  Seen = 'SEEN',
}

export enum UserNotificationType {
  AdminNotifUserAddedToWorkspace = 'ADMIN_NOTIF_USER_ADDED_TO_WORKSPACE',
  AdminNotifWorkspaceRoleChanged = 'ADMIN_NOTIF_WORKSPACE_ROLE_CHANGED',
  AskUserTypeUpgrade = 'ASK_USER_TYPE_UPGRADE',
  FindingOwnerChange = 'FINDING_OWNER_CHANGE',
  FindingReviewerChange = 'FINDING_REVIEWER_CHANGE',
  FindingStatusChange = 'FINDING_STATUS_CHANGE',
  NewWorkspaceAccessRequest = 'NEW_WORKSPACE_ACCESS_REQUEST',
  OwnUserTypeChanged = 'OWN_USER_TYPE_CHANGED',
  PhaseOwnerAssigned = 'PHASE_OWNER_ASSIGNED',
  ProjectDeleted = 'PROJECT_DELETED',
  ProjectOwnershipTransferred = 'PROJECT_OWNERSHIP_TRANSFERRED',
  ProjectOwnerAssigned = 'PROJECT_OWNER_ASSIGNED',
  ProjectStatusChange = 'PROJECT_STATUS_CHANGE',
  QuickstartProjectCreated = 'QUICKSTART_PROJECT_CREATED',
  ReviewApproved = 'REVIEW_APPROVED',
  ReviewCommentAdded = 'REVIEW_COMMENT_ADDED',
  ReviewRejected = 'REVIEW_REJECTED',
  ReviewRequested = 'REVIEW_REQUESTED',
  ThreadCreated = 'THREAD_CREATED',
  ThreadDeleted = 'THREAD_DELETED',
  ThreadReply = 'THREAD_REPLY',
  ThreadResolved = 'THREAD_RESOLVED',
  UserAcceptedInvite = 'USER_ACCEPTED_INVITE',
  UserAddedToWorkspace = 'USER_ADDED_TO_WORKSPACE',
  UserJoinedOrganization = 'USER_JOINED_ORGANIZATION',
  UserMentioned = 'USER_MENTIONED',
  UserMentionedInThread = 'USER_MENTIONED_IN_THREAD',
  UserRemovedFromWorkspace = 'USER_REMOVED_FROM_WORKSPACE',
  WorkspaceDeleted = 'WORKSPACE_DELETED',
  WorkspaceUserRequestApproved = 'WORKSPACE_USER_REQUEST_APPROVED',
  WorkspaceUserRequestRejected = 'WORKSPACE_USER_REQUEST_REJECTED',
}

export type UserPreferencesInput = {
  onboardingStatus?: InputMaybe<Array<OnboardingStatus>>;
};

export type UserPreferencesOutput = {
  __typename?: 'UserPreferencesOutput';
  onboardingStatus: Array<OnboardingStatus>;
};

export enum UserRole {
  Member = 'MEMBER',
  Observer = 'OBSERVER',
  OrgAdmin = 'ORG_ADMIN',
  Superadmin = 'SUPERADMIN',
}

export type UserUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<UserPreferencesInput>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type UserViewActivity = {
  __typename?: 'UserViewActivity';
  date: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  operationName?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organizationId: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  typeId: Scalars['Float']['output'];
  user: User;
  userId: Scalars['Float']['output'];
};

export type UserViewActivityCreateInput = {
  operationName?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  typeId?: InputMaybe<Scalars['Float']['input']>;
};

export type UserWithActivityCounts = {
  __typename?: 'UserWithActivityCounts';
  about?: Maybe<Scalars['String']['output']>;
  activityCounts: Array<ActivityCount>;
  avatarId?: Maybe<Scalars['Float']['output']>;
  color: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  disabled: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  emailVerificationStatus: EmailVerificationStatus;
  id: Scalars['ID']['output'];
  mustChangePassword: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  preferences: UserPreferencesOutput;
  role: UserRole;
  timezone?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Float']['output'];
  updatedDate: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type ValueFieldValue = {
  field: Scalars['String']['input'];
  valuesWithMatch: Array<ValueMatchMap>;
};

export type ValueMatchMap = {
  match: MatchEnum;
  value: Scalars['AdvancedFilterValue']['input'];
};

export type VersionOutput = {
  __typename?: 'VersionOutput';
  artifactName: ArtifactVersion;
  version: Scalars['String']['output'];
};

export type Workspace = {
  __typename?: 'Workspace';
  createdBy: User;
  createdById: Scalars['Float']['output'];
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Float']['output'];
  projectCount: Scalars['Float']['output'];
  type: WorkspaceType;
  updatedDate: Scalars['DateTime']['output'];
  userContext: WorkspaceUserContext;
  userCount: Scalars['Float']['output'];
  userList: PaginatedUserList;
  vecticeId: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
};

export type WorkspaceUserCountArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export type WorkspaceUserListArgs = {
  filters?: InputMaybe<UserListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
};

export type WorkspaceAccessRequest = {
  __typename?: 'WorkspaceAccessRequest';
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  resolver?: Maybe<User>;
  resolverId?: Maybe<Scalars['Float']['output']>;
  status: WorkspaceAccessRequestStatus;
  updatedDate: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Float']['output'];
  version: Scalars['Float']['output'];
  workspaceId: Scalars['Float']['output'];
};

export enum WorkspaceAccessRequestStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type WorkspaceActivityTrendOutput = {
  __typename?: 'WorkspaceActivityTrendOutput';
  count: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
};

export type WorkspaceCountPerUser = {
  __typename?: 'WorkspaceCountPerUser';
  count: Scalars['Float']['output'];
  user: User;
};

export type WorkspaceCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type?: InputMaybe<WorkspaceType>;
};

export type WorkspaceListFiltersInput = {
  access?: InputMaybe<Array<AccessibilityLevel>>;
  advancedFilters?: InputMaybe<Array<GroupFilter>>;
  searchFilter?: InputMaybe<SearchFilter>;
  type?: InputMaybe<Array<WorkspaceType>>;
  workspaceIds?: InputMaybe<Array<Scalars['VecticeId']['input']>>;
};

export type WorkspaceNotificationOutput = {
  __typename?: 'WorkspaceNotificationOutput';
  eventType: NotificationType;
  id?: Maybe<Scalars['Float']['output']>;
  ids?: Maybe<Array<Scalars['Float']['output']>>;
  payload?: Maybe<Workspace>;
};

export type WorkspaceNumberAssetsByCreator = {
  __typename?: 'WorkspaceNumberAssetsByCreator';
  datasetVersionCount: Scalars['Float']['output'];
  modelVersionCount: Scalars['Float']['output'];
  user: User;
};

export type WorkspaceOngoingPhasesOutput = {
  __typename?: 'WorkspaceOngoingPhasesOutput';
  days: Scalars['Float']['output'];
  entity: Phase;
};

export type WorkspaceOngoingProjectsOutput = {
  __typename?: 'WorkspaceOngoingProjectsOutput';
  days: Scalars['Float']['output'];
  entity: Project;
};

export type WorkspaceStatsData = {
  __typename?: 'WorkspaceStatsData';
  completePhases: Scalars['Float']['output'];
  contributors: Scalars['Float']['output'];
  datasetVersions: Scalars['Float']['output'];
  datasets: Scalars['Float']['output'];
  modelVersions: Scalars['Float']['output'];
  models: Scalars['Float']['output'];
  projects: Scalars['Float']['output'];
  runs: Scalars['Float']['output'];
};

export type WorkspaceStatsResult = {
  __typename?: 'WorkspaceStatsResult';
  difference: WorkspaceStatsData;
  stats: WorkspaceStatsData;
};

export enum WorkspaceType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Sample = 'SAMPLE',
  Tutorial = 'TUTORIAL',
}

export type WorkspaceUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type WorkspaceUser = {
  __typename?: 'WorkspaceUser';
  accessibility: AccessibilityLevel;
  createdDate: Scalars['DateTime']['output'];
  deletedDate?: Maybe<Scalars['DateTime']['output']>;
  updatedDate: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Float']['output'];
  version: Scalars['Float']['output'];
  workspace: Workspace;
  workspaceId: Scalars['Float']['output'];
};

export type WorkspaceUserContext = {
  __typename?: 'WorkspaceUserContext';
  accessibility?: Maybe<AccessibilityLevel>;
};

export type WorkspaceUserInput = {
  accessibility?: AccessibilityLevel;
  userIds: Array<Scalars['Float']['input']>;
  workspaceId: Scalars['VecticeId']['input'];
};

export type CommentFragmentFragment = {
  __typename?: 'Thread';
  id: number;
  fileId: number;
  editorId: string;
  status: ThreadStatus;
  createdDate: any;
  content: string;
  isEditedContent: boolean;
  updatedDateContent?: any | null;
  highlightedText: string;
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  repliesInformation: { __typename?: 'ThreadRepliesInformation'; repliesCount: number };
  latestReply?:
    | ({ __typename?: 'ThreadReply'; id: number; content: string } & {
        ' $fragmentRefs'?: { ThreadReplyFragmentFragment: ThreadReplyFragmentFragment };
      })
    | null;
} & { ' $fragmentName'?: 'CommentFragmentFragment' };

export type CreateThreadMutationVariables = Exact<{
  data: ThreadCreateInput;
}>;

export type CreateThreadMutation = {
  __typename?: 'Mutation';
  createThread: { __typename?: 'Thread' } & { ' $fragmentRefs'?: { CommentFragmentFragment: CommentFragmentFragment } };
};

export type CreateThreadReplyMutationVariables = Exact<{
  data: ThreadReplyCreateInput;
}>;

export type CreateThreadReplyMutation = {
  __typename?: 'Mutation';
  createThreadReply: {
    __typename?: 'ThreadReply';
    id: number;
    createdDate: any;
    content: string;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type DeleteThreadMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type DeleteThreadMutation = { __typename?: 'Mutation'; deleteThread: string };

export type DeleteThreadReplyMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type DeleteThreadReplyMutation = { __typename?: 'Mutation'; deleteThreadReply: string };

export type ResolveThreadMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type ResolveThreadMutation = {
  __typename?: 'Mutation';
  updateThread: { __typename?: 'Thread'; id: number; status: ThreadStatus };
};

export type SubscribeToFileCommentsSubscriptionVariables = Exact<{
  fileId: Scalars['Float']['input'];
}>;

export type SubscribeToFileCommentsSubscription = {
  __typename?: 'Subscription';
  subscribeToFileComments: {
    __typename?: 'CommentSubscriptionOutput';
    fileId: number;
    threadId: number;
    content: string;
    status: ThreadStatus;
    isDeleted: boolean;
    replyId?: number | null;
    thread?:
      | ({ __typename?: 'Thread' } & { ' $fragmentRefs'?: { CommentFragmentFragment: CommentFragmentFragment } })
      | null;
    reply?:
      | ({ __typename?: 'ThreadReply' } & {
          ' $fragmentRefs'?: { ThreadReplyFragmentFragment: ThreadReplyFragmentFragment };
        })
      | null;
  };
};

export type ThreadRepliesQueryVariables = Exact<{
  threadId: Scalars['Float']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type ThreadRepliesQuery = {
  __typename?: 'Query';
  threadReplies: {
    __typename?: 'PaginatedThreadReplyResponse';
    items: Array<
      { __typename?: 'ThreadReply'; id: number; content: string } & {
        ' $fragmentRefs'?: { ThreadReplyFragmentFragment: ThreadReplyFragmentFragment };
      }
    >;
  };
};

export type ThreadsQueryVariables = Exact<{
  id: Scalars['Float']['input'];
  pageSize: Scalars['Int']['input'];
  editorIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  status?: InputMaybe<ThreadStatus>;
}>;

export type ThreadsQuery = {
  __typename?: 'Query';
  threads: {
    __typename?: 'PaginatedThreadResponse';
    items: Array<
      { __typename?: 'Thread'; id: number; editorId: string; status: ThreadStatus; content: string } & {
        ' $fragmentRefs'?: { CommentFragmentFragment: CommentFragmentFragment };
      }
    >;
  };
};

export type UpdateThreadMutationVariables = Exact<{
  data: ThreadUpdateInput;
}>;

export type UpdateThreadMutation = {
  __typename?: 'Mutation';
  updateThread: {
    __typename?: 'Thread';
    id: number;
    content: string;
    updatedDateContent?: any | null;
    isEditedContent: boolean;
  };
};

export type UpdateThreadReplyMutationVariables = Exact<{
  data: CommentUpdateInput;
}>;

export type UpdateThreadReplyMutation = {
  __typename?: 'Mutation';
  updateThreadReply: {
    __typename?: 'ThreadReply';
    id: number;
    content: string;
    updatedDateContent?: any | null;
    isEditedContent: boolean;
  };
};

export type ThreadReplyFragmentFragment = {
  __typename?: 'ThreadReply';
  id: number;
  createdDate: any;
  content: string;
  isEditedContent: boolean;
  updatedDateContent?: any | null;
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
} & { ' $fragmentName'?: 'ThreadReplyFragmentFragment' };

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
}>;

export type DeleteProjectMutation = { __typename?: 'Mutation'; deleteProject: string };

export type DeleteWorkspaceMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type DeleteWorkspaceMutation = { __typename?: 'Mutation'; deleteWorkspace: string };

export type LeaveWorkspaceMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type LeaveWorkspaceMutation = { __typename?: 'Mutation'; leaveWorkspace: boolean };

export type TransferProjectOwnershipMutationVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  data: ProjectUpdateInput;
}>;

export type TransferProjectOwnershipMutation = {
  __typename?: 'Mutation';
  updateProject: {
    __typename?: 'Project';
    vecticeId: string;
    ownerId: number;
    owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type UserActivitiesFragment = {
  __typename?: 'PaginatedUserActivityResponse';
  items: Array<
    { __typename?: 'UserActivity'; id: number } & { ' $fragmentRefs'?: { UserActivityFragment: UserActivityFragment } }
  >;
} & { ' $fragmentName'?: 'UserActivitiesFragment' };

export type UserActivityFragment = {
  __typename?: 'UserActivity';
  id: number;
  fromValue?: string | null;
  toValue?: string | null;
  date: any;
  isAutomated: boolean;
  type: ActivityType;
  targetId: number;
  targetVecticeId?: string | null;
  targetName?: string | null;
  targetType: ActivityTargetType;
  updateType: ActivityUpdateType;
  sourceId?: number | null;
  sourceVecticeId?: string | null;
  sourceName?: string | null;
  sourceWorkspaceVecticeId?: string | null;
  parentVecticeId?: string | null;
  parentName?: string | null;
  fromUser?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
  toUser?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
  workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType } | null;
  project?: { __typename?: 'Project'; vecticeId: string; name: string } | null;
  actor?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
} & { ' $fragmentName'?: 'UserActivityFragment' };

export type GetReportLinkInfoQueryVariables = Exact<{
  reportId: Scalars['Float']['input'];
}>;

export type GetReportLinkInfoQuery = { __typename?: 'Query'; CDTReport: { __typename?: 'CDTReport'; name: string } };

export type ReportListItemFragment = ({ __typename?: 'CDTReport'; id: number } & {
  ' $fragmentRefs'?: { ReportRowFragment: ReportRowFragment };
}) & { ' $fragmentName'?: 'ReportListItemFragment' };

export type ReportRowFragment = {
  __typename?: 'CDTReport';
  id: number;
  name: string;
  updatedDate: any;
  createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  project: {
    __typename?: 'Project';
    id: number;
    name: string;
    workspace?: { __typename?: 'Workspace'; vecticeId: string; type: WorkspaceType; name: string } | null;
  };
} & { ' $fragmentName'?: 'ReportRowFragment' };

export type ReportTooltipQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type ReportTooltipQuery = {
  __typename?: 'Query';
  CDTReport: {
    __typename?: 'CDTReport';
    id: number;
    name: string;
    createdDate: any;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type DatasetVersionListItemFragment = ({ __typename?: 'DataSetVersion'; vecticeId: string } & {
  ' $fragmentRefs'?: { DatasetVersionRowFragment: DatasetVersionRowFragment };
}) & { ' $fragmentName'?: 'DatasetVersionListItemFragment' };

export type DatasetVersionRowFragment = {
  __typename?: 'DataSetVersion';
  vecticeId: string;
  versionNumber: number;
  isStarred: boolean;
  updatedDate: any;
  dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string; type: DatasetType };
  datasetSources: Array<{ __typename?: 'DatasetSource'; id: number; size?: number | null; itemsCount: number }>;
  lastUpdatedBy?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
} & { ' $fragmentName'?: 'DatasetVersionRowFragment' };

export type GetDataSetVersionTooltipQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDataSetVersionTooltipQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    versionNumber: number;
    createdDate: any;
    dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string; type: DatasetType };
    datasetSources: Array<{
      __typename?: 'DatasetSource';
      id: number;
      usage?: DatasetSourceUsage | null;
      size?: number | null;
      itemsCount: number;
    }>;
    properties: Array<{ __typename?: 'EntityProperty'; id: number }>;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type GetDatasetVersionWidgetQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionWidgetQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    name?: string | null;
    versionNumber: number;
    properties: Array<{ __typename?: 'EntityProperty'; id: number }>;
    dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string };
    datasetSources: Array<{ __typename?: 'DatasetSource'; id: number; itemsCount: number }>;
    origin?: { __typename?: 'Lineage'; id: number; datasetInputs: Array<string> } | null;
  } & { ' $fragmentRefs'?: { DatasetVersionRowFragment: DatasetVersionRowFragment } };
};

export type DatasetListItemFragment = ({ __typename?: 'DataSet'; vecticeId: string } & {
  ' $fragmentRefs'?: { DatasetRowFragment: DatasetRowFragment };
}) & { ' $fragmentName'?: 'DatasetListItemFragment' };

export type DatasetRowFragment = {
  __typename?: 'DataSet';
  vecticeId: string;
  name: string;
  type: DatasetType;
  versionCount: number;
  updatedDate: any;
  lastUpdatedBy?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
} & { ' $fragmentName'?: 'DatasetRowFragment' };

export type GetDataSetTooltipQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
}>;

export type GetDataSetTooltipQuery = {
  __typename?: 'Query';
  getDataset: {
    __typename?: 'DataSet';
    vecticeId: string;
    name: string;
    type: DatasetType;
    versionCount: number;
    createdDate: any;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type GetDatasetWidgetQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetDatasetWidgetQuery = {
  __typename?: 'Query';
  getDataset: { __typename?: 'DataSet'; vecticeId: string } & {
    ' $fragmentRefs'?: { DatasetRowFragment: DatasetRowFragment };
  };
};

export type GetEntityFileWidgetQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetEntityFileWidgetQuery = {
  __typename?: 'Query';
  getEntityFileById: {
    __typename?: 'EntityFile';
    id: number;
    fileName?: string | null;
    mimeType?: string | null;
    entityId: number;
    type: EntityFilesTypeEnum;
    hasPreview: boolean;
    hasStaticView: boolean;
  } & { ' $fragmentRefs'?: { EntityFileFragmentFragment: EntityFileFragmentFragment } };
};

export type GetEntityFileIterationOriginQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetEntityFileIterationOriginQuery = {
  __typename?: 'Query';
  getIterationById: { __typename?: 'Iteration'; vecticeId: string; name: string };
};

export type GetEntityFileDatasetVersionOriginQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetEntityFileDatasetVersionOriginQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    versionNumber: number;
    dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string; type: DatasetType };
  };
};

export type GetEntityFileModelVersionOriginQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetEntityFileModelVersionOriginQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    versionNumber: number;
    model: { __typename?: 'Model'; vecticeId: string; name: string; type: ModelType };
  };
};

export type EntityMetadataQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type EntityMetadataQuery = {
  __typename?: 'Query';
  entityMetadata: { __typename?: 'EntityMetadata'; id: number; name: string };
};

export type GetFindingTooltipQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetFindingTooltipQuery = {
  __typename?: 'Query';
  Finding: {
    __typename?: 'Finding';
    id: number;
    name: string;
    status: FindingStatus;
    owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type IterationListItemFragment = ({ __typename?: 'Iteration'; vecticeId: string } & {
  ' $fragmentRefs'?: { IterationRowFragment: IterationRowFragment };
}) & { ' $fragmentName'?: 'IterationListItemFragment' };

export type IterationRowFragment = {
  __typename?: 'Iteration';
  vecticeId: string;
  index: number;
  description?: string | null;
  name: string;
  status: IterationStatus;
  starred: boolean;
  updatedDate: any;
  phase: { __typename?: 'Phase'; vecticeId: string; name: string };
  owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
} & { ' $fragmentName'?: 'IterationRowFragment' };

export type GetIterationTooltipQueryVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
}>;

export type GetIterationTooltipQuery = {
  __typename?: 'Query';
  getIterationById: {
    __typename?: 'Iteration';
    vecticeId: string;
    index: number;
    name: string;
    status: IterationStatus;
    createdDate: any;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type ArtifactFragment = {
  __typename?: 'IterationStepArtifact';
  id: number;
  text?: string | null;
  type: IterationStepArtifactType;
  datasetVersion?: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    versionNumber: number;
    dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string };
  } | null;
  entityFile?: {
    __typename?: 'EntityFile';
    id: number;
    fileName?: string | null;
    mimeType?: string | null;
    contentType: TreeItemType;
    entityId: number;
  } | null;
  modelVersion?: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    versionNumber: number;
    model: { __typename?: 'Model'; vecticeId: string; name: string };
  } | null;
  entityMetadata?: { __typename?: 'EntityMetadata'; id: number; name: string } | null;
} & { ' $fragmentName'?: 'ArtifactFragment' };

export type GetIterationWidgetQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetIterationWidgetQuery = {
  __typename?: 'Query';
  getIterationById: { __typename?: 'Iteration'; vecticeId: string; description?: string | null } & {
    ' $fragmentRefs'?: { IterationRowFragment: IterationRowFragment };
  };
};

export type GetIterationArtifactsForWidgetQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetIterationArtifactsForWidgetQuery = {
  __typename?: 'Query';
  getIterationById: {
    __typename?: 'Iteration';
    paginatedArtifacts: {
      __typename?: 'PaginatedIterationStepArtifactResponse';
      items: Array<
        { __typename?: 'IterationStepArtifact'; id: number; type: IterationStepArtifactType } & {
          ' $fragmentRefs'?: { ArtifactFragment: ArtifactFragment };
        }
      >;
    };
  };
};

export type GetIterationStepListForWidgetQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetIterationStepListForWidgetQuery = {
  __typename?: 'Query';
  sections: {
    __typename?: 'PaginatedIterationStepResponse';
    total: number;
    items: Array<{
      __typename?: 'IterationStep';
      id: number;
      name: string;
      description?: string | null;
      paginatedArtifacts: {
        __typename?: 'PaginatedIterationStepArtifactResponse';
        total: number;
        items: Array<
          { __typename?: 'IterationStepArtifact'; id: number; type: IterationStepArtifactType } & {
            ' $fragmentRefs'?: { ArtifactFragment: ArtifactFragment };
          }
        >;
      };
    }>;
  };
};

export type GetLineageTooltipQueryVariables = Exact<{
  lineageId: Scalars['Float']['input'];
}>;

export type GetLineageTooltipQuery = {
  __typename?: 'Query';
  getJobArtifactByJobRunId: {
    __typename?: 'PaginatedLineageArtifactResponse';
    items: Array<{
      __typename?: 'LineageArtifact';
      id: number;
      lineageArtifactType: LineageArtifactType;
      artifactType: ArtifactType;
      dataSetVersion?: {
        __typename?: 'DataSetVersion';
        vecticeId: string;
        name?: string | null;
        versionNumber: number;
        dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string };
      } | null;
      modelVersion?: {
        __typename?: 'ModelVersion';
        vecticeId: string;
        name?: string | null;
        versionNumber: number;
        model: { __typename?: 'Model'; vecticeId: string; name: string };
      } | null;
      codeVersion?: {
        __typename?: 'CodeVersion';
        id: number;
        code?: { __typename?: 'Code'; name: string } | null;
        gitVersion?: {
          __typename?: 'GitVersion';
          commitHash?: string | null;
          uri?: string | null;
          entrypoint?: string | null;
        } | null;
        databricksVersion?: {
          __typename?: 'DatabricksVersion';
          urlNotebook: string;
          relativePath?: string | null;
        } | null;
        otherCodeVersion?: {
          __typename?: 'OtherCodeVersion';
          id: number;
          url: string;
          firstAdditionalInformation?: string | null;
          secondAdditionalInformation?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type GetLineageWidgetQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetLineageWidgetQuery = { __typename?: 'Query'; getJobRunById: { __typename?: 'Lineage'; id: number } };

export type GetModelVersionLinkInfoQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionLinkInfoQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    versionNumber: number;
    model: { __typename?: 'Model'; name: string };
  };
};

export type ModelVersionListItemFragment = ({ __typename?: 'ModelVersion'; vecticeId: string } & {
  ' $fragmentRefs'?: { ModelVersionRowFragment: ModelVersionRowFragment };
}) & { ' $fragmentName'?: 'ModelVersionListItemFragment' };

export type ModelVersionRowFragment = {
  __typename?: 'ModelVersion';
  vecticeId: string;
  versionNumber: number;
  status: ModelVersionStatus;
  algorithmName?: string | null;
  isStarred: boolean;
  updatedDate: any;
  model: { __typename?: 'Model'; vecticeId: string; name: string };
  lastUpdatedBy?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
} & { ' $fragmentName'?: 'ModelVersionRowFragment' };

export type GetModelVersionTooltipQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionTooltipQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    versionNumber: number;
    status: ModelVersionStatus;
    algorithmName?: string | null;
    createdDate: any;
    model: { __typename?: 'Model'; vecticeId: string; name: string };
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    properties: Array<{ __typename?: 'EntityProperty'; id: number }>;
    metrics: Array<{ __typename?: 'EntityMetric'; id: number }>;
  };
};

export type GetModelVersionWidgetQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionWidgetQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    properties: Array<{ __typename?: 'EntityProperty'; id: number }>;
    metrics: Array<{ __typename?: 'EntityMetric'; id: number }>;
    model: { __typename?: 'Model'; vecticeId: string };
    origin?: { __typename?: 'Lineage'; id: number; datasetInputs: Array<string> } | null;
  } & { ' $fragmentRefs'?: { ModelVersionRowFragment: ModelVersionRowFragment } };
};

export type ModelListItemFragment = ({ __typename?: 'Model'; vecticeId: string } & {
  ' $fragmentRefs'?: { ModelRowFragment: ModelRowFragment };
}) & { ' $fragmentName'?: 'ModelListItemFragment' };

export type ModelRowFragment = {
  __typename?: 'Model';
  vecticeId: string;
  name: string;
  type: ModelType;
  updatedDate: any;
  versionStats?: {
    __typename?: 'ModelVersionStatistics';
    experimentationCount: number;
    productionCount: number;
    stagingCount: number;
    retiredCount: number;
  } | null;
  lastUpdatedBy?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
} & { ' $fragmentName'?: 'ModelRowFragment' };

export type GetModelTooltipQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
}>;

export type GetModelTooltipQuery = {
  __typename?: 'Query';
  getModelById: {
    __typename?: 'Model';
    vecticeId: string;
    name: string;
    type: ModelType;
    versionCount: number;
    createdDate: any;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type GetModelWidgetQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetModelWidgetQuery = {
  __typename?: 'Query';
  getModelById: { __typename?: 'Model'; vecticeId: string } & {
    ' $fragmentRefs'?: { ModelRowFragment: ModelRowFragment };
  };
};

export type PhaseListItemFragment = ({ __typename?: 'Phase'; vecticeId: string } & {
  ' $fragmentRefs'?: { PhaseRowFragment: PhaseRowFragment };
}) & { ' $fragmentName'?: 'PhaseListItemFragment' };

export type PhaseRowFragment = {
  __typename?: 'Phase';
  vecticeId: string;
  name: string;
  status: DocumentationPageStatus;
  updatedDate: any;
  owner?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
} & { ' $fragmentName'?: 'PhaseRowFragment' };

export type GetPhaseTooltipQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetPhaseTooltipQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    vecticeId: string;
    name: string;
    status: DocumentationPageStatus;
    activeReview?: { __typename?: 'Review'; vecticeId: string; status: ReviewStatus } | null;
    iterationsCount: { __typename?: 'IterationsCounts'; total: number };
    owner?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
  };
};

export type ProjectCardFragment = {
  __typename?: 'Project';
  vecticeId: string;
  name: string;
  workspace?: { __typename?: 'Workspace'; vecticeId: string; type: WorkspaceType; name: string } | null;
} & { ' $fragmentName'?: 'ProjectCardFragment' };

export type ProjectListItemFragment = ({ __typename?: 'Project'; vecticeId: string } & {
  ' $fragmentRefs'?: { ProjectRowFragment: ProjectRowFragment };
}) & { ' $fragmentName'?: 'ProjectListItemFragment' };

export type ProjectRowFragment = {
  __typename?: 'Project';
  vecticeId: string;
  name: string;
  status: ProjectStatus;
  updatedDate: any;
  phasesCounts: { __typename?: 'DocumentationCounts'; done: number; ongoing: number; toDo: number; total: number };
  owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
} & { ' $fragmentName'?: 'ProjectRowFragment' };

export type GetProjectTooltipQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
}>;

export type GetProjectTooltipQuery = {
  __typename?: 'Query';
  getProjectById: {
    __typename?: 'Project';
    vecticeId: string;
    name: string;
    status: ProjectStatus;
    phasesCounts: { __typename?: 'DocumentationCounts'; done: number; ongoing: number; toDo: number; total: number };
  };
};

export type ReviewListItemFragment = ({
  __typename?: 'Review';
  vecticeId: string;
  phase: { __typename?: 'Phase'; vecticeId: string };
} & { ' $fragmentRefs'?: { ReviewRowFragment: ReviewRowFragment } }) & { ' $fragmentName'?: 'ReviewListItemFragment' };

export type ReviewRowFragment = {
  __typename?: 'Review';
  id: number;
  status: ReviewStatus;
  createdDate: any;
  createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  phase: {
    __typename?: 'Phase';
    vecticeId: string;
    name: string;
    parent: { __typename?: 'Project'; vecticeId: string; name: string };
  };
} & { ' $fragmentName'?: 'ReviewRowFragment' };

export type GetUserTooltipQueryVariables = Exact<{
  userId: Scalars['Float']['input'];
}>;

export type GetUserTooltipQuery = {
  __typename?: 'Query';
  getOrganizationUser: {
    __typename?: 'User';
    id: string;
    name: string;
    avatarId?: number | null;
    email: string;
    color: string;
    role: UserRole;
    disabled: boolean;
  };
};

export type GetWorkspaceTooltipQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceTooltipQuery = {
  __typename?: 'Query';
  getWorkspaceById: {
    __typename?: 'Workspace';
    vecticeId: string;
    name: string;
    type: WorkspaceType;
    projectCount: number;
    userCount: number;
  };
};

export type CdtReportsSelectorQueryVariables = Exact<{
  type?: InputMaybe<CdtReportQueryType>;
  filters: CdtReportFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
}>;

export type CdtReportsSelectorQuery = {
  __typename?: 'Query';
  CDTReports: {
    __typename?: 'PaginatedCDTReportResponse';
    total: number;
    items: Array<{ __typename?: 'CDTReport'; id: number; name: string }>;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type GetDatasetVersionPickerListQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  filters?: InputMaybe<DataSetVersionFilterInput>;
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetDatasetVersionPickerListQuery = {
  __typename?: 'Query';
  getDatasetVersionsList: {
    __typename?: 'PaginatedDataSetVersionResponse';
    items: Array<{ __typename?: 'DataSetVersion'; vecticeId: string; versionNumber: number; isStarred: boolean }>;
  };
};

export type GetModelVersionPickerListQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
  filters?: InputMaybe<ModelVersionListFiltersInput>;
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetModelVersionPickerListQuery = {
  __typename?: 'Query';
  getModelVersionsList: {
    __typename?: 'PaginatedModelVersionResponse';
    items: Array<{ __typename?: 'ModelVersion'; vecticeId: string; versionNumber: number; isStarred: boolean }>;
  };
};

export type GetProjectsSelectorQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  filters: ProjectListFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
}>;

export type GetProjectsSelectorQuery = {
  __typename?: 'Query';
  getProjectList: {
    __typename?: 'PaginatedProjectResponse';
    items: Array<{ __typename?: 'Project'; name: string; vecticeId: string }>;
  };
};

export type DatasetsToSelectQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  versionId?: InputMaybe<Scalars['VecticeId']['input']>;
}>;

export type DatasetsToSelectQuery = {
  __typename?: 'Query';
  getDatasetList: {
    __typename?: 'PaginatedDataSetResponse';
    total: number;
    items: Array<{ __typename?: 'DataSet'; vecticeId: string; name: string }>;
  };
};

export type DatasetVersionsToSelectQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
}>;

export type DatasetVersionsToSelectQuery = {
  __typename?: 'Query';
  getDatasetVersionsList: {
    __typename?: 'PaginatedDataSetVersionResponse';
    total: number;
    items: Array<{
      __typename?: 'DataSetVersion';
      vecticeId: string;
      versionNumber: number;
      dataSet: {
        __typename?: 'DataSet';
        vecticeId: string;
        name: string;
        project: { __typename?: 'Project'; vecticeId: string };
      };
    }>;
  };
};

export type ModelsToSelectQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type ModelsToSelectQuery = {
  __typename?: 'Query';
  getModelList: {
    __typename?: 'PaginatedModelResponse';
    total: number;
    items: Array<{ __typename?: 'Model'; vecticeId: string; name: string }>;
  };
};

export type ModelVersionsToSelectQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
}>;

export type ModelVersionsToSelectQuery = {
  __typename?: 'Query';
  getModelVersionsList: {
    __typename?: 'PaginatedModelVersionResponse';
    total: number;
    items: Array<{
      __typename?: 'ModelVersion';
      vecticeId: string;
      versionNumber: number;
      model: {
        __typename?: 'Model';
        vecticeId: string;
        name: string;
        project: { __typename?: 'Project'; vecticeId: string };
      };
    }>;
  };
};

export type GetIterationsQueryVariables = Exact<{
  filters: IterationFiltersInput;
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetIterationsQuery = {
  __typename?: 'Query';
  getIterationList: {
    __typename?: 'PaginatedIterationResponse';
    total: number;
    items: Array<{
      __typename?: 'Iteration';
      vecticeId: string;
      index: number;
      name: string;
      phase: { __typename?: 'Phase'; vecticeId: string; name: string };
    }>;
  };
};

export type GetSelectedCdtReportQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetSelectedCdtReportQuery = {
  __typename?: 'Query';
  CDTReport: { __typename?: 'CDTReport'; id: number; name: string };
};

export type GetSelectedProjectQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
}>;

export type GetSelectedProjectQuery = {
  __typename?: 'Query';
  getProjectById: { __typename?: 'Project'; vecticeId: string; name: string };
};

export type GetSelectedWorkspaceQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type GetSelectedWorkspaceQuery = {
  __typename?: 'Query';
  getWorkspaceById: { __typename?: 'Workspace'; vecticeId: string; name: string };
};

export type SourceColumnsQueryVariables = Exact<{
  id: Scalars['Float']['input'];
  columnSourceType: ColumnSourceType;
  search?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageIndexInput>;
}>;

export type SourceColumnsQuery = {
  __typename?: 'Query';
  sourceColumns: {
    __typename?: 'PaginatedColumn';
    items: Array<{ __typename?: 'ColumnOutput'; id: number; name: string }>;
  };
};

export type SourceColumnQueryVariables = Exact<{
  id: Scalars['Float']['input'];
  columnSourceType: ColumnSourceType;
}>;

export type SourceColumnQuery = {
  __typename?: 'Query';
  sourceColumn: { __typename?: 'ColumnOutput'; id: number; name: string };
};

export type AuthMethodCardFragment = {
  __typename?: 'KeycloakIdentityProvider';
  alias: string;
  displayName?: string | null;
  enabled?: boolean | null;
  providerId: Provider;
} & { ' $fragmentName'?: 'AuthMethodCardFragment' };

export type GetContributorListQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
  filters?: InputMaybe<ContributorListFiltersInput>;
  page?: InputMaybe<PageInput>;
}>;

export type GetContributorListQuery = {
  __typename?: 'Query';
  getContributorList: {
    __typename?: 'PaginatedUserResponse';
    items: Array<
      { __typename?: 'User'; id: string; name: string } & {
        ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
      }
    >;
  };
};

export type GetActivityInsightsQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetActivityInsightsQuery = {
  __typename?: 'Query';
  getActivityInsights: {
    __typename?: 'ActivityInsightsOutput';
    completedProjectsCount: number;
    completedPhasesCount: number;
    approvedPhasesCount: number;
    modelVersionsCount: number;
    datasetVersionsCount: number;
  };
};

export type GetWorkspaceActivityTrendQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
  userTimezone: Scalars['String']['input'];
}>;

export type GetWorkspaceActivityTrendQuery = {
  __typename?: 'Query';
  getWorkspaceActivityTrend: Array<{ __typename?: 'WorkspaceActivityTrendOutput'; date: any; count: number }>;
};

export type GetWorkspaceCompletedPhasesQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceCompletedPhasesQuery = {
  __typename?: 'Query';
  getMostRecentlyCompletedPhases: {
    __typename?: 'PaginatedPhaseCompletedWithCompletionDate';
    items: Array<{
      __typename?: 'PhaseCompletedWithCompletionDate';
      completionDate: any;
      entity: {
        __typename?: 'Phase';
        vecticeId: string;
        name: string;
        createdDate: any;
        parent: { __typename?: 'Project'; vecticeId: string; name: string };
        owner?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
      };
    }>;
  };
};

export type GetWorkspaceCompletedProjectsQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceCompletedProjectsQuery = {
  __typename?: 'Query';
  getMostRecentlyCompletedProjects: {
    __typename?: 'PaginatedProjectCompletedWithCompletionDate';
    items: Array<{
      __typename?: 'ProjectCompletedWithCompletionDate';
      completionDate: any;
      entity: {
        __typename?: 'Project';
        vecticeId: string;
        name: string;
        createdDate: any;
        owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
      };
    }>;
  };
};

export type GetCompletedReviewsCountPerUserQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetCompletedReviewsCountPerUserQuery = {
  __typename?: 'Query';
  getCompletedReviewsCountPerUser: {
    __typename?: 'PaginatedReviewStatusCountWithTotalPerUserOutput';
    items: Array<{
      __typename?: 'ReviewStatusCountWithTotalPerUserOutput';
      total: number;
      user: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
      items: Array<{ __typename?: 'ReviewStatusWithCountOutput'; status: ReviewStatus; count: number }>;
    }>;
  };
};

export type GetPhaseStatusCountsPerOwnerQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetPhaseStatusCountsPerOwnerQuery = {
  __typename?: 'Query';
  getPhaseStatusCountsPerOwner: {
    __typename?: 'PaginatedPhaseStatusCountWithTotalPerUserOutput';
    items: Array<{
      __typename?: 'PhaseStatusCountWithTotalPerUserOutput';
      items: Array<{ __typename?: 'PhaseStatusWithCountOutput'; status: DocumentationPageStatus; count: number }>;
      user: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetWorkspaceCompletedIterationsCountPerUserQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceCompletedIterationsCountPerUserQuery = {
  __typename?: 'Query';
  getWorkspaceCompletedIterationsCountPerUser: {
    __typename?: 'PaginatedWorkspaceCountPerUserOutput';
    items: Array<{
      __typename?: 'WorkspaceCountPerUser';
      count: number;
      user: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetWorkspaceOngoingPhasesQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceOngoingPhasesQuery = {
  __typename?: 'Query';
  getWorkspaceOngoingPhases: {
    __typename?: 'PaginatedWorkspaceOngoingPhasesOutput';
    items: Array<{
      __typename?: 'WorkspaceOngoingPhasesOutput';
      days: number;
      entity: {
        __typename?: 'Phase';
        vecticeId: string;
        name: string;
        status: DocumentationPageStatus;
        parent: { __typename?: 'Project'; vecticeId: string; name: string };
        owner?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
      };
    }>;
  };
};

export type GetWorkspaceOngoingProjectsQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceOngoingProjectsQuery = {
  __typename?: 'Query';
  getWorkspaceOngoingProjects: {
    __typename?: 'PaginatedWorkspaceOngoingProjectsOutput';
    items: Array<{
      __typename?: 'WorkspaceOngoingProjectsOutput';
      days: number;
      entity: {
        __typename?: 'Project';
        vecticeId: string;
        name: string;
        owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
        phasesCounts: {
          __typename?: 'DocumentationCounts';
          toDo: number;
          ongoing: number;
          done: number;
          total: number;
        };
      };
    }>;
  };
};

export type GetPendingReviewsCountPerUserQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetPendingReviewsCountPerUserQuery = {
  __typename?: 'Query';
  getPendingReviewsCountPerUser: {
    __typename?: 'PaginatedWorkspaceCountPerUserOutput';
    items: Array<{
      __typename?: 'WorkspaceCountPerUser';
      count: number;
      user: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetPhaseStatusesBreakdownQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetPhaseStatusesBreakdownQuery = {
  __typename?: 'Query';
  getPhaseStatusCounts: {
    __typename?: 'PhaseStatusCountWithTotalOutput';
    total: number;
    items: Array<{ __typename?: 'PhaseStatusWithCountOutput'; status: DocumentationPageStatus; count: number }>;
  };
};

export type GetWorkspaceCompletedPhasesCountPerUserQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceCompletedPhasesCountPerUserQuery = {
  __typename?: 'Query';
  getWorkspaceCompletedPhasesCountPerUser: {
    __typename?: 'PaginatedWorkspaceCountPerUserOutput';
    items: Array<{
      __typename?: 'WorkspaceCountPerUser';
      count: number;
      user: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetProjectStatusesBreakdownQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetProjectStatusesBreakdownQuery = {
  __typename?: 'Query';
  getProjectStatusCounts: {
    __typename?: 'ProjectStatusCountWithTotalOutput';
    total: number;
    items: Array<{ __typename?: 'ProjectStatusWithCountOutput'; status: ProjectStatus; count: number }>;
  };
};

export type GetTopAssetsCreatorsQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
}>;

export type GetTopAssetsCreatorsQuery = {
  __typename?: 'Query';
  getTopAssetsCreators: {
    __typename?: 'PaginatedTopAssetCreator';
    items: Array<{
      __typename?: 'WorkspaceNumberAssetsByCreator';
      datasetVersionCount: number;
      modelVersionCount: number;
      user: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetDatasetVersionFileSourceQueryVariables = Exact<{
  fileSourceId: Scalars['Float']['input'];
}>;

export type GetDatasetVersionFileSourceQuery = {
  __typename?: 'Query';
  getDatasetFilesSource: {
    __typename?: 'FileSource';
    id: number;
    name: string;
    size?: number | null;
    columnsNumber?: number | null;
    rowsNumber?: number | null;
    uri?: string | null;
    mimeType: string;
    fileCreatedDate?: any | null;
    fileUpdatedDate?: any | null;
    skippedStatistics?: boolean | null;
    extraMetadata?: Array<{
      __typename?: 'ExtraMetadata';
      key: string;
      value?: string | null;
      displayName?: string | null;
    }> | null;
  };
};

export type GetDatasetVersionDbSourceQueryVariables = Exact<{
  dbSourceId: Scalars['Float']['input'];
}>;

export type GetDatasetVersionDbSourceQuery = {
  __typename?: 'Query';
  getDBSource: {
    __typename?: 'DBTableSource';
    id: number;
    name: string;
    size?: number | null;
    type: DbTableType;
    columnsNumber?: number | null;
    rowsNumber?: number | null;
    uri?: string | null;
    tableCreatedDate?: any | null;
    tableUpdatedDate?: any | null;
    skippedStatistics?: boolean | null;
    extraMetadata?: Array<{
      __typename?: 'ExtraMetadata';
      key: string;
      value?: string | null;
      displayName?: string | null;
    }> | null;
  };
};

export type GetDataSourceFileSourceListQueryVariables = Exact<{
  datasetSourceId: Scalars['Float']['input'];
}>;

export type GetDataSourceFileSourceListQuery = {
  __typename?: 'Query';
  fileSources: {
    __typename?: 'PaginatedDatasetFilesSourceResponse';
    total: number;
    items: Array<{
      __typename?: 'FileSource';
      id: number;
      filename: string;
      mimeType: string;
      rowsNumber?: number | null;
      columnsNumber?: number | null;
      columnsTypesCounts: Array<{ __typename?: 'ColumnTypeCount'; categoryType: CategoryType; count: number }>;
    }>;
  };
};

export type GetDataSourceDbSourceListQueryVariables = Exact<{
  datasetSourceId: Scalars['Float']['input'];
}>;

export type GetDataSourceDbSourceListQuery = {
  __typename?: 'Query';
  dbSources: {
    __typename?: 'PaginatedDBSourceResponse';
    total: number;
    items: Array<{
      __typename?: 'DBTableSource';
      id: number;
      tablename: string;
      type: DbTableType;
      rowsNumber?: number | null;
      columnsNumber?: number | null;
      columnsTypesCounts: Array<{ __typename?: 'ColumnTypeCount'; categoryType: CategoryType; count: number }>;
    }>;
  };
};

export type GetDatasetVersionResourcesQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionResourcesQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    targetColumn?: string | null;
    datasetSources: Array<
      { __typename?: 'DatasetSource'; id: number; type: DatasetSourceType; usage?: DatasetSourceUsage | null } & {
        ' $fragmentRefs'?: { DatasetSourceMenuFragment: DatasetSourceMenuFragment };
      }
    >;
  };
};

export type GetDatasetVersionFileSourceListQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionFileSourceListQuery = {
  __typename?: 'Query';
  fileSources: {
    __typename?: 'PaginatedDatasetFilesSourceResponse';
    total: number;
    items: Array<{
      __typename?: 'FileSource';
      id: number;
      filename: string;
      mimeType: string;
      rowsNumber?: number | null;
      columnsNumber?: number | null;
      columnsTypesCounts: Array<{ __typename?: 'ColumnTypeCount'; categoryType: CategoryType; count: number }>;
    }>;
  };
};

export type GetDatasetVersionDbSourceListQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionDbSourceListQuery = {
  __typename?: 'Query';
  dbSources: {
    __typename?: 'PaginatedDBSourceResponse';
    total: number;
    items: Array<{
      __typename?: 'DBTableSource';
      id: number;
      tablename: string;
      type: DbTableType;
      rowsNumber?: number | null;
      columnsNumber?: number | null;
      columnsTypesCounts: Array<{ __typename?: 'ColumnTypeCount'; categoryType: CategoryType; count: number }>;
    }>;
  };
};

export type DatasetSourceMenuFragment = {
  __typename?: 'DatasetSource';
  id: number;
  itemsCount: number;
  columnsCount: number;
  size?: number | null;
  type: DatasetSourceType;
  usage?: DatasetSourceUsage | null;
} & { ' $fragmentName'?: 'DatasetSourceMenuFragment' };

export type UpdateDatasetVersionMutationVariables = Exact<{
  targetColumn?: InputMaybe<Scalars['String']['input']>;
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type UpdateDatasetVersionMutation = {
  __typename?: 'Mutation';
  updateDatasetVersion: { __typename?: 'DataSetVersion'; vecticeId: string; targetColumn?: string | null };
};

export type UpdateColumnSourceDescriptionMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  columnSourceType: ColumnSourceType;
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateColumnSourceDescriptionMutation = { __typename?: 'Mutation'; updateColumnSourceDescription: string };

export type GetDatasetVersionFileColumnsQueryVariables = Exact<{
  fileSourceId: Scalars['Float']['input'];
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize: Scalars['Int']['input'];
  search: Scalars['String']['input'];
}>;

export type GetDatasetVersionFileColumnsQuery = {
  __typename?: 'Query';
  getFileSourceColumnsList: {
    __typename?: 'PaginatedFileSourceColumnsList';
    total: number;
    items: Array<{
      __typename?: 'FileSourceColumn';
      id: number;
      name: string;
      description?: string | null;
      categoryType: CategoryType;
      statistics?:
        | ({ __typename?: 'DataframeStatistics' } & {
            ' $fragmentRefs'?: {
              BooleanSourceColumnStatisticsFragment: BooleanSourceColumnStatisticsFragment;
              DateSourceColumnStatisticsFragment: DateSourceColumnStatisticsFragment;
              NumericalSourceColumnStatisticsFragment: NumericalSourceColumnStatisticsFragment;
              TextSourceColumnStatisticsFragment: TextSourceColumnStatisticsFragment;
            };
          })
        | null;
    }>;
  };
};

export type GetDatasetVersionDbColumnsQueryVariables = Exact<{
  dbSourceId: Scalars['Float']['input'];
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize: Scalars['Int']['input'];
  search: Scalars['String']['input'];
}>;

export type GetDatasetVersionDbColumnsQuery = {
  __typename?: 'Query';
  getDBSourceColumnsList: {
    __typename?: 'PaginatedDbSourceColumnsList';
    total: number;
    items: Array<{
      __typename?: 'DBColumnSource';
      id: number;
      name: string;
      description?: string | null;
      categoryType: CategoryType;
      statistics?:
        | ({ __typename?: 'DataframeStatistics' } & {
            ' $fragmentRefs'?: {
              BooleanSourceColumnStatisticsFragment: BooleanSourceColumnStatisticsFragment;
              DateSourceColumnStatisticsFragment: DateSourceColumnStatisticsFragment;
              NumericalSourceColumnStatisticsFragment: NumericalSourceColumnStatisticsFragment;
              TextSourceColumnStatisticsFragment: TextSourceColumnStatisticsFragment;
            };
          })
        | null;
    }>;
  };
};

export type BooleanSourceColumnStatisticsFragment = {
  __typename?: 'DataframeStatistics';
  boolean?: { __typename?: 'DataframeBooleanStatistics'; false: number; missing: number; true: number } | null;
} & { ' $fragmentName'?: 'BooleanSourceColumnStatisticsFragment' };

export type DateSourceColumnStatisticsFragment = {
  __typename?: 'DataframeStatistics';
  date?: {
    __typename?: 'DataframeDateStatistics';
    maximum: any;
    mean: any;
    median: any;
    minimum: any;
    missing: number;
  } | null;
} & { ' $fragmentName'?: 'DateSourceColumnStatisticsFragment' };

export type NumericalSourceColumnStatisticsFragment = {
  __typename?: 'DataframeStatistics';
  numerical?: {
    __typename?: 'DataframeNumericalStatistics';
    mean: number;
    missing: number;
    stdDeviation: number;
    quantiles: {
      __typename?: 'DataframeNumericalStatisticsQuantiles';
      qMin: number;
      q25: number;
      q50: number;
      q75: number;
      qMax: number;
    };
  } | null;
} & { ' $fragmentName'?: 'NumericalSourceColumnStatisticsFragment' };

export type TextSourceColumnStatisticsFragment = {
  __typename?: 'DataframeStatistics';
  text?: {
    __typename?: 'DataframeTextStatistics';
    missing: number;
    unique: number;
    mostCommons: Array<{ __typename?: 'DataframeTextStatisticsMostCommonValue'; frequency: number; value: string }>;
  } | null;
} & { ' $fragmentName'?: 'TextSourceColumnStatisticsFragment' };

export type GetDatasetVersionWithOriginQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionWithOriginQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    origin?: { __typename?: 'Lineage'; id: number; datasetInputs: Array<string> } | null;
    dataSet: { __typename?: 'DataSet'; vecticeId: string; project: { __typename?: 'Project'; vecticeId: string } };
  };
};

export type DatasetVersionSidebarFragment = {
  __typename?: 'DataSetVersion';
  vecticeId: string;
  description?: string | null;
  sourceOrigin: string;
  createdDate: any;
  updatedDate: any;
  dataSet: {
    __typename?: 'DataSet';
    vecticeId: string;
    type: DatasetType;
    project: {
      __typename?: 'Project';
      vecticeId: string;
      workspace?: { __typename?: 'Workspace'; vecticeId: string } | null;
    };
  };
  origins: {
    __typename?: 'AssetOriginsOutput';
    phase?: { __typename?: 'AssetOriginOutput'; vecticeId?: string | null; name: string } | null;
    iteration?: { __typename?: 'AssetOriginOutput'; vecticeId?: string | null; name: string } | null;
  };
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  lastUpdatedBy?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
} & { ' $fragmentName'?: 'DatasetVersionSidebarFragment' };

export type UpdateDatasetVersionDescriptionMutationVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
  data: DatasetVersionUpdateInput;
}>;

export type UpdateDatasetVersionDescriptionMutation = {
  __typename?: 'Mutation';
  updateDatasetVersion: { __typename?: 'DataSetVersion'; vecticeId: string; description?: string | null };
};

export type AddToStarredDatasetVersionsMutationVariables = Exact<{
  resource: StarredResourceSettings;
}>;

export type AddToStarredDatasetVersionsMutation = {
  __typename?: 'Mutation';
  addToStarredDatasetVersions: { __typename?: 'DataSetVersion'; vecticeId: string; isStarred: boolean };
};

export type GetDatasetTypeQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetTypeQuery = {
  __typename?: 'Query';
  getDataset: { __typename?: 'DataSet'; vecticeId: string; type: DatasetType };
};

export type GetDatasetVersionsListQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  showStarred?: InputMaybe<Scalars['Boolean']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetDatasetVersionsListQuery = {
  __typename?: 'Query';
  getDatasetVersionsList: {
    __typename?: 'PaginatedDataSetVersionResponse';
    total: number;
    items: Array<{
      __typename?: 'DataSetVersion';
      vecticeId: string;
      versionNumber: number;
      isStarred: boolean;
      createdDate: any;
      origin?: { __typename?: 'Lineage'; id: number } | null;
      properties: Array<
        { __typename?: 'EntityProperty' } & {
          ' $fragmentRefs'?: { PropertiesFieldsFragment: PropertiesFieldsFragment };
        }
      >;
      createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
      datasetSources: Array<{
        __typename?: 'DatasetSource';
        type: DatasetSourceType;
        usage?: DatasetSourceUsage | null;
        size?: number | null;
        itemsCount: number;
        columnsCount: number;
      }>;
    }>;
  };
};

export type GetPropertyKeysOfDatasetQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
}>;

export type GetPropertyKeysOfDatasetQuery = { __typename?: 'Query'; getPropertyKeysOfEntity: Array<string> };

export type GetUserWorkspaceFiltersListQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type GetUserWorkspaceFiltersListQuery = {
  __typename?: 'Query';
  getUserWorkspaceList: {
    __typename?: 'PaginatedWorkspaceResponseOutput';
    items: Array<{ __typename?: 'Workspace'; vecticeId: string; name: string }>;
  };
};

export type GetUniqueAlgorithmNamesQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
  modelIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  search: Scalars['String']['input'];
  projectIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
}>;

export type GetUniqueAlgorithmNamesQuery = {
  __typename?: 'Query';
  getUniqueAlgorithmNames: {
    __typename?: 'PaginatedUniqueAlgorithmNamesOutput';
    items: Array<{ __typename?: 'AlgorithmNameOutput'; algorithmName: string }>;
  };
};

export type GetUniqueInventoryReferencesQueryVariables = Exact<{
  workspaceIdList: Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input'];
  modelIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  search: Scalars['String']['input'];
  projectIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
}>;

export type GetUniqueInventoryReferencesQuery = {
  __typename?: 'Query';
  getUniqueInventoryReferences: {
    __typename?: 'PaginatedUniqueInventoryReferencesOutput';
    items: Array<{ __typename?: 'InventoryReferenceOutput'; inventoryReference: string }>;
  };
};

export type RemoveFindingMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type RemoveFindingMutation = { __typename?: 'Mutation'; removeFinding: number };

export type GetRecentProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecentProjectsQuery = {
  __typename?: 'Query';
  getProjectList: {
    __typename?: 'PaginatedProjectResponse';
    items: Array<
      { __typename?: 'Project'; vecticeId: string; templateUsed?: string | null } & {
        ' $fragmentRefs'?: { ProjectCardFragment: ProjectCardFragment };
      }
    >;
  };
};

export type GetUserIterationsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  pageSize: Scalars['Int']['input'];
}>;

export type GetUserIterationsQuery = {
  __typename?: 'Query';
  getIterationList: {
    __typename?: 'PaginatedIterationResponse';
    items: Array<
      {
        __typename?: 'Iteration';
        vecticeId: string;
        phase: {
          __typename?: 'Phase';
          vecticeId: string;
          parent: {
            __typename?: 'Project';
            vecticeId: string;
            name: string;
            workspace?: { __typename?: 'Workspace'; vecticeId: string; type: WorkspaceType; name: string } | null;
          };
        };
      } & { ' $fragmentRefs'?: { IterationListItemFragment: IterationListItemFragment } }
    >;
    page: { __typename?: 'PageOutput'; afterCursor?: string | null; hasNextPage?: boolean | null };
  };
};

export type GetAffectedReviewsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  pageSize: Scalars['Int']['input'];
}>;

export type GetAffectedReviewsQuery = {
  __typename?: 'Query';
  getReviewList: {
    __typename?: 'PaginatedReviewResponse';
    items: Array<
      {
        __typename?: 'Review';
        id: number;
        phase: {
          __typename?: 'Phase';
          vecticeId: string;
          parent: {
            __typename?: 'Project';
            vecticeId: string;
            workspace?: { __typename?: 'Workspace'; vecticeId: string; type: WorkspaceType; name: string } | null;
          };
        };
      } & { ' $fragmentRefs'?: { ReviewListItemFragment: ReviewListItemFragment } }
    >;
    page: { __typename?: 'PageOutput'; afterCursor?: string | null; hasNextPage?: boolean | null };
  };
};

export type GetUserPhasesQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  pageSize: Scalars['Int']['input'];
}>;

export type GetUserPhasesQuery = {
  __typename?: 'Query';
  getPhaseList: {
    __typename?: 'PaginatedPhaseResponseOutput';
    items: Array<
      {
        __typename?: 'Phase';
        vecticeId: string;
        parent: {
          __typename?: 'Project';
          vecticeId: string;
          name: string;
          workspace?: { __typename?: 'Workspace'; vecticeId: string; type: WorkspaceType; name: string } | null;
        };
      } & { ' $fragmentRefs'?: { PhaseListItemFragment: PhaseListItemFragment } }
    >;
    page: { __typename?: 'PageOutput'; afterCursor?: string | null; hasNextPage?: boolean | null };
  };
};

export type GetUserReportsQueryVariables = Exact<{
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  pageSize: Scalars['Int']['input'];
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
}>;

export type GetUserReportsQuery = {
  __typename?: 'Query';
  CDTReports: {
    __typename?: 'PaginatedCDTReportResponse';
    items: Array<
      { __typename?: 'CDTReport'; id: number } & {
        ' $fragmentRefs'?: { ReportListItemFragment: ReportListItemFragment };
      }
    >;
    page: { __typename?: 'PageOutput'; afterCursor?: string | null; hasNextPage?: boolean | null };
  };
};

export type IterationSidebarFragment = {
  __typename?: 'Iteration';
  vecticeId: string;
  status: IterationStatus;
  description?: string | null;
  createdDate: any;
  updatedDate: any;
  phase: {
    __typename?: 'Phase';
    vecticeId: string;
    parent: {
      __typename?: 'Project';
      vecticeId: string;
      workspace?: { __typename?: 'Workspace'; vecticeId: string; type: WorkspaceType } | null;
    };
  };
  owner: { __typename?: 'User'; id: string; disabled: boolean } & {
    ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
  };
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  lastUpdatedBy?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
} & { ' $fragmentName'?: 'IterationSidebarFragment' };

export type IterationStepArtifactFragment = {
  __typename?: 'IterationStepArtifact';
  id: number;
  text?: string | null;
  type: IterationStepArtifactType;
  index: number;
  entityFileId?: number | null;
  datasetVersion?: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    attachments: {
      __typename?: 'PaginatedEntityFileList';
      items: Array<
        { __typename?: 'EntityFile' } & {
          ' $fragmentRefs'?: { EntityFileFragmentFragment: EntityFileFragmentFragment };
        }
      >;
    };
  } | null;
  entityFile?:
    | ({ __typename?: 'EntityFile' } & {
        ' $fragmentRefs'?: { EntityFileFragmentFragment: EntityFileFragmentFragment };
      })
    | null;
  modelVersion?: { __typename?: 'ModelVersion'; vecticeId: string } | null;
  entityMetadata?: { __typename?: 'EntityMetadata'; id: number; name: string } | null;
} & { ' $fragmentName'?: 'IterationStepArtifactFragment' };

export type IterationStepFragment = {
  __typename?: 'IterationStep';
  id: number;
  name: string;
  description?: string | null;
  updatedDate: any;
  paginatedArtifacts: {
    __typename?: 'PaginatedIterationStepArtifactResponse';
    total: number;
    items: Array<
      {
        __typename?: 'IterationStepArtifact';
        id: number;
        stepId?: number | null;
        type: IterationStepArtifactType;
        index: number;
      } & { ' $fragmentRefs'?: { IterationStepArtifactFragment: IterationStepArtifactFragment } }
    >;
  };
} & { ' $fragmentName'?: 'IterationStepFragment' };

export type LineageAssetFragmentFragment = {
  __typename?: 'LineageArtifact';
  id: number;
  artifactType: ArtifactType;
  dataSetVersion?: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    versionNumber: number;
    dataSet: { __typename?: 'DataSet'; name: string; vecticeId: string };
  } | null;
  modelVersion?: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    versionNumber: number;
    model: { __typename?: 'Model'; name: string; vecticeId: string };
  } | null;
} & { ' $fragmentName'?: 'LineageAssetFragmentFragment' };

export type LineageCodeFragmentFragment = {
  __typename?: 'LineageArtifact';
  codeVersion?: {
    __typename?: 'CodeVersion';
    id: number;
    code?: { __typename?: 'Code'; name: string; description?: string | null } | null;
    gitVersion?: {
      __typename?: 'GitVersion';
      branchName?: string | null;
      commitHash?: string | null;
      repositoryName: string;
      uri?: string | null;
      entrypoint?: string | null;
      isDirty: boolean;
      additionalInformation?: string | null;
    } | null;
    databricksVersion?: {
      __typename?: 'DatabricksVersion';
      urlNotebook: string;
      relativePath?: string | null;
      timestamp?: any | null;
      additionalInformation?: string | null;
    } | null;
    otherCodeVersion?: {
      __typename?: 'OtherCodeVersion';
      id: number;
      url: string;
      firstAdditionalInformation?: string | null;
      secondAdditionalInformation?: string | null;
      thirdAdditionalInformation?: string | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'LineageCodeFragmentFragment' };

export type RemoveCdtReportMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type RemoveCdtReportMutation = { __typename?: 'Mutation'; removeCDTReport: number };

export type DeleteDatasetMutationVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
}>;

export type DeleteDatasetMutation = { __typename?: 'Mutation'; deleteDataset: string };

export type DeleteDatasetVersionMutationVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type DeleteDatasetVersionMutation = { __typename?: 'Mutation'; deleteDatasetVersion: string };

export type RemoveEmailDomainFromOrganizationMutationVariables = Exact<{
  organizationId: Scalars['Float']['input'];
  emailDomain: Scalars['String']['input'];
}>;

export type RemoveEmailDomainFromOrganizationMutation = {
  __typename?: 'Mutation';
  removeEmailDomainFromOrganization: {
    __typename?: 'Organization';
    id: string;
    emailDomains: Array<{ __typename?: 'EmailDomain'; domain: string }>;
  };
};

export type RemoveIterationMutationVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
}>;

export type RemoveIterationMutation = { __typename?: 'Mutation'; removeIteration: string };

export type DeleteModelMutationVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
}>;

export type DeleteModelMutation = { __typename?: 'Mutation'; deleteModel: string };

export type DeleteModelVersionMutationVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type DeleteModelVersionMutation = { __typename?: 'Mutation'; deleteModelVersion: string };

export type RemovePhaseMutationVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
}>;

export type RemovePhaseMutation = { __typename?: 'Mutation'; removePhase: string };

export type DuplicateCdtReportMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  duplicateInput: BaseDocumentationDuplicateInput;
}>;

export type DuplicateCdtReportMutation = {
  __typename?: 'Mutation';
  duplicateCDTReport: { __typename?: 'CDTReport'; id: number };
};

export type DuplicatePhaseMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
  duplicateInput: PhaseDuplicateInput;
}>;

export type DuplicatePhaseMutation = {
  __typename?: 'Mutation';
  duplicatePhase: { __typename?: 'Phase'; vecticeId: string };
};

export type CheckDatasetNameAvailabilityQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  projectId: Scalars['VecticeId']['input'];
  name: Scalars['String']['input'];
}>;

export type CheckDatasetNameAvailabilityQuery = { __typename?: 'Query'; checkDatasetNameAvailability: boolean };

export type UpdateDatasetMutationVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  data: DatasetUpdateInput;
}>;

export type UpdateDatasetMutation = {
  __typename?: 'Mutation';
  updateDataset: { __typename?: 'DataSet'; vecticeId: string; name: string; description?: string | null };
};

export type CheckIterationNameAvailabilityQueryVariables = Exact<{
  parentId: Scalars['VecticeId']['input'];
  name: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['VecticeId']['input']>;
}>;

export type CheckIterationNameAvailabilityQuery = { __typename?: 'Query'; checkIterationNameAvailability: boolean };

export type UpdateIterationMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
  data: IterationUpdateInput;
}>;

export type UpdateIterationMutation = {
  __typename?: 'Mutation';
  updateIteration: { __typename?: 'Iteration'; vecticeId: string; name: string; description?: string | null };
};

export type CheckModelNameAvailabilityQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
  projectId: Scalars['VecticeId']['input'];
  name: Scalars['String']['input'];
}>;

export type CheckModelNameAvailabilityQuery = { __typename?: 'Query'; checkModelNameAvailability: boolean };

export type UpdateModelMutationVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
  data: ModelUpdateInput;
}>;

export type UpdateModelMutation = {
  __typename?: 'Mutation';
  updateModel: { __typename?: 'Model'; vecticeId: string; name: string; description?: string | null };
};

export type UpdateCdtReportMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  data: CdtReportUpdateInput;
}>;

export type UpdateCdtReportMutation = {
  __typename?: 'Mutation';
  updateCDTReport: {
    __typename?: 'CDTReport';
    id: number;
    name: string;
    modelVersion?: { __typename?: 'ModelVersion'; vecticeId: string; name?: string | null } | null;
  };
};

export type CreatePhaseMutationVariables = Exact<{
  parentId: Scalars['VecticeId']['input'];
  createModel: PhaseCreateInput;
}>;

export type CreatePhaseMutation = { __typename?: 'Mutation'; createPhase: { __typename?: 'Phase'; vecticeId: string } };

export type MoveProjectMutationVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  projectName: Scalars['String']['input'];
  targetWorkspaceId: Scalars['VecticeId']['input'];
}>;

export type MoveProjectMutation = {
  __typename?: 'Mutation';
  moveProject: {
    __typename?: 'Project';
    vecticeId: string;
    workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string } | null;
  };
};

export type ModelVersionInformationFragment = {
  __typename?: 'ModelVersion';
  vecticeId: string;
  context?: {
    __typename?: 'ModelVersionContext';
    library?: ModelVersionContextLibrary | null;
    url?: string | null;
    run?: string | null;
    extraInfo?: Array<{
      __typename?: 'ExtraMetadata';
      key: string;
      value?: string | null;
      displayName?: string | null;
    }> | null;
  } | null;
} & { ' $fragmentName'?: 'ModelVersionInformationFragment' };

export type GetModelVersionOverviewQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionOverviewQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    origin?: { __typename?: 'Lineage'; id: number; datasetInputs: Array<string> } | null;
    model: { __typename?: 'Model'; vecticeId: string; project: { __typename?: 'Project'; vecticeId: string } };
  } & { ' $fragmentRefs'?: { ModelVersionInformationFragment: ModelVersionInformationFragment } };
};

export type ModelVersionSidebarFragment = {
  __typename?: 'ModelVersion';
  vecticeId: string;
  description?: string | null;
  algorithmName?: string | null;
  status: ModelVersionStatus;
  risk: ModelVersionRisk;
  approval: ModelVersionApproval;
  inventoryReference?: string | null;
  createdDate: any;
  updatedDate: any;
  model: {
    __typename?: 'Model';
    vecticeId: string;
    type: ModelType;
    project: {
      __typename?: 'Project';
      vecticeId: string;
      workspace?: { __typename?: 'Workspace'; vecticeId: string } | null;
    };
  };
  origins: {
    __typename?: 'AssetOriginsOutput';
    phase?: { __typename?: 'AssetOriginOutput'; vecticeId?: string | null; name: string } | null;
    iteration?: { __typename?: 'AssetOriginOutput'; vecticeId?: string | null; name: string } | null;
  };
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  lastUpdatedBy?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
} & { ' $fragmentName'?: 'ModelVersionSidebarFragment' };

export type UpdateModelVersionDescriptionMutationVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
  data: ModelVersionUpdateInput;
}>;

export type UpdateModelVersionDescriptionMutation = {
  __typename?: 'Mutation';
  updateModelVersion: { __typename?: 'ModelVersion'; vecticeId: string; description?: string | null };
};

export type UpdateModelVersionTechniqueMutationVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
  data: ModelVersionUpdateInput;
}>;

export type UpdateModelVersionTechniqueMutation = {
  __typename?: 'Mutation';
  updateModelVersion: { __typename?: 'ModelVersion'; vecticeId: string; algorithmName?: string | null };
};

export type UpdateModelVersionInventoryMutationVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
  data: ModelVersionUpdateInput;
}>;

export type UpdateModelVersionInventoryMutation = {
  __typename?: 'Mutation';
  updateModelVersion: { __typename?: 'ModelVersion'; vecticeId: string; inventoryReference?: string | null };
};

export type UpdateModelVersionRiskMutationVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
  data: ModelVersionUpdateInput;
}>;

export type UpdateModelVersionRiskMutation = {
  __typename?: 'Mutation';
  updateModelVersion: { __typename?: 'ModelVersion'; vecticeId: string; risk: ModelVersionRisk };
};

export type UpdateModelVersionApprovalMutationVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
  data: ModelVersionUpdateInput;
}>;

export type UpdateModelVersionApprovalMutation = {
  __typename?: 'Mutation';
  updateModelVersion: { __typename?: 'ModelVersion'; vecticeId: string; approval: ModelVersionApproval };
};

export type AddToStarredModelVersionsMutationVariables = Exact<{
  resource: StarredResourceSettings;
}>;

export type AddToStarredModelVersionsMutation = {
  __typename?: 'Mutation';
  addToStarredModelVersions: { __typename?: 'ModelVersion'; vecticeId: string; isStarred: boolean };
};

export type GetMetricKeysOfEntityQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
}>;

export type GetMetricKeysOfEntityQuery = { __typename?: 'Query'; getMetricKeysOfEntity: Array<string> };

export type GetModelVersionsListQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<ListOrderInput>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  showStarred?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  algorithmNames?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  modelVersionStatus?: InputMaybe<Array<ModelVersionStatus> | ModelVersionStatus>;
  risks?: InputMaybe<Array<ModelVersionRisk> | ModelVersionRisk>;
  approvals?: InputMaybe<Array<ModelVersionApproval> | ModelVersionApproval>;
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
}>;

export type GetModelVersionsListQuery = {
  __typename?: 'Query';
  getModelVersionsList: {
    __typename?: 'PaginatedModelVersionResponse';
    total: number;
    items: Array<{
      __typename?: 'ModelVersion';
      vecticeId: string;
      versionNumber: number;
      isStarred: boolean;
      status: ModelVersionStatus;
      risk: ModelVersionRisk;
      approval: ModelVersionApproval;
      algorithmName?: string | null;
      inventoryReference?: string | null;
      createdDate: any;
      origin?: { __typename?: 'Lineage'; id: number } | null;
      properties: Array<
        { __typename?: 'EntityProperty' } & {
          ' $fragmentRefs'?: { PropertiesFieldsFragment: PropertiesFieldsFragment };
        }
      >;
      metrics: Array<
        { __typename?: 'EntityMetric' } & { ' $fragmentRefs'?: { MetricsFieldsFragment: MetricsFieldsFragment } }
      >;
      createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetPropertyKeysOfModelQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
}>;

export type GetPropertyKeysOfModelQuery = { __typename?: 'Query'; getPropertyKeysOfEntity: Array<string> };

export type NewUserNotificationFragment = {
  __typename?: 'UserNotification';
  id: string;
  type: UserNotificationType;
  content?: string | null;
  createdDate: any;
  creator?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
  workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string } | null;
} & { ' $fragmentName'?: 'NewUserNotificationFragment' };

export type CountUnseenUserNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type CountUnseenUserNotificationsQuery = { __typename?: 'Query'; countUnseenUserNotifications: number };

export type GetUserNotificationListQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  filters?: InputMaybe<UserNotificationListFiltersInput>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  pageSize: Scalars['Int']['input'];
}>;

export type GetUserNotificationListQuery = {
  __typename?: 'Query';
  getUserNotificationList: {
    __typename?: 'PaginatedUserNotificationResponse';
    total: number;
    items: Array<{
      __typename?: 'UserNotification';
      id: string;
      type: UserNotificationType;
      content?: string | null;
      createdDate: any;
      creator?:
        | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
        | null;
      workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string } | null;
    }>;
    page: { __typename?: 'PageOutput'; afterCursor?: string | null; hasNextPage?: boolean | null };
  };
};

export type MarkAllUserNotificationsAsSeenMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllUserNotificationsAsSeenMutation = {
  __typename?: 'Mutation';
  markAllUserNotificationsAsSeen: boolean;
};

export type GetFindingByIdQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetFindingByIdQuery = { __typename?: 'Query'; Finding: { __typename?: 'Finding'; name: string } };

export type GetPhaseForMentionQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetPhaseForMentionQuery = {
  __typename?: 'Query';
  getPhaseById: { __typename?: 'Phase'; vecticeId: string; name: string };
};

export type GetCdtReportForMentionQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetCdtReportForMentionQuery = {
  __typename?: 'Query';
  CDTReport: { __typename?: 'CDTReport'; name: string };
};

export type GetWorkspaceAccessRequestQueryVariables = Exact<{
  requestId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceAccessRequestQuery = {
  __typename?: 'Query';
  getWorkspaceAccessRequest: {
    __typename?: 'WorkspaceAccessRequest';
    id: string;
    status: WorkspaceAccessRequestStatus;
  };
};

export type ResolveWorkspaceAccessRequestMutationVariables = Exact<{
  requestId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
  approve: Scalars['Boolean']['input'];
}>;

export type ResolveWorkspaceAccessRequestMutation = {
  __typename?: 'Mutation';
  resolveWorkspaceAccessRequest: {
    __typename?: 'WorkspaceAccessRequest';
    id: string;
    status: WorkspaceAccessRequestStatus;
  };
};

export type GetPhaseForPhaseOwnerChangeQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetPhaseForPhaseOwnerChangeQuery = {
  __typename?: 'Query';
  getPhaseById: { __typename?: 'Phase'; vecticeId: string; name: string };
};

export type GetProjectForProjectOwnerChangeQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetProjectForProjectOwnerChangeQuery = {
  __typename?: 'Query';
  getProjectById: { __typename?: 'Project'; vecticeId: string; name: string };
};

export type GetProjectForProjectOwnershipTransferQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetProjectForProjectOwnershipTransferQuery = {
  __typename?: 'Query';
  getProjectById: { __typename?: 'Project'; vecticeId: string; name: string };
};

export type GetProjectForProjectStatusChangeQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetProjectForProjectStatusChangeQuery = {
  __typename?: 'Query';
  getProjectById: { __typename?: 'Project'; vecticeId: string; name: string };
};

export type GetPhaseForRequestMentionQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetPhaseForRequestMentionQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    vecticeId: string;
    name: string;
    parent: {
      __typename?: 'Project';
      vecticeId: string;
      workspace?: { __typename?: 'Workspace'; vecticeId: string } | null;
    };
  };
};

export type UnseenUserNotificationCountChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type UnseenUserNotificationCountChangedSubscription = {
  __typename?: 'Subscription';
  userNotificationChanged: { __typename?: 'UserNotificationOutput'; unseenCount: number };
};

export type UserNotificationCreatedUnseenSubscriptionVariables = Exact<{ [key: string]: never }>;

export type UserNotificationCreatedUnseenSubscription = {
  __typename?: 'Subscription';
  userNotificationCreatedUnseen: {
    __typename?: 'UserNotificationOutput';
    payload?: Array<{
      __typename?: 'UserNotification';
      id: string;
      type: UserNotificationType;
      content?: string | null;
      createdDate: any;
      status: UserNotificationStatus;
      creator?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
      workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string } | null;
    }> | null;
  };
};

export type GetPhaseOverviewQueryVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
}>;

export type GetPhaseOverviewQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    name: string;
    vecticeId: string;
    description?: string | null;
    enforceRequirementsAsSections: boolean;
    parent: { __typename?: 'Project'; vecticeId: string };
  };
};

export type PhaseSidebarFragment = {
  __typename?: 'Phase';
  vecticeId: string;
  status: DocumentationPageStatus;
  createdDate: any;
  updatedDate: any;
  parent: {
    __typename?: 'Project';
    vecticeId: string;
    workspace?: { __typename?: 'Workspace'; vecticeId: string; type: WorkspaceType } | null;
  };
  owner?:
    | ({ __typename?: 'User'; id: string; disabled: boolean } & {
        ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
      })
    | null;
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  lastUpdatedBy?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
} & { ' $fragmentName'?: 'PhaseSidebarFragment' };

export type EntityMetadataContentQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type EntityMetadataContentQuery = {
  __typename?: 'Query';
  entityMetadata: { __typename?: 'EntityMetadata'; id: number; name: string; content: any };
};

export type GetInitialAssetCountQueryVariables = Exact<{
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  assetType: AssetType;
  search?: InputMaybe<Scalars['String']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type GetInitialAssetCountQuery = {
  __typename?: 'Query';
  searchAssets: {
    __typename?: 'AssetSearchResponse';
    project: { __typename?: 'PaginatedProjectResponse'; total: number };
    dataset: { __typename?: 'PaginatedDataSetResponse'; total: number };
    model: { __typename?: 'PaginatedModelResponse'; total: number };
  };
};

export type SearchProjectAssetsQueryVariables = Exact<{
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  assetType: AssetType;
  search?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type SearchProjectAssetsQuery = {
  __typename?: 'Query';
  searchAssets: {
    __typename?: 'AssetSearchResponse';
    project: {
      __typename?: 'PaginatedProjectResponse';
      total: number;
      items: Array<
        {
          __typename?: 'Project';
          vecticeId: string;
          workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType } | null;
        } & { ' $fragmentRefs'?: { ProjectListItemFragment: ProjectListItemFragment } }
      >;
    };
    dataset: { __typename?: 'PaginatedDataSetResponse'; total: number };
    model: { __typename?: 'PaginatedModelResponse'; total: number };
  };
};

export type SearchDatasetAssetsQueryVariables = Exact<{
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  assetType: AssetType;
  search?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  dsTypes?: InputMaybe<Array<DatasetType> | DatasetType>;
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type SearchDatasetAssetsQuery = {
  __typename?: 'Query';
  searchAssets: {
    __typename?: 'AssetSearchResponse';
    project: { __typename?: 'PaginatedProjectResponse'; total: number };
    dataset: {
      __typename?: 'PaginatedDataSetResponse';
      total: number;
      items: Array<
        {
          __typename?: 'DataSet';
          vecticeId: string;
          project: {
            __typename?: 'Project';
            vecticeId: string;
            name: string;
            workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType } | null;
          };
        } & { ' $fragmentRefs'?: { DatasetListItemFragment: DatasetListItemFragment } }
      >;
    };
    model: { __typename?: 'PaginatedModelResponse'; total: number };
  };
};

export type SearchModelAssetsQueryVariables = Exact<{
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  assetType: AssetType;
  search?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  types?: InputMaybe<Array<ModelType> | ModelType>;
  modelVersionStatus?: InputMaybe<Array<ModelVersionStatus> | ModelVersionStatus>;
  algorithmNames?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  inventoryReferences?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type SearchModelAssetsQuery = {
  __typename?: 'Query';
  searchAssets: {
    __typename?: 'AssetSearchResponse';
    project: { __typename?: 'PaginatedProjectResponse'; total: number };
    dataset: { __typename?: 'PaginatedDataSetResponse'; total: number };
    model: {
      __typename?: 'PaginatedModelResponse';
      total: number;
      items: Array<
        {
          __typename?: 'Model';
          vecticeId: string;
          project: {
            __typename?: 'Project';
            vecticeId: string;
            name: string;
            workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType } | null;
          };
        } & { ' $fragmentRefs'?: { ModelListItemFragment: ModelListItemFragment } }
      >;
    };
  };
};

export type UpdatePhaseStatusMutationVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
  status: DocumentationPageStatus;
}>;

export type UpdatePhaseStatusMutation = {
  __typename?: 'Mutation';
  updatePhase: { __typename?: 'Phase'; vecticeId: string; status: DocumentationPageStatus };
};

export type UpdateIterationStatusMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
  status: IterationStatus;
}>;

export type UpdateIterationStatusMutation = {
  __typename?: 'Mutation';
  updateIteration: { __typename?: 'Iteration'; vecticeId: string; status: IterationStatus };
};

export type UpdateModelVersionStatusMutationVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
  status: ModelVersionStatus;
}>;

export type UpdateModelVersionStatusMutation = {
  __typename?: 'Mutation';
  updateModelVersion: { __typename?: 'ModelVersion'; vecticeId: string; status: ModelVersionStatus };
};

export type UpdatePhaseOwnerMutationVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
  ownerId?: InputMaybe<Scalars['Float']['input']>;
}>;

export type UpdatePhaseOwnerMutation = {
  __typename?: 'Mutation';
  updatePhase: {
    __typename?: 'Phase';
    vecticeId: string;
    ownerId?: number | null;
    owner?:
      | ({ __typename?: 'User'; id: string; disabled: boolean } & {
          ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
        })
      | null;
  };
};

export type SubscribeToUnresolvedFileCommentsSubscriptionVariables = Exact<{
  fileId: Scalars['Float']['input'];
}>;

export type SubscribeToUnresolvedFileCommentsSubscription = {
  __typename?: 'Subscription';
  subscribeToFileComments: {
    __typename?: 'CommentSubscriptionOutput';
    fileId: number;
    threadId: number;
    status: ThreadStatus;
    isDeleted: boolean;
    replyId?: number | null;
  };
};

export type ThreadsUnresolvedCountQueryVariables = Exact<{
  id: Scalars['Float']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type ThreadsUnresolvedCountQuery = {
  __typename?: 'Query';
  threads: {
    __typename?: 'PaginatedThreadResponse';
    total: number;
    items: Array<{ __typename?: 'Thread'; id: number }>;
  };
};

export type CreateEntityMetricMutationVariables = Exact<{
  entityId: Scalars['VecticeId']['input'];
  createMetric: CreateEntityMetricInput;
}>;

export type CreateEntityMetricMutation = {
  __typename?: 'Mutation';
  createEntityMetric: { __typename?: 'EntityMetric' } & {
    ' $fragmentRefs'?: { MetricsFieldsFragment: MetricsFieldsFragment };
  };
};

export type CreateEntityPropertyMutationVariables = Exact<{
  entityId: Scalars['VecticeId']['input'];
  entityType: EntityPropertyType;
  createProperty: CreateEntityPropertyInput;
}>;

export type CreateEntityPropertyMutation = {
  __typename?: 'Mutation';
  createEntityProperty: { __typename?: 'EntityProperty' } & {
    ' $fragmentRefs'?: { PropertiesFieldsFragment: PropertiesFieldsFragment };
  };
};

export type DeleteEntityMetricsMutationVariables = Exact<{
  metricIds: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;

export type DeleteEntityMetricsMutation = { __typename?: 'Mutation'; deleteEntityMetrics: Array<number> };

export type DeleteEntityPropertiesMutationVariables = Exact<{
  entityType: EntityPropertyType;
  propertyIds: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;

export type DeleteEntityPropertiesMutation = { __typename?: 'Mutation'; deleteEntityProperties: Array<number> };

export type GetEntityMetricsByParentIdQueryVariables = Exact<{
  entityId: Scalars['VecticeId']['input'];
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
}>;

export type GetEntityMetricsByParentIdQuery = {
  __typename?: 'Query';
  getResource: {
    __typename?: 'PaginatedEntityMetricResponse';
    total: number;
    items: Array<
      { __typename?: 'EntityMetric' } & { ' $fragmentRefs'?: { MetricsFieldsFragment: MetricsFieldsFragment } }
    >;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type GetEntityPropertiesByParentIdQueryVariables = Exact<{
  entityType: EntityPropertyType;
  entityId: Scalars['VecticeId']['input'];
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
}>;

export type GetEntityPropertiesByParentIdQuery = {
  __typename?: 'Query';
  getResource: {
    __typename?: 'PaginatedEntityPropertyResponse';
    total: number;
    items: Array<
      { __typename?: 'EntityProperty' } & { ' $fragmentRefs'?: { PropertiesFieldsFragment: PropertiesFieldsFragment } }
    >;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type UpdateEntityMetricMutationVariables = Exact<{
  updateMetric: UpdateEntityMetricInput;
}>;

export type UpdateEntityMetricMutation = {
  __typename?: 'Mutation';
  updateEntityMetric: { __typename?: 'EntityMetric' } & {
    ' $fragmentRefs'?: { MetricsFieldsFragment: MetricsFieldsFragment };
  };
};

export type UpdateEntityPropertyMutationVariables = Exact<{
  entityType: EntityPropertyType;
  updateProperty: UpdateEntityPropertyInput;
}>;

export type UpdateEntityPropertyMutation = {
  __typename?: 'Mutation';
  updateEntityProperty: { __typename?: 'EntityProperty' } & {
    ' $fragmentRefs'?: { PropertiesFieldsFragment: PropertiesFieldsFragment };
  };
};

export type AskUserTypeUpgradeMutationVariables = Exact<{ [key: string]: never }>;

export type AskUserTypeUpgradeMutation = { __typename?: 'Mutation'; askUserTypeUpgrade: boolean };

export type WorkspaceCardFragment = {
  __typename?: 'Workspace';
  vecticeId: string;
  name: string;
  type: WorkspaceType;
  description?: string | null;
  projectCount: number;
  updatedDate: any;
  userList: {
    __typename?: 'PaginatedUserList';
    total: number;
    items: Array<
      { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }
    >;
  };
  userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
} & { ' $fragmentName'?: 'WorkspaceCardFragment' };

export type WorkspaceGridFragment = {
  __typename?: 'PaginatedWorkspaceResponseOutput';
  items: Array<
    { __typename?: 'Workspace'; vecticeId: string } & {
      ' $fragmentRefs'?: { WorkspaceCardFragment: WorkspaceCardFragment };
    }
  >;
} & { ' $fragmentName'?: 'WorkspaceGridFragment' };

export type GetOrganizationWorkspaceListQueryVariables = Exact<{
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  order?: InputMaybe<ListOrderInput>;
  search: Scalars['String']['input'];
  type?: InputMaybe<Array<WorkspaceType> | WorkspaceType>;
}>;

export type GetOrganizationWorkspaceListQuery = {
  __typename?: 'Query';
  getOrganizationWorkspaceList: {
    __typename?: 'PaginatedWorkspaceResponseOutput';
    total: number;
    items: Array<{
      __typename?: 'Workspace';
      vecticeId: string;
      type: WorkspaceType;
      name: string;
      description?: string | null;
      userCount: number;
      projectCount: number;
      updatedDate: any;
      createdDate: any;
    }>;
  };
};

export type GetPublicConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetPublicConfigQuery = {
  __typename?: 'Query';
  getPublicConfig: {
    __typename?: 'PublicConfigOutput';
    sendEmail: boolean;
    isTrialEnabled: boolean;
    versions: Array<{ __typename?: 'VersionOutput'; artifactName: ArtifactVersion; version: string }>;
    featureFlags: Array<{ __typename?: 'FeatureFlagValue'; code: string; enabled: boolean }>;
  };
};

export type GetOrgConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrgConfigQuery = {
  __typename?: 'Query';
  getOrgConfig: {
    __typename?: 'OrgConfigOutput';
    deployment: ConfigDeployment;
    sendEmail: boolean;
    isTrialEnabled: boolean;
    versions: Array<{ __typename?: 'VersionOutput'; artifactName: ArtifactVersion; version: string }>;
    featureFlags: Array<{ __typename?: 'FeatureFlagValue'; code: string; enabled: boolean }>;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      level: OrganizationLevel;
      displayName?: string | null;
      emailDomains: Array<{ __typename?: 'EmailDomain'; domain: string }>;
      configuration: {
        __typename?: 'OrganizationConfigOutput';
        passwordAuthenticationEnabled: boolean;
        autoProvisioning: boolean;
        emailNotificationEnabled: boolean;
        dfStatisticsRowThreshold: number;
        dfStatisticsSampleRows: number;
        dfStatisticsMaxColumns: number;
        everyOneCanCreateWorkspace: boolean;
        everyOneCanInviteUser: boolean;
      };
      licenseOptions: {
        __typename?: 'OrganizationLicenseOptions';
        isSingleSignOnEnabled: boolean;
        isAskAIEnabled: boolean;
      };
    };
  };
};

export type FileHistoryEntryFragment = {
  __typename?: 'FileHistoryWithSessionOutput';
  sessionUuid: string;
  fileHistoryId: string;
  numberOfHistoriesInSession: number;
  createdDate: any;
  user: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
} & { ' $fragmentName'?: 'FileHistoryEntryFragment' };

export type GetFileHistoryListQueryVariables = Exact<{
  fileId: Scalars['Float']['input'];
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FileHistoryFilter>;
}>;

export type GetFileHistoryListQuery = {
  __typename?: 'Query';
  getFileHistoryList: {
    __typename?: 'PaginatedFileHistoryResponse';
    page: { __typename?: 'PageOutput'; hasNextPage?: boolean | null; afterCursor?: string | null };
    items: Array<
      { __typename?: 'FileHistoryWithSessionOutput' } & {
        ' $fragmentRefs'?: { FileHistoryEntryFragment: FileHistoryEntryFragment };
      }
    >;
  };
};

export type GetFileHistoryQueryVariables = Exact<{
  fileHistoryId: Scalars['String']['input'];
}>;

export type GetFileHistoryQuery = {
  __typename?: 'Query';
  getFileHistory: { __typename?: 'FileHistory'; id: string; createdDate: any; content: string };
};

export type RestoreHistoryMutationVariables = Exact<{
  fileHistoryId: Scalars['String']['input'];
}>;

export type RestoreHistoryMutation = { __typename?: 'Mutation'; restoreHistory: boolean };

export type GetCdtReportFileQueryVariables = Exact<{
  reportId: Scalars['Float']['input'];
}>;

export type GetCdtReportFileQuery = {
  __typename?: 'Query';
  CDTReport: {
    __typename?: 'CDTReport';
    id: number;
    name: string;
    file: {
      __typename?: 'DocumentationFile';
      id: number;
      content: string;
      headerContent: string;
      footerContent: string;
      isFileLocked: boolean;
      lockoutDuration?: number | null;
      fingerprint?: string | null;
      lockedUser?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
    };
  };
};

export type GetFindingFileQueryVariables = Exact<{
  findingId: Scalars['Float']['input'];
}>;

export type GetFindingFileQuery = {
  __typename?: 'Query';
  Finding: {
    __typename?: 'Finding';
    id: number;
    name: string;
    file: {
      __typename?: 'DocumentationFile';
      id: number;
      content: string;
      headerContent: string;
      footerContent: string;
      isFileLocked: boolean;
      lockoutDuration?: number | null;
      fingerprint?: string | null;
      lockedUser?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
    };
  };
};

export type FileUpdatedSubscriptionVariables = Exact<{
  fileId: Scalars['Float']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
}>;

export type FileUpdatedSubscription = {
  __typename?: 'Subscription';
  fileUpdated: {
    __typename?: 'FileSubscriptionOutput';
    id?: number | null;
    file?: {
      __typename?: 'DocumentationFile';
      id: number;
      content: string;
      isFileLocked: boolean;
      lockoutDuration?: number | null;
      fingerprint?: string | null;
      lockedUser?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
    } | null;
  };
};

export type GetDocumentationFileQueryVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
}>;

export type GetDocumentationFileQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    vecticeId: string;
    name: string;
    parent: { __typename?: 'Project'; id: number; name: string };
    file: {
      __typename?: 'DocumentationFile';
      id: number;
      content: string;
      isFileLocked: boolean;
      lockoutDuration?: number | null;
      fingerprint?: string | null;
      lockedUser?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
    };
  };
};

export type UnlockFileMutationVariables = Exact<{
  fileId: Scalars['Float']['input'];
}>;

export type UnlockFileMutation = { __typename?: 'Mutation'; unlockFile: boolean };

export type UpdateFileContentMutationVariables = Exact<{
  fileId: Scalars['Float']['input'];
  data: FileContentUpdateInput;
}>;

export type UpdateFileContentMutation = {
  __typename?: 'Mutation';
  updateFileContent: {
    __typename?: 'DocumentationFile';
    id: number;
    updatedDate: any;
    isFileLocked: boolean;
    fingerprint?: string | null;
    lastUpdatedBy?:
      | ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
      | null;
  };
};

export type GetAttachmentsToInsertQueryVariables = Exact<{
  entityId: Scalars['VecticeId']['input'];
  type: EntityMetadataTypeEnum;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAttachmentsToInsertQuery = {
  __typename?: 'Query';
  attachments: Array<
    {
      __typename?: 'AttachmentOutput';
      id: number;
      name: string;
      attachmentType: AttachmentTypeEnum;
      entityId: number;
      mimeType?: string | null;
      type: EntityMetadataTypeEnum;
      content?: any | null;
    } & { ' $fragmentRefs'?: { AttachmentFragmentFragment: AttachmentFragmentFragment } }
  >;
};

export type GetDatasetVersionsToInsertQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDatasetVersionsToInsertQuery = {
  __typename?: 'Query';
  getDatasetVersionsList: {
    __typename?: 'PaginatedDataSetVersionResponse';
    total: number;
    items: Array<
      {
        __typename?: 'DataSetVersion';
        vecticeId: string;
        name?: string | null;
        versionNumber: number;
        attachments: { __typename?: 'PaginatedEntityFileList'; total: number };
      } & { ' $fragmentRefs'?: { DatasetVersionRowFragment: DatasetVersionRowFragment } }
    >;
  };
};

export type GetDatasetsToInsertQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDatasetsToInsertQuery = {
  __typename?: 'Query';
  getDatasetList: {
    __typename?: 'PaginatedDataSetResponse';
    total: number;
    items: Array<
      { __typename?: 'DataSet'; vecticeId: string; versionCount: number; name: string } & {
        ' $fragmentRefs'?: { DatasetRowFragment: DatasetRowFragment };
      }
    >;
  };
};

export type GetIterationArtifactsCountQueryVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
}>;

export type GetIterationArtifactsCountQuery = {
  __typename?: 'Query';
  getIterationById: {
    __typename?: 'Iteration';
    id: number;
    paginatedArtifacts: { __typename?: 'PaginatedIterationStepArtifactResponse'; total: number };
  };
};

export type GetIterationArtifactsToInsertQueryVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
}>;

export type GetIterationArtifactsToInsertQuery = {
  __typename?: 'Query';
  getIterationById: {
    __typename?: 'Iteration';
    id: number;
    paginatedArtifacts: {
      __typename?: 'PaginatedIterationStepArtifactResponse';
      total: number;
      items: Array<{
        __typename?: 'IterationStepArtifact';
        id: number;
        text?: string | null;
        type: IterationStepArtifactType;
        datasetVersion?:
          | ({
              __typename?: 'DataSetVersion';
              vecticeId: string;
              versionNumber: number;
              dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string };
              attachments: { __typename?: 'PaginatedEntityFileList'; total: number };
            } & { ' $fragmentRefs'?: { DatasetVersionRowFragment: DatasetVersionRowFragment } })
          | null;
        modelVersion?:
          | ({
              __typename?: 'ModelVersion';
              vecticeId: string;
              versionNumber: number;
              model: { __typename?: 'Model'; vecticeId: string; name: string };
              attachments: { __typename?: 'PaginatedEntityFileList'; total: number };
            } & { ' $fragmentRefs'?: { ModelVersionRowFragment: ModelVersionRowFragment } })
          | null;
        entityFile?:
          | ({
              __typename?: 'EntityFile';
              id: number;
              type: EntityFilesTypeEnum;
              mimeType?: string | null;
              entityId: number;
            } & { ' $fragmentRefs'?: { EntityFileFragmentFragment: EntityFileFragmentFragment } })
          | null;
        entityMetadata?: { __typename?: 'EntityMetadata'; id: number; name: string; content: any } | null;
      }>;
    };
  };
};

export type GetIterationStepsToInsertQueryVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
}>;

export type GetIterationStepsToInsertQuery = {
  __typename?: 'Query';
  sections: {
    __typename?: 'PaginatedIterationStepResponse';
    total: number;
    items: Array<{
      __typename?: 'IterationStep';
      id: number;
      name: string;
      paginatedArtifacts: { __typename?: 'PaginatedIterationStepArtifactResponse'; total: number };
    }>;
  };
};

export type GetIterationStepArtifactsToInsertQueryVariables = Exact<{
  stepId: Scalars['Float']['input'];
}>;

export type GetIterationStepArtifactsToInsertQuery = {
  __typename?: 'Query';
  getStepById: {
    __typename?: 'IterationStep';
    id: number;
    paginatedArtifacts: {
      __typename?: 'PaginatedIterationStepArtifactResponse';
      total: number;
      items: Array<{
        __typename?: 'IterationStepArtifact';
        id: number;
        text?: string | null;
        type: IterationStepArtifactType;
        datasetVersion?:
          | ({
              __typename?: 'DataSetVersion';
              vecticeId: string;
              versionNumber: number;
              dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string; type: DatasetType };
              attachments: { __typename?: 'PaginatedEntityFileList'; total: number };
            } & { ' $fragmentRefs'?: { DatasetVersionRowFragment: DatasetVersionRowFragment } })
          | null;
        modelVersion?:
          | ({
              __typename?: 'ModelVersion';
              vecticeId: string;
              versionNumber: number;
              model: { __typename?: 'Model'; vecticeId: string; type: ModelType; name: string };
              attachments: { __typename?: 'PaginatedEntityFileList'; total: number };
            } & { ' $fragmentRefs'?: { ModelVersionRowFragment: ModelVersionRowFragment } })
          | null;
        entityFile?:
          | ({
              __typename?: 'EntityFile';
              id: number;
              type: EntityFilesTypeEnum;
              fileName?: string | null;
              mimeType?: string | null;
              entityId: number;
            } & { ' $fragmentRefs'?: { EntityFileFragmentFragment: EntityFileFragmentFragment } })
          | null;
        entityMetadata?: { __typename?: 'EntityMetadata'; id: number; name: string; content: any } | null;
      }>;
    };
  };
};

export type GetIterationsToInsertQueryVariables = Exact<{
  filters: IterationFiltersInput;
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetIterationsToInsertQuery = {
  __typename?: 'Query';
  getIterationList: {
    __typename?: 'PaginatedIterationResponse';
    total: number;
    items: Array<
      {
        __typename?: 'Iteration';
        vecticeId: string;
        index: number;
        name: string;
        sections: { __typename?: 'PaginatedIterationStepResponse'; total: number };
        paginatedArtifacts: { __typename?: 'PaginatedIterationStepArtifactResponse'; total: number };
      } & { ' $fragmentRefs'?: { IterationRowFragment: IterationRowFragment } }
    >;
  };
};

export type GetModelVersionsToInsertQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetModelVersionsToInsertQuery = {
  __typename?: 'Query';
  getModelVersionsList: {
    __typename?: 'PaginatedModelVersionResponse';
    total: number;
    items: Array<
      {
        __typename?: 'ModelVersion';
        vecticeId: string;
        name?: string | null;
        versionNumber: number;
        attachments: { __typename?: 'PaginatedEntityFileList'; total: number };
      } & { ' $fragmentRefs'?: { ModelVersionRowFragment: ModelVersionRowFragment } }
    >;
  };
};

export type GetModelsToInsertQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetModelsToInsertQuery = {
  __typename?: 'Query';
  getModelList: {
    __typename?: 'PaginatedModelResponse';
    total: number;
    items: Array<
      { __typename?: 'Model'; vecticeId: string; versionCount: number; name: string } & {
        ' $fragmentRefs'?: { ModelRowFragment: ModelRowFragment };
      }
    >;
  };
};

export type GetPhasesToInsertQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPhasesToInsertQuery = {
  __typename?: 'Query';
  getPhaseList: {
    __typename?: 'PaginatedPhaseResponseOutput';
    total: number;
    items: Array<
      {
        __typename?: 'Phase';
        vecticeId: string;
        name: string;
        iterationsCount: { __typename?: 'IterationsCounts'; total: number };
      } & { ' $fragmentRefs'?: { PhaseRowFragment: PhaseRowFragment } }
    >;
  };
};

export type GenerateDocumentationMutationVariables = Exact<{
  data: GenerateDocumentationInput;
}>;

export type GenerateDocumentationMutation = { __typename?: 'Mutation'; generateDocumentation: string };

export type SearchAllAskAiAssetsQueryVariables = Exact<{
  filters: AskAiAssetListFiltersInput;
  assetType: AskAiSearchAssetType;
  page?: InputMaybe<PageIndexInput>;
}>;

export type SearchAllAskAiAssetsQuery = {
  __typename?: 'Query';
  searchAllAskAIAssets: {
    __typename?: 'AssetsAskAISearchResponse';
    all: {
      __typename?: 'PaginatedAskAIAssetResponse';
      total: number;
      items: Array<{
        __typename?: 'AskAIAssetResponse';
        name: string;
        vecticeId: string;
        versionNumber?: number | null;
        isStarred: boolean;
        type: AskAiAssetType;
      }>;
    };
    phase: {
      __typename?: 'PaginatedPhaseResponseOutput';
      total: number;
      items: Array<{ __typename?: 'Phase'; name: string; vecticeId: string; version: number }>;
    };
    datasetVersion: {
      __typename?: 'PaginatedDataSetVersionResponse';
      total: number;
      items: Array<{
        __typename?: 'DataSetVersion';
        vecticeId: string;
        isStarred: boolean;
        versionNumber: number;
        dataSet: { __typename?: 'DataSet'; name: string; type: DatasetType };
      }>;
    };
    modelVersion: {
      __typename?: 'PaginatedModelVersionResponse';
      total: number;
      items: Array<{
        __typename?: 'ModelVersion';
        vecticeId: string;
        isStarred: boolean;
        versionNumber: number;
        model: { __typename?: 'Model'; name: string; type: ModelType };
      }>;
    };
  };
};

export type GetIterationNameQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetIterationNameQuery = {
  __typename?: 'Query';
  getIterationById: {
    __typename?: 'Iteration';
    name: string;
    phase: { __typename?: 'Phase'; name: string; vecticeId: string };
  };
};

export type GetBreadcrumbModelVersionQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetBreadcrumbModelVersionQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    name?: string | null;
    vecticeId: string;
    versionNumber: number;
    model: { __typename?: 'Model'; name: string; vecticeId: string };
  };
};

export type GetBreadcrumbDatasetVersionQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetBreadcrumbDatasetVersionQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    name?: string | null;
    vecticeId: string;
    versionNumber: number;
    dataSet: { __typename?: 'DataSet'; name: string; vecticeId: string };
  };
};

export type GetIterationIndexQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetIterationIndexQuery = {
  __typename?: 'Query';
  getIterationById: { __typename?: 'Iteration'; index: number; name: string };
};

export type CheckLlmPromptNameAvailabilityQueryVariables = Exact<{
  type: LlmPromptTypeEnum;
  resourceId?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
}>;

export type CheckLlmPromptNameAvailabilityQuery = { __typename?: 'Query'; checkLLMPromptNameAvailability: boolean };

export type CreateLlmPromptMutationVariables = Exact<{
  data: LlmPromptCreateInput;
}>;

export type CreateLlmPromptMutation = {
  __typename?: 'Mutation';
  createLLMPrompt: {
    __typename?: 'LLMPrompt';
    id: number;
    type: LlmPromptTypeEnum;
    name: string;
    content: string;
    updatedDate: any;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type GenerateAssetDocumentationMutationVariables = Exact<{
  data: GenerateLlmDocumentationInput;
}>;

export type GenerateAssetDocumentationMutation = {
  __typename?: 'Mutation';
  generateAssetDocumentation: { __typename?: 'GenerateMultipleOutputs'; input: string; outputs: Array<string> };
};

export type LlmPromptsQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  filters: LlmPromptFiltersInput;
  page?: InputMaybe<PageIndexInput>;
}>;

export type LlmPromptsQuery = {
  __typename?: 'Query';
  llmPrompts: {
    __typename?: 'PaginatedLLMPromptResponseOutput';
    total: number;
    items: Array<{
      __typename?: 'LLMPrompt';
      id: number;
      name: string;
      content: string;
      updatedDate: any;
      createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type RemoveLlmPromptMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type RemoveLlmPromptMutation = { __typename?: 'Mutation'; removeLLMPrompt: string };

export type UpdateLlmPromptMutationVariables = Exact<{
  data: LlmPromptUpdateInput;
  id: Scalars['Float']['input'];
}>;

export type UpdateLlmPromptMutation = {
  __typename?: 'Mutation';
  updateLLMPrompt: { __typename?: 'LLMPrompt'; id: number; type: LlmPromptTypeEnum; name: string; content: string };
};

export type DatasetImageOriginQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type DatasetImageOriginQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    versionNumber: number;
    dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string };
  };
};

export type IterationImageOriginQueryVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
}>;

export type IterationImageOriginQuery = {
  __typename?: 'Query';
  getIterationById: { __typename?: 'Iteration'; vecticeId: string; name: string };
};

export type ModelImageOriginQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type ModelImageOriginQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    versionNumber: number;
    model: { __typename?: 'Model'; vecticeId: string; name: string };
  };
};

export type CreateUserMentionNotificationMutationVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  userId: Scalars['Float']['input'];
  content: UserMentionNotificationContentInput;
}>;

export type CreateUserMentionNotificationMutation = {
  __typename?: 'Mutation';
  createUserMentionNotification: { __typename?: 'UserNotification'; id: string };
};

export type GetMentionedUserQueryVariables = Exact<{
  userId: Scalars['Float']['input'];
}>;

export type GetMentionedUserQuery = {
  __typename?: 'Query';
  getOrganizationUser: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
};

export type GenerateTextMutationVariables = Exact<{
  data: GenerateDocumentationInput;
}>;

export type GenerateTextMutation = { __typename?: 'Mutation'; generateDocumentation: string };

export type UserFieldsFragment = {
  __typename?: 'User';
  id: string;
  avatarId?: number | null;
  name: string;
  email: string;
  color: string;
} & { ' $fragmentName'?: 'UserFieldsFragment' };

export type UserPreferencesFragment = {
  __typename?: 'UserPreferencesOutput';
  onboardingStatus: Array<OnboardingStatus>;
} & { ' $fragmentName'?: 'UserPreferencesFragment' };

export type ModelVersionInformationFieldsFragment = {
  __typename?: 'ModelVersion';
  vecticeId: string;
  versionNumber: number;
  model: { __typename?: 'Model'; name: string };
} & { ' $fragmentName'?: 'ModelVersionInformationFieldsFragment' };

export type MetricsFieldsFragment = { __typename?: 'EntityMetric'; id: number; key: string; value: number } & {
  ' $fragmentName'?: 'MetricsFieldsFragment';
};

export type PropertiesFieldsFragment = { __typename?: 'EntityProperty'; id: number; key: string; value: string } & {
  ' $fragmentName'?: 'PropertiesFieldsFragment';
};

export type PageAfterCursorFieldsFragment = {
  __typename?: 'PageOutput';
  afterCursor?: string | null;
  hasNextPage?: boolean | null;
} & { ' $fragmentName'?: 'PageAfterCursorFieldsFragment' };

export type PageIndexFieldsFragment = { __typename?: 'PageOutput'; index?: number | null; size: number } & {
  ' $fragmentName'?: 'PageIndexFieldsFragment';
};

export type EntityFileFragmentFragment = {
  __typename?: 'EntityFile';
  id: number;
  fileName?: string | null;
  mimeType?: string | null;
  hasPreview: boolean;
} & { ' $fragmentName'?: 'EntityFileFragmentFragment' };

export type AttachmentFragmentFragment = {
  __typename?: 'AttachmentOutput';
  id: number;
  attachmentType: AttachmentTypeEnum;
  name: string;
  entityId: number;
  type: EntityMetadataTypeEnum;
  content?: any | null;
  mimeType?: string | null;
  hasPreview?: boolean | null;
} & { ' $fragmentName'?: 'AttachmentFragmentFragment' };

export type CreateProjectMutationVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  data: ProjectCreateInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: { __typename?: 'Project'; vecticeId: string; name: string; description?: string | null };
};

export type UpdateLineageMutationVariables = Exact<{
  versionId: Scalars['VecticeId']['input'];
  data: LineageUpdateInput;
  type: LineageType;
}>;

export type UpdateLineageMutation = { __typename?: 'Mutation'; updateLineage: boolean };

export type UpdateUserMutationVariables = Exact<{
  data: UserUpdateInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'User'; about?: string | null } & {
    ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
  };
};

export type UpdateUserPreferencesMutationVariables = Exact<{
  data: UserPreferencesInput;
}>;

export type UpdateUserPreferencesMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    preferences: { __typename?: 'UserPreferencesOutput'; onboardingStatus: Array<OnboardingStatus> } & {
      ' $fragmentRefs'?: { UserPreferencesFragment: UserPreferencesFragment };
    };
  };
};

export type AddUsersToWorkspaceMutationVariables = Exact<{
  data: WorkspaceUserInput;
}>;

export type AddUsersToWorkspaceMutation = {
  __typename?: 'Mutation';
  addUsersToWorkspace: Array<{ __typename?: 'WorkspaceUser'; userId: number }>;
};

export type AddMyselfToWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type AddMyselfToWorkspaceMutation = {
  __typename?: 'Mutation';
  addMyselfToWorkspace: { __typename?: 'WorkspaceUser'; userId: number };
};

export type GenerateApiKeyMutationVariables = Exact<{
  apiKey: ApiKeyCreateInput;
}>;

export type GenerateApiKeyMutation = {
  __typename?: 'Mutation';
  generateApiKey: { __typename?: 'ApiKeyCreateOutput'; key: string };
};

export type CheckIfApiKeyNameExistsQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type CheckIfApiKeyNameExistsQuery = { __typename?: 'Query'; checkIfApiKeyNameExists: boolean };

export type GetExportedArchivesListQueryVariables = Exact<{ [key: string]: never }>;

export type GetExportedArchivesListQuery = {
  __typename?: 'Query';
  getExportedArchivesList: Array<{ __typename?: 'Archive'; name: string; path: string; itemCreatedDate?: any | null }>;
};

export type AuthenticateWithPasswordQueryVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type AuthenticateWithPasswordQuery = {
  __typename?: 'Query';
  authenticateWithPassword: { __typename?: 'RealmInformation'; realm: string; clientId: string };
};

export type CancelIterationMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type CancelIterationMutation = {
  __typename?: 'Mutation';
  updateIteration: { __typename?: 'Iteration'; vecticeId: string; status: IterationStatus };
};

export type CancelReviewMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type CancelReviewMutation = {
  __typename?: 'Mutation';
  cancelReview: { __typename?: 'Review'; vecticeId: string; status: ReviewStatus };
};

export type CdtTemplatesQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  filters?: InputMaybe<CdtTemplateFilterInput>;
}>;

export type CdtTemplatesQuery = {
  __typename?: 'Query';
  CDTTemplates: {
    __typename?: 'PaginatedCDTTemplateResponse';
    total: number;
    items: Array<{
      __typename?: 'CDTTemplate';
      id: number;
      name: string;
      description?: string | null;
      isMacro: boolean;
      isRichText: boolean;
      enabled: boolean;
      content: string;
      headerContent: string;
      footerContent: string;
      groupName?: string | null;
      updatedDate: any;
      assets: Array<{ __typename?: 'CDTTemplateAsset'; id: number; name: string; type: CdtTemplateAssetType }>;
      createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
    page: { __typename?: 'PageOutput'; afterCursor?: string | null };
  };
};

export type CdtTemplatesVariablesQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  filters?: InputMaybe<CdtTemplateFilterInput>;
}>;

export type CdtTemplatesVariablesQuery = {
  __typename?: 'Query';
  CDTTemplates: {
    __typename?: 'PaginatedCDTTemplateResponse';
    total: number;
    items: Array<{
      __typename?: 'CDTTemplate';
      id: number;
      assets: Array<{ __typename?: 'CDTTemplateAsset'; name: string; type: CdtTemplateAssetType }>;
    }>;
  };
};

export type CdtTemplateContentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Float']['input']>;
}>;

export type CdtTemplateContentQuery = {
  __typename?: 'Query';
  CDTTemplate: {
    __typename?: 'CDTTemplate';
    id: number;
    name: string;
    enabled: boolean;
    description?: string | null;
    groupName?: string | null;
    content: string;
    isMacro: boolean;
    headerContent: string;
    footerContent: string;
    assets: Array<{ __typename?: 'CDTTemplateAsset'; id: number; name: string; type: CdtTemplateAssetType }>;
  };
};

export type CreateCdtReportMutationVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  data: CdtReportCreateInput;
}>;

export type CreateCdtReportMutation = {
  __typename?: 'Mutation';
  createCDTReport: { __typename?: 'CDTReport'; id: number; name: string };
};

export type GenerateCdtMarkdownMutationVariables = Exact<{
  data: CdtGenerateMarkdownInput;
}>;

export type GenerateCdtMarkdownMutation = { __typename?: 'Mutation'; generateCDTMarkdown: string };

export type PreviewMacroMutationVariables = Exact<{
  assets: Array<CdtReportAssetInput> | CdtReportAssetInput;
  content: Scalars['String']['input'];
}>;

export type PreviewMacroMutation = { __typename?: 'Mutation'; previewMacro: string };

export type RemoveCdtTemplateMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type RemoveCdtTemplateMutation = { __typename?: 'Mutation'; removeCDTTemplate: number };

export type CreateCdtTemplateMutationVariables = Exact<{
  data: CdtTemplateCreateInput;
}>;

export type CreateCdtTemplateMutation = {
  __typename?: 'Mutation';
  createCDTTemplate: { __typename?: 'CDTTemplate'; id: number; name: string; enabled: boolean };
};

export type UpdateCdtTemplateMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  data: CdtTemplateUpdateInput;
}>;

export type UpdateCdtTemplateMutation = {
  __typename?: 'Mutation';
  updateCDTTemplate: {
    __typename?: 'CDTTemplate';
    id: number;
    name: string;
    enabled: boolean;
    description?: string | null;
  };
};

export type GlobalTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GlobalTemplateQuery = {
  __typename?: 'Query';
  GlobalTemplate: {
    __typename?: 'GlobalTemplate';
    id: string;
    name: string;
    content: string;
    headerContent?: string | null;
    footerContent?: string | null;
  };
};

export type CheckIterationStepNameAvailabilityQueryVariables = Exact<{
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
}>;

export type CheckIterationStepNameAvailabilityQuery = {
  __typename?: 'Query';
  checkIterationStepNameAvailability: boolean;
};

export type CheckPhaseNameAvailabilityQueryVariables = Exact<{
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['VecticeId']['input']>;
}>;

export type CheckPhaseNameAvailabilityQuery = { __typename?: 'Query'; checkPhaseNameAvailability: boolean };

export type CheckStepDefinitionNameAvailabilityQueryVariables = Exact<{
  name: Scalars['String']['input'];
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
}>;

export type CheckStepDefinitionNameAvailabilityQuery = {
  __typename?: 'Query';
  checkStepDefinitionNameAvailability: boolean;
};

export type CloseReviewMutationVariables = Exact<{
  review: ReviewUpdateInput;
  id: Scalars['VecticeId']['input'];
}>;

export type CloseReviewMutation = {
  __typename?: 'Mutation';
  closeReview: { __typename?: 'Review'; vecticeId: string; status: ReviewStatus } & {
    ' $fragmentRefs'?: { ReviewFragment: ReviewFragment };
  };
};

export type GetCodeArtifactByJobRunIdQueryVariables = Exact<{
  lineageId: Scalars['Float']['input'];
}>;

export type GetCodeArtifactByJobRunIdQuery = {
  __typename?: 'Query';
  getJobArtifactByJobRunId: {
    __typename?: 'PaginatedLineageArtifactResponse';
    items: Array<{
      __typename?: 'LineageArtifact';
      id: number;
      version: number;
      lineageArtifactType: LineageArtifactType;
      lineageId: number;
      artifactType: ArtifactType;
      codeVersion?: {
        __typename?: 'CodeVersion';
        id: number;
        code?: { __typename?: 'Code'; name: string; description?: string | null } | null;
        gitVersion?: {
          __typename?: 'GitVersion';
          branchName?: string | null;
          commitHash?: string | null;
          repositoryName: string;
          uri?: string | null;
          entrypoint?: string | null;
          isDirty: boolean;
          additionalInformation?: string | null;
        } | null;
        databricksVersion?: {
          __typename?: 'DatabricksVersion';
          urlNotebook: string;
          relativePath?: string | null;
          timestamp?: any | null;
          additionalInformation?: string | null;
        } | null;
        otherCodeVersion?: {
          __typename?: 'OtherCodeVersion';
          id: number;
          url: string;
          firstAdditionalInformation?: string | null;
          secondAdditionalInformation?: string | null;
          thirdAdditionalInformation?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type CompleteIterationMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type CompleteIterationMutation = {
  __typename?: 'Mutation';
  updateIteration: { __typename?: 'Iteration'; vecticeId: string; status: IterationStatus };
};

export type CreateFindingMutationVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  data: FindingCreateInput;
}>;

export type CreateFindingMutation = {
  __typename?: 'Mutation';
  createFinding: { __typename?: 'Finding'; id: number; name: string };
};

export type CreateIterationStepMutationVariables = Exact<{
  parentId: Scalars['VecticeId']['input'];
  createModel: BaseStepCreateInput;
}>;

export type CreateIterationStepMutation = {
  __typename?: 'Mutation';
  createIterationStep: {
    __typename?: 'IterationStep';
    id: number;
    name: string;
    description?: string | null;
    index: number;
  };
};

export type CreateIterationStepArtifactMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
  data: IterationStepArtifactInput;
}>;

export type CreateIterationStepArtifactMutation = {
  __typename?: 'Mutation';
  createIterationStepArtifact: {
    __typename?: 'IterationStepArtifact';
    id: number;
    index: number;
    type: IterationStepArtifactType;
    text?: string | null;
    iterationId: number;
    stepId?: number | null;
  };
};

export type CreateReviewCommentMutationVariables = Exact<{
  reviewId: Scalars['VecticeId']['input'];
  content: Scalars['String']['input'];
}>;

export type CreateReviewCommentMutation = {
  __typename?: 'Mutation';
  createReviewComment: {
    __typename?: 'ReviewComment';
    id: number;
    content: string;
    createdDate: any;
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  };
};

export type CreateStepDefinitionMutationVariables = Exact<{
  parentId: Scalars['VecticeId']['input'];
  createModel: BaseStepCreateInput;
}>;

export type CreateStepDefinitionMutation = {
  __typename?: 'Mutation';
  createStepDefinition: {
    __typename?: 'StepDefinition';
    id: number;
    name: string;
    description?: string | null;
    index: number;
  };
};

export type DeleteEntityFileMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type DeleteEntityFileMutation = { __typename?: 'Mutation'; deleteEntityFile: number };

export type DeleteEntityMetadataMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type DeleteEntityMetadataMutation = { __typename?: 'Mutation'; deleteEntityMetadata: number };

export type ExtractEntityFileMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type ExtractEntityFileMutation = {
  __typename?: 'Mutation';
  extractEntityFile: { __typename?: 'ExtractFileResult'; images: number; tables: number };
};

export type GetAssetAttachmentsQueryVariables = Exact<{
  entityId: Scalars['VecticeId']['input'];
  type: EntityMetadataTypeEnum;
}>;

export type GetAssetAttachmentsQuery = {
  __typename?: 'Query';
  attachments: Array<
    {
      __typename?: 'AttachmentOutput';
      id: number;
      name: string;
      hasPreview?: boolean | null;
      mimeType?: string | null;
      attachmentType: AttachmentTypeEnum;
    } & { ' $fragmentRefs'?: { AttachmentFragmentFragment: AttachmentFragmentFragment } }
  >;
};

export type GetDatasetVersionQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    versionNumber: number;
    dataSet: {
      __typename?: 'DataSet';
      vecticeId: string;
      name: string;
      project: { __typename?: 'Project'; vecticeId: string };
    };
  };
};

export type GetEntityFileListQueryVariables = Exact<{
  entityId: Scalars['VecticeId']['input'];
  type: EntityFilesTypeEnum;
}>;

export type GetEntityFileListQuery = {
  __typename?: 'Query';
  getEntityFileList: {
    __typename?: 'PaginatedEntityFileList';
    total: number;
    items: Array<
      {
        __typename?: 'EntityFile';
        id: number;
        fileName?: string | null;
        hasPreview: boolean;
        mimeType?: string | null;
      } & { ' $fragmentRefs'?: { EntityFileFragmentFragment: EntityFileFragmentFragment } }
    >;
  };
};

export type GetIterationByIdQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetIterationByIdQuery = {
  __typename?: 'Query';
  getIterationById: {
    __typename?: 'Iteration';
    vecticeId: string;
    index: number;
    name: string;
    description?: string | null;
    status: IterationStatus;
    starred: boolean;
    phaseId: number;
    phase: {
      __typename?: 'Phase';
      vecticeId: string;
      name: string;
      parent: { __typename?: 'Project'; vecticeId: string };
    };
    paginatedArtifacts: {
      __typename?: 'PaginatedIterationStepArtifactResponse';
      total: number;
      items: Array<
        {
          __typename?: 'IterationStepArtifact';
          id: number;
          type: IterationStepArtifactType;
          index: number;
          stepId?: number | null;
        } & { ' $fragmentRefs'?: { IterationStepArtifactFragment: IterationStepArtifactFragment } }
      >;
    };
  } & { ' $fragmentRefs'?: { IterationSidebarFragment: IterationSidebarFragment } };
};

export type SubscribeToIterationCountSubscriptionVariables = Exact<{
  filters: IterationFiltersInput;
}>;

export type SubscribeToIterationCountSubscription = {
  __typename?: 'Subscription';
  subscribeToIterationList: { __typename?: 'PaginatedIterationSubResponse'; total: number };
};

export type GetIterationListQueryVariables = Exact<{
  filters: IterationFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
}>;

export type GetIterationListQuery = {
  __typename?: 'Query';
  getIterationList: {
    __typename?: 'PaginatedIterationResponse';
    total: number;
    items: Array<{
      __typename?: 'Iteration';
      vecticeId: string;
      index: number;
      name: string;
      starred: boolean;
      status: IterationStatus;
      createdDate: any;
      updatedDate: any;
      phaseId: number;
      owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetIterationPickerListQueryVariables = Exact<{
  filters: IterationFiltersInput;
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetIterationPickerListQuery = {
  __typename?: 'Query';
  getIterationList: {
    __typename?: 'PaginatedIterationResponse';
    items: Array<{ __typename?: 'Iteration'; vecticeId: string; name: string; index: number }>;
  };
};

export type GetIterationStepListQueryVariables = Exact<{
  parentId: Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetIterationStepListQuery = {
  __typename?: 'Query';
  sections: {
    __typename?: 'PaginatedIterationStepResponse';
    items: Array<
      {
        __typename?: 'IterationStep';
        id: number;
        index: number;
        name: string;
        paginatedArtifacts: {
          __typename?: 'PaginatedIterationStepArtifactResponse';
          total: number;
          items: Array<
            {
              __typename?: 'IterationStepArtifact';
              id: number;
              index: number;
              stepId?: number | null;
              type: IterationStepArtifactType;
            } & { ' $fragmentRefs'?: { IterationStepArtifactFragment: IterationStepArtifactFragment } }
          >;
        };
      } & { ' $fragmentRefs'?: { IterationStepFragment: IterationStepFragment } }
    >;
  };
};

export type GetModelVersionNameQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionNameQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    versionNumber: number;
    model: {
      __typename?: 'Model';
      vecticeId: string;
      name: string;
      project: { __typename?: 'Project'; vecticeId: string };
    };
  };
};

export type GetPhaseByIdQueryVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type GetPhaseByIdQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    vecticeId: string;
    name: string;
    ownerId?: number | null;
    enforceRequirementsAsSections: boolean;
    activeReview?: { __typename?: 'Review'; status: ReviewStatus } | null;
    parent: {
      __typename?: 'Project';
      vecticeId: string;
      workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType } | null;
    };
  } & { ' $fragmentRefs'?: { PhaseSidebarFragment: PhaseSidebarFragment } };
};

export type GetPhaseListQueryVariables = Exact<{
  filters: PhaseFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
}>;

export type GetPhaseListQuery = {
  __typename?: 'Query';
  getPhaseList: {
    __typename?: 'PaginatedPhaseResponseOutput';
    items: Array<{
      __typename?: 'Phase';
      vecticeId: string;
      index: number;
      name: string;
      description?: string | null;
      status: DocumentationPageStatus;
      iterationsCount: { __typename?: 'IterationsCounts'; total: number };
      reviewsCount: { __typename?: 'ReviewsCounts'; totalReviews: number };
      parent: { __typename?: 'Project'; vecticeId: string };
    }>;
  };
};

export type SubscribeToReviewSubscriptionVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type SubscribeToReviewSubscription = {
  __typename?: 'Subscription';
  subscribeToReview: { __typename?: 'Review'; vecticeId: string } & {
    ' $fragmentRefs'?: { ReviewFragment: ReviewFragment };
  };
};

export type GetReviewCommentListQueryVariables = Exact<{
  filters: ReviewCommentFiltersInput;
  page?: InputMaybe<PageInput>;
}>;

export type GetReviewCommentListQuery = {
  __typename?: 'Query';
  getReviewCommentList: {
    __typename?: 'PaginatedReviewCommentResponse';
    items: Array<{
      __typename?: 'ReviewComment';
      id: number;
      createdDate: any;
      content: string;
      createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type SubscribeToReviewCommentListSubscriptionVariables = Exact<{
  filters: ReviewCommentFiltersInput;
}>;

export type SubscribeToReviewCommentListSubscription = {
  __typename?: 'Subscription';
  subscribeToReviewCommentList: {
    __typename?: 'PaginatedReviewCommentSubResponse';
    items: Array<{
      __typename?: 'ReviewComment';
      id: number;
      createdDate: any;
      content: string;
      createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    }>;
  };
};

export type GetReviewListQueryVariables = Exact<{
  filters: ReviewFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
}>;

export type GetReviewListQuery = {
  __typename?: 'Query';
  getReviewList: {
    __typename?: 'PaginatedReviewResponse';
    total: number;
    items: Array<
      { __typename?: 'Review'; vecticeId: string; status: ReviewStatus } & {
        ' $fragmentRefs'?: { ReviewFragment: ReviewFragment };
      }
    >;
  };
};

export type GetStepDefinitionListQueryVariables = Exact<{
  filters: BaseDocumentationListFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
}>;

export type GetStepDefinitionListQuery = {
  __typename?: 'Query';
  getStepDefinitionList: {
    __typename?: 'PaginatedStepDefinitionResponseOutput';
    total: number;
    items: Array<{
      __typename?: 'StepDefinition';
      id: number;
      index: number;
      name: string;
      slug: string;
      description?: string | null;
      parent: { __typename?: 'Phase'; vecticeId: string };
    }>;
  };
};

export type GetOrganizationUsersOfOrganizationQueryVariables = Exact<{
  filters?: InputMaybe<UserListFiltersInput>;
}>;

export type GetOrganizationUsersOfOrganizationQuery = {
  __typename?: 'Query';
  getOrganizationUsersForUser: Array<
    { __typename?: 'User'; id: string; role: UserRole; name: string; email: string } & {
      ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
    }
  >;
};

export type TemplateHeaderFooterContentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Float']['input']>;
}>;

export type TemplateHeaderFooterContentQuery = {
  __typename?: 'Query';
  CDTTemplate: { __typename?: 'CDTTemplate'; id: number; name: string; headerContent: string; footerContent: string };
};

export type ReportHeaderFooterContentQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type ReportHeaderFooterContentQuery = {
  __typename?: 'Query';
  CDTReport: {
    __typename?: 'CDTReport';
    fileId: number;
    name: string;
    file: { __typename?: 'DocumentationFile'; headerContent: string; footerContent: string };
  };
};

export type FindingHeaderFooterContentQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type FindingHeaderFooterContentQuery = {
  __typename?: 'Query';
  Finding: {
    __typename?: 'Finding';
    fileId: number;
    name: string;
    file: { __typename?: 'DocumentationFile'; headerContent: string; footerContent: string };
  };
};

export type UpdateCdtHeaderFooterContentMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  data: CdtTemplateUpdateInput;
}>;

export type UpdateCdtHeaderFooterContentMutation = {
  __typename?: 'Mutation';
  updateCDTTemplate: {
    __typename?: 'CDTTemplate';
    id: number;
    content: string;
    headerContent: string;
    footerContent: string;
  };
};

export type UpdateDocFileHeaderFooterContentMutationVariables = Exact<{
  fileId: Scalars['Float']['input'];
  data: FileHeaderFooterUpdateInput;
}>;

export type UpdateDocFileHeaderFooterContentMutation = {
  __typename?: 'Mutation';
  updateFileHeaderFooter: {
    __typename?: 'DocumentationFile';
    id: number;
    headerContent: string;
    footerContent: string;
  };
};

export type GetIdentityProviderListQueryVariables = Exact<{ [key: string]: never }>;

export type GetIdentityProviderListQuery = {
  __typename?: 'Query';
  getIdentityProviderList: Array<
    { __typename?: 'KeycloakIdentityProvider'; alias: string } & {
      ' $fragmentRefs'?: { AuthMethodCardFragment: AuthMethodCardFragment };
    }
  >;
};

export type GetIdentityProviderQueryVariables = Exact<{
  alias: Scalars['String']['input'];
}>;

export type GetIdentityProviderQuery = {
  __typename?: 'Query';
  getIdentityProvider: {
    __typename?: 'KeycloakIdentityProvider';
    alias: string;
    displayName?: string | null;
    enabled?: boolean | null;
    config?:
      | { __typename?: 'KeycloakOpenIDConfig' }
      | {
          __typename?: 'KeycloakSAMLConfig';
          entityId: string;
          singleLogoutServiceUrl?: string | null;
          singleSignOnServiceUrl?: string | null;
          forceAuthn?: boolean | null;
        }
      | { __typename?: 'KeycloakSocialConfig'; clientId: string; clientSecret: string; defaultScope?: string | null }
      | null;
  };
};

export type CreateIdentityProviderMutationVariables = Exact<{
  identityProvider: KeycloakIdentityProviderCreate;
  socialConfig?: InputMaybe<KeycloakSocialConfigCreate>;
  samlConfig?: InputMaybe<KeycloakSamlConfigCreate>;
}>;

export type CreateIdentityProviderMutation = {
  __typename?: 'Mutation';
  createIdentityProvider: {
    __typename?: 'KeycloakIdentityProvider';
    alias: string;
    providerId: Provider;
    displayName?: string | null;
    enabled?: boolean | null;
    config?:
      | { __typename?: 'KeycloakOpenIDConfig' }
      | {
          __typename?: 'KeycloakSAMLConfig';
          entityId: string;
          singleLogoutServiceUrl?: string | null;
          singleSignOnServiceUrl?: string | null;
          forceAuthn?: boolean | null;
        }
      | { __typename?: 'KeycloakSocialConfig'; clientId: string; clientSecret: string; defaultScope?: string | null }
      | null;
  };
};

export type UpdateIdentityProviderMutationVariables = Exact<{
  identityProvider: KeycloakIdentityProviderUpdate;
  socialConfig?: InputMaybe<KeycloakSocialConfigUpdate>;
  samlConfig?: InputMaybe<KeycloakSamlConfigUpdate>;
}>;

export type UpdateIdentityProviderMutation = {
  __typename?: 'Mutation';
  updateIdentityProvider: {
    __typename?: 'KeycloakIdentityProvider';
    alias: string;
    providerId: Provider;
    displayName?: string | null;
    enabled?: boolean | null;
    config?:
      | { __typename?: 'KeycloakOpenIDConfig' }
      | {
          __typename?: 'KeycloakSAMLConfig';
          entityId: string;
          singleLogoutServiceUrl?: string | null;
          singleSignOnServiceUrl?: string | null;
          forceAuthn?: boolean | null;
        }
      | { __typename?: 'KeycloakSocialConfig'; clientId: string; clientSecret: string; defaultScope?: string | null }
      | null;
  };
};

export type GetJobArtifactByJobRunIdQueryVariables = Exact<{
  lineageId: Scalars['Float']['input'];
}>;

export type GetJobArtifactByJobRunIdQuery = {
  __typename?: 'Query';
  getJobArtifactByJobRunId: {
    __typename?: 'PaginatedLineageArtifactResponse';
    items: Array<
      {
        __typename?: 'LineageArtifact';
        id: number;
        version: number;
        lineageArtifactType: LineageArtifactType;
        lineageId: number;
        artifactType: ArtifactType;
      } & {
        ' $fragmentRefs'?: {
          LineageAssetFragmentFragment: LineageAssetFragmentFragment;
          LineageCodeFragmentFragment: LineageCodeFragmentFragment;
        };
      }
    >;
  };
};

export type LogoutQueryVariables = Exact<{ [key: string]: never }>;

export type LogoutQuery = { __typename?: 'Query'; logout: boolean };

export type MoveArtifactsMutationVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
  artifactsIdList: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
  targetStepId?: InputMaybe<Scalars['Float']['input']>;
  targetStepIndex?: InputMaybe<Scalars['Float']['input']>;
}>;

export type MoveArtifactsMutation = {
  __typename?: 'Mutation';
  moveArtifacts: Array<{ __typename?: 'IterationStepArtifact'; id: number; stepId?: number | null }>;
};

export type CheckWorkspaceNameAvailabilityQueryVariables = Exact<{
  name: Scalars['String']['input'];
  exceptId?: InputMaybe<Scalars['VecticeId']['input']>;
}>;

export type CheckWorkspaceNameAvailabilityQuery = { __typename?: 'Query'; checkWorkspaceNameAvailability: boolean };

export type CheckProjectNameAvailabilityQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  projectId?: InputMaybe<Scalars['VecticeId']['input']>;
  name: Scalars['String']['input'];
}>;

export type CheckProjectNameAvailabilityQuery = { __typename?: 'Query'; checkProjectNameAvailability: boolean };

export type CheckCdtReportNameAvailabilityQueryVariables = Exact<{
  parentId: Scalars['VecticeId']['input'];
  resourceId?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
}>;

export type CheckCdtReportNameAvailabilityQuery = { __typename?: 'Query'; checkCDTReportNameAvailability: boolean };

export type CheckCdtTemplateNameAvailabilityQueryVariables = Exact<{
  resourceId?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  isRichText?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CheckCdtTemplateNameAvailabilityQuery = { __typename?: 'Query'; checkCDTTemplateNameAvailability: boolean };

export type GetAllOrganizationsQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<OrganizationLevel> | OrganizationLevel>;
  page?: InputMaybe<PageInput>;
}>;

export type GetAllOrganizationsQuery = {
  __typename?: 'Query';
  getAllOrganizations: {
    __typename?: 'PaginatedOrganizationList';
    total: number;
    items: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      level: OrganizationLevel;
      description?: string | null;
      createdDate: any;
      emailDomains: Array<{ __typename?: 'EmailDomain'; domain: string }>;
    }>;
  };
};

export type GetAccessibilityLevelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccessibilityLevelsQuery = { __typename?: 'Query'; getAccessibilityLevels: Array<AccessibilityLevel> };

export type SetUserRoleMutationVariables = Exact<{
  role: UserRole;
  userId: Scalars['Float']['input'];
}>;

export type SetUserRoleMutation = {
  __typename?: 'Mutation';
  setUserRole: { __typename?: 'User'; id: string; role: UserRole };
};

export type DisableUserMutationVariables = Exact<{
  userId: Scalars['Float']['input'];
}>;

export type DisableUserMutation = {
  __typename?: 'Mutation';
  disableUser: { __typename?: 'User'; id: string; disabled: boolean };
};

export type EnableUserMutationVariables = Exact<{
  userId: Scalars['Float']['input'];
}>;

export type EnableUserMutation = {
  __typename?: 'Mutation';
  enableUser: { __typename?: 'User'; id: string; disabled: boolean };
};

export type CreateOrganizationMutationVariables = Exact<{
  data: OrganizationCreateInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: { __typename?: 'Organization'; id: string; name: string; description?: string | null };
};

export type UpdateOrganizationDescriptionMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  data: OrganizationUpdateInput;
}>;

export type UpdateOrganizationDescriptionMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'Organization';
    id: string;
    description?: string | null;
    level: OrganizationLevel;
  };
};

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['Float']['input'];
}>;

export type DeleteOrganizationMutation = { __typename?: 'Mutation'; deleteOrganization: string };

export type CheckOrganizationEmailDomainAvailabilityQueryVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;

export type CheckOrganizationEmailDomainAvailabilityQuery = {
  __typename?: 'Query';
  checkOrganizationEmailDomainAvailability: boolean;
};

export type GetRealmInformationFromEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type GetRealmInformationFromEmailQuery = {
  __typename?: 'Query';
  getRealmInformationFromEmail: { __typename?: 'RealmInformation'; realm: string; clientId: string };
};

export type InviteTrialUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type InviteTrialUserMutation = { __typename?: 'Mutation'; inviteTrialUser: TrialUserInviteStatus };

export type RemoveIterationStepMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type RemoveIterationStepMutation = { __typename?: 'Mutation'; removeIterationStep: string };

export type RemoveStepDefinitionMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type RemoveStepDefinitionMutation = { __typename?: 'Mutation'; removeStepDefinition: string };

export type RenameEntityFileMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  newName: Scalars['String']['input'];
}>;

export type RenameEntityFileMutation = {
  __typename?: 'Mutation';
  renameEntityFile: { __typename?: 'EntityFile'; id: number; fileName?: string | null };
};

export type RenameEntityMetadataMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
}>;

export type RenameEntityMetadataMutation = {
  __typename?: 'Mutation';
  renameEntityMetadata: { __typename?: 'EntityMetadata'; id: number; name: string };
};

export type ResetOrganizationFeatureFlagMutationVariables = Exact<{
  organizationId: Scalars['Float']['input'];
  code: Scalars['String']['input'];
}>;

export type ResetOrganizationFeatureFlagMutation = {
  __typename?: 'Mutation';
  resetOrganizationFeatureFlag: {
    __typename?: 'OrgFeatureFlagValue';
    code: string;
    enabled: boolean;
    organizationId: number;
    overridden: boolean;
  };
};

export type RestorePhaseMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
}>;

export type RestorePhaseMutation = { __typename?: 'Mutation'; restorePhase: string };

export type UpdateFindingMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  data: FindingUpdateInput;
}>;

export type UpdateFindingMutation = {
  __typename?: 'Mutation';
  updateFinding: { __typename?: 'Finding'; id: number; name: string };
};

export type UpdateFindingOwnerMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  ownerId: Scalars['Float']['input'];
}>;

export type UpdateFindingOwnerMutation = {
  __typename?: 'Mutation';
  updateFinding: {
    __typename?: 'Finding';
    id: number;
    ownerId: number;
    owner: { __typename?: 'User'; id: string; disabled: boolean } & {
      ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
    };
  };
};

export type UpdateFindingAssigneeMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  assigneeId?: InputMaybe<Scalars['Float']['input']>;
}>;

export type UpdateFindingAssigneeMutation = {
  __typename?: 'Mutation';
  updateFinding: {
    __typename?: 'Finding';
    id: number;
    ownerId: number;
    reviewer?:
      | ({ __typename?: 'User'; id: string; disabled: boolean } & {
          ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
        })
      | null;
  };
};

export type UpdateFindingStatusMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  status: FindingStatus;
}>;

export type UpdateFindingStatusMutation = {
  __typename?: 'Mutation';
  updateFinding: { __typename?: 'Finding'; id: number; status: FindingStatus };
};

export type UpdateFindingSeverityMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  severity: FindingSeverity;
}>;

export type UpdateFindingSeverityMutation = {
  __typename?: 'Mutation';
  updateFinding: { __typename?: 'Finding'; id: number; severity: FindingSeverity };
};

export type UpdateFindingModelVersionMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  modelVersionId?: InputMaybe<Scalars['VecticeId']['input']>;
}>;

export type UpdateFindingModelVersionMutation = {
  __typename?: 'Mutation';
  updateFinding: {
    __typename?: 'Finding';
    id: number;
    modelVersion?:
      | ({ __typename?: 'ModelVersion'; vecticeId: string } & {
          ' $fragmentRefs'?: { ModelVersionInformationFieldsFragment: ModelVersionInformationFieldsFragment };
        })
      | null;
  };
};

export type UpdateFindingReportMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  reportId?: InputMaybe<Scalars['Float']['input']>;
}>;

export type UpdateFindingReportMutation = {
  __typename?: 'Mutation';
  updateFinding: {
    __typename?: 'Finding';
    id: number;
    report?: { __typename?: 'CDTReport'; id: number; name: string } | null;
  };
};

export type UpdateFindingDueDateMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  date?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type UpdateFindingDueDateMutation = {
  __typename?: 'Mutation';
  updateFinding: { __typename?: 'Finding'; id: number; dueDate?: any | null };
};

export type UpdateSectionMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  updateModel: BaseStepUpdateInput;
}>;

export type UpdateSectionMutation = {
  __typename?: 'Mutation';
  updateIterationStep: {
    __typename?: 'IterationStep';
    id: number;
    description?: string | null;
    index: number;
    name: string;
  };
};

export type UpdateIterationStarredStatusMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
  starred: Scalars['Boolean']['input'];
}>;

export type UpdateIterationStarredStatusMutation = {
  __typename?: 'Mutation';
  updateIteration: { __typename?: 'Iteration'; vecticeId: string; starred: boolean };
};

export type UpdateIterationStepArtifactMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  data: UpdateIterationStepArtifactInput;
}>;

export type UpdateIterationStepArtifactMutation = {
  __typename?: 'Mutation';
  updateIterationStepArtifact: { __typename?: 'IterationStepArtifact'; id: number; text?: string | null };
};

export type UpdatePhaseMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
  updateModel: PhaseUpdateInput;
}>;

export type UpdatePhaseMutation = {
  __typename?: 'Mutation';
  updatePhase: {
    __typename?: 'Phase';
    vecticeId: string;
    index: number;
    name: string;
    description?: string | null;
    status: DocumentationPageStatus;
    ownerId?: number | null;
    enforceRequirementsAsSections: boolean;
    owner?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
  };
};

export type UpdateStepDefinitionMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  updateModel: BaseStepUpdateInput;
}>;

export type UpdateStepDefinitionMutation = {
  __typename?: 'Mutation';
  updateStepDefinition: {
    __typename?: 'StepDefinition';
    id: number;
    name: string;
    slug: string;
    description?: string | null;
  };
};

export type UpdateStepPositionMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  updateModel: BaseStepUpdateInput;
}>;

export type UpdateStepPositionMutation = {
  __typename?: 'Mutation';
  updateStepDefinition: { __typename?: 'StepDefinition'; id: number; index: number };
};

export type ChangeUserPasswordMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['Float']['input']>;
  newPassword: Scalars['String']['input'];
}>;

export type ChangeUserPasswordMutation = { __typename?: 'Mutation'; changeUserPassword: boolean };

export type GetUserActivityListQueryVariables = Exact<{
  workspaceIdList?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  projectIds?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  targetFilter?: InputMaybe<ActivityTargetFilter>;
  activityTargetTypes?: InputMaybe<Array<ActivityTargetType> | ActivityTargetType>;
  activityTypes?: InputMaybe<Array<ActivityType> | ActivityType>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  pageSize: Scalars['Int']['input'];
  onlyMine?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetUserActivityListQuery = {
  __typename?: 'Query';
  getUserActivityList: {
    __typename?: 'PaginatedUserActivityResponse';
    total: number;
    page: { __typename?: 'PageOutput'; hasNextPage?: boolean | null; afterCursor?: string | null };
  } & { ' $fragmentRefs'?: { UserActivitiesFragment: UserActivitiesFragment } };
};

export type CreateUserInviteMutationVariables = Exact<{
  data: UserInviteInput;
}>;

export type CreateUserInviteMutation = {
  __typename?: 'Mutation';
  createUserInvite: Array<{
    __typename?: 'UserInviteResponse';
    email: string;
    status: UserInviteStatus;
    invite?: { __typename?: 'UserInvite'; inviteLinkToken: string; email: string } | null;
  }>;
};

export type CancelUserInviteMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type CancelUserInviteMutation = { __typename?: 'Mutation'; cancelUserInvite: boolean };

export type ResendUserInviteMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type ResendUserInviteMutation = { __typename?: 'Mutation'; resendUserInvite: boolean };

export type CreateUserViewActivityMutationVariables = Exact<{
  userViewActivity: UserViewActivityCreateInput;
}>;

export type CreateUserViewActivityMutation = {
  __typename?: 'Mutation';
  createUserViewActivity: { __typename?: 'UserViewActivity'; id: number };
};

export type GetUserWorkspaceAccessibilityQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  userId: Scalars['Float']['input'];
}>;

export type GetUserWorkspaceAccessibilityQuery = { __typename?: 'Query'; accessLevel: AccessibilityLevel };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never }>;

export type WhoAmIQuery = {
  __typename?: 'Query';
  whoAmI: {
    __typename?: 'UserAndDefaultWorkspaceOutput';
    user: {
      __typename?: 'User';
      id: string;
      avatarId?: number | null;
      name: string;
      email: string;
      emailVerificationStatus: EmailVerificationStatus;
      about?: string | null;
      organizationId: number;
      role: UserRole;
      preferences: { __typename?: 'UserPreferencesOutput'; onboardingStatus: Array<OnboardingStatus> } & {
        ' $fragmentRefs'?: { UserPreferencesFragment: UserPreferencesFragment };
      };
    } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    defaultWorkspace?: { __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType } | null;
  };
};

export type GetUserWorkspaceListToInviteQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type GetUserWorkspaceListToInviteQuery = {
  __typename?: 'Query';
  getUserWorkspaceListToInvite: {
    __typename?: 'PaginatedWorkspaceResponseOutput';
    items: Array<{ __typename?: 'Workspace'; vecticeId: string; name: string }>;
  };
};

export type GetAllUserWorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUserWorkspacesQuery = {
  __typename?: 'Query';
  getUserWorkspaceList: {
    __typename?: 'PaginatedWorkspaceResponseOutput';
    items: Array<{ __typename?: 'Workspace'; vecticeId: string; name: string }>;
  };
};

export type GetWorkspaceMemberListQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
  filters?: InputMaybe<UserListFiltersInput>;
}>;

export type GetWorkspaceMemberListQuery = {
  __typename?: 'Query';
  getWorkspaceMemberList: {
    __typename?: 'PaginatedUserList';
    items: Array<
      { __typename?: 'User'; id: string; role: UserRole; name: string; email: string } & {
        ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
      }
    >;
  };
};

export type GetWorkspaceTargetListQueryVariables = Exact<{
  filters: WorkspaceListFiltersInput;
}>;

export type GetWorkspaceTargetListQuery = {
  __typename?: 'Query';
  getUserWorkspaceList: {
    __typename?: 'PaginatedWorkspaceResponseOutput';
    items: Array<{ __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType }>;
  };
};

export type GetDatasetContextQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetContextQuery = {
  __typename?: 'Query';
  getDataset: {
    __typename?: 'DataSet';
    vecticeId: string;
    project: {
      __typename?: 'Project';
      vecticeId: string;
      name: string;
      workspace?: {
        __typename?: 'Workspace';
        vecticeId: string;
        name: string;
        type: WorkspaceType;
        userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
      } | null;
    };
  };
};

export type GetDatasetVersionContextQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionContextQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    dataSet: {
      __typename?: 'DataSet';
      vecticeId: string;
      project: {
        __typename?: 'Project';
        vecticeId: string;
        name: string;
        workspace?: {
          __typename?: 'Workspace';
          vecticeId: string;
          name: string;
          type: WorkspaceType;
          userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
        } | null;
      };
    };
  };
};

export type GetIterationContextQueryVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
}>;

export type GetIterationContextQuery = {
  __typename?: 'Query';
  getIterationById: {
    __typename?: 'Iteration';
    vecticeId: string;
    phase: {
      __typename?: 'Phase';
      vecticeId: string;
      name: string;
      index: number;
      parent: {
        __typename?: 'Project';
        vecticeId: string;
        name: string;
        workspace?: {
          __typename?: 'Workspace';
          vecticeId: string;
          name: string;
          type: WorkspaceType;
          userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
        } | null;
      };
    };
  };
};

export type GetModelContextQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
}>;

export type GetModelContextQuery = {
  __typename?: 'Query';
  getModelById: {
    __typename?: 'Model';
    vecticeId: string;
    project: {
      __typename?: 'Project';
      vecticeId: string;
      name: string;
      workspace?: {
        __typename?: 'Workspace';
        vecticeId: string;
        name: string;
        type: WorkspaceType;
        userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
      } | null;
    };
  };
};

export type GetModelVersionContextQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionContextQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    model: {
      __typename?: 'Model';
      vecticeId: string;
      project: {
        __typename?: 'Project';
        vecticeId: string;
        name: string;
        workspace?: {
          __typename?: 'Workspace';
          vecticeId: string;
          name: string;
          type: WorkspaceType;
          userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
        } | null;
      };
    };
  };
};

export type GetPhaseContextQueryVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
}>;

export type GetPhaseContextQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    vecticeId: string;
    name: string;
    index: number;
    parent: {
      __typename?: 'Project';
      vecticeId: string;
      name: string;
      workspace?: {
        __typename?: 'Workspace';
        vecticeId: string;
        name: string;
        type: WorkspaceType;
        userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
      } | null;
    };
  };
};

export type GetProjectContextQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
}>;

export type GetProjectContextQuery = {
  __typename?: 'Query';
  getProjectById: {
    __typename?: 'Project';
    vecticeId: string;
    name: string;
    workspace?: {
      __typename?: 'Workspace';
      vecticeId: string;
      name: string;
      type: WorkspaceType;
      userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
    } | null;
  };
};

export type GetReportContextQueryVariables = Exact<{
  reportId: Scalars['Float']['input'];
}>;

export type GetReportContextQuery = {
  __typename?: 'Query';
  CDTReport: {
    __typename?: 'CDTReport';
    id: number;
    name: string;
    project: {
      __typename?: 'Project';
      vecticeId: string;
      name: string;
      workspace?: {
        __typename?: 'Workspace';
        vecticeId: string;
        name: string;
        type: WorkspaceType;
        userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
      } | null;
    };
  };
};

export type GetFindingContextQueryVariables = Exact<{
  findingId: Scalars['Float']['input'];
}>;

export type GetFindingContextQuery = {
  __typename?: 'Query';
  Finding: {
    __typename?: 'Finding';
    id: number;
    name: string;
    project: {
      __typename?: 'Project';
      vecticeId: string;
      name: string;
      workspace?: {
        __typename?: 'Workspace';
        vecticeId: string;
        name: string;
        type: WorkspaceType;
        userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
      } | null;
    };
  };
};

export type GetWorkspaceContextQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceContextQuery = {
  __typename?: 'Query';
  getWorkspaceById: {
    __typename?: 'Workspace';
    vecticeId: string;
    name: string;
    type: WorkspaceType;
    userContext: { __typename?: 'WorkspaceUserContext'; accessibility?: AccessibilityLevel | null };
  };
};

export type GetUserProjectsQueryVariables = Exact<{
  workspaceId?: InputMaybe<Scalars['VecticeId']['input']>;
  filters?: InputMaybe<ProjectListFiltersInput>;
}>;

export type GetUserProjectsQuery = {
  __typename?: 'Query';
  getProjectList: {
    __typename?: 'PaginatedProjectResponse';
    items: Array<{ __typename?: 'Project'; vecticeId: string; templateUsed?: string | null }>;
  };
};

export type CreateWorkspaceMutationVariables = Exact<{
  data: WorkspaceCreateInput;
}>;

export type CreateWorkspaceMutation = {
  __typename?: 'Mutation';
  createWorkspace: { __typename?: 'Workspace'; vecticeId: string; name: string; description?: string | null };
};

export type CdtReportInfoQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type CdtReportInfoQuery = {
  __typename?: 'Query';
  CDTReport: {
    __typename?: 'CDTReport';
    id: number;
    modelVersion?: {
      __typename?: 'ModelVersion';
      id: number;
      vecticeId: string;
      inventoryReference?: string | null;
    } | null;
  };
};

export type GetModelVersionInfoQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionInfoQuery = {
  __typename?: 'Query';
  getModelVersion: { __typename?: 'ModelVersion'; id: number; vecticeId: string; inventoryReference?: string | null };
};

export type DeleteApiKeyMutationVariables = Exact<{
  apiKeyId: Scalars['Float']['input'];
}>;

export type DeleteApiKeyMutation = { __typename?: 'Mutation'; deleteApiKey: boolean };

export type GetApiKeyListQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  userId: Scalars['Float']['input'];
}>;

export type GetApiKeyListQuery = {
  __typename?: 'Query';
  getApiKeyList: {
    __typename?: 'PaginatedApiKeyOutput';
    total: number;
    items: Array<{ __typename?: 'ApiKey'; id: number; name: string; createdDate: any; status: ApiKeyStatus }>;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type UpdateApiKeyMutationVariables = Exact<{
  apiKey: ApiKeyUpdateInput;
  apiKeyId: Scalars['Float']['input'];
}>;

export type UpdateApiKeyMutation = {
  __typename?: 'Mutation';
  updateApiKey: { __typename?: 'ApiKey'; id: number; status: ApiKeyStatus; name: string };
};

export type ChangeOldPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  oldPassword?: InputMaybe<Scalars['String']['input']>;
}>;

export type ChangeOldPasswordMutation = { __typename?: 'Mutation'; changeUserPassword: boolean };

export type SendEmailVerificationMutationVariables = Exact<{ [key: string]: never }>;

export type SendEmailVerificationMutation = { __typename?: 'Mutation'; sendEmailVerification: EmailVerificationStatus };

export type UpdateFeatureFlagAvailabilityMutationVariables = Exact<{
  code: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
}>;

export type UpdateFeatureFlagAvailabilityMutation = {
  __typename?: 'Mutation';
  updateFeatureFlagAvailability: { __typename?: 'FeatureFlagValue'; code: string; enabled: boolean };
};

export type UpdateOrgFeatureFlagAvailabilityMutationVariables = Exact<{
  organizationId: Scalars['Float']['input'];
  code: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
}>;

export type UpdateOrgFeatureFlagAvailabilityMutation = {
  __typename?: 'Mutation';
  updateOrganizationFeatureFlagAvailability: {
    __typename?: 'OrgFeatureFlagValue';
    code: string;
    enabled: boolean;
    organizationId: number;
    overridden: boolean;
  };
};

export type GetEpicFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEpicFeatureFlagsQuery = {
  __typename?: 'Query';
  getEpicFeatureFlags: Array<{
    __typename?: 'EpicFeatureFlag';
    code: string;
    description?: string | null;
    maturity: FeatureFlagMaturity;
    enabled: boolean;
    ticket?: string | null;
    date?: any | null;
    overrides?: number | null;
    stories?: Array<{
      __typename?: 'StoryFeatureFlag';
      code: string;
      description?: string | null;
      status: StoryFlagStatus;
      enabled: boolean;
      ticket?: string | null;
      date?: any | null;
      overrides?: number | null;
    }> | null;
  }>;
};

export type GetOrganizationFeatureFlagsQueryVariables = Exact<{
  organizationId: Scalars['Float']['input'];
}>;

export type GetOrganizationFeatureFlagsQuery = {
  __typename?: 'Query';
  getOrganizationFeatureFlags: Array<{
    __typename?: 'OrgFeatureFlagValue';
    organizationId: number;
    code: string;
    enabled: boolean;
    overridden: boolean;
  }>;
};

export type GetFeatureFlagOrgOverridesQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type GetFeatureFlagOrgOverridesQuery = {
  __typename?: 'Query';
  getFeatureFlagOrgOverrides: Array<{
    __typename?: 'OrganizationFeatureFlag';
    enabled: boolean;
    organization: { __typename?: 'Organization'; id: string; name: string };
  }>;
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  getOrganization: { __typename?: 'Organization'; id: string; name: string } & {
    ' $fragmentRefs'?: { OrganizationSettingsFragment: OrganizationSettingsFragment };
  };
};

export type OrganizationSettingsFragment = ({ __typename?: 'Organization'; id: string } & {
  ' $fragmentRefs'?: {
    OrganizationDescriptionAndLevelFragment: OrganizationDescriptionAndLevelFragment;
    OrganizationDomainsFragment: OrganizationDomainsFragment;
    OrganizationConfigurationFragment: OrganizationConfigurationFragment;
    OrganizationLicenseOptionsFragment: OrganizationLicenseOptionsFragment;
  };
}) & { ' $fragmentName'?: 'OrganizationSettingsFragment' };

export type OrganizationConfigurationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  emailsWhitelisted: boolean;
} & { ' $fragmentName'?: 'OrganizationConfigurationFragment' };

export type UpdateEmailAvailabilityForOrganizationMutationVariables = Exact<{
  organizationId: Scalars['Float']['input'];
  emailsEnabled: Scalars['Boolean']['input'];
}>;

export type UpdateEmailAvailabilityForOrganizationMutation = {
  __typename?: 'Mutation';
  updateEmailAvailabilityForOrganization: boolean;
};

export type OrganizationDescriptionAndLevelFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  description?: string | null;
  level: OrganizationLevel;
} & { ' $fragmentName'?: 'OrganizationDescriptionAndLevelFragment' };

export type OrganizationDomainsFragment = {
  __typename?: 'Organization';
  id: string;
  emailDomains: Array<{ __typename?: 'EmailDomain'; domain: string }>;
} & { ' $fragmentName'?: 'OrganizationDomainsFragment' };

export type AddEmailDomainToOrganizationMutationVariables = Exact<{
  organizationId: Scalars['Float']['input'];
  emailDomain: Scalars['String']['input'];
}>;

export type AddEmailDomainToOrganizationMutation = {
  __typename?: 'Mutation';
  addEmailDomainToOrganization: {
    __typename?: 'Organization';
    id: string;
    emailDomains: Array<{ __typename?: 'EmailDomain'; domain: string }>;
  };
};

export type OrganizationLicenseOptionsFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  licenseOptions: {
    __typename?: 'OrganizationLicenseOptions';
    isSingleSignOnEnabled: boolean;
    isAskAIEnabled: boolean;
  };
} & { ' $fragmentName'?: 'OrganizationLicenseOptionsFragment' };

export type SetLicenseOptionsMutationVariables = Exact<{
  organizationId: Scalars['Float']['input'];
  licenseOptions: OrganizationLicenseOptionsInput;
}>;

export type SetLicenseOptionsMutation = {
  __typename?: 'Mutation';
  setLicenseOptions: {
    __typename?: 'OrganizationLicenseOptions';
    isSingleSignOnEnabled: boolean;
    isAskAIEnabled: boolean;
  };
};

export type GetOrganizationUserAndInvitesListQueryVariables = Exact<{
  filters?: InputMaybe<UserAndInvitesFiltersInput>;
  order?: InputMaybe<ListOrderUserAndInvitesInput>;
  organizationId?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<PageIndexInput>;
}>;

export type GetOrganizationUserAndInvitesListQuery = {
  __typename?: 'Query';
  getUserAndInvitesList: {
    __typename?: 'PaginatedUserAndInvitesResponse';
    total: number;
    items: Array<{
      __typename?: 'UserAndInvites';
      status: UserAndInvitesStatus;
      type: UserAndInvitesType;
      user?: Array<
        { __typename?: 'User'; disabled: boolean; role: UserRole; createdDate: any } & {
          ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
        }
      > | null;
      userInvite?: Array<{
        __typename?: 'UserInvite';
        id: string;
        email: string;
        inviteLinkToken: string;
        createdDate: any;
        role: UserRole;
      }> | null;
    }>;
  };
};

export type ExportMacrosQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportMacrosQuery = { __typename?: 'Query'; exportMacros: boolean };

export type ExportProjectQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  organizationId?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportProjectQuery = { __typename?: 'Query'; exportProject: boolean };

export type UpdateBigQueryUserViewActivityMutationVariables = Exact<{ [key: string]: never }>;

export type UpdateBigQueryUserViewActivityMutation = {
  __typename?: 'Mutation';
  updateBigQueryUserViewActivity: boolean;
};

export type ExportWorkspaceQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  organizationId?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportWorkspaceQuery = { __typename?: 'Query'; exportWorkspace: boolean };

export type CreateSuperAdminUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type CreateSuperAdminUserMutation = {
  __typename?: 'Mutation';
  createSuperAdminUser: { __typename?: 'User'; id: string; name: string } & {
    ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
  };
};

export type ListSuperAdminUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListSuperAdminUsersQuery = {
  __typename?: 'Query';
  listSuperAdminUsers: Array<
    { __typename?: 'User'; id: string; name: string; createdDate: any; disabled: boolean } & {
      ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
    }
  >;
};

export type AuthenticateWithUniqueCodeQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  realm: Scalars['String']['input'];
  code: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['String']['input']>;
}>;

export type AuthenticateWithUniqueCodeQuery = {
  __typename?: 'Query';
  authenticateWithUniqueCode: { __typename?: 'RealmInformation'; realm: string; clientId: string };
};

export type GetStepForRedirectionQueryVariables = Exact<{
  stepId: Scalars['Float']['input'];
}>;

export type GetStepForRedirectionQuery = {
  __typename?: 'Query';
  getStepById: { __typename?: 'IterationStep'; id: number; parent: { __typename?: 'Iteration'; vecticeId: string } };
};

export type CdtReportQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type CdtReportQuery = {
  __typename?: 'Query';
  CDTReport: {
    __typename?: 'CDTReport';
    id: number;
    name: string;
    project: { __typename?: 'Project'; vecticeId: string };
    createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    modelVersion?:
      | ({ __typename?: 'ModelVersion' } & {
          ' $fragmentRefs'?: { ModelVersionInformationFieldsFragment: ModelVersionInformationFieldsFragment };
        })
      | null;
    file: {
      __typename?: 'DocumentationFile';
      id: number;
      content: string;
      isFileLocked: boolean;
      lockoutDuration?: number | null;
      fingerprint?: string | null;
      lockedUser?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
    };
  };
};

export type GetDatasetVersionDetailsQueryVariables = Exact<{
  datasetVersionId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetVersionDetailsQuery = {
  __typename?: 'Query';
  getDatasetVersion: {
    __typename?: 'DataSetVersion';
    vecticeId: string;
    name?: string | null;
    isStarred: boolean;
    versionNumber: number;
    dataSet: { __typename?: 'DataSet'; vecticeId: string; name: string };
  } & { ' $fragmentRefs'?: { DatasetVersionSidebarFragment: DatasetVersionSidebarFragment } };
};

export type GetDatasetDetailsQueryVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
}>;

export type GetDatasetDetailsQuery = {
  __typename?: 'Query';
  getDataset: {
    __typename?: 'DataSet';
    vecticeId: string;
    name: string;
    project: { __typename?: 'Project'; vecticeId: string };
  } & { ' $fragmentRefs'?: { DatasetSidebarFragment: DatasetSidebarFragment } };
};

export type UpdateDatasetTypeMutationVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  data: DatasetUpdateInput;
}>;

export type UpdateDatasetTypeMutation = {
  __typename?: 'Mutation';
  updateDataset: { __typename?: 'DataSet'; vecticeId: string; type: DatasetType };
};

export type DatasetSidebarFragment = {
  __typename?: 'DataSet';
  vecticeId: string;
  description?: string | null;
  type: DatasetType;
  sourceOrigin: string;
  createdDate: any;
  updatedDate: any;
  project: {
    __typename?: 'Project';
    vecticeId: string;
    workspace?: { __typename?: 'Workspace'; vecticeId: string } | null;
  };
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  lastUpdatedBy?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
} & { ' $fragmentName'?: 'DatasetSidebarFragment' };

export type UpdateDatasetDescriptionMutationVariables = Exact<{
  datasetId: Scalars['VecticeId']['input'];
  data: DatasetUpdateInput;
}>;

export type UpdateDatasetDescriptionMutation = {
  __typename?: 'Mutation';
  updateDataset: { __typename?: 'DataSet'; vecticeId: string; description?: string | null };
};

export type GetWorkspaceNameQueryVariables = Exact<{
  workspaceIds?: InputMaybe<Array<Scalars['VecticeId']['input']> | Scalars['VecticeId']['input']>;
}>;

export type GetWorkspaceNameQuery = {
  __typename?: 'Query';
  getOrganizationWorkspaceList: {
    __typename?: 'PaginatedWorkspaceResponseOutput';
    items: Array<{ __typename?: 'Workspace'; vecticeId: string; name: string }>;
  };
};

export type RequestWorkspaceAccessMutationVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type RequestWorkspaceAccessMutation = {
  __typename?: 'Mutation';
  requestWorkspaceAccess: { __typename?: 'WorkspaceAccessRequest'; id: string };
};

export type GetProjectDocumentationQueryVariables = Exact<{
  filters: PhaseFiltersInput;
}>;

export type GetProjectDocumentationQuery = {
  __typename?: 'Query';
  getPhaseList: {
    __typename?: 'PaginatedPhaseResponseOutput';
    items: Array<{
      __typename?: 'Phase';
      vecticeId: string;
      name: string;
      file: { __typename?: 'DocumentationFile'; id: number; content: string };
    }>;
  };
};

export type GetFindingQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetFindingQuery = {
  __typename?: 'Query';
  Finding: {
    __typename?: 'Finding';
    id: number;
    name: string;
    fileId: number;
    file: { __typename?: 'DocumentationFile'; id: number; content: string };
    project: { __typename?: 'Project'; name: string };
  };
};

export type GetFindingDetailsQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;

export type GetFindingDetailsQuery = {
  __typename?: 'Query';
  Finding: {
    __typename?: 'Finding';
    id: number;
    name: string;
    severity: FindingSeverity;
    status: FindingStatus;
    dueDate?: any | null;
    createdDate: any;
    updatedDate: any;
    report?: { __typename?: 'CDTReport'; id: number; name: string } | null;
    modelVersion?:
      | ({ __typename?: 'ModelVersion'; vecticeId: string; inventoryReference?: string | null } & {
          ' $fragmentRefs'?: { ModelVersionInformationFieldsFragment: ModelVersionInformationFieldsFragment };
        })
      | null;
    project: { __typename?: 'Project'; vecticeId: string };
    owner: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    reviewer?:
      | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
      | null;
    createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
    lastUpdatedBy?:
      | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
      | null;
  };
};

export type CreatePasswordRecoveryRequestMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type CreatePasswordRecoveryRequestMutation = { __typename?: 'Mutation'; createPasswordRecoveryRequest: boolean };

export type RemoveAssetsFromIterationMutationVariables = Exact<{
  iterationId: Scalars['VecticeId']['input'];
  artifactsIdList: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;

export type RemoveAssetsFromIterationMutation = { __typename?: 'Mutation'; removeAssetsFromIteration: number };

export type GetModelVersionDetailsQueryVariables = Exact<{
  modelVersionId: Scalars['VecticeId']['input'];
}>;

export type GetModelVersionDetailsQuery = {
  __typename?: 'Query';
  getModelVersion: {
    __typename?: 'ModelVersion';
    vecticeId: string;
    name?: string | null;
    isStarred: boolean;
    versionNumber: number;
    model: { __typename?: 'Model'; vecticeId: string; name: string };
  } & { ' $fragmentRefs'?: { ModelVersionSidebarFragment: ModelVersionSidebarFragment } };
};

export type GetModelDetailsQueryVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
}>;

export type GetModelDetailsQuery = {
  __typename?: 'Query';
  getModelById: {
    __typename?: 'Model';
    vecticeId: string;
    name: string;
    project: { __typename?: 'Project'; vecticeId: string };
  } & { ' $fragmentRefs'?: { ModelSidebarFragment: ModelSidebarFragment } };
};

export type UpdateModelTypeMutationVariables = Exact<{
  modelId: Scalars['VecticeId']['input'];
  type: ModelType;
}>;

export type UpdateModelTypeMutation = {
  __typename?: 'Mutation';
  updateModel: { __typename?: 'Model'; vecticeId: string; type: ModelType };
};

export type ModelSidebarFragment = {
  __typename?: 'Model';
  vecticeId: string;
  name: string;
  description?: string | null;
  type: ModelType;
  createdDate: any;
  updatedDate: any;
  versionStats?: {
    __typename?: 'ModelVersionStatistics';
    experimentationCount: number;
    stagingCount: number;
    productionCount: number;
    retiredCount: number;
    discardedCount: number;
  } | null;
  project: {
    __typename?: 'Project';
    vecticeId: string;
    workspace?: { __typename?: 'Workspace'; vecticeId: string } | null;
  };
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  lastUpdatedBy?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
} & { ' $fragmentName'?: 'ModelSidebarFragment' };

export type ChangeOrgDisplayNameMutationVariables = Exact<{
  displayName: Scalars['String']['input'];
}>;

export type ChangeOrgDisplayNameMutation = { __typename?: 'Mutation'; changeOrgDisplayName: boolean };

export type SetOrganizationSettingsMutationVariables = Exact<{
  configuration: OrganizationConfigInput;
}>;

export type SetOrganizationSettingsMutation = {
  __typename?: 'Mutation';
  setOrganizationSettings: {
    __typename?: 'OrganizationConfigOutput';
    passwordAuthenticationEnabled: boolean;
    autoProvisioning: boolean;
    emailNotificationEnabled: boolean;
    dfStatisticsRowThreshold: number;
    dfStatisticsSampleRows: number;
    dfStatisticsMaxColumns: number;
    everyOneCanInviteUser: boolean;
  };
};

export type GetUserAndInvitesListQueryVariables = Exact<{
  filters?: InputMaybe<UserAndInvitesFiltersInput>;
  order?: InputMaybe<ListOrderUserAndInvitesInput>;
  page?: InputMaybe<PageIndexInput>;
}>;

export type GetUserAndInvitesListQuery = {
  __typename?: 'Query';
  getUserAndInvitesList: {
    __typename?: 'PaginatedUserAndInvitesResponse';
    total: number;
    items: Array<{
      __typename?: 'UserAndInvites';
      status: UserAndInvitesStatus;
      type: UserAndInvitesType;
      user?: Array<
        { __typename?: 'User'; id: string; createdDate: any; disabled: boolean; role: UserRole } & {
          ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
        }
      > | null;
      userInvite?: Array<{
        __typename?: 'UserInvite';
        id: string;
        email: string;
        role: UserRole;
        inviteLinkToken: string;
        updatedDate: any;
      }> | null;
    }>;
  };
};

export type CheckRecoverPasswordRequestQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type CheckRecoverPasswordRequestQuery = {
  __typename?: 'Query';
  checkRecoverPasswordRequest: PasswordRecoveryRequestStatus;
};

export type RecoverPasswordAfterRequestMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
}>;

export type RecoverPasswordAfterRequestMutation = {
  __typename?: 'Mutation';
  recoverPasswordAfterRequest: { __typename?: 'User'; id: string; email: string };
};

export type GetStarredIterationListQueryVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
}>;

export type GetStarredIterationListQuery = {
  __typename?: 'Query';
  getIterationList: {
    __typename?: 'PaginatedIterationResponse';
    items: Array<
      { __typename?: 'Iteration'; vecticeId: string } & {
        ' $fragmentRefs'?: { IterationListItemFragment: IterationListItemFragment };
      }
    >;
  };
};

export type GetPhaseSummaryQueryVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
}>;

export type GetPhaseSummaryQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    vecticeId: string;
    name: string;
    description?: string | null;
    parent: { __typename?: 'Project'; vecticeId: string };
  } & { ' $fragmentRefs'?: { PhaseSidebarFragment: PhaseSidebarFragment } };
};

export type RequestReviewMutationVariables = Exact<{
  review: ReviewCreateInput;
}>;

export type RequestReviewMutation = {
  __typename?: 'Mutation';
  requestReview: { __typename?: 'Review'; vecticeId: string; status: ReviewStatus } & {
    ' $fragmentRefs'?: { ReviewFragment: ReviewFragment };
  };
};

export type ReviewFragment = {
  __typename?: 'Review';
  vecticeId: string;
  createdDate: any;
  updatedDate: any;
  message?: string | null;
  status: ReviewStatus;
  feedback?: string | null;
  createdById: number;
  reviewerId: number;
  createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  reviewer: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
} & { ' $fragmentName'?: 'ReviewFragment' };

export type GetActiveReviewQueryVariables = Exact<{
  phaseId: Scalars['VecticeId']['input'];
}>;

export type GetActiveReviewQuery = {
  __typename?: 'Query';
  getPhaseById: {
    __typename?: 'Phase';
    vecticeId: string;
    status: DocumentationPageStatus;
    activeReview?: { __typename?: 'Review'; vecticeId: string; status: ReviewStatus } | null;
  };
};

export type GetDatasetListQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  dsTypes?: InputMaybe<Array<DatasetType> | DatasetType>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
}>;

export type GetDatasetListQuery = {
  __typename?: 'Query';
  getDatasetList: {
    __typename?: 'PaginatedDataSetResponse';
    total: number;
    items: Array<
      { __typename?: 'DataSet'; vecticeId: string } & {
        ' $fragmentRefs'?: { DatasetListItemFragment: DatasetListItemFragment };
      }
    >;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type FindingsQueryVariables = Exact<{
  filters: FindingFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  type?: InputMaybe<FindingQueryType>;
}>;

export type FindingsQuery = {
  __typename?: 'Query';
  Findings: {
    __typename?: 'PaginatedFindingResponse';
    total: number;
    items: Array<{
      __typename?: 'Finding';
      id: number;
      name: string;
      status: FindingStatus;
      severity: FindingSeverity;
      createdDate: any;
      updatedDate: any;
      closedDate?: any | null;
      dueDate?: any | null;
      reviewer?: ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } }) | null;
      owner: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
      modelVersion?:
        | ({ __typename?: 'ModelVersion'; inventoryReference?: string | null } & {
            ' $fragmentRefs'?: { ModelVersionInformationFieldsFragment: ModelVersionInformationFieldsFragment };
          })
        | null;
      report?: { __typename?: 'CDTReport'; id: number; name: string } | null;
    }>;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type GetModelListQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  types?: InputMaybe<Array<ModelType> | ModelType>;
  modelVersionStatus?: InputMaybe<Array<ModelVersionStatus> | ModelVersionStatus>;
  algorithmNames?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type GetModelListQuery = {
  __typename?: 'Query';
  getModelList: {
    __typename?: 'PaginatedModelResponse';
    total: number;
    items: Array<
      { __typename?: 'Model'; vecticeId: string } & {
        ' $fragmentRefs'?: { ModelListItemFragment: ModelListItemFragment };
      }
    >;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type GetPhaseListForProjectOverviewQueryVariables = Exact<{
  parentId: Scalars['VecticeId']['input'];
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
}>;

export type GetPhaseListForProjectOverviewQuery = {
  __typename?: 'Query';
  getPhaseList: {
    __typename?: 'PaginatedPhaseResponseOutput';
    items: Array<
      { __typename?: 'Phase'; vecticeId: string } & {
        ' $fragmentRefs'?: { PhaseListItemFragment: PhaseListItemFragment };
      }
    >;
  };
};

export type GetProjectOverviewQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
}>;

export type GetProjectOverviewQuery = {
  __typename?: 'Query';
  getProjectById: {
    __typename?: 'Project';
    vecticeId: string;
    description?: string | null;
    phasesCounts: { __typename?: 'DocumentationCounts'; done: number; ongoing: number; toDo: number; total: number };
  } & { ' $fragmentRefs'?: { ProjectSidebarFragment: ProjectSidebarFragment } };
};

export type ProjectSidebarFragment = {
  __typename?: 'Project';
  vecticeId: string;
  status: ProjectStatus;
  createdDate: any;
  updatedDate: any;
  phasesCounts: { __typename?: 'DocumentationCounts'; total: number };
  createdBy: { __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
  lastUpdatedBy?:
    | ({ __typename?: 'User'; id: string } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
    | null;
  owner: { __typename?: 'User'; id: string; disabled: boolean } & {
    ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
  };
  workspace?: { __typename?: 'Workspace'; vecticeId: string } | null;
} & { ' $fragmentName'?: 'ProjectSidebarFragment' };

export type CdtReportsQueryVariables = Exact<{
  type?: InputMaybe<CdtReportQueryType>;
  filters: CdtReportFiltersInput;
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
}>;

export type CdtReportsQuery = {
  __typename?: 'Query';
  CDTReports: {
    __typename?: 'PaginatedCDTReportResponse';
    total: number;
    items: Array<{
      __typename?: 'CDTReport';
      id: number;
      name: string;
      createdDate: any;
      updatedDate: any;
      createdBy: { __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } };
      modelVersion?:
        | ({ __typename?: 'ModelVersion'; inventoryReference?: string | null } & {
            ' $fragmentRefs'?: { ModelVersionInformationFieldsFragment: ModelVersionInformationFieldsFragment };
          })
        | null;
    }>;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type GetProjectSettingsQueryVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
}>;

export type GetProjectSettingsQuery = {
  __typename?: 'Query';
  getProjectById: {
    __typename?: 'Project';
    vecticeId: string;
    name: string;
    description?: string | null;
    ownerId: number;
    workspace?: { __typename?: 'Workspace'; vecticeId: string; name: string; type: WorkspaceType } | null;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['VecticeId']['input'];
  data: ProjectUpdateInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: { __typename?: 'Project'; vecticeId: string; name: string; description?: string | null };
};

export type CreateUserFromInviteMutationVariables = Exact<{
  data: UserCreateInput;
}>;

export type CreateUserFromInviteMutation = {
  __typename?: 'Mutation';
  createUserFromInvite: { __typename?: 'User'; name: string; email: string };
};

export type GetUserInviteWithAuthInfoQueryVariables = Exact<{
  inviteToken: Scalars['String']['input'];
}>;

export type GetUserInviteWithAuthInfoQuery = {
  __typename?: 'Query';
  getUserInviteWithAuthInfo: {
    __typename?: 'UserInviteWithAuthInfoOutput';
    passwordAuthenticationEnabled: boolean;
    userInvite: {
      __typename?: 'UserInvite';
      email: string;
      isTrial: boolean;
      invitingUser?:
        | ({ __typename?: 'User' } & { ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment } })
        | null;
    };
  };
};

export type CheckEmailVerificationTokenMutationVariables = Exact<{
  emailVerificationToken: Scalars['String']['input'];
}>;

export type CheckEmailVerificationTokenMutation = {
  __typename?: 'Mutation';
  checkEmailVerificationToken: EmailVerificationStatus;
};

export type GetWorkspaceUserListForInviteQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceUserListForInviteQuery = {
  __typename?: 'Query';
  getWorkspaceUserList: {
    __typename?: 'PaginatedWorkspaceUserList';
    items: Array<{ __typename?: 'WorkspaceUser'; user: { __typename?: 'User'; id: string } }>;
  };
};

export type GetWorkspaceUserListQueryVariables = Exact<{
  order?: InputMaybe<ListOrderInput>;
  page?: InputMaybe<PageInput>;
  workspaceId: Scalars['VecticeId']['input'];
  filters?: InputMaybe<UserListFiltersInput>;
}>;

export type GetWorkspaceUserListQuery = {
  __typename?: 'Query';
  getWorkspaceUserList: {
    __typename?: 'PaginatedWorkspaceUserList';
    total: number;
    items: Array<{
      __typename?: 'WorkspaceUser';
      userId: number;
      accessibility: AccessibilityLevel;
      createdDate: any;
      workspace: { __typename?: 'Workspace'; vecticeId: string; name: string };
      user: { __typename?: 'User'; createdDate: any; role: UserRole } & {
        ' $fragmentRefs'?: { UserFieldsFragment: UserFieldsFragment };
      };
    }>;
  };
};

export type RemoveUserFromWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  userId: Scalars['Float']['input'];
}>;

export type RemoveUserFromWorkspaceMutation = { __typename?: 'Mutation'; removeUserFromWorkspace: string };

export type SetUserWorkspaceAccessibilityMutationVariables = Exact<{
  accessibility: AccessibilityLevel;
  userId: Scalars['Float']['input'];
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type SetUserWorkspaceAccessibilityMutation = {
  __typename?: 'Mutation';
  setUserWorkspaceAccessibility: {
    __typename?: 'WorkspaceUser';
    userId: number;
    accessibility: AccessibilityLevel;
    workspace: { __typename?: 'Workspace'; vecticeId: string };
  };
};

export type GetProjectListQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
  order?: InputMaybe<ListOrderInput>;
}>;

export type GetProjectListQuery = {
  __typename?: 'Query';
  getProjectList: {
    __typename?: 'PaginatedProjectResponse';
    total: number;
    items: Array<
      { __typename?: 'Project'; vecticeId: string } & {
        ' $fragmentRefs'?: { ProjectListItemFragment: ProjectListItemFragment };
      }
    >;
    page: { __typename?: 'PageOutput' } & { ' $fragmentRefs'?: { PageIndexFieldsFragment: PageIndexFieldsFragment } };
  };
};

export type GetProjectSelectorListQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
  filters?: InputMaybe<ProjectListFiltersInput>;
}>;

export type GetProjectSelectorListQuery = {
  __typename?: 'Query';
  getProjectList: {
    __typename?: 'PaginatedProjectResponse';
    items: Array<{ __typename?: 'Project'; vecticeId: string; name: string }>;
  };
};

export type GetWorkspaceSettingsQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type GetWorkspaceSettingsQuery = {
  __typename?: 'Query';
  getWorkspaceById: {
    __typename?: 'Workspace';
    vecticeId: string;
    name: string;
    description?: string | null;
    type: WorkspaceType;
  };
};

export type UpdateWorkspaceMutationVariables = Exact<{
  id: Scalars['VecticeId']['input'];
  data: WorkspaceUpdateInput;
}>;

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation';
  updateWorkspace: { __typename?: 'Workspace'; vecticeId: string; name: string; description?: string | null };
};

export type GetUserDefaultWorkspaceQueryVariables = Exact<{
  workspaceId: Scalars['VecticeId']['input'];
}>;

export type GetUserDefaultWorkspaceQuery = {
  __typename?: 'Query';
  getWorkspaceById: { __typename?: 'Workspace' } & {
    ' $fragmentRefs'?: { WorkspaceCardFragment: WorkspaceCardFragment };
  };
};

export type GetUserWorkspaceListQueryVariables = Exact<{
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  order?: InputMaybe<ListOrderInput>;
  search: Scalars['String']['input'];
  type?: InputMaybe<Array<WorkspaceType> | WorkspaceType>;
  advancedFilters?: InputMaybe<Array<GroupFilter> | GroupFilter>;
}>;

export type GetUserWorkspaceListQuery = {
  __typename?: 'Query';
  getUserWorkspaceList: { __typename?: 'PaginatedWorkspaceResponseOutput'; total: number } & {
    ' $fragmentRefs'?: { WorkspaceGridFragment: WorkspaceGridFragment };
  };
};

export const UserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>;
export const ThreadReplyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'threadReplyFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadReply' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThreadReplyFragmentFragment, unknown>;
export const CommentFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repliesInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'threadReplyFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'threadReplyFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadReply' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentFragmentFragment, unknown>;
export const UserActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'toValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAutomated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceWorkspaceVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserActivityFragment, unknown>;
export const UserActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userActivities' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginatedUserActivityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userActivity' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'toValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAutomated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceWorkspaceVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserActivitiesFragment, unknown>;
export const ReportRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reportRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportRowFragment, unknown>;
export const ReportListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reportListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'reportRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reportRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportListItemFragment, unknown>;
export const DatasetVersionRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetSources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetVersionRowFragment, unknown>;
export const DatasetVersionListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetVersionListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetVersionRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetSources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetVersionListItemFragment, unknown>;
export const DatasetRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetRowFragment, unknown>;
export const DatasetListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetListItemFragment, unknown>;
export const IterationRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IterationRowFragment, unknown>;
export const IterationListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'iterationRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IterationListItemFragment, unknown>;
export const ArtifactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'artifact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtifactFragment, unknown>;
export const ModelVersionRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelVersionRowFragment, unknown>;
export const ModelVersionListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelVersionListItemFragment, unknown>;
export const ModelRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelRowFragment, unknown>;
export const ModelListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelListItemFragment, unknown>;
export const PhaseRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhaseRowFragment, unknown>;
export const PhaseListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'phaseRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhaseListItemFragment, unknown>;
export const ProjectCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectCardFragment, unknown>;
export const ProjectRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phasesCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ongoing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toDo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRowFragment, unknown>;
export const ProjectListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'projectRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phasesCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ongoing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toDo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectListItemFragment, unknown>;
export const ReviewRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reviewRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewRowFragment, unknown>;
export const ReviewListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reviewListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'reviewRow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reviewRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewListItemFragment, unknown>;
export const AuthMethodCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'authMethodCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakIdentityProvider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthMethodCardFragment, unknown>;
export const DatasetSourceMenuFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetSourceMenu' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columnsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetSourceMenuFragment, unknown>;
export const BooleanSourceColumnStatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BooleanSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boolean' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'false' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'true' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BooleanSourceColumnStatisticsFragment, unknown>;
export const DateSourceColumnStatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DateSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'median' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DateSourceColumnStatisticsFragment, unknown>;
export const NumericalSourceColumnStatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NumericalSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numerical' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quantiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'qMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q25' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q50' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q75' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qMax' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stdDeviation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NumericalSourceColumnStatisticsFragment, unknown>;
export const TextSourceColumnStatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mostCommons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'unique' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TextSourceColumnStatisticsFragment, unknown>;
export const DatasetVersionSidebarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DatasetVersionSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceOrigin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'origins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iteration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetVersionSidebarFragment, unknown>;
export const IterationSidebarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IterationSidebarFragment, unknown>;
export const EntityFileFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityFileFragmentFragment, unknown>;
export const IterationStepArtifactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationStepArtifact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityFileId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IterationStepArtifactFragment, unknown>;
export const IterationStepFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationStep' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStep' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedArtifacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stepId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IterationStepArtifact' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationStepArtifact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityFileId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IterationStepFragment, unknown>;
export const LineageAssetFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'lineageAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LineageArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artifactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LineageAssetFragmentFragment, unknown>;
export const LineageCodeFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'lineageCodeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LineageArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codeVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'code' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gitVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'commitHash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entrypoint' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDirty' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'databricksVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'urlNotebook' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relativePath' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherCodeVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstAdditionalInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondAdditionalInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdAdditionalInformation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LineageCodeFragmentFragment, unknown>;
export const ModelVersionInformationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'context' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'library' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'run' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extraInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelVersionInformationFragment, unknown>;
export const ModelVersionSidebarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModelVersionSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'risk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'origins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iteration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelVersionSidebarFragment, unknown>;
export const NewUserNotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewUserNotification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserNotification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewUserNotificationFragment, unknown>;
export const PhaseSidebarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhaseSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhaseSidebarFragment, unknown>;
export const WorkspaceCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Workspace' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeDisabledUsers' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceCardFragment, unknown>;
export const WorkspaceGridFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceGrid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginatedWorkspaceResponseOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkspaceCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Workspace' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeDisabledUsers' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceGridFragment, unknown>;
export const FileHistoryEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fileHistoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileHistoryWithSessionOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileHistoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHistoriesInSession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileHistoryEntryFragment, unknown>;
export const UserPreferencesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userPreferences' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserPreferencesOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onboardingStatus' } }],
      },
    },
  ],
} as unknown as DocumentNode<UserPreferencesFragment, unknown>;
export const ModelVersionInformationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelVersionInformationFieldsFragment, unknown>;
export const MetricsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'metricsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityMetric' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MetricsFieldsFragment, unknown>;
export const PropertiesFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'propertiesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertiesFieldsFragment, unknown>;
export const PageAfterCursorFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageAfterCursorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageAfterCursorFieldsFragment, unknown>;
export const PageIndexFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageIndexFieldsFragment, unknown>;
export const AttachmentFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'attachmentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachmentOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachmentFragmentFragment, unknown>;
export const OrganizationDescriptionAndLevelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationDescriptionAndLevel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationDescriptionAndLevelFragment, unknown>;
export const OrganizationDomainsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationDomains' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailDomains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationDomainsFragment, unknown>;
export const OrganizationConfigurationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationConfiguration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailsWhitelisted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationConfigurationFragment, unknown>;
export const OrganizationLicenseOptionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationLicenseOptions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licenseOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSingleSignOnEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAskAIEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationLicenseOptionsFragment, unknown>;
export const OrganizationSettingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationDescriptionAndLevel' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'organizationDomains' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'organizationConfiguration' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'organizationLicenseOptions' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationDescriptionAndLevel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationDomains' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailDomains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationConfiguration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailsWhitelisted' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationLicenseOptions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licenseOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSingleSignOnEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAskAIEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationSettingsFragment, unknown>;
export const DatasetSidebarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DatasetSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceOrigin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetSidebarFragment, unknown>;
export const ModelSidebarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModelSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discardedCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelSidebarFragment, unknown>;
export const ReviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Review' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewFragment, unknown>;
export const ProjectSidebarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phasesCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectSidebarFragment, unknown>;
export const CreateThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'commentFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'threadReplyFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadReply' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repliesInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'threadReplyFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateThreadMutation, CreateThreadMutationVariables>;
export const CreateThreadReplyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createThreadReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadReplyCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createThreadReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateThreadReplyMutation, CreateThreadReplyMutationVariables>;
export const DeleteThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteThreadMutation, DeleteThreadMutationVariables>;
export const DeleteThreadReplyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteThreadReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteThreadReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteThreadReplyMutation, DeleteThreadReplyMutationVariables>;
export const ResolveThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resolveThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'EnumValue', value: 'RESOLVED' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResolveThreadMutation, ResolveThreadMutationVariables>;
export const SubscribeToFileCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToFileComments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToFileComments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'commentFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'threadReplyFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'threadReplyFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadReply' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repliesInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'threadReplyFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeToFileCommentsSubscription, SubscribeToFileCommentsSubscriptionVariables>;
export const ThreadRepliesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'threadReplies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threadReplies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'threadId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'threadReplyFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'threadReplyFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadReply' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThreadRepliesQuery, ThreadRepliesQueryVariables>;
export const ThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'threads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'editorIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadStatus' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'editorIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'editorIds' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editorId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'commentFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'threadReplyFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadReply' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repliesInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'threadReplyFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThreadsQuery, ThreadsQueryVariables>;
export const UpdateThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateThreadMutation, UpdateThreadMutationVariables>;
export const UpdateThreadReplyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateThreadReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CommentUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateThreadReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedDateContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEditedContent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateThreadReplyMutation, UpdateThreadReplyMutationVariables>;
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const DeleteWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const LeaveWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'leaveWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveWorkspaceMutation, LeaveWorkspaceMutationVariables>;
export const TransferProjectOwnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'transferProjectOwnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransferProjectOwnershipMutation, TransferProjectOwnershipMutationVariables>;
export const GetReportLinkInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReportLinkInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportLinkInfoQuery, GetReportLinkInfoQueryVariables>;
export const ReportTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'reportTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportTooltipQuery, ReportTooltipQueryVariables>;
export const GetDataSetVersionTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataSetVersionTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datasetSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataSetVersionTooltipQuery, GetDataSetVersionTooltipQueryVariables>;
export const GetDatasetVersionWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetVersionRow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datasetSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'datasetInputs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetSources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionWidgetQuery, GetDatasetVersionWidgetQueryVariables>;
export const GetDataSetTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataSetTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataSetTooltipQuery, GetDataSetTooltipQueryVariables>;
export const GetDatasetWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetRow' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetWidgetQuery, GetDatasetWidgetQueryVariables>;
export const GetEntityFileWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityFileWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEntityFileById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasStaticView' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityFileWidgetQuery, GetEntityFileWidgetQueryVariables>;
export const GetEntityFileIterationOriginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityFileIterationOrigin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityFileIterationOriginQuery, GetEntityFileIterationOriginQueryVariables>;
export const GetEntityFileDatasetVersionOriginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityFileDatasetVersionOrigin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityFileDatasetVersionOriginQuery, GetEntityFileDatasetVersionOriginQueryVariables>;
export const GetEntityFileModelVersionOriginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityFileModelVersionOrigin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityFileModelVersionOriginQuery, GetEntityFileModelVersionOriginQueryVariables>;
export const EntityMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'entityMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityMetadataQuery, EntityMetadataQueryVariables>;
export const GetFindingTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFindingTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Finding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFindingTooltipQuery, GetFindingTooltipQueryVariables>;
export const GetIterationTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationTooltipQuery, GetIterationTooltipQueryVariables>;
export const GetIterationWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'iterationRow' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationWidgetQuery, GetIterationWidgetQueryVariables>;
export const GetIterationArtifactsForWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationArtifactsForWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatedArtifacts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'artifact' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'artifact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationArtifactsForWidgetQuery, GetIterationArtifactsForWidgetQueryVariables>;
export const GetIterationStepListForWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationStepListForWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'index', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginatedArtifacts' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'index' },
                                  value: { kind: 'IntValue', value: '1' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'IntValue', value: '100' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'artifact' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'artifact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationStepListForWidgetQuery, GetIterationStepListForWidgetQueryVariables>;
export const GetLineageTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLineageTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lineageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getJobArtifactByJobRunId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lineageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lineageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lineageArtifactType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'artifactType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataSetVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dataSet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modelVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'model' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gitVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'commitHash' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entrypoint' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'databricksVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'urlNotebook' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'relativePath' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'otherCodeVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstAdditionalInformation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'secondAdditionalInformation' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLineageTooltipQuery, GetLineageTooltipQueryVariables>;
export const GetLineageWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLineageWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getJobRunById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lineageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLineageWidgetQuery, GetLineageWidgetQueryVariables>;
export const GetModelVersionLinkInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionLinkInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionLinkInfoQuery, GetModelVersionLinkInfoQueryVariables>;
export const GetModelVersionTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionTooltipQuery, GetModelVersionTooltipQueryVariables>;
export const GetModelVersionWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionRow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'datasetInputs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionWidgetQuery, GetModelVersionWidgetQueryVariables>;
export const GetModelTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelTooltipQuery, GetModelTooltipQueryVariables>;
export const GetModelWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelWidget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelRow' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelWidgetQuery, GetModelWidgetQueryVariables>;
export const GetPhaseTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeReview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iterationsCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseTooltipQuery, GetPhaseTooltipQueryVariables>;
export const GetProjectTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phasesCounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ongoing' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'toDo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectTooltipQuery, GetProjectTooltipQueryVariables>;
export const GetUserTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserTooltipQuery, GetUserTooltipQueryVariables>;
export const GetWorkspaceTooltipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceTooltip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'includeDisabledUsers' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceTooltipQuery, GetWorkspaceTooltipQueryVariables>;
export const CdtReportsSelectorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CDTReportsSelector' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReportQueryType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReportFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CdtReportsSelectorQuery, CdtReportsSelectorQueryVariables>;
export const GetDatasetVersionPickerListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionPickerList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersionFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionPickerListQuery, GetDatasetVersionPickerListQueryVariables>;
export const GetModelVersionPickerListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionPickerList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionListFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionPickerListQuery, GetModelVersionPickerListQueryVariables>;
export const GetProjectsSelectorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectsSelector' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectListFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageIndexInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectsSelectorQuery, GetProjectsSelectorQueryVariables>;
export const DatasetsToSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'datasetsToSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'versionId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetsToSelectQuery, DatasetsToSelectQueryVariables>;
export const DatasetVersionsToSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'datasetVersionsToSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataSet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetVersionsToSelectQuery, DatasetVersionsToSelectQueryVariables>;
export const ModelsToSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'modelsToSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelsToSelectQuery, ModelsToSelectQueryVariables>;
export const ModelVersionsToSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'modelVersionsToSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelVersionsToSelectQuery, ModelVersionsToSelectQueryVariables>;
export const GetIterationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phase' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationsQuery, GetIterationsQueryVariables>;
export const GetSelectedCdtReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSelectedCDTReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedCdtReportQuery, GetSelectedCdtReportQueryVariables>;
export const GetSelectedProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSelectedProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedProjectQuery, GetSelectedProjectQueryVariables>;
export const GetSelectedWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSelectedWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedWorkspaceQuery, GetSelectedWorkspaceQueryVariables>;
export const SourceColumnsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sourceColumns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'columnSourceType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ColumnSourceType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageIndexInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceColumns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'columnSourceType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'columnSourceType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourceColumnsQuery, SourceColumnsQueryVariables>;
export const SourceColumnDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sourceColumn' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'columnSourceType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ColumnSourceType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourceColumn' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'columnSourceType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'columnSourceType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourceColumnQuery, SourceColumnQueryVariables>;
export const GetContributorListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getContributorList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContributorListFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              { kind: 'ObjectField', name: { kind: 'Name', value: 'size' }, value: { kind: 'IntValue', value: '30' } },
              { kind: 'ObjectField', name: { kind: 'Name', value: 'index' }, value: { kind: 'IntValue', value: '1' } },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContributorList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContributorListQuery, GetContributorListQueryVariables>;
export const GetActivityInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getActivityInsights' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivityInsights' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numberOfDays' },
                value: { kind: 'IntValue', value: '30' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'completedProjectsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedPhasesCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPhasesCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelVersionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'datasetVersionsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityInsightsQuery, GetActivityInsightsQueryVariables>;
export const GetWorkspaceActivityTrendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceActivityTrend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userTimezone' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceActivityTrend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userTimezone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userTimezone' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceActivityTrendQuery, GetWorkspaceActivityTrendQueryVariables>;
export const GetWorkspaceCompletedPhasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceCompletedPhases' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMostRecentlyCompletedPhases' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceCompletedPhasesQuery, GetWorkspaceCompletedPhasesQueryVariables>;
export const GetWorkspaceCompletedProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceCompletedProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMostRecentlyCompletedProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceCompletedProjectsQuery, GetWorkspaceCompletedProjectsQueryVariables>;
export const GetCompletedReviewsCountPerUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCompletedReviewsCountPerUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCompletedReviewsCountPerUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompletedReviewsCountPerUserQuery, GetCompletedReviewsCountPerUserQueryVariables>;
export const GetPhaseStatusCountsPerOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseStatusCountsPerOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseStatusCountsPerOwner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseStatusCountsPerOwnerQuery, GetPhaseStatusCountsPerOwnerQueryVariables>;
export const GetWorkspaceCompletedIterationsCountPerUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceCompletedIterationsCountPerUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceCompletedIterationsCountPerUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numberOfDays' },
                value: { kind: 'IntValue', value: '30' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceCompletedIterationsCountPerUserQuery,
  GetWorkspaceCompletedIterationsCountPerUserQueryVariables
>;
export const GetWorkspaceOngoingPhasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceOngoingPhases' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceOngoingPhases' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceOngoingPhasesQuery, GetWorkspaceOngoingPhasesQueryVariables>;
export const GetWorkspaceOngoingProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceOngoingProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceOngoingProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phasesCounts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'toDo' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ongoing' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceOngoingProjectsQuery, GetWorkspaceOngoingProjectsQueryVariables>;
export const GetPendingReviewsCountPerUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPendingReviewsCountPerUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPendingReviewsCountPerUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPendingReviewsCountPerUserQuery, GetPendingReviewsCountPerUserQueryVariables>;
export const GetPhaseStatusesBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseStatusesBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseStatusCounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseStatusesBreakdownQuery, GetPhaseStatusesBreakdownQueryVariables>;
export const GetWorkspaceCompletedPhasesCountPerUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceCompletedPhasesCountPerUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceCompletedPhasesCountPerUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numberOfDays' },
                value: { kind: 'IntValue', value: '30' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceCompletedPhasesCountPerUserQuery,
  GetWorkspaceCompletedPhasesCountPerUserQueryVariables
>;
export const GetProjectStatusesBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectStatusesBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectStatusCounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectStatusesBreakdownQuery, GetProjectStatusesBreakdownQueryVariables>;
export const GetTopAssetsCreatorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTopAssetsCreators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTopAssetsCreators' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numberOfDays' },
                value: { kind: 'IntValue', value: '30' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'datasetVersionCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modelVersionCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopAssetsCreatorsQuery, GetTopAssetsCreatorsQueryVariables>;
export const GetDatasetVersionFileSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionFileSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetFilesSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnsNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rowsNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileCreatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUpdatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skippedStatistics' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extraMetadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionFileSourceQuery, GetDatasetVersionFileSourceQueryVariables>;
export const GetDatasetVersionDbSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionDbSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dbSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDBSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dbSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dbSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnsNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rowsNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tableCreatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tableUpdatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skippedStatistics' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extraMetadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionDbSourceQuery, GetDatasetVersionDbSourceQueryVariables>;
export const GetDataSourceFileSourceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataSourceFileSourceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileSources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'datasetSourceId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetSourceId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '15' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rowsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'columnsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnsTypesCounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'categoryType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataSourceFileSourceListQuery, GetDataSourceFileSourceListQueryVariables>;
export const GetDataSourceDbSourceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataSourceDbSourceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbSources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'datasetSourceId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetSourceId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '15' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tablename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rowsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'columnsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnsTypesCounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'categoryType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataSourceDbSourceListQuery, GetDataSourceDbSourceListQueryVariables>;
export const GetDatasetVersionResourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionResources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetColumn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datasetSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetSourceMenu' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetSourceMenu' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columnsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionResourcesQuery, GetDatasetVersionResourcesQueryVariables>;
export const GetDatasetVersionFileSourceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionFileSourceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileSources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'datasetVersionId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rowsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'columnsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnsTypesCounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'categoryType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionFileSourceListQuery, GetDatasetVersionFileSourceListQueryVariables>;
export const GetDatasetVersionDbSourceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionDbSourceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbSources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'datasetVersionId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tablename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rowsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'columnsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnsTypesCounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'categoryType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionDbSourceListQuery, GetDatasetVersionDbSourceListQueryVariables>;
export const UpdateDatasetVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDatasetVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetColumn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'targetColumn' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'targetColumn' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetColumn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDatasetVersionMutation, UpdateDatasetVersionMutationVariables>;
export const UpdateColumnSourceDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateColumnSourceDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'columnSourceType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ColumnSourceType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateColumnSourceDescription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'columnSourceType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'columnSourceType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateColumnSourceDescriptionMutation, UpdateColumnSourceDescriptionMutationVariables>;
export const GetDatasetVersionFileColumnsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionFileColumns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFileSourceColumnsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileSourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchFilter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'search' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'fields' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'name', block: false }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'categoryType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BooleanSourceColumnStatistics' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DateSourceColumnStatistics' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'NumericalSourceColumnStatistics' },
                            },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextSourceColumnStatistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BooleanSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boolean' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'false' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'true' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DateSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'median' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NumericalSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numerical' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quantiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'qMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q25' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q50' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q75' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qMax' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stdDeviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mostCommons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'unique' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionFileColumnsQuery, GetDatasetVersionFileColumnsQueryVariables>;
export const GetDatasetVersionDbColumnsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionDbColumns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dbSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDBSourceColumnsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dbSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dbSourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchFilter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'search' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'fields' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'name', block: false }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'categoryType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BooleanSourceColumnStatistics' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DateSourceColumnStatistics' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'NumericalSourceColumnStatistics' },
                            },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextSourceColumnStatistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BooleanSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boolean' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'false' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'true' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DateSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'median' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NumericalSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numerical' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quantiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'qMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q25' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q50' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'q75' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qMax' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stdDeviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextSourceColumnStatistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataframeStatistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'missing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mostCommons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'unique' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionDbColumnsQuery, GetDatasetVersionDbColumnsQueryVariables>;
export const GetDatasetVersionWithOriginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionWithOrigin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'datasetInputs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionWithOriginQuery, GetDatasetVersionWithOriginQueryVariables>;
export const UpdateDatasetVersionDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDatasetVersionDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetVersionUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDatasetVersionDescriptionMutation, UpdateDatasetVersionDescriptionMutationVariables>;
export const AddToStarredDatasetVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addToStarredDatasetVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resource' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StarredResourceSettings' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addToStarredDatasetVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resource' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddToStarredDatasetVersionsMutation, AddToStarredDatasetVersionsMutationVariables>;
export const GetDatasetTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetTypeQuery, GetDatasetTypeQueryVariables>;
export const GetDatasetVersionsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createdBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'showStarred' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'createdBy' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'showStarred' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'showStarred' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'origin' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'propertiesFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datasetSources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'columnsCount' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'propertiesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionsListQuery, GetDatasetVersionsListQueryVariables>;
export const GetPropertyKeysOfDatasetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPropertyKeysOfDataset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPropertyKeysOfEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'DATASET' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertyKeysOfDatasetQuery, GetPropertyKeysOfDatasetQueryVariables>;
export const GetUserWorkspaceFiltersListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserWorkspaceFiltersList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserWorkspaceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchFilter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'search' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'fields' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'name', block: false }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserWorkspaceFiltersListQuery, GetUserWorkspaceFiltersListQueryVariables>;
export const GetUniqueAlgorithmNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUniqueAlgorithmNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUniqueAlgorithmNames' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUniqueAlgorithmNamesQuery, GetUniqueAlgorithmNamesQueryVariables>;
export const GetUniqueInventoryReferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUniqueInventoryReferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUniqueInventoryReferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUniqueInventoryReferencesQuery, GetUniqueInventoryReferencesQueryVariables>;
export const RemoveFindingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeFinding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveFindingMutation, RemoveFindingMutationVariables>;
export const GetRecentProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRecentProjects' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'myRecent' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'updatedDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '6' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'templateUsed' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'projectCard' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRecentProjectsQuery, GetRecentProjectsQueryVariables>;
export const GetUserIterationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserIterations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyMine' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'updatedDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'afterCursor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'iterationListItem' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phase' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workspace' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'iterationRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserIterationsQuery, GetUserIterationsQueryVariables>;
export const GetAffectedReviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAffectedReviews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReviewList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'Pending' }] },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'affectedToMe' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'afterCursor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'reviewListItem' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phase' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workspace' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reviewRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reviewListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'reviewRow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAffectedReviewsQuery, GetAffectedReviewsQueryVariables>;
export const GetUserPhasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserPhases' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'NotStarted' },
                          { kind: 'EnumValue', value: 'Draft' },
                          { kind: 'EnumValue', value: 'InReview' },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyMine' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'updatedDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'afterCursor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'phaseListItem' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workspace' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'phaseRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserPhasesQuery, GetUserPhasesQueryVariables>;
export const GetUserReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'updatedDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'afterCursor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'reportListItem' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reportRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'reportListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'reportRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserReportsQuery, GetUserReportsQueryVariables>;
export const RemoveCdtReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeCDTReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveCdtReportMutation, RemoveCdtReportMutationVariables>;
export const DeleteDatasetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDataset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDatasetMutation, DeleteDatasetMutationVariables>;
export const DeleteDatasetVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDatasetVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDatasetVersionMutation, DeleteDatasetVersionMutationVariables>;
export const RemoveEmailDomainFromOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeEmailDomainFromOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailDomain' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeEmailDomainFromOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailDomain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailDomain' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDomains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveEmailDomainFromOrganizationMutation,
  RemoveEmailDomainFromOrganizationMutationVariables
>;
export const RemoveIterationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeIteration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeIteration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveIterationMutation, RemoveIterationMutationVariables>;
export const DeleteModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteModelMutation, DeleteModelMutationVariables>;
export const DeleteModelVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteModelVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteModelVersionMutation, DeleteModelVersionMutationVariables>;
export const RemovePhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removePhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removePhase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemovePhaseMutation, RemovePhaseMutationVariables>;
export const DuplicateCdtReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'duplicateCDTReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'duplicateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseDocumentationDuplicateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateCDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'duplicateInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'duplicateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateCdtReportMutation, DuplicateCdtReportMutationVariables>;
export const DuplicatePhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'duplicatePhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'duplicateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhaseDuplicateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicatePhase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'duplicateInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'duplicateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicatePhaseMutation, DuplicatePhaseMutationVariables>;
export const CheckDatasetNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkDatasetNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkDatasetNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckDatasetNameAvailabilityQuery, CheckDatasetNameAvailabilityQueryVariables>;
export const UpdateDatasetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDataset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDatasetMutation, UpdateDatasetMutationVariables>;
export const CheckIterationNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkIterationNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkIterationNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckIterationNameAvailabilityQuery, CheckIterationNameAvailabilityQueryVariables>;
export const UpdateIterationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIteration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIteration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIterationMutation, UpdateIterationMutationVariables>;
export const CheckModelNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkModelNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkModelNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckModelNameAvailabilityQuery, CheckModelNameAvailabilityQueryVariables>;
export const UpdateModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelUpdateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'model' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelMutation, UpdateModelMutationVariables>;
export const UpdateCdtReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCDTReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReportUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modelVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCdtReportMutation, UpdateCdtReportMutationVariables>;
export const CreatePhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createModel' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhaseCreateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPhase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'createModel' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePhaseMutation, CreatePhaseMutationVariables>;
export const MoveProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'moveProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetWorkspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectMoveInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'targetWorkspaceId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'targetWorkspaceId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveProjectMutation, MoveProjectMutationVariables>;
export const GetModelVersionOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'datasetInputs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionInformation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'context' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'library' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'run' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extraInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionOverviewQuery, GetModelVersionOverviewQueryVariables>;
export const UpdateModelVersionDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModelVersionDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelVersionDescriptionMutation, UpdateModelVersionDescriptionMutationVariables>;
export const UpdateModelVersionTechniqueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModelVersionTechnique' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelVersionTechniqueMutation, UpdateModelVersionTechniqueMutationVariables>;
export const UpdateModelVersionInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModelVersionInventory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelVersionInventoryMutation, UpdateModelVersionInventoryMutationVariables>;
export const UpdateModelVersionRiskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModelVersionRisk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'risk' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelVersionRiskMutation, UpdateModelVersionRiskMutationVariables>;
export const UpdateModelVersionApprovalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModelVersionApproval' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approval' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelVersionApprovalMutation, UpdateModelVersionApprovalMutationVariables>;
export const AddToStarredModelVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addToStarredModelVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resource' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StarredResourceSettings' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addToStarredModelVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resource' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddToStarredModelVersionsMutation, AddToStarredModelVersionsMutationVariables>;
export const GetMetricKeysOfEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMetricKeysOfEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMetricKeysOfEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'MODEL' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMetricKeysOfEntityQuery, GetMetricKeysOfEntityQueryVariables>;
export const GetModelVersionsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'showStarred' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createdBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'algorithmNames' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionStatus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionStatus' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'risks' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionRisk' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvals' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionApproval' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'createdBy' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'modelVersionStatus' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionStatus' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'risks' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'risks' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'approvals' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'approvals' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'showStarred' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'showStarred' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'algorithmNames' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'algorithmNames' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'risk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'origin' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'propertiesFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'metricsFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'propertiesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'metricsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityMetric' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionsListQuery, GetModelVersionsListQueryVariables>;
export const GetPropertyKeysOfModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPropertyKeysOfModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPropertyKeysOfEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'MODEL' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertyKeysOfModelQuery, GetPropertyKeysOfModelQueryVariables>;
export const CountUnseenUserNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'countUnseenUserNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'countUnseenUserNotifications' } }],
      },
    },
  ],
} as unknown as DocumentNode<CountUnseenUserNotificationsQuery, CountUnseenUserNotificationsQueryVariables>;
export const GetUserNotificationListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserNotificationList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserNotificationListFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserNotificationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'afterCursor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserNotificationListQuery, GetUserNotificationListQueryVariables>;
export const MarkAllUserNotificationsAsSeenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markAllUserNotificationsAsSeen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'markAllUserNotificationsAsSeen' } }],
      },
    },
  ],
} as unknown as DocumentNode<MarkAllUserNotificationsAsSeenMutation, MarkAllUserNotificationsAsSeenMutationVariables>;
export const GetFindingByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFindingById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Finding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFindingByIdQuery, GetFindingByIdQueryVariables>;
export const GetPhaseForMentionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseForMention' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseForMentionQuery, GetPhaseForMentionQueryVariables>;
export const GetCdtReportForMentionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCDTReportForMention' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCdtReportForMentionQuery, GetCdtReportForMentionQueryVariables>;
export const GetWorkspaceAccessRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceAccessRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceAccessRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceAccessRequestQuery, GetWorkspaceAccessRequestQueryVariables>;
export const ResolveWorkspaceAccessRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resolveWorkspaceAccessRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approve' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resolveWorkspaceAccessRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approve' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approve' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResolveWorkspaceAccessRequestMutation, ResolveWorkspaceAccessRequestMutationVariables>;
export const GetPhaseForPhaseOwnerChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseForPhaseOwnerChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseForPhaseOwnerChangeQuery, GetPhaseForPhaseOwnerChangeQueryVariables>;
export const GetProjectForProjectOwnerChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectForProjectOwnerChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectForProjectOwnerChangeQuery, GetProjectForProjectOwnerChangeQueryVariables>;
export const GetProjectForProjectOwnershipTransferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectForProjectOwnershipTransfer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectForProjectOwnershipTransferQuery,
  GetProjectForProjectOwnershipTransferQueryVariables
>;
export const GetProjectForProjectStatusChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectForProjectStatusChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectForProjectStatusChangeQuery, GetProjectForProjectStatusChangeQueryVariables>;
export const GetPhaseForRequestMentionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseForRequestMention' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseForRequestMentionQuery, GetPhaseForRequestMentionQueryVariables>;
export const UnseenUserNotificationCountChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'unseenUserNotificationCountChanged' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userNotificationChanged' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'unseenCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnseenUserNotificationCountChangedSubscription,
  UnseenUserNotificationCountChangedSubscriptionVariables
>;
export const UserNotificationCreatedUnseenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'userNotificationCreatedUnseen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userNotificationCreatedUnseen' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserNotificationCreatedUnseenSubscription,
  UserNotificationCreatedUnseenSubscriptionVariables
>;
export const GetPhaseOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enforceRequirementsAsSections' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseOverviewQuery, GetPhaseOverviewQueryVariables>;
export const EntityMetadataContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'entityMetadataContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityMetadataContentQuery, EntityMetadataContentQueryVariables>;
export const GetInitialAssetCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInitialAssetCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInitialAssetCountQuery, GetInitialAssetCountQueryVariables>;
export const SearchProjectAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchProjectAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'projectListItem' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workspace' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phasesCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ongoing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toDo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'projectRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchProjectAssetsQuery, SearchProjectAssetsQueryVariables>;
export const SearchDatasetAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchDatasetAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dsTypes' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetType' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dsTypes' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dsTypes' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetListItem' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workspace' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchDatasetAssetsQuery, SearchDatasetAssetsQueryVariables>;
export const SearchModelAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchModelAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelType' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionStatus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionStatus' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'algorithmNames' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inventoryReferences' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'types' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'modelVersionStatus' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionStatus' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'algorithmNames' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'algorithmNames' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'inventoryReferences' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'inventoryReferences' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelListItem' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workspace' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchModelAssetsQuery, SearchModelAssetsQueryVariables>;
export const UpdatePhaseStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePhaseStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentationPageStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePhase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateModel' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhaseStatusMutation, UpdatePhaseStatusMutationVariables>;
export const UpdateIterationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIterationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStatus' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIteration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIterationStatusMutation, UpdateIterationStatusMutationVariables>;
export const UpdateModelVersionStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModelVersionStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelVersionStatusMutation, UpdateModelVersionStatusMutationVariables>;
export const UpdatePhaseOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePhaseOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ownerId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePhase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateModel' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ownerId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ownerId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhaseOwnerMutation, UpdatePhaseOwnerMutationVariables>;
export const SubscribeToUnresolvedFileCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToUnresolvedFileComments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToFileComments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToUnresolvedFileCommentsSubscription,
  SubscribeToUnresolvedFileCommentsSubscriptionVariables
>;
export const ThreadsUnresolvedCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'threadsUnresolvedCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'EnumValue', value: 'UNRESOLVED' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThreadsUnresolvedCountQuery, ThreadsUnresolvedCountQueryVariables>;
export const CreateEntityMetricDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEntityMetric' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createMetric' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateEntityMetricInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEntityMetric' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'modelVersion' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createMetric' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'createMetric' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'metricsFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'metricsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityMetric' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEntityMetricMutation, CreateEntityMetricMutationVariables>;
export const CreateEntityPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEntityProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityPropertyType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createProperty' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateEntityPropertyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEntityProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createProperty' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'createProperty' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'propertiesFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'propertiesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEntityPropertyMutation, CreateEntityPropertyMutationVariables>;
export const DeleteEntityMetricsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEntityMetrics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metricIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEntityMetrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'modelVersion' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metricIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metricIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEntityMetricsMutation, DeleteEntityMetricsMutationVariables>;
export const DeleteEntityPropertiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEntityProperties' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityPropertyType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'propertyIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEntityProperties' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'propertyIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEntityPropertiesMutation, DeleteEntityPropertiesMutationVariables>;
export const GetEntityMetricsByParentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityMetricsByParentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'getResource' },
            name: { kind: 'Name', value: 'getEntityMetricsByParentId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'modelVersion' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'key', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'metricsFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'metricsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityMetric' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityMetricsByParentIdQuery, GetEntityMetricsByParentIdQueryVariables>;
export const GetEntityPropertiesByParentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityPropertiesByParentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityPropertyType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'getResource' },
            name: { kind: 'Name', value: 'getEntityPropertiesByParentId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'key', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'propertiesFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'propertiesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityPropertiesByParentIdQuery, GetEntityPropertiesByParentIdQueryVariables>;
export const UpdateEntityMetricDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEntityMetric' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateMetric' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateEntityMetricInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEntityMetric' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'modelVersion' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateMetric' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateMetric' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'metricsFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'metricsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityMetric' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEntityMetricMutation, UpdateEntityMetricMutationVariables>;
export const UpdateEntityPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEntityProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityPropertyType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateProperty' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateEntityPropertyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEntityProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateProperty' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateProperty' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'propertiesFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'propertiesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEntityPropertyMutation, UpdateEntityPropertyMutationVariables>;
export const AskUserTypeUpgradeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'askUserTypeUpgrade' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'askUserTypeUpgrade' } }],
      },
    },
  ],
} as unknown as DocumentNode<AskUserTypeUpgradeMutation, AskUserTypeUpgradeMutationVariables>;
export const GetOrganizationWorkspaceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationWorkspaceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkspaceType' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationWorkspaceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchFilter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'search' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'fields' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'name', block: false }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userCount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'includeDisabledUsers' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationWorkspaceListQuery, GetOrganizationWorkspaceListQueryVariables>;
export const GetPublicConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPublicConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sendEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrialEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'artifactName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureFlags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicConfigQuery, GetPublicConfigQueryVariables>;
export const GetOrgConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrgConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deployment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrialEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'artifactName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureFlags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDomains' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'passwordAuthenticationEnabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'autoProvisioning' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailNotificationEnabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dfStatisticsRowThreshold' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dfStatisticsSampleRows' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dfStatisticsMaxColumns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'everyOneCanCreateWorkspace' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'everyOneCanInviteUser' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseOptions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isSingleSignOnEnabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isAskAIEnabled' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrgConfigQuery, GetOrgConfigQueryVariables>;
export const GetFileHistoryListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFileHistoryList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileHistoryFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFileHistoryList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '20' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'afterCursor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'fileHistoryEntry' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fileHistoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileHistoryWithSessionOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileHistoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHistoriesInSession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFileHistoryListQuery, GetFileHistoryListQueryVariables>;
export const GetFileHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFileHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileHistoryId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFileHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileHistoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileHistoryId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFileHistoryQuery, GetFileHistoryQueryVariables>;
export const RestoreHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'restoreHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileHistoryId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileHistoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileHistoryId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreHistoryMutation, RestoreHistoryMutationVariables>;
export const GetCdtReportFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCDTReportFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFileLocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockoutDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fingerprint' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lockedUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCdtReportFileQuery, GetCdtReportFileQueryVariables>;
export const GetFindingFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFindingFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'findingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Finding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'findingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFileLocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockoutDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fingerprint' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lockedUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFindingFileQuery, GetFindingFileQueryVariables>;
export const FileUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'fileUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFileLocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockoutDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fingerprint' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lockedUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileUpdatedSubscription, FileUpdatedSubscriptionVariables>;
export const GetDocumentationFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDocumentationFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFileLocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockoutDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fingerprint' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lockedUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDocumentationFileQuery, GetDocumentationFileQueryVariables>;
export const UnlockFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unlockFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unlockFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnlockFileMutation, UnlockFileMutationVariables>;
export const UpdateFileContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileContentUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileContent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFileLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fingerprint' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUpdatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFileContentMutation, UpdateFileContentMutationVariables>;
export const GetAttachmentsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAttachmentsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityMetadataTypeEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'attachmentFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'attachmentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachmentOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAttachmentsToInsertQuery, GetAttachmentsToInsertQueryVariables>;
export const GetDatasetVersionsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetVersionRow' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetSources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionsToInsertQuery, GetDatasetVersionsToInsertQueryVariables>;
export const GetDatasetsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetRow' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetsToInsertQuery, GetDatasetsToInsertQueryVariables>;
export const GetIterationArtifactsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationArtifactsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatedArtifacts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'stepStrategy' },
                            value: { kind: 'EnumValue', value: 'EXCLUDES' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'withAssetDeleted' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationArtifactsCountQuery, GetIterationArtifactsCountQueryVariables>;
export const GetIterationArtifactsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationArtifactsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatedArtifacts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'stepStrategy' },
                            value: { kind: 'EnumValue', value: 'EXCLUDES' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'withAssetDeleted' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'datasetVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dataSet' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                                    },
                                  },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetVersionRow' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'modelVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'model' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                                    },
                                  },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionRow' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'entityFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'entityMetadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetSources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationArtifactsToInsertQuery, GetIterationArtifactsToInsertQueryVariables>;
export const GetIterationStepsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationStepsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'index', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginatedArtifacts' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'withAssetDeleted' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationStepsToInsertQuery, GetIterationStepsToInsertQueryVariables>;
export const GetIterationStepArtifactsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationStepArtifactsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stepId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStepById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stepId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatedArtifacts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'withAssetDeleted' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'datasetVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dataSet' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                                    },
                                  },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetVersionRow' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'modelVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'model' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                                    },
                                  },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionRow' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'entityFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'entityMetadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetSources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationStepArtifactsToInsertQuery, GetIterationStepArtifactsToInsertQueryVariables>;
export const GetIterationsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sections' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginatedArtifacts' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'stepStrategy' },
                                  value: { kind: 'EnumValue', value: 'EXCLUDES' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'withAssetDeleted' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'iterationRow' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationsToInsertQuery, GetIterationsToInsertQueryVariables>;
export const GetModelVersionsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersionsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionRow' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionsToInsertQuery, GetModelVersionsToInsertQueryVariables>;
export const GetModelsToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelsToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelRow' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelsToInsertQuery, GetModelsToInsertQueryVariables>;
export const GetPhasesToInsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhasesToInsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'index', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iterationsCount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'phaseRow' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhasesToInsertQuery, GetPhasesToInsertQueryVariables>;
export const GenerateDocumentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateDocumentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GenerateDocumentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateDocumentation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateDocumentationMutation, GenerateDocumentationMutationVariables>;
export const SearchAllAskAiAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAllAskAIAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AskAIAssetListFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AskAISearchAssetType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageIndexInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAllAskAIAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'all' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datasetVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dataSet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modelVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'model' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAllAskAiAssetsQuery, SearchAllAskAiAssetsQueryVariables>;
export const GetIterationNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationNameQuery, GetIterationNameQueryVariables>;
export const GetBreadcrumbModelVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBreadcrumbModelVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBreadcrumbModelVersionQuery, GetBreadcrumbModelVersionQueryVariables>;
export const GetBreadcrumbDatasetVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBreadcrumbDatasetVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBreadcrumbDatasetVersionQuery, GetBreadcrumbDatasetVersionQueryVariables>;
export const GetIterationIndexDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationIndex' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationIndexQuery, GetIterationIndexQueryVariables>;
export const CheckLlmPromptNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkLLMPromptNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LLMPromptTypeEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkLLMPromptNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckLlmPromptNameAvailabilityQuery, CheckLlmPromptNameAvailabilityQueryVariables>;
export const CreateLlmPromptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLLMPrompt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LLMPromptCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLLMPrompt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLlmPromptMutation, CreateLlmPromptMutationVariables>;
export const GenerateAssetDocumentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateAssetDocumentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GenerateLLMDocumentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateAssetDocumentation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'input' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateAssetDocumentationMutation, GenerateAssetDocumentationMutationVariables>;
export const LlmPromptsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'llmPrompts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LLMPromptFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageIndexInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'llmPrompts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LlmPromptsQuery, LlmPromptsQueryVariables>;
export const RemoveLlmPromptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeLLMPrompt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeLLMPrompt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveLlmPromptMutation, RemoveLlmPromptMutationVariables>;
export const UpdateLlmPromptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLLMPrompt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LLMPromptUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLLMPrompt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLlmPromptMutation, UpdateLlmPromptMutationVariables>;
export const DatasetImageOriginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'datasetImageOrigin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DatasetImageOriginQuery, DatasetImageOriginQueryVariables>;
export const IterationImageOriginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'iterationImageOrigin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IterationImageOriginQuery, IterationImageOriginQueryVariables>;
export const ModelImageOriginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'modelImageOrigin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelImageOriginQuery, ModelImageOriginQueryVariables>;
export const CreateUserMentionNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserMentionNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMentionNotificationContentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserMentionNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'content' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMentionNotificationMutation, CreateUserMentionNotificationMutationVariables>;
export const GetMentionedUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMentionedUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMentionedUserQuery, GetMentionedUserQueryVariables>;
export const GenerateTextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateText' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GenerateDocumentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateDocumentation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateTextMutation, GenerateTextMutationVariables>;
export const CreateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateLineageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLineage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LineageUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LineageType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLineage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLineageMutation, UpdateLineageMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserUpdateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'about' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserPreferencesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferences' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userPreferences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'onboardingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userPreferences' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserPreferencesOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onboardingStatus' } }],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;
export const AddUsersToWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUsersToWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkspaceUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUsersToWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'userId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUsersToWorkspaceMutation, AddUsersToWorkspaceMutationVariables>;
export const AddMyselfToWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addMyselfToWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addMyselfToWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'userId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddMyselfToWorkspaceMutation, AddMyselfToWorkspaceMutationVariables>;
export const GenerateApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiKeyCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateApiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export const CheckIfApiKeyNameExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkIfApiKeyNameExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkIfApiKeyNameExists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKeyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckIfApiKeyNameExistsQuery, CheckIfApiKeyNameExistsQueryVariables>;
export const GetExportedArchivesListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getExportedArchivesList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getExportedArchivesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCreatedDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExportedArchivesListQuery, GetExportedArchivesListQueryVariables>;
export const AuthenticateWithPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'authenticateWithPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticateWithPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthenticateWithPasswordQuery, AuthenticateWithPasswordQueryVariables>;
export const CancelIterationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelIteration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIteration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'EnumValue', value: 'Abandoned' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelIterationMutation, CancelIterationMutationVariables>;
export const CancelReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelReviewMutation, CancelReviewMutationVariables>;
export const CdtTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CDTTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTTemplateFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isMacro' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRichText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'groupName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CdtTemplatesQuery, CdtTemplatesQueryVariables>;
export const CdtTemplatesVariablesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CDTTemplatesVariables' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTTemplateFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CdtTemplatesVariablesQuery, CdtTemplatesVariablesQueryVariables>;
export const CdtTemplateContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CDTTemplateContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMacro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CdtTemplateContentQuery, CdtTemplateContentQueryVariables>;
export const CreateCdtReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCDTReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReportCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCdtReportMutation, CreateCdtReportMutationVariables>;
export const GenerateCdtMarkdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateCDTMarkdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTGenerateMarkdownInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateCDTMarkdown' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateCdtMarkdownMutation, GenerateCdtMarkdownMutationVariables>;
export const PreviewMacroDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'previewMacro' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assets' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReportAssetInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewMacro' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assets' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assets' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'content' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewMacroMutation, PreviewMacroMutationVariables>;
export const RemoveCdtTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeCDTTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCDTTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveCdtTemplateMutation, RemoveCdtTemplateMutationVariables>;
export const CreateCdtTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCDTTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTTemplateCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCDTTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCdtTemplateMutation, CreateCdtTemplateMutationVariables>;
export const UpdateCdtTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCDTTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTTemplateUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCDTTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCdtTemplateMutation, UpdateCdtTemplateMutationVariables>;
export const GlobalTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GlobalTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GlobalTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalTemplateQuery, GlobalTemplateQueryVariables>;
export const CheckIterationStepNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkIterationStepNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkIterationStepNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckIterationStepNameAvailabilityQuery, CheckIterationStepNameAvailabilityQueryVariables>;
export const CheckPhaseNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkPhaseNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkPhaseNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckPhaseNameAvailabilityQuery, CheckPhaseNameAvailabilityQueryVariables>;
export const CheckStepDefinitionNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkStepDefinitionNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkStepDefinitionNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckStepDefinitionNameAvailabilityQuery,
  CheckStepDefinitionNameAvailabilityQueryVariables
>;
export const CloseReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'closeReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'review' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closeReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'review' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'review' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Review' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Review' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CloseReviewMutation, CloseReviewMutationVariables>;
export const GetCodeArtifactByJobRunIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCodeArtifactByJobRunId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lineageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getJobArtifactByJobRunId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lineageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lineageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lineageArtifactType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lineageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'artifactType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gitVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'commitHash' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'repositoryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entrypoint' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isDirty' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'databricksVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'urlNotebook' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'relativePath' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'otherCodeVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstAdditionalInformation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'secondAdditionalInformation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'thirdAdditionalInformation' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCodeArtifactByJobRunIdQuery, GetCodeArtifactByJobRunIdQueryVariables>;
export const CompleteIterationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'completeIteration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIteration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'EnumValue', value: 'Completed' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteIterationMutation, CompleteIterationMutationVariables>;
export const CreateFindingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFinding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FindingCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFindingMutation, CreateFindingMutationVariables>;
export const CreateIterationStepDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createIterationStep' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseStepCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIterationStep' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'createModel' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIterationStepMutation, CreateIterationStepMutationVariables>;
export const CreateIterationStepArtifactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createIterationStepArtifact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifactInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIterationStepArtifact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iterationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIterationStepArtifactMutation, CreateIterationStepArtifactMutationVariables>;
export const CreateReviewCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createReviewComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reviewId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReviewComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reviewComment' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reviewId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReviewCommentMutation, CreateReviewCommentMutationVariables>;
export const CreateStepDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStepDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseStepCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStepDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'createModel' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateStepDefinitionMutation, CreateStepDefinitionMutationVariables>;
export const DeleteEntityFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEntityFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEntityFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEntityFileMutation, DeleteEntityFileMutationVariables>;
export const DeleteEntityMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEntityMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEntityMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEntityMetadataMutation, DeleteEntityMetadataMutationVariables>;
export const ExtractEntityFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'extractEntityFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extractEntityFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tables' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExtractEntityFileMutation, ExtractEntityFileMutationVariables>;
export const GetAssetAttachmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssetAttachments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityMetadataTypeEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'attachmentFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'attachmentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachmentOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetAttachmentsQuery, GetAssetAttachmentsQueryVariables>;
export const GetDatasetVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionQuery, GetDatasetVersionQueryVariables>;
export const GetEntityFileListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityFileList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFilesTypeEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEntityFileList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityFileListQuery, GetEntityFileListQueryVariables>;
export const GetIterationByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phaseId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatedArtifacts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'stepStrategy' },
                            value: { kind: 'EnumValue', value: 'EXCLUDES' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stepId' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IterationStepArtifact' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IterationSidebar' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationStepArtifact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityFileId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationByIdQuery, GetIterationByIdQueryVariables>;
export const SubscribeToIterationCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToIterationCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToIterationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeToIterationCountSubscription, SubscribeToIterationCountSubscriptionVariables>;
export const GetIterationListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phaseId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owner' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationListQuery, GetIterationListQueryVariables>;
export const GetIterationPickerListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationPickerList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationPickerListQuery, GetIterationPickerListQueryVariables>;
export const GetIterationStepListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationStepList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginatedArtifacts' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'index' },
                                  value: { kind: 'IntValue', value: '1' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'IntValue', value: '100' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'stepId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IterationStepArtifact' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IterationStep' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityFileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityFile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreview' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationStepArtifact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStepArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'datasetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityFileId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'entityFileFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IterationStep' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IterationStep' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedArtifacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stepId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IterationStepArtifact' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationStepListQuery, GetIterationStepListQueryVariables>;
export const GetModelVersionNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionNameQuery, GetModelVersionNameQueryVariables>;
export const GetPhaseByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enforceRequirementsAsSections' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeReview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PhaseSidebar' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhaseSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseByIdQuery, GetPhaseByIdQueryVariables>;
export const GetPhaseListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhaseFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iterationsCount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewsCount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalReviews' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseListQuery, GetPhaseListQueryVariables>;
export const SubscribeToReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Review' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Review' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeToReviewSubscription, SubscribeToReviewSubscriptionVariables>;
export const GetReviewCommentListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReviewCommentList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCommentFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReviewCommentList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReviewCommentListQuery, GetReviewCommentListQueryVariables>;
export const SubscribeToReviewCommentListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToReviewCommentList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCommentFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToReviewCommentList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToReviewCommentListSubscription,
  SubscribeToReviewCommentListSubscriptionVariables
>;
export const GetReviewListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReviewList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReviewList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Review' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Review' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReviewListQuery, GetReviewListQueryVariables>;
export const GetStepDefinitionListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStepDefinitionList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseDocumentationListFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStepDefinitionList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStepDefinitionListQuery, GetStepDefinitionListQueryVariables>;
export const GetOrganizationUsersOfOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationUsersOfOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserListFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationUsersForUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationUsersOfOrganizationQuery, GetOrganizationUsersOfOrganizationQueryVariables>;
export const TemplateHeaderFooterContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TemplateHeaderFooterContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateHeaderFooterContentQuery, TemplateHeaderFooterContentQueryVariables>;
export const ReportHeaderFooterContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportHeaderFooterContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportHeaderFooterContentQuery, ReportHeaderFooterContentQueryVariables>;
export const FindingHeaderFooterContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindingHeaderFooterContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Finding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindingHeaderFooterContentQuery, FindingHeaderFooterContentQueryVariables>;
export const UpdateCdtHeaderFooterContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCDTHeaderFooterContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTTemplateUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCDTTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCdtHeaderFooterContentMutation, UpdateCdtHeaderFooterContentMutationVariables>;
export const UpdateDocFileHeaderFooterContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDocFileHeaderFooterContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileHeaderFooterUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileHeaderFooter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headerContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footerContent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDocFileHeaderFooterContentMutation,
  UpdateDocFileHeaderFooterContentMutationVariables
>;
export const GetIdentityProviderListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIdentityProviderList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIdentityProviderList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'authMethodCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'authMethodCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakIdentityProvider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIdentityProviderListQuery, GetIdentityProviderListQueryVariables>;
export const GetIdentityProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIdentityProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'alias' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIdentityProvider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alias' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'alias' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSAMLConfig' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'singleLogoutServiceUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'singleSignOnServiceUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'forceAuthn' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSocialConfig' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'defaultScope' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIdentityProviderQuery, GetIdentityProviderQueryVariables>;
export const CreateIdentityProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createIdentityProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identityProvider' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakIdentityProviderCreate' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'socialConfig' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSocialConfigCreate' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'samlConfig' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSAMLConfigCreate' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIdentityProvider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identityProvider' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'identityProvider' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'socialConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'socialConfig' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'samlConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'samlConfig' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
                { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSAMLConfig' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'singleLogoutServiceUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'singleSignOnServiceUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'forceAuthn' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSocialConfig' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'defaultScope' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIdentityProviderMutation, CreateIdentityProviderMutationVariables>;
export const UpdateIdentityProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIdentityProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identityProvider' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakIdentityProviderUpdate' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'socialConfig' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSocialConfigUpdate' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'samlConfig' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSAMLConfigUpdate' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIdentityProvider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identityProvider' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'identityProvider' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'socialConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'socialConfig' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'samlConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'samlConfig' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
                { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSAMLConfig' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'singleLogoutServiceUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'singleSignOnServiceUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'forceAuthn' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakSocialConfig' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'defaultScope' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIdentityProviderMutation, UpdateIdentityProviderMutationVariables>;
export const GetJobArtifactByJobRunIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getJobArtifactByJobRunId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lineageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getJobArtifactByJobRunId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lineageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lineageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lineageArtifactType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lineageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'artifactType' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'lineageAssetFragment' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'lineageCodeFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'lineageAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LineageArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artifactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSetVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'lineageCodeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LineageArtifact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codeVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'code' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gitVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'commitHash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entrypoint' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDirty' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'databricksVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'urlNotebook' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relativePath' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherCodeVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstAdditionalInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondAdditionalInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdAdditionalInformation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetJobArtifactByJobRunIdQuery, GetJobArtifactByJobRunIdQueryVariables>;
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'logout' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'logout' } }] },
    },
  ],
} as unknown as DocumentNode<LogoutQuery, LogoutQueryVariables>;
export const MoveArtifactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'moveArtifacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artifactsIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetStepId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetStepIndex' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveArtifacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'iterationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'artifactsIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'artifactsIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetStepId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetStepId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetStepIndex' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetStepIndex' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveArtifactsMutation, MoveArtifactsMutationVariables>;
export const CheckWorkspaceNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkWorkspaceNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'exceptId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkWorkspaceNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'exceptId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'exceptId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckWorkspaceNameAvailabilityQuery, CheckWorkspaceNameAvailabilityQueryVariables>;
export const CheckProjectNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkProjectNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkProjectNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckProjectNameAvailabilityQuery, CheckProjectNameAvailabilityQueryVariables>;
export const CheckCdtReportNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkCDTReportNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkCDTReportNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckCdtReportNameAvailabilityQuery, CheckCdtReportNameAvailabilityQueryVariables>;
export const CheckCdtTemplateNameAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkCDTTemplateNameAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isRichText' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkCDTTemplateNameAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isRichText' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isRichText' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckCdtTemplateNameAvailabilityQuery, CheckCdtTemplateNameAvailabilityQueryVariables>;
export const GetAllOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllOrganizations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'levels' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationLevel' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllOrganizations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'levels' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'levels' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDomains' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>;
export const GetAccessibilityLevelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccessibilityLevels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'getAccessibilityLevels' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetAccessibilityLevelsQuery, GetAccessibilityLevelsQueryVariables>;
export const SetUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserRole' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setUserRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetUserRoleMutation, SetUserRoleMutationVariables>;
export const DisableUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'disableUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disableUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DisableUserMutation, DisableUserMutationVariables>;
export const EnableUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'enableUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnableUserMutation, EnableUserMutationVariables>;
export const CreateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const UpdateOrganizationDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationDescriptionMutation, UpdateOrganizationDescriptionMutationVariables>;
export const DeleteOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const CheckOrganizationEmailDomainAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkOrganizationEmailDomainAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userEmail' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkOrganizationEmailDomainAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userEmail' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userEmail' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckOrganizationEmailDomainAvailabilityQuery,
  CheckOrganizationEmailDomainAvailabilityQueryVariables
>;
export const GetRealmInformationFromEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRealmInformationFromEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRealmInformationFromEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRealmInformationFromEmailQuery, GetRealmInformationFromEmailQueryVariables>;
export const InviteTrialUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteTrialUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteTrialUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteTrialUserMutation, InviteTrialUserMutationVariables>;
export const RemoveIterationStepDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeIterationStep' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeIterationStep' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveIterationStepMutation, RemoveIterationStepMutationVariables>;
export const RemoveStepDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeStepDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeStepDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveStepDefinitionMutation, RemoveStepDefinitionMutationVariables>;
export const RenameEntityFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'renameEntityFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameEntityFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameEntityFileMutation, RenameEntityFileMutationVariables>;
export const RenameEntityMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'renameEntityMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameEntityMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameEntityMetadataMutation, RenameEntityMetadataMutationVariables>;
export const ResetOrganizationFeatureFlagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetOrganizationFeatureFlag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetOrganizationFeatureFlag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overridden' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetOrganizationFeatureFlagMutation, ResetOrganizationFeatureFlagMutationVariables>;
export const RestorePhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'restorePhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restorePhase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestorePhaseMutation, RestorePhaseMutationVariables>;
export const UpdateFindingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFinding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FindingUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingMutation, UpdateFindingMutationVariables>;
export const UpdateFindingOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFindingOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ownerId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ownerId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ownerId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingOwnerMutation, UpdateFindingOwnerMutationVariables>;
export const UpdateFindingAssigneeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFindingAssignee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assigneeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reviewerId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'assigneeId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingAssigneeMutation, UpdateFindingAssigneeMutationVariables>;
export const UpdateFindingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFindingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'FindingStatus' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingStatusMutation, UpdateFindingStatusMutationVariables>;
export const UpdateFindingSeverityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFindingSeverity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'severity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'FindingSeverity' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'severity' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'severity' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingSeverityMutation, UpdateFindingSeverityMutationVariables>;
export const UpdateFindingModelVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFindingModelVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'modelVersionId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modelVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionInformationFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingModelVersionMutation, UpdateFindingModelVersionMutationVariables>;
export const UpdateFindingReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFindingReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reportId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingReportMutation, UpdateFindingReportMutationVariables>;
export const UpdateFindingDueDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFindingDueDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dueDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFindingDueDateMutation, UpdateFindingDueDateMutationVariables>;
export const UpdateSectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseStepUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIterationStep' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSectionMutation, UpdateSectionMutationVariables>;
export const UpdateIterationStarredStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIterationStarredStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'starred' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIteration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'starred' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'starred' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIterationStarredStatusMutation, UpdateIterationStarredStatusMutationVariables>;
export const UpdateIterationStepArtifactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIterationStepArtifact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateIterationStepArtifactInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIterationStepArtifact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIterationStepArtifactMutation, UpdateIterationStepArtifactMutationVariables>;
export const UpdatePhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhaseUpdateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePhase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enforceRequirementsAsSections' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhaseMutation, UpdatePhaseMutationVariables>;
export const UpdateStepDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStepDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseStepUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStepDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateStepDefinitionMutation, UpdateStepDefinitionMutationVariables>;
export const UpdateStepPositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStepPosition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseStepUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStepDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateModel' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateStepPositionMutation, UpdateStepPositionMutationVariables>;
export const ChangeUserPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeUserPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeUserPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const GetUserActivityListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserActivityList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTargetFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityTargetTypes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTargetType' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityTypes' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityType' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'onlyMine' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserActivityList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIdList' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'activityTypes' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'activityTypes' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'activityTargetTypes' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'activityTargetTypes' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'targetFilter' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'targetFilter' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyMine' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'onlyMine' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'afterCursor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userActivities' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'afterCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'toValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAutomated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceWorkspaceVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentVecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userActivities' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginatedUserActivityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userActivity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserActivityListQuery, GetUserActivityListQueryVariables>;
export const CreateUserInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserInviteInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'inviteLinkToken' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserInviteMutation, CreateUserInviteMutationVariables>;
export const CancelUserInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelUserInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelUserInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelUserInviteMutation, CancelUserInviteMutationVariables>;
export const ResendUserInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendUserInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendUserInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResendUserInviteMutation, ResendUserInviteMutationVariables>;
export const CreateUserViewActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserViewActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userViewActivity' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserViewActivityCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserViewActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userViewActivity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userViewActivity' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserViewActivityMutation, CreateUserViewActivityMutationVariables>;
export const GetUserWorkspaceAccessibilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserWorkspaceAccessibility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'accessLevel' },
            name: { kind: 'Name', value: 'getUserWorkspaceAccessibility' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserWorkspaceAccessibilityQuery, GetUserWorkspaceAccessibilityQueryVariables>;
export const WhoAmIDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'whoAmI' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailVerificationStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preferences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userPreferences' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'onboardingStatus' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultWorkspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userPreferences' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserPreferencesOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onboardingStatus' } }],
      },
    },
  ],
} as unknown as DocumentNode<WhoAmIQuery, WhoAmIQueryVariables>;
export const GetUserWorkspaceListToInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserWorkspaceListToInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'getUserWorkspaceListToInvite' },
            name: { kind: 'Name', value: 'getUserWorkspaceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchFilter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'search' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'fields' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'name', block: false }],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'PRIVATE' },
                          { kind: 'EnumValue', value: 'SAMPLE' },
                          { kind: 'EnumValue', value: 'TUTORIAL' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserWorkspaceListToInviteQuery, GetUserWorkspaceListToInviteQueryVariables>;
export const GetAllUserWorkspacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllUserWorkspaces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserWorkspaceList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllUserWorkspacesQuery, GetAllUserWorkspacesQueryVariables>;
export const GetWorkspaceMemberListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceMemberList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserListFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceMemberList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceMemberListQuery, GetWorkspaceMemberListQueryVariables>;
export const GetWorkspaceTargetListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceTargetList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkspaceListFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserWorkspaceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'name', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceTargetListQuery, GetWorkspaceTargetListQueryVariables>;
export const GetDatasetContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userContext' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetContextQuery, GetDatasetContextQueryVariables>;
export const GetDatasetVersionContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workspace' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userContext' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionContextQuery, GetDatasetVersionContextQueryVariables>;
export const GetIterationContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIterationContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workspace' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userContext' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIterationContextQuery, GetIterationContextQueryVariables>;
export const GetModelContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userContext' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelContextQuery, GetModelContextQueryVariables>;
export const GetModelVersionContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workspace' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userContext' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionContextQuery, GetModelVersionContextQueryVariables>;
export const GetPhaseContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userContext' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseContextQuery, GetPhaseContextQueryVariables>;
export const GetProjectContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userContext' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectContextQuery, GetProjectContextQueryVariables>;
export const GetReportContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReportContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userContext' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportContextQuery, GetReportContextQueryVariables>;
export const GetFindingContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFindingContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'findingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Finding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'findingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userContext' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFindingContextQuery, GetFindingContextQueryVariables>;
export const GetWorkspaceContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userContext' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceContextQuery, GetWorkspaceContextQueryVariables>;
export const GetUserProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectListFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'createdDate', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'templateUsed' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserProjectsQuery, GetUserProjectsQueryVariables>;
export const CreateWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkspaceCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const CdtReportInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CDTReportInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modelVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CdtReportInfoQuery, CdtReportInfoQueryVariables>;
export const GetModelVersionInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionInfoQuery, GetModelVersionInfoQueryVariables>;
export const DeleteApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKeyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKeyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKeyId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const GetApiKeyListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApiKeyList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              { kind: 'ObjectField', name: { kind: 'Name', value: 'size' }, value: { kind: 'IntValue', value: '30' } },
              { kind: 'ObjectField', name: { kind: 'Name', value: 'index' }, value: { kind: 'IntValue', value: '1' } },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getApiKeyList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiKeyListQuery, GetApiKeyListQueryVariables>;
export const UpdateApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiKeyUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKeyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKeyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKeyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;
export const ChangeOldPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeOldPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeUserPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangeOldPasswordMutation, ChangeOldPasswordMutationVariables>;
export const SendEmailVerificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendEmailVerification' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sendEmailVerification' } }],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;
export const UpdateFeatureFlagAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFeatureFlagAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'enabled' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeatureFlagAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'enabled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFeatureFlagAvailabilityMutation, UpdateFeatureFlagAvailabilityMutationVariables>;
export const UpdateOrgFeatureFlagAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrgFeatureFlagAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'enabled' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationFeatureFlagAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'enabled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overridden' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrgFeatureFlagAvailabilityMutation,
  UpdateOrgFeatureFlagAvailabilityMutationVariables
>;
export const GetEpicFeatureFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEpicFeatureFlags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEpicFeatureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maturity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overrides' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ticket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'overrides' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEpicFeatureFlagsQuery, GetEpicFeatureFlagsQueryVariables>;
export const GetOrganizationFeatureFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationFeatureFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationFeatureFlags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overridden' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationFeatureFlagsQuery, GetOrganizationFeatureFlagsQueryVariables>;
export const GetFeatureFlagOrgOverridesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFeatureFlagOrgOverrides' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFeatureFlagOrgOverrides' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeatureFlagOrgOverridesQuery, GetFeatureFlagOrgOverridesQueryVariables>;
export const GetOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettings' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationDescriptionAndLevel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationDomains' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailDomains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationConfiguration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailsWhitelisted' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'organizationLicenseOptions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licenseOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSingleSignOnEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAskAIEnabled' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationDescriptionAndLevel' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'organizationDomains' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'organizationConfiguration' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'organizationLicenseOptions' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const UpdateEmailAvailabilityForOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmailAvailabilityForOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailsEnabled' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEmailAvailabilityForOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailsEnabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailsEnabled' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmailAvailabilityForOrganizationMutation,
  UpdateEmailAvailabilityForOrganizationMutationVariables
>;
export const AddEmailDomainToOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addEmailDomainToOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailDomain' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addEmailDomainToOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailDomain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailDomain' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDomains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddEmailDomainToOrganizationMutation, AddEmailDomainToOrganizationMutationVariables>;
export const SetLicenseOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setLicenseOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'licenseOptions' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationLicenseOptionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setLicenseOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'licenseOptions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'licenseOptions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSingleSignOnEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAskAIEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetLicenseOptionsMutation, SetLicenseOptionsMutationVariables>;
export const GetOrganizationUserAndInvitesListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationUserAndInvitesList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserAndInvitesFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderUserAndInvitesInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageIndexInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserAndInvitesList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userInvite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inviteLinkToken' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationUserAndInvitesListQuery, GetOrganizationUserAndInvitesListQueryVariables>;
export const ExportMacrosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'exportMacros' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportMacros' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportMacrosQuery, ExportMacrosQueryVariables>;
export const ExportProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'exportProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportProjectQuery, ExportProjectQueryVariables>;
export const UpdateBigQueryUserViewActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBigQueryUserViewActivity' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updateBigQueryUserViewActivity' } }],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBigQueryUserViewActivityMutation, UpdateBigQueryUserViewActivityMutationVariables>;
export const ExportWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'exportWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportWorkspaceQuery, ExportWorkspaceQueryVariables>;
export const CreateSuperAdminUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSuperAdminUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSuperAdminUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSuperAdminUserMutation, CreateSuperAdminUserMutationVariables>;
export const ListSuperAdminUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listSuperAdminUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listSuperAdminUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListSuperAdminUsersQuery, ListSuperAdminUsersQueryVariables>;
export const AuthenticateWithUniqueCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'authenticateWithUniqueCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'realm' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticateWithUniqueCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'realm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'realm' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUri' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthenticateWithUniqueCodeQuery, AuthenticateWithUniqueCodeQueryVariables>;
export const GetStepForRedirectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStepForRedirection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stepId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStepById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stepId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStepForRedirectionQuery, GetStepForRedirectionQueryVariables>;
export const CdtReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CDTReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modelVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionInformationFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFileLocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockoutDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fingerprint' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lockedUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CdtReportQuery, CdtReportQueryVariables>;
export const GetDatasetVersionDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetVersionDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DatasetVersionSidebar' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DatasetVersionSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSetVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceOrigin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'origins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iteration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetVersionDetailsQuery, GetDatasetVersionDetailsQueryVariables>;
export const GetDatasetDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DatasetSidebar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DatasetSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceOrigin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetDetailsQuery, GetDatasetDetailsQueryVariables>;
export const UpdateDatasetTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDatasetType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDatasetTypeMutation, UpdateDatasetTypeMutationVariables>;
export const UpdateDatasetDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDatasetDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDataset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datasetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datasetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDatasetDescriptionMutation, UpdateDatasetDescriptionMutationVariables>;
export const GetWorkspaceNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationWorkspaceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceIds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceNameQuery, GetWorkspaceNameQueryVariables>;
export const RequestWorkspaceAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestWorkspaceAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestWorkspaceAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestWorkspaceAccessMutation, RequestWorkspaceAccessMutationVariables>;
export const GetProjectDocumentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDocumentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhaseFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'index', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectDocumentationQuery, GetProjectDocumentationQueryVariables>;
export const GetFindingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFinding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Finding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFindingQuery, GetFindingQueryVariables>;
export const GetFindingDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFindingDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Finding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modelVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionInformationFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUpdatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFindingDetailsQuery, GetFindingDetailsQueryVariables>;
export const CreatePasswordRecoveryRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPasswordRecoveryRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPasswordRecoveryRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePasswordRecoveryRequestMutation, CreatePasswordRecoveryRequestMutationVariables>;
export const RemoveAssetsFromIterationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeAssetsFromIteration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artifactsIdList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeAssetsFromIteration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'iterationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'iterationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'artifactsIdList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'artifactsIdList' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveAssetsFromIterationMutation, RemoveAssetsFromIterationMutationVariables>;
export const GetModelVersionDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelVersionDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelVersionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModelVersionSidebar' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModelVersionSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'algorithmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'risk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'origins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iteration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelVersionDetailsQuery, GetModelVersionDetailsQueryVariables>;
export const GetModelDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModelSidebar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModelSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discardedCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelDetailsQuery, GetModelDetailsQueryVariables>;
export const UpdateModelTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateModelType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'model' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateModelTypeMutation, UpdateModelTypeMutationVariables>;
export const ChangeOrgDisplayNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeOrgDisplayName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeOrgDisplayName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangeOrgDisplayNameMutation, ChangeOrgDisplayNameMutationVariables>;
export const SetOrganizationSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setOrganizationSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'configuration' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationConfigInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setOrganizationSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'configuration' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'configuration' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'passwordAuthenticationEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoProvisioning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailNotificationEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dfStatisticsRowThreshold' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dfStatisticsSampleRows' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dfStatisticsMaxColumns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'everyOneCanInviteUser' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetOrganizationSettingsMutation, SetOrganizationSettingsMutationVariables>;
export const GetUserAndInvitesListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserAndInvitesList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserAndInvitesFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderUserAndInvitesInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageIndexInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserAndInvitesList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userInvite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inviteLinkToken' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserAndInvitesListQuery, GetUserAndInvitesListQueryVariables>;
export const CheckRecoverPasswordRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkRecoverPasswordRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkRecoverPasswordRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckRecoverPasswordRequestQuery, CheckRecoverPasswordRequestQueryVariables>;
export const RecoverPasswordAfterRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recoverPasswordAfterRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverPasswordAfterRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecoverPasswordAfterRequestMutation, RecoverPasswordAfterRequestMutationVariables>;
export const GetStarredIterationListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStarredIterationList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIterationList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phaseId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'starred' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'index', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '30' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'iterationListItem' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'starred' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'iterationListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Iteration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'iterationRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStarredIterationListQuery, GetStarredIterationListQueryVariables>;
export const GetPhaseSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PhaseSidebar' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhaseSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseSummaryQuery, GetPhaseSummaryQueryVariables>;
export const RequestReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'review' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'review' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'review' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Review' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Review' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Review' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestReviewMutation, RequestReviewMutationVariables>;
export const GetActiveReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getActiveReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phaseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeReview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActiveReviewQuery, GetActiveReviewQueryVariables>;
export const GetDatasetListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDatasetList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dsTypes' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatasetType' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDatasetList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dsTypes' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dsTypes' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetListItem' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'datasetListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'datasetRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDatasetListQuery, GetDatasetListQueryVariables>;
export const FindingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Findings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FindingFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FindingQueryType' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Findings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owner' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modelVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionInformationFields' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindingsQuery, FindingsQueryVariables>;
export const GetModelListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelType' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionStatus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersionStatus' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'algorithmNames' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getModelList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'types' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'modelVersionStatus' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'modelVersionStatus' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'algorithmNames' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'algorithmNames' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelListItem' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'experimentationCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stagingCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retiredCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelListQuery, GetModelListQueryVariables>;
export const GetPhaseListForProjectOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhaseListForProjectOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhaseList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'phaseListItem' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phaseListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Phase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'phaseRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhaseListForProjectOverviewQuery, GetPhaseListForProjectOverviewQueryVariables>;
export const GetProjectOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phasesCounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ongoing' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'toDo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectSidebar' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSidebar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phasesCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastUpdatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectOverviewQuery, GetProjectOverviewQueryVariables>;
export const CdtReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CDTReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReportQueryType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CDTReportFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CDTReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modelVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'modelVersionInformationFields' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inventoryReference' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'modelVersionInformationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'versionNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CdtReportsQuery, CdtReportsQueryVariables>;
export const GetProjectSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectSettingsQuery, GetProjectSettingsQueryVariables>;
export const UpdateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const CreateUserFromInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserFromInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserCreateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserFromInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserFromInviteMutation, CreateUserFromInviteMutationVariables>;
export const GetUserInviteWithAuthInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserInviteWithAuthInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inviteToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserInviteWithAuthInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inviteToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inviteToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'passwordAuthenticationEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userInvite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitingUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserInviteWithAuthInfoQuery, GetUserInviteWithAuthInfoQueryVariables>;
export const CheckEmailVerificationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkEmailVerificationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailVerificationToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkEmailVerificationToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailVerificationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailVerificationToken' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckEmailVerificationTokenMutation, CheckEmailVerificationTokenMutationVariables>;
export const GetWorkspaceUserListForInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceUserListForInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceUserList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeDisabledUsers' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceUserListForInviteQuery, GetWorkspaceUserListForInviteQueryVariables>;
export const GetWorkspaceUserListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceUserList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserListFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceUserList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accessibility' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workspace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceUserListQuery, GetWorkspaceUserListQueryVariables>;
export const RemoveUserFromWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUserFromWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveUserFromWorkspaceMutation, RemoveUserFromWorkspaceMutationVariables>;
export const SetUserWorkspaceAccessibilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setUserWorkspaceAccessibility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accessibility' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessibilityLevel' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setUserWorkspaceAccessibility' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessibility' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accessibility' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetUserWorkspaceAccessibilityMutation, SetUserWorkspaceAccessibilityMutationVariables>;
export const GetProjectListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contributors' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contributors' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'projectListItem' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'pageIndexFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phasesCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ongoing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toDo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'projectListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'projectRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageIndexFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectListQuery, GetProjectListQueryVariables>;
export const GetProjectSelectorListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectSelectorList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectListFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'StringValue', value: 'name', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectSelectorListQuery, GetProjectSelectorListQueryVariables>;
export const GetWorkspaceSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceSettingsQuery, GetWorkspaceSettingsQueryVariables>;
export const UpdateWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkspaceUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const GetUserDefaultWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserDefaultWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'VecticeId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkspaceCard' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Workspace' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeDisabledUsers' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDefaultWorkspaceQuery, GetUserDefaultWorkspaceQueryVariables>;
export const GetUserWorkspaceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserWorkspaceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkspaceType' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupFilter' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserWorkspaceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'advancedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'advancedFilters' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchFilter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'search' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'fields' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'name', block: false }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkspaceGrid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Workspace' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeDisabledUsers' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'userFields' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accessibility' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceGrid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginatedWorkspaceResponseOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vecticeId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkspaceCard' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserWorkspaceListQuery, GetUserWorkspaceListQueryVariables>;
