import { graphql } from '../../gql';

export const GET_WORKSPACE_MEMBER_LIST = graphql(`
  query getWorkspaceMemberList($workspaceId: VecticeId!, $order: ListOrderInput, $filters: UserListFiltersInput) {
    getWorkspaceMemberList(workspaceId: $workspaceId, order: $order, filters: $filters) {
      items {
        id
        role
        name
        email
        ...userFields
      }
    }
  }
`);

export const GET_USER_WORKSPACE_TARGET_LIST = graphql(`
  query getWorkspaceTargetList($filters: WorkspaceListFiltersInput!) {
    getUserWorkspaceList(page: { size: 10 }, filters: $filters, order: { field: "name", direction: ASC }) {
      items {
        vecticeId
        name
        type
      }
    }
  }
`);
