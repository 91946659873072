import { useLazyQuery, useQuery } from '@apollo/client';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';

import type { AssetSelectorProps } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { ReactComponent as ModelIcon } from '../../assets/icons/specials/ic-model.svg';
import { Model, ModelVersion } from '../../gql/graphql';
import { MODEL_VERSION } from '../../graphql/queries';
import { buildOrder } from '../../graphql/utils';
import {
  Breadcrumb,
  Breadcrumbs,
  Button,
  Dropdown,
  FlexContainer,
  Input,
  MenuItem,
  Skeleton,
  Typography,
  WithLoading,
} from '../../ui';
import { MenuWithSearch } from '../../ui-presets';
import { getVecticeResourceTypeIcon, isModelId, isModelVersionId, isNil } from '../../utils';

import { ModelVersionPicker } from './ModelVersionPicker';
import { MODELS, MODEL_VERSIONS } from './queries';

import styles from './AssetSelector.module.scss';

type SelectedModelVersion = Pick<ModelVersion, 'vecticeId' | 'versionNumber'> & {
  model: Pick<Model, 'vecticeId' | 'name'>;
};

const DEBOUNCE_TIMEOUT = 600;

export const ModelVersionSelector = ({
  assetType,
  canClearAsset = true,
  disabled = false,
  dropdownPlacement = 'bottomLeft',
  fullWidth,
  projectId,
  selectedAssetId,
  showInput = true,
  triggerClassName,
  unavailableAssetIds,
  onSelect,
}: AssetSelectorProps) => {
  const [IDInputError, setIDInputError] = useState<string>();
  const [IDInputLoading, setIDInputLoading] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<SelectedModelVersion>();
  const [search, setSearch] = useState('');
  const [_versionId, setVersionId] = useState<string | undefined>(undefined);

  const [fetchDefaultVersion, { loading: loadingDefaultVersion }] = useLazyQuery(MODEL_VERSIONS, {
    onError: () => setIDInputError($t({ id: 'ModelVersionSelector.notFound', defaultMessage: 'Model not found' })),
  });
  const [fetchSelectedVersion, { loading: loadingSelectedVersion }] = useLazyQuery(MODEL_VERSION, {
    onError: () => setIDInputError($t({ id: 'ModelVersionSelector.notFound', defaultMessage: 'Model not found' })),
  });
  const loadingVersion = loadingDefaultVersion || loadingSelectedVersion;

  const fetchModel = async (id: string) => {
    const { data } = await fetchDefaultVersion({
      variables: {
        modelId: id,
        order: buildOrder([{ field: 'isStarred' }, { field: 'createdDate' }]),
      },
    });
    if (data?.getModelVersionsList.items.length) {
      const version = data.getModelVersionsList.items[0];
      if (projectId && version && projectId !== version.model.project.vecticeId) {
        setIDInputError(
          $t({ id: 'ModelVersionSelector.unavailable', defaultMessage: 'This model cannot be selected' }),
        );
        return;
      }
      onSelect(version.vecticeId);
      setIDInputError(undefined);
      setSelectedVersion(version);
    }
  };

  const fetchVersion = async (id: string, onSelectCb?: (id: string) => void) => {
    const { data } = await fetchSelectedVersion({ variables: { modelVersionId: id } });
    if (data?.getModelVersion) {
      if (projectId && projectId !== data.getModelVersion.model.project.vecticeId) {
        setIDInputError(
          $t({ id: 'ModelVersionSelector.unavailable', defaultMessage: 'This model cannot be selected' }),
        );
        return;
      }
      onSelectCb?.(id);
      setIDInputError(undefined);
      setSelectedVersion(data.getModelVersion);
    }
  };

  useEffect(() => {
    const fetchSelectedModel = (id: string) => fetchVersion(id);
    if (selectedAssetId && !selectedVersion) {
      fetchSelectedModel(selectedAssetId);
    } else if (isNil(selectedAssetId)) setSelectedVersion(undefined);
  }, [selectedAssetId]);

  const onSelectVersion = async (id: string) => {
    await fetchVersion(id);
    onSelect(id);
    setSearch('');
  };

  const { data: modelData, loading: loadingModels } = useQuery(MODELS, {
    skip: !projectId,
    variables: { projectId: projectId!, search },
  });
  const options = modelData?.getModelList.items;

  const clearAsset = () => {
    setSearch('');
    onSelect(null);
    setIDInputError(undefined);
    setSelectedVersion(undefined);
  };

  const handleIDInput = async (value: string) => {
    setIDInputError(undefined);
    if (!value) return;
    if (isModelVersionId(value)) {
      setIDInputLoading(true);
      await fetchVersion(value, onSelect);
    } else if (isModelId(value)) {
      setIDInputLoading(true);
      await fetchModel(value);
    } else {
      setIDInputError(
        $t({ id: 'ModelVersionSelector.invalidFormat', defaultMessage: 'Expected format: MDL-XXX or MDV-XXX' }),
      );
    }
    setIDInputLoading(false);
  };

  const handleSearch = (value: string) => {
    if (isModelVersionId(value)) {
      setVersionId(value.toUpperCase());
      setSearch('');
    } else {
      setVersionId(undefined);
      if (isModelId(value)) {
        setSearch(value.toUpperCase());
      } else {
        setSearch(value);
      }
    }
  };
  const debouncedSearch = debounce(handleSearch, DEBOUNCE_TIMEOUT);

  const overlay = (
    <MenuWithSearch isEmpty={!options?.length} loading={loadingModels} search={search} onSearch={debouncedSearch}>
      {options?.map((model) => (
        <MenuItem key={`option-${model.vecticeId}`} leftIcon={ModelIcon} onClick={() => fetchModel(model.vecticeId)}>
          <FlexContainer>
            <Typography variant="callout">{model.name}</Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  return (
    <WithLoading
      loading={!IDInputLoading && loadingVersion}
      skeleton={<Skeleton.Paragraph dark height="36px" width="300px" />}
    >
      {selectedVersion ? (
        <FlexContainer gap={18}>
          <Breadcrumbs>
            <Breadcrumb
              aria-label={selectedVersion.model.name}
              color="primary"
              icon={getVecticeResourceTypeIcon(assetType)}
              iconSize={20}
              inert
              weight="regular"
            >
              {selectedVersion.model.name}
            </Breadcrumb>
            <Breadcrumb color="primary" inert>
              <ModelVersionPicker
                dropdownClassName={styles.menu}
                dropdownPlacement="bottomRight"
                modelId={selectedVersion.model.vecticeId}
                modelVersionId={selectedVersion.vecticeId}
                modelVersionNumber={selectedVersion.versionNumber}
                unavailableAssetIds={unavailableAssetIds}
                showStarredFirst
                triggerVariant="callout"
                onSelect={onSelectVersion}
              />
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      ) : (
        <FlexContainer align="baseline" className={cn(styles.selector, { [styles.fullWidth]: fullWidth })} gap={16}>
          <Dropdown
            overlay={overlay}
            trigger={['click']}
            placement={dropdownPlacement}
            onVisibleChange={(visible) => {
              if (!visible) setSearch('');
            }}
          >
            <Button
              aria-label="Select asset"
              className={cn(styles.trigger, triggerClassName, { [styles.disabled]: disabled })}
              disabled={disabled}
              variant="white"
            >
              <Typography ellipsis color="tertiary">
                {$t({ id: 'assetSelector.selectAModel', defaultMessage: 'Select a model' })}
              </Typography>
            </Button>
          </Dropdown>
          {showInput && (
            <>
              <Typography
                align="center"
                className={styles.separator}
                color="disabled"
                paragraph
                transform="lowercase"
                variant="callout"
                weight="semi-bold"
              >
                {$t({ id: 'global.or', defaultMessage: 'Or' })}
              </Typography>
              <Input
                aria-label="Model ID"
                className={styles.input}
                debounceDelay={DEBOUNCE_TIMEOUT}
                disabled={disabled}
                error={IDInputError}
                loading={IDInputLoading}
                placeholder={$t({ id: 'assetSelector.enterModelID', defaultMessage: 'Enter a model ID' })}
                onDebouncedChange={handleIDInput}
              />
            </>
          )}
        </FlexContainer>
      )}
    </WithLoading>
  );
};
