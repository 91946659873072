import cn from 'classnames';
import React, { useMemo, useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import {
  getFindingSeverityColor,
  getFindingSeverityDisplayName,
  getFindingSeverityOptions,
} from '../../entities/utils';
import { FindingSeverity as FindingSeverityEnum } from '../../gql/graphql';
import { Badge, Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';

import styles from './RevampedAssetStatus.module.scss';

interface FindingSeverityProps extends ButtonProps {
  severity: FindingSeverityEnum;
  handleSeverityUpdate: (severity: FindingSeverityEnum) => void;
}

export const FindingSeverity = ({ className, severity, handleSeverityUpdate, ...props }: FindingSeverityProps) => {
  const { current: severityOptions } = useRef(getFindingSeverityOptions());

  const menu = useMemo(
    () => (
      <Menu aria-label="Issue Severity Menu">
        {severityOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={severity === option.value}
            onClick={() => handleSeverityUpdate(option.value)}
          >
            <Badge size="sm" color={getFindingSeverityColor(option.value)}>
              {getFindingSeverityDisplayName(option.value)}
            </Badge>
          </MenuItem>
        ))}
      </Menu>
    ),
    [severityOptions, severity, handleSeverityUpdate],
  );

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        aria-label="Issue severity select"
        className={cn(styles[getFindingSeverityColor(severity)], className)}
        {...props}
      >
        {getFindingSeverityDisplayName(severity)}
      </DropdownTrigger>
    </Dropdown>
  );
};
