import cn from 'classnames';
import React, { useMemo, useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import {
  getModelVersionApprovalColor,
  getModelVersionApprovalDisplayName,
  getModelVersionApprovalOptions,
} from '../../entities/utils';
import { ModelVersionApproval as ModelVersionApprovalEnum } from '../../gql/graphql';
import { Badge, Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';

import styles from './RevampedAssetStatus.module.scss';

interface ModelVersionApprovalProps extends ButtonProps {
  approval?: ModelVersionApprovalEnum;
  handleApprovalUpdate: (approval: ModelVersionApprovalEnum) => void;
}

export const ModelVersionApproval = ({
  approval,
  handleApprovalUpdate,
  className,
  ...props
}: ModelVersionApprovalProps) => {
  const { current: approvalOptions } = useRef(getModelVersionApprovalOptions());

  const menu = useMemo(
    () => (
      <Menu aria-label="Model version approval menu">
        {approvalOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={approval === option.value}
            onClick={() => handleApprovalUpdate(option.value)}
          >
            <Badge size="sm" color={getModelVersionApprovalColor(option.value)}>
              {option.label}
            </Badge>
          </MenuItem>
        ))}
      </Menu>
    ),
    [approvalOptions, approval, handleApprovalUpdate],
  );

  if (!approval) {
    return null;
  }

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        aria-label="Model version approval select"
        className={cn(styles[getModelVersionApprovalColor(approval)], className)}
        {...props}
      >
        {getModelVersionApprovalDisplayName(approval)}
      </DropdownTrigger>
    </Dropdown>
  );
};
