import { useCallback, useMemo } from 'react';

import { useQueryParam } from '../../hooks';
import { SearchParameter } from '../../routes';

import {
  algorithmField,
  datasetSourceOriginField,
  starredField,
  createdDateField,
  metricsField,
  propertiesField,
  activityTypeField,
  contributorField,
  createdByField,
  datasetTypeField,
  iterationStatusField,
  inventoryReferenceField,
  modelHasVersionInField,
  modelTypeField,
  modelVersionStatusField,
  modelVersionApprovalField,
  modelVersionRiskField,
  ownerField,
  projectStatusField,
  workspaceTypeField,
} from './plugins';

const parseAsBoolean = [starredField];
const parseAsObject = [createdDateField, metricsField, propertiesField];
const parseAsArray = [
  activityTypeField,
  algorithmField,
  contributorField,
  createdByField,
  datasetTypeField,
  datasetSourceOriginField,
  iterationStatusField,
  inventoryReferenceField,
  modelHasVersionInField,
  modelTypeField,
  modelVersionStatusField,
  modelVersionApprovalField,
  modelVersionRiskField,
  ownerField,
  projectStatusField,
  workspaceTypeField,
];

export const encodeObjectFilter = (field: string, filter: any) => {
  switch (field) {
    // dates
    case createdDateField: {
      return encodeURIComponent(JSON.stringify(filter));
    }
    // metrics & properties
    case metricsField:
    case propertiesField: {
      if (filter.some((f: any) => !!f.value)) {
        // encode only filters with a non empty value
        return encodeURIComponent(JSON.stringify(filter.filter((f: any) => !!f.value)));
      }
      return undefined;
    }
    default:
      return encodeURIComponent(filter);
  }
};

export const encodeFilters = (filters: Record<string, any>) => {
  let encoded = '';
  Object.entries(filters).forEach(([field, value]) => {
    if (!!field && !!value) {
      let encodedFilter: string | undefined = encodeURIComponent(value);

      if (parseAsArray.includes(field)) {
        encodedFilter = value.map(encodeURIComponent);
      } else if (parseAsObject.includes(field)) {
        encodedFilter = encodeObjectFilter(field, value);
      }

      if (encodedFilter !== undefined) {
        encoded += `${field}:${encodedFilter} `;
      }
    }
  });
  return encoded.trim();
};

export const decodeFilters = (query: string) => {
  const arr = query.split(' ');

  const filters: Record<string, any> = {};

  arr.forEach((filter) => {
    const [field, value] = filter.split(':');
    if (!!field && value !== undefined) {
      if (parseAsBoolean.includes(field)) {
        filters[field] = value === 'true';
      } else if (parseAsObject.includes(field)) {
        const decodedJson = decodeURIComponent(value);
        filters[field] = JSON.parse(decodedJson);
      } else if (parseAsArray.includes(field)) {
        filters[field] = value.split(',').map(decodeURIComponent);
      } else {
        filters[field] = decodeURIComponent(value);
      }
    }
  });

  return filters;
};

export const useUrlFilters = () => {
  const [filtersParam, setFiltersParam] = useQueryParam(SearchParameter.FILTERS);

  const filters = useMemo(() => decodeFilters(filtersParam || ''), [filtersParam]);

  const setFilters = useCallback(
    (updatedFilters: Record<string, any>) => {
      setFiltersParam(encodeFilters(updatedFilters));
    },
    [setFiltersParam],
  );

  return [filters, setFilters] as [Record<string, any>, (filters: Record<string, any>) => void];
};
