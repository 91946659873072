import { useMutation, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';

import { ReactComponent as EditIcon } from '../assets/icons/interface/ic-edit.svg';
import { FormatProject } from '../components_v3/asset-display';
import { AssetSelector } from '../components_v3/asset-selector';
import { commonDatePickerConfig } from '../components_v3/filters/ui';
import { FindingSeverity } from '../components_v3/status/FindingSeverity';
import { FindingStatus } from '../components_v3/status/FindingStatus';
import { FindingUser } from '../components_v3/status/FindingUser';
import { HintTooltip } from '../components_v3/tooltip';
import { useAuthentication } from '../context';
import { getWorkspaceIcon } from '../entities/utils';
import {
  Finding,
  FindingSeverity as FindingSeverityEnum,
  FindingStatus as FindingStatusEnum,
  Project,
  User,
  Workspace,
} from '../gql/graphql';
import { UserIdentity } from '../graphql/fragments';
import { CREATE_FINDING } from '../graphql/queries/createFinding.mutation';
import { useWithFeatureFlags } from '../guards';
import { useVecticeForm } from '../hooks';
import {
  AssetLink,
  Breadcrumb,
  Breadcrumbs,
  Button,
  FlexContainer,
  FormItemContainer,
  Grid,
  Input,
  ModalForm,
  Picker,
  Required,
  Summary,
  TextArea,
  Typography,
  message,
} from '../ui';
import {
  VecticeResourceType,
  defaultDescriptionFieldConfiguration,
  defaultNameFieldConfiguration,
  getVecticeResourceTypeLabel,
  isDefined,
  isNil,
} from '../utils';

import { CDT_REPORT_INFO, MODEL_VERSION_INFO } from './query';

import styles from './CreateFindingModal.module.scss';

export interface CreateFindingModalProps {
  modelVersionId?: string;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  reportId?: string;
  workspace?: Pick<Workspace, 'vecticeId' | 'name' | 'type'> | null;
  onClose: () => void;
  onSuccess?: (finding: Pick<Finding, 'id' | 'name'>) => void;
}

export interface FormData {
  dueDate: string;
  initialText: string;
  modelVersionId: string | null;
  name: string;
  owner: Pick<User, 'id'> & UserIdentity;
  projectId: string | null;
  reportId: string | null;
  reviewer?: Pick<User, 'id'> & UserIdentity;
  severity: FindingSeverityEnum;
  status: FindingStatusEnum;
  workspaceId: string | null;
}

export const CreateFindingModal = ({
  modelVersionId,
  project,
  reportId,
  workspace,
  onClose,
  onSuccess,
}: CreateFindingModalProps) => {
  const { user: connectedUser } = useAuthentication();

  const hideReportInput = modelVersionId !== undefined && reportId === undefined;

  const [editProjectContext, setEditProjectContext] = useState(false);
  const [inventoryReference, setInventoryReference] = useState<string | undefined>(undefined);
  const { allowed: withDescription } = useWithFeatureFlags({ featureFlag: 'description-on-create' });

  const { control, formState, setValue, preSubmit, registerWithErrors, watch } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      modelVersionId,
      owner: connectedUser,
      projectId: project?.vecticeId,
      reportId,
      reviewer: undefined,
      severity: FindingSeverityEnum.Undefined,
      status: FindingStatusEnum.NotStarted,
      workspaceId: workspace?.vecticeId,
    },
  });
  const { hasErrors, isSubmitting, errors } = formState;

  const workspaceIdInput = watch('workspaceId');
  const projectIdInput = watch('projectId');
  const reportIdInput = watch('reportId');
  const modelVersionIdInput = watch('modelVersionId');

  useQuery(CDT_REPORT_INFO, {
    skip: !reportIdInput,
    variables: {
      id: Number(reportIdInput),
    },
    onCompleted: (data) => {
      const info = data.CDTReport.modelVersion;
      if (isNil(modelVersionIdInput) && info?.vecticeId) {
        setValue('modelVersionId', info.vecticeId);
        setInventoryReference(info?.inventoryReference ?? 'N/A');
      }
    },
  });

  useQuery(MODEL_VERSION_INFO, {
    skip: !modelVersionIdInput,
    variables: {
      modelVersionId: modelVersionIdInput!,
    },
    onCompleted: (data) => {
      const info = data.getModelVersion.inventoryReference;
      if (inventoryReference === undefined) setInventoryReference(info ?? 'N/A');
    },
  });

  const [createFinding] = useMutation(CREATE_FINDING, {
    refetchQueries: ['Findings'],
    update: () => onClose(),
    onCompleted: ({ createFinding: { id, name } }) => onSuccess?.({ id, name }),
    onError: (error) => message.error(error.message),
  });

  const handleCleanWorkspace = () => {
    setValue('projectId', null);
    handleCleanProject();
  };

  const handleCleanProject = () => {
    setValue('modelVersionId', null);
    setValue('reportId', null);
    setInventoryReference(undefined);
  };

  const handleSubmit = useCallback(
    async ({
      projectId: projectIdForm,
      modelVersionId,
      reportId,
      name,
      status,
      owner,
      reviewer,
      severity,
      dueDate,
      initialText,
    }: FormData) => {
      await createFinding({
        variables: {
          projectId: projectIdForm!,
          data: {
            name,
            status,
            ownerId: parseInt(owner.id),
            reviewerId: reviewer ? parseInt(reviewer.id) : undefined,
            severity,
            dueDate,
            modelVersionId,
            initialText,
            reportId: reportId ? parseInt(reportId) : undefined,
          },
        },
      });
    },
    [createFinding],
  );

  return (
    <ModalForm
      disabled={hasErrors}
      isSubmitting={isSubmitting}
      title={$t({ id: 'Issue.modal.title', defaultMessage: 'Create issue' })}
      submitLabel={$t({ id: 'Issue.modal.submit', defaultMessage: 'Create' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
    >
      <Input
        {...registerWithErrors('name', {
          ...defaultNameFieldConfiguration(),
          required: $t({
            id: 'form.issueCreate.fieldRequired',
            defaultMessage: 'Issue name is required',
          }),
        })}
        afterLabel={<Required />}
        autoFocus
        gutterBottom
        label={$t({
          id: 'modal.resource.name',
          defaultMessage: '{resource} name',
          values: {
            resource: getVecticeResourceTypeLabel(VecticeResourceType.FINDING),
          },
        })}
        placeholder={getVecticeResourceTypeLabel(VecticeResourceType.FINDING)}
      />
      {withDescription && (
        <TextArea
          {...registerWithErrors('initialText', defaultDescriptionFieldConfiguration())}
          gutterBottom
          label={$t({ id: 'modal.resource.description', defaultMessage: 'Description' })}
          hint={$t({ id: 'modal.resource.optional', defaultMessage: 'Optional' })}
          placeholder={$t({
            id: 'issue.description.placeholder',
            defaultMessage: 'Describe the issue',
          })}
        />
      )}
      {!editProjectContext && isDefined(workspace) && isDefined(project) ? (
        <FormItemContainer
          gutterBottom
          label={$t({
            id: 'form.issueCreate.project.label',
            defaultMessage: 'Project',
          })}
        >
          <FlexContainer gap={24}>
            <Breadcrumbs className={styles.breadcrumbs}>
              <Breadcrumb inert>
                <AssetLink color="inherit" icon={getWorkspaceIcon(workspace.type, true)} name={workspace.name} />
              </Breadcrumb>
              <Breadcrumb className={styles.labelComponent} inert>
                <FormatProject name={project.name} />
              </Breadcrumb>
            </Breadcrumbs>
            <Button
              aria-label={$t({ id: 'form.issueCreate.editContext', defaultMessage: 'Edit context' })}
              color="gray"
              leftIcon={EditIcon}
              size="xs"
              variant="phantom"
              onClick={() => setEditProjectContext(true)}
            />
          </FlexContainer>
        </FormItemContainer>
      ) : (
        <>
          <Controller
            control={control}
            name="workspaceId"
            rules={{
              required: $t({
                id: 'form.issueCreate.workspace.required',
                defaultMessage: 'The Workspace is required',
              }),
            }}
            render={({ field: { value, onChange } }) => (
              <FormItemContainer
                label={$t({
                  id: 'form.issueCreate.workspaceLabel',
                  defaultMessage: 'Workspace',
                })}
                afterLabel={<Required />}
                error={errors.workspaceId?.message}
                gutterBottom
              >
                <AssetSelector
                  assetType={VecticeResourceType.WORKSPACE}
                  workspaceId={workspace?.vecticeId}
                  selectedAssetId={value}
                  onSelect={(selectedId) => {
                    if (isNil(selectedId)) handleCleanWorkspace();
                    onChange(selectedId);
                  }}
                />
              </FormItemContainer>
            )}
          />
          <Controller
            control={control}
            name="projectId"
            rules={{
              required: $t({
                id: 'form.issueCreate.project.required',
                defaultMessage: 'The Project is required',
              }),
            }}
            render={({ field: { value, onChange } }) => (
              <FormItemContainer
                label={$t({
                  id: 'form.issueCreate.project.label',
                  defaultMessage: 'Project',
                })}
                afterLabel={<Required />}
                error={errors.projectId?.message}
                gutterBottom
              >
                <AssetSelector
                  assetType={VecticeResourceType.PROJECT}
                  disabled={!workspaceIdInput}
                  workspaceId={workspaceIdInput ?? undefined}
                  selectedAssetId={value}
                  onSelect={(selectedId) => {
                    onChange(selectedId);
                    if (isNil(selectedId)) {
                      handleCleanProject();
                    }
                  }}
                />
              </FormItemContainer>
            )}
          />
        </>
      )}
      {!hideReportInput && (
        <Controller
          control={control}
          name="reportId"
          render={({ field: { value, onChange } }) => (
            <FormItemContainer
              label={$t({
                id: 'form.issueCreate.cdtReport.label',
                defaultMessage: 'Report',
              })}
              hint={$t({ id: 'modal.issueCreate.optional', defaultMessage: 'Optional' })}
              gutterBottom
            >
              <AssetSelector
                assetType={VecticeResourceType.CDT_REPORT}
                disabled={!workspaceIdInput || !projectIdInput}
                projectId={projectIdInput ?? undefined}
                selectedAssetId={value}
                onSelect={(selectedId) => {
                  if (isNil(selectedId)) setInventoryReference(undefined);
                  onChange(selectedId);
                }}
              />
            </FormItemContainer>
          )}
        />
      )}
      <Controller
        control={control}
        name="modelVersionId"
        render={({ field: { value, onChange } }) => (
          <FormItemContainer
            label={$t({
              id: 'form.issueCreate.modelVersion.label',
              defaultMessage: 'Model version',
            })}
            hint={$t({ id: 'modal.issueCreate.optional', defaultMessage: 'Optional' })}
            gutterBottom
          >
            <AssetSelector
              assetType={VecticeResourceType.MODEL_VERSION}
              disabled={!workspaceIdInput || !projectIdInput}
              projectId={projectIdInput ?? undefined}
              selectedAssetId={value}
              onSelect={(selectedId) => {
                if (isNil(selectedId)) setInventoryReference(undefined);
                onChange(selectedId);
              }}
            />
          </FormItemContainer>
        )}
      />
      {inventoryReference && (
        <FormItemContainer
          label={$t({
            id: 'form.issueCreate.modelVersion.inventory.label',
            defaultMessage: 'Model inventory ID',
          })}
        >
          <Typography paragraph>{inventoryReference}</Typography>
        </FormItemContainer>
      )}
      <Summary
        className={styles.summary}
        withBackground={false}
        detailsClassName={styles.summary_details}
        summary={
          <FlexContainer>
            <Typography component="h3" color="primary" variant="body" weight="semi-bold">
              {$t({
                id: 'issue.modal.configuration.summary.moreDetails.label',
                defaultMessage: 'More details',
              })}
            </Typography>
            <HintTooltip
              hint={$t({
                id: 'issue.modal.configuration.summary.details.help',
                defaultMessage:
                  'Provide detailed information about the issue, including its due date, status, owner, and assignee. This information can be edited later as needed.',
              })}
            />
          </FlexContainer>
        }
      >
        <Grid columns={2} gap={24}>
          <Controller
            control={control}
            name="dueDate"
            render={({ field: { value, onChange } }) => (
              <FormItemContainer
                label={$t({
                  id: 'form.issueCreate.dueDate',
                  defaultMessage: 'Due date',
                })}
                className={styles.dueDate}
                hint={$t({ id: 'modal.issueCreate.optional', defaultMessage: 'Optional' })}
              >
                <Picker<Dayjs>
                  {...commonDatePickerConfig}
                  disabledDate={(date) => date.isBefore(dayjs())}
                  value={value ? dayjs(value) : null}
                  onSelect={onChange}
                  onChange={onChange}
                />
              </FormItemContainer>
            )}
          />
          <Controller
            control={control}
            name="severity"
            render={({ field: { value, onChange } }) => (
              <FormItemContainer
                hint={$t({ id: 'modal.issueCreate.optional', defaultMessage: 'Optional' })}
                label={$t({
                  id: 'form.issueCreate.severity.Label',
                  defaultMessage: 'Issue severity',
                })}
              >
                <FindingSeverity severity={value} handleSeverityUpdate={onChange} />
              </FormItemContainer>
            )}
          />
          <Controller
            control={control}
            name="status"
            render={({ field: { value, onChange } }) => (
              <FormItemContainer
                label={$t({
                  id: 'form.issueCreate.status.label',
                  defaultMessage: 'Issue status',
                })}
              >
                <FindingStatus
                  options={[
                    FindingStatusEnum.NotStarted,
                    FindingStatusEnum.InProgress,
                    FindingStatusEnum.RemediationPending,
                  ]}
                  status={value}
                  handleStatusUpdate={onChange}
                />
              </FormItemContainer>
            )}
          />

          <Controller
            control={control}
            name="owner"
            render={({ field: { value, onChange } }) => (
              <FormItemContainer
                label={$t({
                  id: 'form.issueCreate.ownerLabel',
                  defaultMessage: 'Owner',
                })}
              >
                <FindingUser user={value} handleUserChange={onChange} workspaceId={workspaceIdInput} />
              </FormItemContainer>
            )}
          />

          <Controller
            control={control}
            name="reviewer"
            render={({ field: { value, onChange } }) => (
              <FormItemContainer
                hint={$t({ id: 'modal.issueCreate.optional', defaultMessage: 'Optional' })}
                label={$t({
                  id: 'form.issueCreate.reviewerLabel',
                  defaultMessage: 'Assignee',
                })}
              >
                <FindingUser allowUnassigned user={value} handleUserChange={onChange} workspaceId={workspaceIdInput} />
              </FormItemContainer>
            )}
          />
        </Grid>
      </Summary>
    </ModalForm>
  );
};
