import React from 'react';

import { getModelVersionApprovalColor, getModelVersionApprovalDisplayName } from '../../entities/utils';
import { ModelVersionApproval } from '../../gql/graphql';
import { Badge, BadgeProps } from '../../ui';

interface ModelVersionApprovalBadgeProps extends BadgeProps {
  approval?: ModelVersionApproval | null;
  contrast?: boolean;
}

export const ModelVersionApprovalBadge = ({
  size = 'sm',
  contrast = false,
  approval,
  ...props
}: ModelVersionApprovalBadgeProps) => (
  <Badge size={size} contrast={contrast} color={getModelVersionApprovalColor(approval)} {...props}>
    {getModelVersionApprovalDisplayName(approval)}
  </Badge>
);
