import { useLazyQuery, useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import type { AssetSelectorProps, SelectedParent } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { ReactComponent as PhaseIcon } from '../../assets/icons/specials/ic-phase.svg';
import { Iteration, OrderDirection, Phase } from '../../gql/graphql';
import { GET_ITERATION_BY_ID, GET_PHASE_LIST } from '../../graphql/queries';
import {
  Breadcrumb,
  Breadcrumbs,
  Button,
  Divider,
  Dropdown,
  EmptySelect,
  FilterSearch,
  FlexContainer,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  WithLoading,
} from '../../ui';
import { getVecticeResourceTypeIcon } from '../../utils';

import { IterationPicker } from './IterationPicker';
import { ITERATIONS } from './queries';

import styles from './AssetSelector.module.scss';

type SelectedIteration = Pick<Iteration, 'vecticeId' | 'index' | 'name'> & {
  phase: Pick<Phase, 'vecticeId' | 'name'>;
};

export const IterationSelector = ({
  assetType,
  canClearAsset = true,
  dropdownPlacement = 'bottomRight',
  projectId,
  phaseId,
  selectedAssetId,
  triggerClassName,
  unavailableAssetIds,
  onSelect,
}: AssetSelectorProps) => {
  const [selectedVersion, setSelectedVersion] = useState<SelectedIteration>();
  const [search, setSearch] = useState<undefined | string>(undefined);

  const [fetchDefaultVersion, { loading: loadingDefaultVersion }] = useLazyQuery(ITERATIONS);
  const [fetchSelectedVersion, { loading: loadingSelectedVersion }] = useLazyQuery(GET_ITERATION_BY_ID);
  const loadingVersion = loadingDefaultVersion || loadingSelectedVersion;

  const fetchVersion = async (id: string) => {
    const { data } = await fetchSelectedVersion({ variables: { id } });
    if (data?.getIterationById) {
      setSelectedVersion(data.getIterationById);
    }
  };

  const { data: phaseData, loading: loadingPhases } = useQuery(GET_PHASE_LIST, {
    skip: !projectId,
    variables: {
      filters: {
        search,
        parentId: projectId!,
        withIterations: true,
      },
      order: {
        field: 'index',
        direction: OrderDirection.Asc,
      },
      page: {
        size: 100,
      },
    },
  });
  const options = phaseData?.getPhaseList.items;

  useEffect(() => {
    if (search !== undefined) return;
    const fetchSelectPhase = (id: string) => fetchVersion(id);
    if (selectedAssetId === undefined && !loadingPhases) {
      const phaseInContext = options?.find((option) => option.vecticeId === phaseId);
      if (phaseInContext) onSelectPhase(phaseInContext);
      else setSelectedVersion(undefined);
    }

    if (selectedAssetId === null) setSelectedVersion(undefined);

    if (selectedAssetId && !selectedVersion) {
      fetchSelectPhase(selectedAssetId);
    }
  }, [selectedAssetId, phaseId, loadingPhases, search]);

  const onSelectVersion = async (id: string) => {
    setSearch(undefined);
    await fetchVersion(id);
    onSelect(id);
  };

  const onSelectPhase = async (phase: SelectedParent) => {
    const { data } = await fetchDefaultVersion({
      variables: {
        filters: {
          phaseId: phase.vecticeId!,
        },
      },
    });
    if (data?.getIterationList) {
      const version = data.getIterationList.items[0];
      setSelectedVersion(version);
      onSelect(version.vecticeId);
    }
  };

  const clearAsset = () => {
    setSearch(undefined);
    setSelectedVersion(undefined);
    onSelect(null);
  };

  const overlay = (
    <Menu className={styles.menu} preventCloseOnClick>
      <FilterSearch initialSearch={search} onSearch={setSearch} autoFocus />
      <Divider component="div" />
      <WithLoading loading={loadingPhases} loadingSize={20}>
        {options && (
          <>
            {!options.length && (
              <EmptySelect text={$t({ id: 'phasePicker.noVersion', defaultMessage: 'No phase to show' })} />
            )}
            {options.length > 0 &&
              options.map((phase) => (
                <MenuItem key={`option-${phase.vecticeId}`} leftIcon={PhaseIcon} onClick={() => onSelectPhase(phase)}>
                  <FlexContainer>
                    <Typography variant="callout">{phase.name}</Typography>
                  </FlexContainer>
                </MenuItem>
              ))}
          </>
        )}
      </WithLoading>
    </Menu>
  );

  if (!loadingPhases && search === '' && options?.length === 0) {
    return (
      <Typography className={styles.emptyAssetSelector} weight="semi-bold" color="disabled" variant="callout">
        {$t({ id: 'assetSelector.iterationSelector.empty', defaultMessage: 'No assets available' })}
      </Typography>
    );
  }

  return (
    <WithLoading loading={loadingVersion} skeleton={<Skeleton.Paragraph dark height="25px" width="300px" />}>
      {!selectedVersion && (
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          placement={dropdownPlacement}
          onVisibleChange={(visible) => {
            if (!visible) setSearch('');
          }}
        >
          <Button aria-label="Select asset" className={cn(styles.trigger, triggerClassName)} variant="white">
            <Typography ellipsis color="tertiary">
              {$t({ id: 'assetSelector.selectAnIteration', defaultMessage: 'Select an iteration' })}
            </Typography>
          </Button>
        </Dropdown>
      )}
      {selectedVersion && (
        <FlexContainer gap={18}>
          <Breadcrumbs>
            <Breadcrumb
              aria-label={selectedVersion.phase.name}
              color="primary"
              icon={getVecticeResourceTypeIcon(assetType)}
              iconSize={20}
              inert
              weight="regular"
            >
              {selectedVersion.phase.name}
            </Breadcrumb>
            <Breadcrumb color="primary" inert>
              <IterationPicker
                dropdownClassName={styles.menu}
                dropdownPlacement="bottomRight"
                unavailableAssetIds={unavailableAssetIds}
                phaseId={selectedVersion.phase.vecticeId}
                iterationId={selectedVersion.vecticeId}
                iterationName={selectedVersion.name}
                triggerVariant="callout"
                onSelect={onSelectVersion}
              />
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      )}
    </WithLoading>
  );
};
