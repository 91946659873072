import { ModelVersionRisk } from '../../gql/graphql';
import { BadgeColors } from '../../ui';

type modelVersionRiskType = ModelVersionRisk | string | null;

export const getModelVersionRiskDisplayName = (risk?: modelVersionRiskType) => {
  switch (risk) {
    case 'LOW':
      return $t({ id: 'modelVersionRisk.low.label', defaultMessage: 'Low' });
    case 'MEDIUM':
      return $t({ id: 'modelVersionRisk.medium.label', defaultMessage: 'Medium' });
    case 'HIGH':
      return $t({ id: 'modelVersionRisk.high.label', defaultMessage: 'High' });
    case 'UNDEFINED':
      return $t({ id: 'modelVersionRisk.undefined.label', defaultMessage: 'Undefined' });
    default:
      return risk || $t({ id: 'modelVersionRisk.unknown.label', defaultMessage: 'Undefined' });
  }
};

export const getModelVersionRiskColor = (status?: modelVersionRiskType): BadgeColors => {
  switch (status) {
    case ModelVersionRisk.Low:
      return 'success';
    case ModelVersionRisk.Medium:
      return 'warning';
    case ModelVersionRisk.High:
      return 'danger';
    default:
      return 'default';
  }
};

export const getModelVersionRiskOptions = () => [
  {
    label: getModelVersionRiskDisplayName(ModelVersionRisk.Low),
    value: ModelVersionRisk.Low,
  },
  {
    label: getModelVersionRiskDisplayName(ModelVersionRisk.Medium),
    value: ModelVersionRisk.Medium,
  },
  {
    label: getModelVersionRiskDisplayName(ModelVersionRisk.High),
    value: ModelVersionRisk.High,
  },
  {
    label: getModelVersionRiskDisplayName(ModelVersionRisk.Undefined),
    value: ModelVersionRisk.Undefined,
  },
];
