import { useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import type { AssetSelectorProps } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/specials/ic-project.svg';
import { OrderDirection, Project } from '../../gql/graphql';
import {
  Breadcrumb,
  Breadcrumbs,
  Button,
  Dropdown,
  FlexContainer,
  MenuItem,
  Skeleton,
  Typography,
  WithLoading,
} from '../../ui';
import { MenuWithSearch } from '../../ui-presets';
import { getVecticeResourceTypeIcon, isNil } from '../../utils';

import { GET_PROJECTS_SELECTOR } from './ProjectSelector.query';
import { PROJECT_BY_ID } from './queries';

import styles from './AssetSelector.module.scss';

type SelectedProject = Pick<Project, 'vecticeId' | 'name'>;

export const ProjectSelector = ({
  assetType,
  canClearAsset = true,
  disabled = false,
  selectedAssetId,
  triggerClassName,
  workspaceId,
  onSelect,
}: AssetSelectorProps) => {
  const [selectedVersion, setSelectedVersion] = useState<SelectedProject>();
  const [search, setSearch] = useState<string | undefined>(undefined);

  const { data: projectsData, loading: loadingProjects } = useQuery(GET_PROJECTS_SELECTOR, {
    skip: !workspaceId || disabled,
    variables: {
      workspaceId: workspaceId!,
      filters: {
        search,
      },
      order: { field: 'updatedDate', direction: OrderDirection.Desc },
      page: {
        size: 10,
        index: 1,
      },
    },
  });
  const options = projectsData?.getProjectList.items;

  const { loading } = useQuery(PROJECT_BY_ID, {
    skip: !selectedAssetId,
    variables: { projectId: selectedAssetId! },
    onCompleted: (data) => setSelectedVersion(data.getProjectById),
  });

  useEffect(() => {
    if (isNil(selectedAssetId)) {
      setSelectedVersion(undefined);
    }
  }, [selectedAssetId]);

  const clearAsset = () => {
    setSearch(undefined);
    setSelectedVersion(undefined);
    onSelect(null);
  };

  const overlay = (
    <MenuWithSearch
      search={search}
      onSearch={setSearch}
      loading={loadingProjects}
      isEmpty={!options || !options.length}
    >
      {options?.map((project) => (
        <MenuItem
          key={`option-${project.vecticeId}`}
          leftIcon={ProjectIcon}
          onClick={() => {
            setSearch(undefined);
            setSelectedVersion(project);
            onSelect(project.vecticeId);
          }}
        >
          <FlexContainer>
            <Typography variant="callout">{project.name}</Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  if (!loadingProjects && search === '' && options?.length === 0) {
    return (
      <Typography className={styles.emptyAssetSelector} variant="callout" color="disabled" weight="semi-bold">
        {$t({ id: 'assetSelector.projectSelector.empty', defaultMessage: 'No assets available' })}
      </Typography>
    );
  }

  return (
    <WithLoading loading={loading} skeleton={<Skeleton.Paragraph dark height="22px" width="300px" />}>
      {!selectedVersion && (
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={(visible) => {
            if (!visible) setSearch('');
          }}
        >
          <Button
            aria-label="Select asset"
            className={cn(styles.trigger, triggerClassName, { [styles.disabled]: disabled })}
            disabled={disabled}
            variant="white"
          >
            <Typography ellipsis color="tertiary">
              {$t({ id: 'assetSelector.selectAProject', defaultMessage: 'Select a project' })}
            </Typography>
          </Button>
        </Dropdown>
      )}
      {selectedVersion && (
        <FlexContainer gap={18}>
          <Breadcrumbs>
            <Breadcrumb
              aria-label={selectedVersion.name}
              color="primary"
              icon={getVecticeResourceTypeIcon(assetType)}
              iconSize={20}
              inert
              weight="regular"
            >
              {selectedVersion.name}
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      )}
    </WithLoading>
  );
};
