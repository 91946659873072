import { useLazyQuery, useQuery } from '@apollo/client';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';

import type { AssetSelectorProps } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { ReactComponent as DatasetIcon } from '../../assets/icons/specials/ic-dataset.svg';
import { DataSet, DataSetVersion } from '../../gql/graphql';
import { DATASET_VERSION } from '../../graphql/queries';
import { buildOrder } from '../../graphql/utils';
import {
  Breadcrumb,
  Breadcrumbs,
  Button,
  Dropdown,
  FlexContainer,
  Input,
  MenuItem,
  Skeleton,
  Typography,
  WithLoading,
} from '../../ui';
import { MenuWithSearch } from '../../ui-presets';
import { getVecticeResourceTypeIcon, isDatasetId, isDatasetVersionId, isNil } from '../../utils';

import { DatasetVersionPicker } from './DatasetVersionPicker';
import { DATASETS, DATASET_VERSIONS } from './queries';

import styles from './AssetSelector.module.scss';

type SelectedDatasetVersion = Pick<DataSetVersion, 'vecticeId' | 'versionNumber'> & {
  dataSet: Pick<DataSet, 'vecticeId' | 'name'>;
};

const DEBOUNCE_TIMEOUT = 600;

export const DatasetVersionSelector = ({
  assetType,
  canClearAsset = true,
  disabled,
  dropdownPlacement = 'bottomLeft',
  fullWidth,
  projectId,
  selectedAssetId,
  showInput = true,
  triggerClassName,
  unavailableAssetIds,
  onSelect,
}: AssetSelectorProps) => {
  const [IDInputError, setIDInputError] = useState<string>();
  const [IDInputLoading, setIDInputLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedVersion, setSelectedVersion] = useState<SelectedDatasetVersion>();
  const [versionId, setVersionId] = useState<string | undefined>(undefined);

  const [fetchDefaultVersion, { loading: loadingDefaultVersion }] = useLazyQuery(DATASET_VERSIONS, {
    onError: () => setIDInputError($t({ id: 'DatasetVersionSelector.notFound', defaultMessage: 'Dataset not found' })),
  });
  const [fetchSelectedVersion, { loading: loadingSelectedVersion }] = useLazyQuery(DATASET_VERSION, {
    onError: () => setIDInputError($t({ id: 'DatasetVersionSelector.notFound', defaultMessage: 'Dataset not found' })),
  });
  const loadingVersion = loadingDefaultVersion || loadingSelectedVersion;

  const fetchDataset = async (id: string) => {
    const { data } = await fetchDefaultVersion({
      variables: {
        datasetId: id,
        order: buildOrder([{ field: 'isStarred' }, { field: 'createdDate' }]),
      },
    });
    if (data?.getDatasetVersionsList.items.length) {
      const version = data.getDatasetVersionsList.items[0];
      if (projectId && version && projectId !== version.dataSet.project.vecticeId) {
        setIDInputError(
          $t({ id: 'DatasetVersionSelector.unavailable', defaultMessage: 'This dataset cannot be selected' }),
        );
        return;
      }
      onSelect(version.vecticeId);
      setIDInputError(undefined);
      setSelectedVersion(version);
      setSearch('');
    }
  };

  const fetchVersion = async (id: string, onSelectCb?: (id: string) => void) => {
    const { data } = await fetchSelectedVersion({ variables: { datasetVersionId: id } });
    if (data?.getDatasetVersion) {
      if (projectId && projectId !== data.getDatasetVersion.dataSet.project.vecticeId) {
        setIDInputError(
          $t({ id: 'DatasetVersionSelector.unavailable', defaultMessage: 'This dataset cannot be selected' }),
        );
        return;
      }
      onSelectCb?.(id);
      setIDInputError(undefined);
      setSelectedVersion(data.getDatasetVersion);
    }
  };

  useEffect(() => {
    const fetchSelectedDataset = (id: string) => fetchVersion(id);
    if (selectedAssetId && !selectedVersion) {
      fetchSelectedDataset(selectedAssetId);
    } else if (isNil(selectedAssetId)) setSelectedVersion(undefined);
  }, [selectedAssetId]);

  const { data: datasetData, loading: loadingDatasets } = useQuery(DATASETS, {
    skip: !projectId,
    variables: { projectId: projectId!, search, versionId },
  });
  const options = datasetData?.getDatasetList.items;

  const clearAsset = () => {
    setSearch('');
    onSelect(null);
    setIDInputError(undefined);
    setSelectedVersion(undefined);
  };

  const handleIDInput = async (value: string) => {
    setIDInputError(undefined);
    if (!value) return;
    if (unavailableAssetIds?.find((id) => id.toLowerCase() === value.toLowerCase())) {
      setIDInputError(
        $t({ id: 'DatasetVersionSelector.unavailable', defaultMessage: 'This dataset cannot be selected' }),
      );
      return;
    }
    if (isDatasetVersionId(value)) {
      setIDInputLoading(true);
      await fetchVersion(value, onSelect);
    } else if (isDatasetId(value)) {
      setIDInputLoading(true);
      await fetchDataset(value);
    } else {
      setIDInputError(
        $t({ id: 'DatasetVersionSelector.invalidFormat', defaultMessage: 'Expected format: DTS-XXX or DTV-XXX' }),
      );
    }
    setIDInputLoading(false);
  };

  const handleSearch = (value: string) => {
    if (isDatasetVersionId(value)) {
      setVersionId(value.toUpperCase());
      setSearch('');
    } else {
      setVersionId(undefined);
      if (isDatasetId(value)) {
        setSearch(value.toUpperCase());
      } else {
        setSearch(value);
      }
    }
  };
  const debouncedSearch = debounce(handleSearch, DEBOUNCE_TIMEOUT);

  const overlay = (
    <MenuWithSearch isEmpty={!options?.length} loading={loadingDatasets} search={search} onSearch={debouncedSearch}>
      {options?.map((dataset) => (
        <MenuItem
          leftIcon={DatasetIcon}
          key={`option-${dataset.vecticeId}`}
          onClick={() => fetchDataset(dataset.vecticeId)}
          disabled={unavailableAssetIds?.some((id) => id === dataset.vecticeId)}
        >
          <FlexContainer>
            <Typography variant="callout">{dataset.name}</Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  return (
    <WithLoading
      loading={!IDInputLoading && loadingVersion}
      skeleton={<Skeleton.Paragraph dark height="36px" width="300px" />}
    >
      {selectedVersion ? (
        <FlexContainer className={styles.selectedVersion} gap={16}>
          <Breadcrumbs>
            <Breadcrumb
              aria-label={selectedVersion.dataSet.name}
              color="primary"
              icon={getVecticeResourceTypeIcon(assetType)}
              iconSize={20}
              inert
              weight="regular"
            >
              {selectedVersion.dataSet.name}
            </Breadcrumb>
            <Breadcrumb color="primary" inert>
              <DatasetVersionPicker
                datasetId={selectedVersion.dataSet.vecticeId}
                datasetVersionId={selectedVersion.vecticeId}
                datasetVersionNumber={selectedVersion.versionNumber}
                dropdownClassName={styles.menu}
                dropdownPlacement="bottomRight"
                showStarredFirst
                triggerVariant="callout"
                unavailableAssetIds={unavailableAssetIds}
                onSelect={(value) => fetchVersion(value, onSelect)}
              />
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      ) : (
        <FlexContainer align="baseline" className={cn(styles.selector, { [styles.fullWidth]: fullWidth })} gap={16}>
          <Dropdown
            overlay={overlay}
            trigger={['click']}
            placement={dropdownPlacement}
            onVisibleChange={(visible) => {
              if (!visible) setSearch('');
            }}
          >
            <Button
              aria-label="Select asset"
              className={cn(styles.trigger, triggerClassName, { [styles.disabled]: disabled })}
              disabled={disabled}
              variant="white"
            >
              <Typography ellipsis color="tertiary">
                {$t({ id: 'assetSelector.selectADataset', defaultMessage: 'Select a dataset' })}
              </Typography>
            </Button>
          </Dropdown>
          {showInput && (
            <>
              <Typography
                align="center"
                className={styles.separator}
                color="disabled"
                paragraph
                transform="lowercase"
                variant="callout"
                weight="semi-bold"
              >
                {$t({ id: 'global.or', defaultMessage: 'Or' })}
              </Typography>
              <Input
                aria-label="Dataset ID"
                className={styles.input}
                debounceDelay={DEBOUNCE_TIMEOUT}
                disabled={disabled}
                error={IDInputError}
                loading={IDInputLoading}
                placeholder={$t({ id: 'assetSelector.enterDatasetID', defaultMessage: 'Enter a dataset ID' })}
                onDebouncedChange={handleIDInput}
              />
            </>
          )}
        </FlexContainer>
      )}
    </WithLoading>
  );
};
