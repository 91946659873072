import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as CaretIcon } from '../../../assets/icons/interface/ic-caret-down.svg';
import { ReactComponent as FindingIcon } from '../../../assets/icons/interface/ic-finding.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/ic-project.svg';
import { ReactComponent as WorkspaceIcon } from '../../../assets/icons/specials/ic-workspace.svg';
import { UserRole } from '../../../gql/graphql';
import { useWithFeatureFlags, useWithRoles, useWithWorkspaceCreationRight } from '../../../guards';
import { useRetrievePageContext } from '../../../hooks';
import { useModals } from '../../../modals';
import { Dropdown, FlexContainer, Icon, Menu, MenuItem, message, Typography } from '../../../ui';

import styles from './Links.module.scss';

export const CreateLink = () => {
  const { workspace, project } = useRetrievePageContext();
  const { modelVersionId, reportId } = useParams();
  const { createFinding, createProject, createWorkspace } = useModals();

  const { allowed: canEdit } = useWithRoles({ organizationRole: [UserRole.OrgAdmin, UserRole.Member] });
  const { allowed: canCreateWorkspaces } = useWithWorkspaceCreationRight();
  const { allowed: canCreateFinding } = useWithFeatureFlags({ featureFlag: 'create-finding-from-top-level' });

  const menu = useMemo(
    () => (
      <Menu>
        <MenuItem leftIcon={ProjectIcon} onClick={() => createProject({ workspace })}>
          {$t({ id: 'navbar.links.create.project', defaultMessage: 'Create project' })}
        </MenuItem>
        {canCreateWorkspaces && (
          <MenuItem leftIcon={WorkspaceIcon} onClick={createWorkspace}>
            {$t({ id: 'navbar.links.create.workspace', defaultMessage: 'Create workspace' })}
          </MenuItem>
        )}
        {canCreateFinding && (
          <MenuItem
            leftIcon={FindingIcon}
            onClick={() =>
              createFinding({
                modelVersionId,
                project,
                reportId,
                workspace,
                onSuccess: ({ name }) =>
                  message.success(
                    $t({
                      id: 'issue.modal.create.success',
                      defaultMessage: 'Issue "{name}" created, visible in the Issues page',
                      values: { name },
                    }),
                  ),
              })
            }
          >
            {$t({ id: 'navbar.links.create.issue', defaultMessage: 'Create issue' })}
          </MenuItem>
        )}
      </Menu>
    ),
    [workspace, project, modelVersionId, reportId, canCreateFinding],
  );

  if (!canEdit) {
    return null;
  }

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <button className={styles.link}>
        <FlexContainer gap={8} component="span" wrap="nowrap" className={styles.link_label}>
          <Typography variant="callout" weight="semi-bold" ellipsis>
            {$t({ id: 'navbar.links.create', defaultMessage: 'Create' })}
          </Typography>
          <Icon icon={CaretIcon} size={16} />
        </FlexContainer>
      </button>
    </Dropdown>
  );
};
