import cn from 'classnames';
import React, { useMemo, useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import {
  getModelVersionRiskColor,
  getModelVersionRiskDisplayName,
  getModelVersionRiskOptions,
} from '../../entities/utils';
import { ModelVersionRisk as ModelVersionRiskEnum } from '../../gql/graphql';
import { Badge, Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';

import styles from './RevampedAssetStatus.module.scss';

interface ModelVersionRiskProps extends ButtonProps {
  risk?: ModelVersionRiskEnum;
  handleRiskUpdate: (risk: ModelVersionRiskEnum) => void;
}

export const ModelVersionRisk = ({ risk, handleRiskUpdate, className, ...props }: ModelVersionRiskProps) => {
  const { current: riskOptions } = useRef(getModelVersionRiskOptions());

  const menu = useMemo(
    () => (
      <Menu aria-label="Model version risk menu">
        {riskOptions.map((option) => (
          <MenuItem key={option.value} selected={risk === option.value} onClick={() => handleRiskUpdate(option.value)}>
            <Badge size="sm" color={getModelVersionRiskColor(option.value)}>
              {option.label}
            </Badge>
          </MenuItem>
        ))}
      </Menu>
    ),
    [riskOptions, risk, handleRiskUpdate],
  );

  if (!risk) {
    return null;
  }

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        aria-label="Model version risk select"
        className={cn(styles[getModelVersionRiskColor(risk)], className)}
        {...props}
      >
        {getModelVersionRiskDisplayName(risk)}
      </DropdownTrigger>
    </Dropdown>
  );
};
